import styled from '@emotion/styled';

export const DashboardCrecerComponent = styled.div`
  width: 85%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 20px 0px 0px 0px;
  position: fixed;
  right: 0px;
  overflow: auto;
`;

