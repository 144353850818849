import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import VolunteerLog from './volunteersLogs/VolunteerLog';
import experienceService from '../../services/experienceService';
import ExperienceTeamArea from './volunteersLogs/volunteersArea';
import { Button } from 'semantic-ui-react';

const VolunteersLogs = () => {
  const [inputs, setInputs] = useState({});
  const [experienceTeam, set_experienceTeam] = useState([]);
  const [step, set_step] = useState(1);
  const [loading, set_loading] = useState(false);

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  let query = window.location.search;
  let parsedQueryString = queryString.parse(query);

  useEffect(() => {
    const { identification, phone, serviceArea } = parsedQueryString;
    if (identification && phone && serviceArea) {
      setInputs({ ...inputs, identification, phone, serviceArea });
      getInfo();
    }
  }, []);

  function getInfo() {
    set_loading(true);
    experienceService
      .VolunteerLog({ ...parsedQueryString, ...inputs })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_experienceTeam(response.data.experienceTeam);
          setInputs({
            ...inputs,
            ...(response?.data?.volunteer || {}),
          });
          set_step(2);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  return (
    <section className="AdminTable Voluntarios">
      {step === 2 && <Button onClick={() => set_step(1)}>Atrás</Button>}
      <h1 style={{ textAlign: 'center' }}>Voluntarios de Experiencia</h1>
      {step === 1 && (
        <VolunteerLog
          inputs={inputs}
          setInputs={setInputs}
          getInfo={getInfo}
          parsedQueryString={parsedQueryString}
          set_loading={set_loading}
          loading={loading}
          handleInputs={handleInputs}
        />
      )}
      {step === 2 && (
        <ExperienceTeamArea
          set_step={set_step}
          experienceTeam={experienceTeam}
          inputs={inputs}
          getInfo={getInfo}
          set_loading={set_loading}
          loading={loading}
          setInputs={setInputs}
        />
      )}
    </section>
  );
};

export default VolunteersLogs;
