export const habeasData = (
  <p style={{ margin: '20px 0', lineHeight: '16px' }}>
    <small>
      Al enviar este formulario autoriza el tratamiento y la protección de sus datos personales, con
      el fin de que se le informe acerca de eventos y servicios de forma segura. Así mismo, autorizo
      a la Iglesia Comunidad Cristiana de Fe y Viento Fresco, para que realice sobre la información
      suministrada un tratamiento de información, el cual consistirá, pero sin limitarse, en la
      recolección, uso, archivo y/o supresión de la información suministrada. La Iglesia se
      compromete a realizar la debida protección de los datos personales suministrados con base en
      la Ley 1581 de 2012 y sus decretos reglamentarios, respetando los derechos que los titulares
      poseen y ofreciendo las herramientas necesarias para que estos se garanticen. Igualmente se
      autoriza el uso y tratamiento de fotos y videos con fines comunicativos, en nuestras
      diferentes plataformas digitales y comunicación interna de la iglesia.
      <br />
      Si desea más información,{' '}
      <a
        href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=49981"
        target="_blank"
        rel="noreferrer"
      >
        puede dar click en TÉRMINOS Y CONDICIONES para ampliar la información.
      </a>
    </small>
  </p>
);
