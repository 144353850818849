import axios from 'axios';
import Swal from 'sweetalert2';
import authService from './auth';

let baseURL = process.env.REACT_APP_NODE_ENV;

const instance = axios.create({ baseURL });

instance.interceptors.request.use(
  function onIntercept(config) {
    const token = authService.getSession();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function onError(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function onIntercept(response) {
    return response;
  },
  function onError(error) {
    if (error.response.status === 401) {
      Swal.fire('Vuelve a iniciar sesión.');
      setTimeout(() => {
        authService.logout();
      }, 1000);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
