import React from 'react';
import { Select, Input, Button } from 'semantic-ui-react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import CounselingsRow from '../../components/common/adminTable/counselingRow';
import CounselingModal from '../church-members/counseling/counselingTutorForm';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import membersForms from '../../services/membersForms';
let { counselingProfessionalFields, chPerm } = require('../../components/helpers');

const filters = {
  contacted: [
    { key: 'Si', value: true, text: 'Si' },
    { key: 'No', value: false, text: 'No' },
  ],
};

const Counseling = (props) => {
  const [allCounselings, set_allCounselings] = React.useState([]);
  const [loaded, set_loaded] = React.useState(null);
  const [showCounselings, set_showCounselings] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);
  const [handleDate, set_handleDate] = React.useState({});
  const [focused, set_focused] = React.useState({});

  React.useEffect(() => {
    getInfo();
  }, [
    inputs.professionalContacted,
    inputs.comunidadContacted,
    inputs.professionalField,
    inputs.rangeDate,
  ]);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  function getInfo(page = false) {
    set_loaded(false);
    set_endPage(false);

    let filters = {};
    Object.entries(inputs)
      .filter((input) => input[1] != null)
      .forEach((element) => {
        filters[element[0]] = element[1];
      });

    let body = { filter: { ...filters }, currentPage };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    membersForms
      .counselingList(body)
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);

          if (response.data.conselingVolunteers) {
            counselingProfessionalFields = response.data.conselingVolunteers.map((volunteer) => {
              return `${volunteer.serviceArea} - ${volunteer.name} ${volunteer.lastName}`;
            });
          }

          if (page && body.currentPage > 1)
            set_allCounselings([...allCounselings, ...response.data.counselingList]);
          else set_allCounselings(response.data.counselingList);

          if (response.data.counselingList.length === 0) set_endPage(true);
        } else {
          set_loaded(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  const handleDateChange = (startDate, endDate) => {
    setInputs({
      ...inputs,
      rangeDate: {
        startDate: startDate ? startDate._d.getTime() : null,
        endDate: endDate ? endDate._d.getTime() : null,
      },
    });
    set_handleDate({ startDate, endDate });
  };

  const inputStyles = {
    display: 'inline-block',
    width: '45%',
    margin: ' 7px 0',
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => props.history.goBack()}>Atrás</Button>
        <Button primary onClick={() => getInfo()}>
          Buscar
        </Button>
        <div>
          <Input
            loading={!loaded}
            name={'keyword'}
            onChange={handleInputs}
            value={inputs.keyword}
            className={'crecerp1c3'}
            style={inputStyles}
            onKeyPress={onEnter}
            placeholder="Buscar..."
          />
          {!chPerm(['profesionalCounselor'], true) && (
            <Select
              placeholder="Contactado por Comunidad"
              style={inputStyles}
              onChange={selectHandle}
              loading={!loaded}
              name="comunidadContacted"
              options={[{ key: null, value: null, text: 'Todos' }, ...filters.contacted]}
            />
          )}
          <Select
            placeholder="Profesional asignado"
            style={inputStyles}
            onChange={selectHandle}
            loading={!loaded}
            name="professionalField"
            options={[
              { key: null, value: null, text: 'Todos' },
              ...counselingProfessionalFields.map((opt) => {
                return { key: opt, value: opt, text: opt };
              }),
            ]}
          />
          <Select
            placeholder="Contactado por profesional"
            style={inputStyles}
            onChange={selectHandle}
            loading={!loaded}
            name="professionalContacted"
            options={[{ key: null, value: null, text: 'Todos' }, ...filters.contacted]}
          />
          <DateRangePicker
            startDatePlaceholderText="Fecha Inicio"
            endDatePlaceholderText="Fecha Fin"
            startDate={handleDate.startDate}
            startDateId="startDateRange"
            minimumNights={0}
            onClose={() => getInfo()}
            endDate={handleDate.endDate}
            endDateId="endDateRange"
            onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
            showClearDates
            focusedInput={focused}
            onFocusChange={(focused) => set_focused(focused)}
            isOutsideRange={() => false}
          />
        </div>
      </div>
      <div>
        <p>Total en pantalla: {allCounselings.length}</p>
        <table>
          <thead>
            <tr>
              <th>Fecha de Creación</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Celular</th>
              <th>Tiene grupo</th>
              <th>Prof. Asignado</th>
              <th>Cont. Prof.</th>
              <th>Cont. Comun.</th>
              <th>Ver</th>
            </tr>
          </thead>
          <tbody>
            {allCounselings.map((counseling, i) => (
              <>
                {showCounselings === counseling.id && (
                  <CounselingModal
                    counselingProfessionalFields={counselingProfessionalFields}
                    set_showCounselings={set_showCounselings}
                    showCounselings={showCounselings}
                    counseling={counseling}
                    getInfo={getInfo}
                  />
                )}
                <CounselingsRow counseling={counseling} set_showCounselings={set_showCounselings} />
              </>
            ))}
          </tbody>
        </table>
        <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
          <Button disabled={endPage || !loaded} onClick={() => loadMore()}>
            {loaded ? 'Cargar más' : 'Cargando...'}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Counseling;
