import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import churchForms from '../../services/churchForms';
import authService from '../../services/auth';
import ComunidadNetworksRow from '../../components/common/adminTable/comunidadNetworkRow';
import { Select, Input, Button } from 'semantic-ui-react';
import ComunidadNetworkRegisterFilled from '../../components/comunidad-network/NetworkFilled';
import { selectArea } from '../../components/helpers';

const ComunidadNetworkAdmin = (props) => {
  const [comunidadNetworks, set_ComunidadNetworks] = React.useState([]);
  const [loading, set_loading] = React.useState(true);
  const [showComunidadNetwork, set_showComunidadNetwork] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);
  const currentUser = authService.getCurrentUser();

  React.useEffect(() => {
    getInfo();
  }, [inputs.published]);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  React.useEffect(() => {
    if (showComunidadNetwork === null) getInfo();
  }, [showComunidadNetwork]);

  function getInfo(page = false) {
    set_loading(true);
    let body = {
      published: inputs.published,
      keyword: inputs.keyword,
      personKeyword: inputs.personKeyword,
      currentPage,
    };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    churchForms
      .getComunidadNetwork(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);

          if (page && body.currentPage > 1)
            set_ComunidadNetworks([...comunidadNetworks, ...response.data.network]);
          else set_ComunidadNetworks(response.data.network);

          if (response.data.network.length === 0) set_endPage(true);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(true);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => props.history.goBack()}>Atrás</Button>
        <Button primary onClick={() => getInfo()}>
          Recargar
        </Button>
        <h2>Directorio de Empresas, Vendedores y Servicios</h2>
        <div className="options">
          <div className="search-query">
            <p>Buscar</p>
            <Input
              loading={loading}
              name={'keyword'}
              onChange={handleInputs}
              onKeyPress={onEnter}
              value={inputs.keyword}
              placeholder="Buscar por empresa..."
            />
            <Input
              loading={loading}
              name={'personKeyword'}
              onKeyPress={onEnter}
              onChange={handleInputs}
              value={inputs.personKeyword}
              placeholder="Buscar por propietario..."
            />
          </div>
          <div className="published">
            <p>Publicado</p>
            {selectArea({
              Select,
              options: ['No', 'Si'],
              label: 'Filtrar por publicado',
              name: 'published',
              loading,
              handler: selectHandle,
            })}
          </div>
        </div>
      </div>
      {<p>Total en pantalla: {comunidadNetworks.length}</p>}

      <table>
        <thead>
          <tr>
            <th>Cedula</th>
            <th>Nombre</th>
            <th>Celular</th>
            <th>Empresa</th>
            <th>Producto</th>
            <th>Fecha creación</th>
            <th>Publicado</th>
            <th>Ver</th>
            <th>Eliminar</th>
          </tr>
        </thead>
        <tbody>
          {comunidadNetworks.map((network) => (
            <>
              {showComunidadNetwork === network.id && (
                <ComunidadNetworkRegisterFilled
                  inputs={network}
                  set_showComunidadNetwork={set_showComunidadNetwork}
                />
              )}
              <ComunidadNetworksRow
                network={network}
                getInfo={getInfo}
                currentUser={currentUser}
                set_showComunidadNetwork={set_showComunidadNetwork}
              />
            </>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
        <Button disabled={endPage || loading} onClick={() => loadMore()}>
          {!loading ? 'Cargar más' : 'Cargando...'}
        </Button>
      </div>
    </section>
  );
};

export default ComunidadNetworkAdmin;
