import React, { useEffect } from 'react';
import Form from '../../form/Form';
import Swal from 'sweetalert2';
import groupService from '../../../services/groupService';
import { Button, Input, Select, TextArea } from 'semantic-ui-react';
import { clickableStyles, errorInput, textArea } from '../../helpers';
import { habeasData } from '../../helpers/habeasData';
import { questions } from './questions';
import styled from '@emotion/styled';
import ReCAPTCHA from 'react-google-recaptcha';
import SignatureCanvas from 'react-signature-canvas';

const FormComp = styled('div')`
  width: 80%;
  .Registration__form {
    background: white;
  }
  .form-prospect {
    p {
      text-align: justify;
    }
  }
  p.disciple-name {
    font-weight: bold;
    text-align: center;
    font-size: 32px;
  }
  h2 {
    margin: 30px 0 0 0;
  }
  label {
    margin: 10px 0 0 0;
  }
  .error {
    color: red;
  }
  #transversal {
    textarea {
      &.error {
        border: 1px solid red;
        background-color: #fffcfc;
      }
    }
  }
  .sign {
    margin: 20px 0;
    button {
      margin: 7px auto;
      display: block;
    }
  }
  @media screen and (max-width: 950px) {
    width: 90%;
  }
  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

const ProspectLeadersForm = (props) => {
  const [Attendant, set_Attendant] = React.useState({});
  const [inputs, set_Inputs] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [sent, set_sent] = React.useState(false);
  const [sending, set_sending] = React.useState(false);
  const [showCaptcha, set_showCaptcha] = React.useState(true);
  const [captchaLoaded, set_captchaLoaded] = React.useState(true);
  const [loading, set_loading] = React.useState('Cargando...');

  const { hexaCode } = props.match.params;

  let canvasSign = {};

  const reloadCaptcha = () => {
    set_showCaptcha(false);
    setTimeout(() => set_showCaptcha(true), 500);
  };

  const verifyForm = () => {
    let errorInputs = {};

    let attendantMandatoryInputs = [];

    if (hexaCode) {
      attendantMandatoryInputs = ['name', 'lastName', 'identification', 'phone'];
    }

    attendantMandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: Attendant[input] },
      });
    });

    const mandatoryInputs = Object.values(
      hexaCode ? questions?.prospect : questions?.prospect_leader
    )
      ?.map((a) => a.id)
      .filter((a) => !!a);

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    errorInputs = errorInput({
      errorInputs,
      input: { name: 'sign', value: !canvasSign.isEmpty() },
    });

    if (inputs['captcha'] && inputs['captcha'] !== 'null') {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'captcha',
          value: inputs?.['captcha']?.length >= 10,
        },
      });
    }

    if (Attendant['identification'] && Attendant['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: Attendant['identification'].length >= 5,
        },
      });
    }

    if (Attendant['phone'] && Attendant['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: Attendant['phone'].length >= 10,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  let sendForm = (e) => {
    e.preventDefault();
    if (verifyForm()) return reloadCaptcha();
    set_sending(true);

    let body = {
      Attendant,
      inputs,
      sign: JSON.stringify(canvasSign.toData().flat()),
      hexaCode: hexaCode,
    };
    groupService
      .sendProspectLeaders(body)
      .then((response) => {
        set_sending(false);
        if (response.data.success) {
          set_sent(true);
        }
        Swal.fire(response?.data?.message);
      })
      .catch((error) => {
        set_sending(false);
        Swal.fire(error?.response?.data?.message);
      });
  };

  let getAttendant = (e) => {
    if (hexaCode) {
      let body = { hexaCode };
      groupService
        .getProspectLeader(body)
        .then((response) => {
          set_loading('');
          if (response.data.success) {
            set_Attendant({ ...(response.data.prospectLeader?.Guest || {}) });
          }
        })
        .catch((error) => {
          set_loading(error?.response?.data?.message);
        });
    } else {
      set_loading('');
    }
  };

  useEffect(() => getAttendant(), []);

  const handleAttendant = (event) =>
    set_Attendant({ ...Attendant, [event.target.name]: event.target.value });

  const handleInputs = (event) =>
    set_Inputs({ ...inputs, [event.target.name]: event.target.value });

  const showQuestions = (questions) =>
    questions.map((question) => {
      if (question.type === 'title') {
        return <h2>{question?.text}</h2>;
      }
      if (question.type === 'text') {
        return <p>{question?.text}</p>;
      }
      if (question.type === 'select') {
        return (
          <div>
            <label
              style={{ display: 'block' }}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              htmlFor={question.id}
            >
              {question.text}
            </label>
            <Select
              options={question.options.map((option) => ({
                key: option,
                text: option,
                value: option,
              }))}
              label={question.text}
              name={question.id}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              disabled={sending}
              value={inputs[question.id]}
              onChange={(a, { value }) => set_Inputs({ ...inputs, [question.id]: value })}
              loading={sending}
            />
          </div>
        );
      }
      if (question.type === 'textArea') {
        return (
          <div>
            <label
              style={{ display: 'block' }}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              htmlFor={question.id}
            >
              {question.text}
            </label>
            {textArea({
              TextArea,
              label: question.text,
              name: question.id,
              disabled: sending,
              value: inputs[question.id],
              handler: (event) => handleInputs(event),
              loading: sending,
              errorInputs,
            })}
          </div>
        );
      }
      if (question.type === 'input') {
        return (
          <div>
            <label
              style={{ display: 'block' }}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              htmlFor={question.id}
            >
              {question.text}
            </label>
            <Input
              name={question.id}
              placeholder={question.text}
              className={`${errorInputs[question.id] ? 'error' : ''}`}
              disabled={sending}
              type={question.kind === 'number' ? 'number' : 'text'}
              value={inputs[question.id]}
              onChange={(event) => handleInputs(event)}
              loading={sending}
            />
          </div>
        );
      }
      if (question.type === 'form') {
        return (
          <>
            <h2>{question?.text}</h2>
            <Form Attendant={Attendant} handleInputs={handleAttendant} errorInputs={errorInputs} />
          </>
        );
      }
      return null;
    });

  if (loading)
    return (
      <FormComp>
        <div className="Registration__form">{loading && <h1>{loading}</h1>}</div>
      </FormComp>
    );

  if (sent) {
    return (
      <FormComp>
        <div className="Registration__form">
          {hexaCode ? (
            <>
              <h1>¡Gracias {Attendant.name}!</h1>
              <p>
                Agradecemos profundamente tu sinceridad y el tiempo que has dedicado para responder
                a cada pregunta. El siguiente paso en el proceso es validar tus respuestas con el
                área de discipulado y con tu líder. Si continúas avanzando, se te programará una
                entrevista, ya sea de forma presencial o virtual, para completar el proceso.
              </p>
            </>
          ) : (
            <>
              <h1>¡Gracias {inputs.leader_name}!</h1>
              <p>
                Te agradecemos profundamente por tu sinceridad y el tiempo dedicado a responder cada
                pregunta. El siguiente paso en el proceso es validar tus respuestas con el área de
                discipulado. Si el postulante continúa avanzando en el proceso, se le enviará una
                encuesta adicional para completar la evaluación.
              </p>
            </>
          )}
          <p>En caso contrario, te informaremos sobre los próximos pasos a seguir.</p>
        </div>
      </FormComp>
    );
  }

  return (
    <FormComp>
      <div className="Registration__form">
        <>
          <h1>
            Bienvenid{Attendant.gender === 'Femenino' ? 'a' : 'o'} {Attendant.name}
          </h1>
          {Object.values(errorInputs).includes(true) && (
            <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
          )}
          <div className="form-prospect">
            {hexaCode
              ? showQuestions(questions.prospect)
              : showQuestions(questions.prospect_leader)}
          </div>
          <div className="sign">
            <p>Tu firma</p>
            <SignatureCanvas
              penColor="black"
              ref={(ref) => {
                canvasSign = ref;
              }}
              canvasProps={{
                width: 300,
                height: 200,
                className: `sigCanvas  ${errorInputs['sign'] ? 'error' : ''}`,
              }}
            />
            <p>
              <strong>
                {hexaCode ? (
                  <>
                    {Attendant.name} {Attendant.lastName}
                  </>
                ) : (
                  inputs?.leader_name
                )}
              </strong>
            </p>
            <Button
              style={{ display: 'block', margin: '7px auto' }}
              size="mini"
              onClick={() => canvasSign.clear()}
            >
              Limpiar firma
            </Button>
          </div>
          {habeasData}
          {Object.values(errorInputs).includes(true) && (
            <h4 style={{ color: 'red' }}>Todos los campos son obligatorios.</h4>
          )}
          <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
            {showCaptcha && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={(value) => set_Inputs({ ...inputs, captcha: value })}
                asyncScriptOnLoad={() => set_captchaLoaded(false)}
              />
            )}
            {!captchaLoaded && showCaptcha && (
              <p style={clickableStyles} onClick={reloadCaptcha}>
                Si no carga el captcha, haz clic aqui para obtener uno nuevo.
              </p>
            )}
          </div>
          <Button onClick={(e) => sendForm(e)} primary disabled={sending}>
            Enviar
          </Button>
        </>
      </div>
    </FormComp>
  );
};

export default ProspectLeadersForm;
