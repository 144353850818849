import { css } from '@emotion/react';

export const RegistrationComponent = css`
  .Registration {
    background-color: #fff;
    border-radius: 22px;
    height: auto;
    max-width: 80vw;
    padding: 1.1rem;

    &__title {
      color: #2596c2;
      font-size: 2.5rem;
    }
    &__input {
      padding: 0.3rem 0.5rem 0.3rem 1rem;
      font-size: 20px;
      border-radius: 22px;
      border: none;
      margin: 0.1rem auto;
      &::placeholder {
        color: #a3a3a3;
      }
    }
    &__form {
      text-align: center;
      .dropdown {
        width: 30%;
        margin-top: 15px;
      }
    }
    &__tags {
      p {
        margin: 5px 0 0 0;
      }
      .dia {
        h3 {
          margin: 10px 0;
        }
        width: 40%;
        display: inline-block;
      }
    }
  }
`;
