import moment from 'moment';
import { Button, Image } from 'semantic-ui-react';
import { profileImage } from '../../helpers';

const GeneracionesServiceGuestsRow = (props) => {
  const { guest, history } = props;
  const { GeneracionesGuest, entryConfirmed } = guest;
  const { name, lastName, birthdate, parent, aditionalinformation } = GeneracionesGuest;
  const {
    name: parentName,
    lastName: parentLastName,
    phone,
    identification: parentIdentification,
  } = parent;

  const objectToString = (obj) =>
    Object.entries(obj).reduce(
      (text, el) => (text += `-${el[0].replace(/_/g, ' ')}: <strong>${el[1]}</strong> <br/>`),
      ''
    );

  return (
    <tr>
      <td>
        <Image src={profileImage(GeneracionesGuest)} rounded size="small" />
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {name} {lastName}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{moment().diff(birthdate, 'years')}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {parentName} {parentLastName}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{entryConfirmed ? 'Sí' : 'No'}</p>
      </td>
      <td>
        {Object.keys(aditionalinformation || {}).length ? (
          <div
            dangerouslySetInnerHTML={{
              __html: objectToString(aditionalinformation),
            }}
          />
        ) : (
          <p style={{ margin: '0' }}>No</p>
        )}
      </td>
      <td>
        <Button
          onClick={() =>
            history.push(`/servicio/generaciones?identification=${parentIdentification}`)
          }
        >
          Ver
        </Button>
      </td>
    </tr>
  );
};

export default GeneracionesServiceGuestsRow;
