import { css } from '@emotion/react';

export const GroupsRegistrationComponent = css`
  .group_registration {
    width: 90%;
    padding: 10px;
    margin: 0 auto 0 60px;
    p {
      &.title {
        font-size: 35px;
        margin: 0 0 9px 0;
      }
    }
    .Registration__form {
      margin-top: 20px;
    }
    input + label {
      text-align: start;
    }
    .selectGroup {
      .select {
        display: inline-block;
        cursor: pointer;
        margin: 10px;
        width: 500px;
        h3 {
          margin: 5px 0;
        }
        p {
          margin: 5px 0;
        }
        .members {
          span.one {
            display: inline;
          }
          span.two {
            display: none;
          }
        }
        &:hover {
          .members {
            span.one {
              display: none;
            }
            span.two {
              text-align: center;
              display: block;
              color: black;
            }
          }
        }
      }
    }
    .selectedGroup {
      p {
        margin: 3px 0;
      }
    }
    .presencial {
      padding: 20px;
      .online {
        label {
          padding-top: 7px;
        }
      }
      label {
        padding: 0 20px;
        vertical-align: bottom;
      }
    }
    @media only screen and (max-width: 800px) {
      margin: 0 auto;
      width: 95%;
    }
  }
  @media only screen and (max-width: 800px) {
    .md-fullWidth {
      width: 100% !important;
    }
  }

  .fluid.multiple.selection {
    width: 75% !important;
    margin: 10px auto !important;
    .dropdown.icon {
      width: 60px !important;
    }
  }
`;
