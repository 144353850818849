import { Button } from 'semantic-ui-react';

const ChurchServiceGuestsRow = (props) => {
  const { guest, history, aditionalinformationUsers, inputs, paidEvent } = props;
  const { attended, eventSlugname, Guest, aditionalinformation, payment } = guest;
  const { identification, name, phone, lastName } = Guest;

  const objectToString = (obj) =>
    Object.entries(obj).reduce(
      (text, el) => (text += `-${el[0].replace(/_/g, ' ')}: <strong>${el[1]}</strong> <br/>`),
      ''
    );

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {name} {lastName}
        </p>
      </td>

      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      {!inputs.info && !inputs.payment && (
        <td>
          <p style={{ margin: '0' }}>{attended ? 'Sí' : 'No'}</p>
        </td>
      )}
      {aditionalinformationUsers.length !== 0 && (
        <td>
          {inputs.info ? (
            <div dangerouslySetInnerHTML={{ __html: objectToString(aditionalinformation) }} />
          ) : (
            <p style={{ margin: '0' }}>
              {Object.keys(aditionalinformation || {}).length ? 'Sí' : 'No'}
            </p>
          )}
        </td>
      )}
      {paidEvent && (
        <td>
          {inputs.payment ? (
            <div dangerouslySetInnerHTML={{ __html: objectToString(payment) }} />
          ) : (
            <p style={{ margin: '0' }}>{Object.keys(payment || {}).length ? 'Sí' : 'No'}</p>
          )}
        </td>
      )}
      <td>
        <Button
          onClick={() =>
            history.push(
              `checkin?event=Auditorio_Principal&id=${identification}&eventSN=${eventSlugname}`
            )
          }
        >
          Ver usuario
        </Button>
      </td>
    </tr>
  );
};

export default ChurchServiceGuestsRow;
