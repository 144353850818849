import React from 'react';
import Button from '../../../components/common/Button';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import crecerForms from '../../../services/crecer';
import Clase3Spiritual from './clase3Spiritual';

const RepeatFormCrecerP1C3 = (props) => {
  const [Answer, set_Answer] = React.useState([]);
  const [loaded, set_loaded] = React.useState(null);
  const [inputsLogin, setInputsLogin] = React.useState({});

  function getInfo() {
    if (!inputsLogin.identification || !inputsLogin.phone) return;

    crecerForms
      .RepeatCrecerP1C3Form({ phone: inputsLogin.phone }, inputsLogin.identification)
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);
          set_Answer(response.data.Answer);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const handleLoginInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputsLogin({ ...inputsLogin, [name]: value });
  };

  return (
    <section className="AdminTable">
      {loaded === null ? (
        <form onSubmit={(e) => getInfo(e)} className="Registration__form">
          <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
            Escribe tu cedula y telefono
          </label>
          <input
            placeholder="Cedula"
            type="number"
            name={'identification'}
            className="Registration__input Registration__input-id"
            value={inputsLogin.identification}
            min={0}
            onChange={handleLoginInputs}
            style={{ width: '33%', margin: '5px' }}
          />

          <input
            style={{ width: '33%', margin: '5px' }}
            onChange={handleLoginInputs}
            name={'phone'}
            value={inputsLogin.phone}
            placeholder="Teléfono"
            min={0}
            type="number"
            className="Registration__input Registration__input-identification"
          />
          <Button text="Entrar" disabled={loaded === false} onClick={(e) => getInfo(e)} />
        </form>
      ) : (
        <Clase3Spiritual repeating student={Answer} />
      )}
    </section>
  );
};

export default RepeatFormCrecerP1C3;
