import { useState, useEffect } from 'react';
import { Select, Button } from 'semantic-ui-react';
import {
  changeDocTitle,
  churchMinistries,
  experienceVolunteersRoles,
  formSelectService,
  scrollUp,
  sortObjects,
} from '../../components/helpers';
import ReportButton from '../admin/ReportButton';
import ConfirmedVolunteers from '../experience/volunteersLogs/confirmedVolunteers';
import eventService from '../../services/eventService';
import { DateRangePicker } from 'react-dates';
import moment from '../../services/moment_instance';
import { renderMonthElement } from '../../components/form/Form';
import { Link } from 'react-router-dom';

const AllConfirmedVolunteers = (props) => {
  const [loading, set_loading] = useState(true);
  const [inputs, setInputs] = useState({ eventSlugname: 'Auditorio_Principal' });
  const [services, set_services] = useState([]);
  const [previousEventsNames, set_previousEventsNames] = useState([]);
  const [focused, set_focused] = useState({});
  const [handleDate, set_handleDate] = useState({
    startDate: moment().startOf('day').isoWeekday(1),
    endDate: moment().endOf('day').isoWeekday(8),
  });

  useEffect(() => {
    changeDocTitle('Voluntarios Confirmados');
  }, []);

  useEffect(() => {
    scrollUp();
    getPreviousEventNames();
  }, []);

  const eventSlugname = inputs.eventSlugname || 'Auditorio_Principal';

  useEffect(() => {
    set_loading(true);
    let body = { eventSlugname };
    eventService
      .getAuditoriumEvents(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_services(sortObjects(response.data.events, 'date', 'asc'));
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  }, [eventSlugname]);

  const getPreviousEventNames = () => {
    set_loading(true);
    eventService
      .getEventsNames({ postDays: 150 })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleDateChange = (startDate, endDate) => {
    setInputs((prev) => ({
      ...prev,
      startDate: startDate ? startDate.startOf('day')._d.getTime() : null,
      endDate: endDate ? endDate.endOf('day')._d.getTime() : null,
    }));
    set_handleDate({ startDate, endDate });
  };

  return (
    <section className="AdminTable">
      <div className="AdminTable__content">
        <div>
          <Link to="/admin?folder=volunteers">
            <Button primary>Atrás</Button>
          </Link>
          <ReportButton
            URL={`/experience/VolunteersConfirmedServiceReport`}
            fileName={`reporte_voluntarios_confirmados.xlsx`}
            event="Reporte Voluntarios Confirmados"
            filters={inputs}
          />
        </div>
        <Select
          placeholder="Selecciona el area para consultar."
          style={{
            display: 'inline-block',
            margin: ' 7px 0',
            width: '45%',
          }}
          onChange={selectHandle}
          name="serviceArea"
          loading={loading}
          options={[
            { key: '', value: '', text: 'Todos' },
            ...churchMinistries.sort().map((a) => {
              return { key: a, value: a, text: a };
            }),
          ]}
        />
        <Select
          placeholder="Selecciona el rol para consultar."
          style={{
            display: 'inline-block',
            margin: ' 7px 0',
            width: '45%',
          }}
          onChange={selectHandle}
          name="role"
          loading={loading}
          options={[
            { key: '', value: '', text: 'Todos' },
            ...experienceVolunteersRoles.sort().map((a) => {
              return { key: a, value: a, text: a };
            }),
          ]}
        />
        <div>
          {formSelectService({
            Select,
            label: 'Proximo evento a servir',
            name: 'eventSlugname',
            handler: selectHandle,
            value: eventSlugname,
            options: ['Servicio_del_fin_de_semana', ...previousEventsNames],
          })}
        </div>
        <div style={{ padding: '20px 0 0 0' }}>
          <DateRangePicker
            startDatePlaceholderText="Fecha Inicio"
            endDatePlaceholderText="Fecha Fin"
            startDate={handleDate.startDate}
            startDateId="startDateRange"
            minimumNights={0}
            endDate={handleDate.endDate}
            endDateId="endDateRange"
            onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
            focusedInput={focused}
            onFocusChange={(focused) => set_focused(focused)}
            isOutsideRange={() => false}
            renderMonthElement={renderMonthElement}
          />
        </div>
      </div>
      <div>
        <ConfirmedVolunteers
          history={props.history}
          services={services}
          set_loading={set_loading}
          eventSlugname={inputs.eventSlugname}
          serviceArea={inputs.serviceArea}
          role={inputs.role}
          setInputs={setInputs}
          inputs={inputs}
          handleDate={handleDate}
          showAreaCount
        />
      </div>
    </section>
  );
};

export default AllConfirmedVolunteers;
