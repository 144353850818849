import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import churchForms from '../services/churchForms';
import ComunidadNetworkCard from './comunidad-network/comunidadNetworkCard';
import { Input, Card } from 'semantic-ui-react';
import ComunidadNetworkCatalogModal from './comunidad-network/NetworkCatalogFilled';
import { shuffle } from '../components/helpers';

const ComunidadNetworkCatalog = (props) => {
  const [comunidadNetworks, set_ComunidadNetworks] = React.useState([]);
  const [loading, set_loading] = React.useState(true);
  const [showComunidadNetwork, set_showComunidadNetwork] = React.useState(null);
  const [inputs, setInputs] = React.useState({});

  React.useEffect(() => {
    getInfo();
  }, [inputs]);

  function getInfo() {
    set_loading(true);

    churchForms
      .getComunidadNetwork({
        published: 'Si',
        keyword: inputs.keyword,
        catalog: true,
      })
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          set_ComunidadNetworks(shuffle(response.data.network));
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(true);
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <section className="comunidad-network" id="network">
      <div className="AdminTable__content">
        <h2>Directorio de Empresas, Vendedores y Servicios</h2>
        <p className="salute">
          La Iglesia Comunidad Cristiana de Fe y Viento Fresco solo está refiriendo una base de
          datos de emprendedores, pero en ningún caso los está recomendando, ni se hace responsable
          de ninguna transacción comercial, laboral o civil entre los mismos.
        </p>
        <p className="salute">
          Oramos a Dios para que entre los emprendedores crezcan las buenas relaciones y obtengas
          bendiciones en todo, basados en Gálatas 6:10 “Así que, según tengamos oportunidad, hagamos
          bien a todos, y mayormente a los de la familia de la fe”.
        </p>
        <div className="options">
          <div className="search-query catalog">
            <p>Buscar</p>
            <Input
              loading={loading}
              name={'keyword'}
              onChange={handleInputs}
              value={inputs.keyword}
              placeholder="Buscar..."
            />
          </div>
        </div>
      </div>
      <Card.Group>
        {comunidadNetworks.map((network) => (
          <>
            {showComunidadNetwork === network.id && (
              <ComunidadNetworkCatalogModal
                inputs={network}
                set_showComunidadNetwork={set_showComunidadNetwork}
              />
            )}
            <ComunidadNetworkCard
              network={network}
              set_showComunidadNetwork={set_showComunidadNetwork}
            />
          </>
        ))}
      </Card.Group>
    </section>
  );
};

export default ComunidadNetworkCatalog;
