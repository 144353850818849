import { useEffect, useState } from 'react';
import { Button, Checkbox, Image, Modal, Select } from 'semantic-ui-react';
import styled from '@emotion/styled';
import { churchMinistries, deleteForm, profileImage, sortArray } from '../../components/helpers';
import churchForms from '../../services/churchForms';
import Swal from 'sweetalert2';
import membersForms from '../../services/membersForms';
import { getVolunteerAreas } from '../../components/common/adminTable/NewVolunteersRow';
import experienceService from '../../services/experienceService';
import { ManuallyUpdateGuestLeaders } from './components/manuallyUpdateGuestLeaders';

const ChecksComp = styled.div`
  border-bottom: 1px solid #b6b6b6;
  border-top: 1px solid #b6b6b6;
  padding: 10px 0;
  margin: 20px 0px;
  p {
    margin: 0;
  }
  .button {
    margin: 20px 0 0 0;
  }
`;

const NewVolunteerModal = (props) => {
  const { NewVolunteer = {}, set_showVolunteer, getInfo } = props;
  const {
    identification,
    name,
    lastName,
    phone,
    email,
    gender,
    serviceAreas,
    crecerlevel,
    Volunteers,
    haveAGroup,
    GuestLeader,
    ocupation,
  } = NewVolunteer;
  const [inputs, set_inputs] = useState({});
  const [loading, set_loading] = useState(false);

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_inputs({ ...inputs, [name]: value });
  };

  useEffect(() => {
    set_inputs({
      leadercheck: NewVolunteer.leadercheck,
      training: NewVolunteer.training,
    });
  }, [NewVolunteer]);

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  const addVolunteer = () => {
    set_loading(true);
    const body = {
      identification,
      phone,
      role: 'Voluntario',
      section: 'Pendientes',
      serviceArea: inputs.serviceArea,
    };
    churchForms
      .volunteerForm(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          Swal.fire(response.data.message);
          getInfo?.();
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error && error.response.data) {
          Swal.fire(error.response.data.message);
        }
      });
  };

  const updateNewVolunteer = () => {
    set_loading(true);
    const body = {
      leadercheck: inputs.leadercheck,
      training: inputs.training,
      id: NewVolunteer.id,
    };
    experienceService
      .UpdateNewVolunteersForm(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          Swal.fire(response.data.message);
          getInfo?.();
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error && error.response.data) {
          Swal.fire(error.response.data.message);
        }
      });
  };

  return (
    <Modal open onClose={() => set_showVolunteer(null)}>
      <Modal.Header>
        Aspirante {name} {lastName}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Image
            src={profileImage(NewVolunteer)}
            alt={`Foto_${name}_${lastName}`}
            rounded
            size="small"
          />
          <p style={{ margin: '15px 0' }}>
            <strong>Nombre: </strong>
            {name}
          </p>
          <p>
            <strong>Apellido: </strong>
            {lastName}
          </p>
          <p>
            <strong>Cedula: </strong>
            {identification}
          </p>
          <p>
            <strong>Telefono: </strong>
            {phone}
          </p>
          <p>
            <strong>Email: </strong>
            {email}
          </p>
          <p>
            <strong>Genero: </strong>
            {gender}
          </p>
          <p>
            <strong>Ocupacion: </strong>
            {ocupation}
          </p>
          {serviceAreas?.length && (
            <p>
              <strong>Areas de interés: </strong>
              {serviceAreas?.replace(/_/g, ' ')}
            </p>
          )}
          <p>
            <strong>Pasos de crecer: </strong>
            {crecerlevel?.replace(/_/g, ' ')}
          </p>
          <Button
            primary
            onClick={() => {
              const win = window.open(
                `/checkin?event=crecer${identification ? `&id=${identification}` : ''}`,
                '_blank'
              );
              win.focus();
            }}
          >
            Ir a crecer
          </Button>
          <p>
            <strong>Tiene un grupo: </strong>
            {haveAGroup || GuestLeader?.leaderPhone ? 'Si' : 'No'}
          </p>
          {GuestLeader && (
            <p>
              <strong>Lider: </strong>
              <p>
                {GuestLeader.leaderFullName} - {GuestLeader.leaderPhone}
              </p>
            </p>
          )}
          <ManuallyUpdateGuestLeaders
            set_loading={set_loading}
            loading={loading}
            GuestLeader={GuestLeader}
            guest={NewVolunteer}
            getInfo={getInfo}
          />
          <p>
            <strong>Ya es voluntario: </strong>
            {!!Volunteers?.length ? getVolunteerAreas(Volunteers)?.join(', ') : 'No'}
          </p>
          <ChecksComp>
            <p>Aprobado por su lider</p>
            <Select
              placeholder={`Aprobad${gender === 'Masculino' ? 'o' : 'a'} por su lider`}
              onChange={selectHandle}
              style={{ margin: '0 0 20px 0' }}
              value={inputs?.leadercheck}
              name={'leadercheck'}
              loading={loading}
              options={['Sin Consultar', 'Aprobado', 'No aprobado'].map((a) => ({
                key: a,
                value: a,
                text: a,
              }))}
            />
            <div>
              <Checkbox
                label="¿Asistió al entrenamiento?"
                name="training"
                checked={inputs.training}
                toggle
                onClick={() => set_inputs({ ...inputs, training: !inputs.training })}
              />
            </div>
            <Button loading={loading} onClick={updateNewVolunteer}>
              Enviar cambios
            </Button>
          </ChecksComp>
          {Boolean(Volunteers.length) ? (
            <Button
              secondary
              onClick={() => {
                const win = window.open(`/all-volunteers?keyword=${identification}`, '_blank');
                win.focus();
              }}
            >
              Ir a ver en voluntarios
            </Button>
          ) : (
            <>
              <h2>Acciones</h2>
              <p style={{ margin: 0 }}>
                Agregar a un equipo de experiencia <br />{' '}
                <small>
                  Esta acción agrega al voluntario a una lista del coordinador y el debe
                  introducirlo activamente.
                </small>
              </p>
              <Select
                placeholder={'Area de Servicio'}
                onChange={selectHandle}
                style={{ margin: '0 0 20px 0' }}
                value={inputs?.serviceArea}
                name={'serviceArea'}
                loading={loading}
                options={sortArray(churchMinistries).map((a) => ({
                  key: a,
                  value: a,
                  text: a,
                }))}
              />
              <Button loading={loading} onClick={addVolunteer}>
                Agregar
              </Button>
              <div>
                <p style={{ margin: 0 }}>
                  Sacar al aspirante <br />{' '}
                  <small>Esta acción oculta al aspirante de esta lista.</small>
                </p>
                <Button
                  loading={loading}
                  onClick={() =>
                    deleteForm({
                      subtitle: name,
                      Swal,
                      func: ({ text, successAlert }) => {
                        membersForms
                          .SDeleteNewVolunteersForm({ id: NewVolunteer.id, deleted: text })
                          .then((response) => {
                            if (response.data.success) {
                              successAlert();
                              getInfo();
                            } else {
                              Swal.fire(response.data.message);
                            }
                          })
                          .catch((error) => {
                            if (error.response) Swal.fire(error.response.data.message);
                          });
                      },
                    })
                  }
                >
                  Sacar
                </Button>
              </div>
            </>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => set_showVolunteer(null)} primary>
          Salir
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default NewVolunteerModal;
