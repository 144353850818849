import { useEffect, useState } from 'react';
import ExperienceTeamAreaRow from '../../../components/common/adminTable/ExperienceTeamAreaRow';
import experienceService from '../../../services/experienceService';
import { Button, Checkbox } from 'semantic-ui-react';
import { getOneEventPerDay } from './volunteersArea';
import { clickableStyles } from '../../../components/helpers';
import { cancelThisWeekendService, thisWeekendService } from './confirmationHelpers';

const VolunteersBySection = ({
  volunteers,
  history,
  set_loading,
  loading,
  eventSlugname,
  hola,
  getInfo,
  services,
  inputs,
}) => {
  const [volunteersByEvent, set_volunteersByEvent] = useState([]);
  const [volunteersRowChecks, set_volunteersRowChecks] = useState([]);
  const [showImgs, set_showImgs] = useState(true);

  useEffect(() => {
    getVolunteersByEvent();
  }, [services]);

  const getVolunteersByEvent = () => {
    set_loading(true);
    experienceService
      .getAllVolunteerServiceLogs({
        eventSlugname,
      })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_volunteersRowChecks([]);
          set_volunteersByEvent(response.data.volunteersByEvent);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const service = getOneEventPerDay(services)?.find((a) => inputs.event_id === a?.id);

  return (
    <div style={{ margin: '20px 0 0 0' }}>
      {volunteersRowChecks.length > 0 && (
        <>
          <p style={{ margin: '20px 0 0 0' }}>
            Seleccionados: {volunteersRowChecks.length} -{' '}
            <span style={clickableStyles} onClick={() => set_volunteersRowChecks([])}>
              Limpiar
            </span>
          </p>
          <Button
            primary
            disabled={loading}
            onClick={() =>
              thisWeekendService(
                volunteersRowChecks,
                service,
                eventSlugname,
                {},
                getVolunteersByEvent,
                set_loading
              )
            }
          >
            Confirmar seleccionados
          </Button>
          <Button
            disabled={loading}
            onClick={() =>
              cancelThisWeekendService(
                volunteersRowChecks.map((volunteer) => volunteer.volunteer_id),
                service,
                eventSlugname,
                {},
                getVolunteersByEvent,
                set_loading
              )
            }
          >
            Cancelar seleccionados
          </Button>
        </>
      )}
      <Button primary onClick={() => set_showImgs(!showImgs)}>
        {showImgs ? 'Ocultar' : 'Mostrar'} fotos
      </Button>
      <table>
        <thead>
          <tr>
            <th>
              <Checkbox
                checked={
                  volunteersRowChecks?.length === volunteers?.filter((v) => !v.suspended)?.length
                }
                onChange={() => {
                  if (
                    volunteersRowChecks?.length !== volunteers?.filter((v) => !v.suspended)?.length
                  ) {
                    set_volunteersRowChecks(
                      volunteers
                        ?.filter((v) => !v.suspended)
                        ?.map((volunteer) => ({
                          guest_id: volunteer?.Guest?.id,
                          volunteer_id: volunteer?.id,
                        }))
                    );
                  } else {
                    set_volunteersRowChecks([]);
                  }
                }}
              />
            </th>
            <th>Cedula</th>
            {showImgs && <th>Foto</th>}
            <th>Nombre</th>
            <th>Celular</th>
            <th>Rol</th>
            <th>Lideres</th>
            <th>Activo</th>
            <th>Editar</th>
            <th>Sirve en este evento</th>
          </tr>
        </thead>
        <tbody>
          {volunteers.map((volunteer) => (
            <ExperienceTeamAreaRow
              volunteerReserved={volunteersByEvent
                ?.find?.((event) => inputs.event_id === event?.id)
                ?.EventVolunteers?.some(
                  (reserved) => Number(reserved.volunteer_id) === Number(volunteer?.id)
                )}
              set_volunteersRowChecks={set_volunteersRowChecks}
              getVolunteersByEvent={getVolunteersByEvent}
              volunteersRowChecks={volunteersRowChecks}
              eventSlugname={eventSlugname}
              set_loading={set_loading}
              volunteer={volunteer}
              updateData={getInfo}
              mainInputs={inputs}
              showImgs={showImgs}
              history={history}
              loading={loading}
              service={service}
              showLeaders
              showActive
              showEdit
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VolunteersBySection;
