import React from 'react';
import { Button } from 'semantic-ui-react';
import moment from 'moment';
import eventService from '../../../../services/eventService';
import Swal from 'sweetalert2';
import printQueueService from '../../../../services/printQueue';
import { addHours, eventSlugnameTrim } from '../../../../components/helpers';
import createHistory from 'history/createBrowserHistory';
import PaymentInformation from './event-modals/paymentInformation';
import AdditionalInfoShow from './event-modals/additionalInfoShow';

const EntryCheckpoint = (props) => {
  const { Attendant, set_errorData, reserved, getAttendantInfo } = props;
  const [loading, set_loading] = React.useState(false);
  const [paymentInfoModal, set_paymentInfoModal] = React.useState(false);
  const [addInfoModal, set_addInfoModal] = React.useState(false);

  const inHours = (date) => addHours(-1, date) < new Date() && new Date() < addHours(1, date);

  const ifAttendedAnEvent = () => {
    const resLength = Attendant.allReservations.length;
    if (resLength > 0) {
      for (let i = 0; i < resLength; i++) {
        if (Attendant.allReservations[i]['AuditoriumGuests.attended'] === null) {
          return Attendant.allReservations[i];
        }
      }
    }
    return Attendant.reserved;
  };

  const reload = (route) => {
    const history = createHistory();
    history.push(route);
    history.go(0);
  };

  const markAttendancePost = (event) => {
    set_loading(true);
    const { eventSlugname } = event;

    let body = {
      audEvent_id: event['AuditoriumGuests.audEvent_id'],
      audGuest_id: event['AuditoriumGuests.id'],
      guest_id: event['AuditoriumGuests.guest_id'],
      eventSlugname,
    };

    eventService
      .markAttendance(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_errorData(response.data.message);
          reload(
            `/checkin?event=Auditorio_Principal&eventSN=${event['AuditoriumGuests.eventSlugname']}`
          );
        } else {
          Swal.fire(response.data.message);
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) {
          if (error.response) set_errorData(error.response.data.message);
          Swal.fire(error.response.data.message);
        }
      });
  };

  function markEntry(event) {
    Swal.fire({
      title: '¿Marcar la entrada?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No, volver',
    }).then((result) => {
      if (result.value) {
        markAttendancePost(event);
      }
    });
  }

  const cancelReservation = ({ identification, phone, name, eventSlugname }) => {
    Swal.fire({
      title: `¿Seguro que desea cancelar la reserva de ${name}?`,
      showCancelButton: true,
      confirmButtonText: 'Si, cancelar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        eventService
          .cancelReservation({
            cancelled: 'admin',
            identification,
            phone,
            eventSlugname,
          })
          .then((response) => {
            if (response.data.success) {
              Swal.fire(response.data.message);
              setTimeout(() => {
                reload(`/checkin?event=Auditorio_Principal&eventSN=${eventSlugname}`);
              }, 3000);
            } else {
              Swal.fire(response.data.message);
            }
          })
          .catch((err) => {
            if (err.response && err.response.data) Swal.fire(err.response.data.message);
          });
      }
    });
  };

  function addToAPrintQueue() {
    const { reserved, name, lastName, identification, phone } = Attendant;

    printQueueService
      .addToAPrintQueue({
        event: reserved['AuditoriumGuests.eventSlugname'],
        name: `${name} ${lastName}`,
        identification,
        info: {
          parentFullname: reserved['AuditoriumGuests.parentFullname'],
          parentIdentification: reserved['AuditoriumGuests.parentIdentification'],
          phone,
          code: Math.random().toString(36).substring(8),
        },
      })
      .then((response) => {
        if (response.data.success) {
          Swal.fire(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
      });
  }

  const reserveInformation = () => {
    return (
      <>
        {Attendant?.allReservations && Attendant?.allReservations.length > 1 ? (
          <>
            {Attendant.allReservations.map((reserve) => (
              <>
                <p
                  style={{
                    margin: '5px 0 0 0',
                    fontSize: '15px',
                  }}
                >
                  <strong>Entrada como participante</strong>
                </p>
                <p style={{ margin: 0 }}>
                  <span style={reserve['AuditoriumGuests.attended'] ? { color: 'red' } : {}}>
                    {reserve['AuditoriumGuests.id']}
                  </span>
                  {' - '}
                  {moment.tz(reserve.date, 'America/Bogota').format('dddd DD [de] MMMM - hh:mm a')}
                </p>
              </>
            ))}
          </>
        ) : (
          <>
            <p style={{ margin: 0 }}>
              {moment
                .tz(ifAttendedAnEvent().date, 'America/Bogota')
                .format('dddd DD [de] MMMM - hh:mm a')}
            </p>
            {!inHours(ifAttendedAnEvent().date) && (
              <p style={{ color: 'red', margin: 0 }}>Entrada fuera de horas.</p>
            )}
          </>
        )}
      </>
    );
  };

  const checkPendingPayment = () => {
    if (
      ifAttendedAnEvent()['paidEvent'] &&
      parseInt(ifAttendedAnEvent().paidEvent) >
        parseInt(ifAttendedAnEvent()?.['AuditoriumGuests.payment']?.amount || 0)
    ) {
      if (Object.keys(ifAttendedAnEvent()?.['AuditoriumGuests.payment'] || {})?.length === 0) {
        return <p style={{ color: 'red', fontSize: '25px' }}>No registra donación</p>;
      }
      if (!!ifAttendedAnEvent()?.['AuditoriumGuests.payment']?.partialPaid) {
        return <p style={{ color: 'red', fontSize: '25px' }}>Hay un pago pendiente</p>;
      }
    }
    return reserveInformation();
  };

  return (
    <>
      {checkPendingPayment()}
      <h2 className="CheckIn__subtitle">Asistencia</h2>
      {Attendant && Attendant.reserved && (
        <>
          {Attendant.reserved['eventSlugname'] && (
            <p style={{ fontWeight: 'bold' }}>
              <small>Evento:</small> {eventSlugnameTrim(Attendant.reserved['eventSlugname'])}
            </p>
          )}
          {Attendant.reserved['AuditoriumGuests.parentFullname'] &&
            Attendant.reserved['AuditoriumGuests.parentIdentification'] &&
            moment().diff(Attendant.birthdate, 'years') < 14 && (
              <>
                <p style={{ fontWeight: 'bold' }}>
                  <span style={{ display: 'block' }}>
                    <small>Nombre del Acudiente:</small>{' '}
                    {Attendant.reserved['AuditoriumGuests.parentFullname']}
                  </span>
                  <small>Cedula del Acudiente:</small>{' '}
                  {Attendant.reserved['AuditoriumGuests.parentIdentification']}
                </p>
                <Button size="mini" onClick={() => addToAPrintQueue()}>
                  Imprimir Entrada Gen.
                </Button>
              </>
            )}
        </>
      )}

      {addInfoModal && (
        <AdditionalInfoShow
          set_addInfoModal={set_addInfoModal}
          aditionalinformation={ifAttendedAnEvent()?.['AuditoriumGuests.aditionalinformation']}
        />
      )}
      {!!Object.keys(ifAttendedAnEvent()?.['AuditoriumGuests.aditionalinformation'] || {})
        .length && (
        <Button onClick={() => set_addInfoModal(true)}>Ver Informacion Adicional</Button>
      )}

      <p style={{ margin: '10px 0 0 0' }}>
        <strong>Codigo de reserva:</strong> {ifAttendedAnEvent()['AuditoriumGuests.id']}
      </p>

      {Attendant.seat && (
        <p className="seatnumber">
          <strong>Asiento:</strong> {Attendant.seat.row}
          {Attendant.seat.column}
        </p>
      )}

      {ifAttendedAnEvent() && ifAttendedAnEvent()['AuditoriumGuests.attended'] ? (
        <>
          <p style={{ color: 'red', marginBottom: 0 }}>Ya marca entrada</p>
          <p style={{ margin: 0 }}>
            <small>Si crees que es un error comunicalo con soporte.</small>
          </p>
        </>
      ) : (
        <>
          {ifAttendedAnEvent()['paidEvent'] &&
          Object.keys(ifAttendedAnEvent()['AuditoriumGuests.payment']).length === 0 ? (
            ''
          ) : (
            <Button primary onClick={() => markEntry(ifAttendedAnEvent())} loading={loading}>
              Marcar Entrada
            </Button>
          )}
          <Button
            color="red"
            loading={loading}
            style={{ margin: '10px' }}
            onClick={(e) => cancelReservation({ ...Attendant, ...reserved })}
          >
            Cancelar Entrada
          </Button>
        </>
      )}

      {ifAttendedAnEvent()['paidEvent'] && (
        <PaymentInformation
          set_paymentInfoModal={set_paymentInfoModal}
          paymentInfoModal={paymentInfoModal}
          getAttendantInfo={getAttendantInfo}
          loading={loading}
          set_loading={set_loading}
          event={ifAttendedAnEvent()}
          prevInputs={Attendant}
        />
      )}
    </>
  );
};

export default EntryCheckpoint;
