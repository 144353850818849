import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Input, Select } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  errorInput,
  eventSlugnameTrim,
  leaderCategories,
  groupCategories,
  seminary,
  clickableStyles,
  avoCaptConstructor,
  kindgrouplist,
  localhostname,
} from '../../helpers';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

const GroupsLogs = (props) => {
  const { inputs, setInputs, leaders, getInfo, parsedQueryString, set_loading, loading } = props;
  const [errorInputs, set_errorInputs] = React.useState({});
  const [filterKindOfGroup, set_filterKindOfGroup] = React.useState(false);
  const [createAGroupAction, set_createAGroupAction] = React.useState(false);

  const verifyForm = () => {
    let mandatoryInputs = ['identification', 'phone'];

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(inputs['identification']) !== parseInt(parsedQueryString.ac)
    ) {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(inputs['identification']) !== parseInt(parsedQueryString.ac)
    ) {
      if (inputs['captcha'] && inputs['captcha'] !== 'null') {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: 'captcha',
            value: inputs['captcha'].length >= 10,
          },
        });
      }
    }

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  React.useEffect(() => {
    if (!parsedQueryString.activityName && inputs.kindgroup)
      props.history.push(`?kindgroup=${inputs.kindgroup}`);
  }, [inputs.kindgroup]);

  React.useEffect(() => {
    if (inputs.identification && inputs.phone && parsedQueryString.ac)
      enterReservation({ preventDefault: () => {} });
    if (parsedQueryString.filterKindOfGroup) {
      set_filterKindOfGroup(true);
      Swal.fire('Selecciona la categoria que deseas filtar y luego presiona entrar');
    }
  }, [inputs.identification]);

  const enterReservation = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    set_loading(true);
    getInfo();
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value });
  }

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  const formSelect = ({ name, label, value, options = [], style = {}, handler = selectHandle }) => (
    <Select
      style={style}
      placeholder={label}
      className={errorInputs[name] ? 'error' : ''}
      onChange={handler}
      value={value}
      name={name}
      id={name}
      options={options.map((opt) => {
        return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
      })}
    />
  );

  let categoriesgrouplist = groupCategories;

  if (inputs.kindgroup === 'Grupo_Seminario') {
    categoriesgrouplist = seminary;
  } else if (inputs.kindgroup === 'Equipo_de_Líderes') {
    categoriesgrouplist = leaderCategories;
  }

  return (
    <>
      <h1>¡Bienvenido{Boolean(leaders) && ' Líder'}!</h1>
      <form onSubmit={(e) => enterReservation(e)} className="Registration__form">
        <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
          Escribe tus datos
        </label>
        {Object.values(errorInputs).includes(true) && (
          <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
        )}
        <Input
          style={{ width: '45%' }}
          onChange={handleInputs}
          name={'identification'}
          value={inputs.identification}
          type="number"
          placeholder="Identificación"
          id="identification"
          className={`Registration__input Registration__input-${'identification'} ${
            errorInputs['identification'] ? 'error' : ''
          }`}
        />
        <Input
          placeholder="Celular"
          id="phone"
          name={'phone'}
          type="number"
          className={`Registration__input Registration__input-${'phone'} ${
            errorInputs['phone'] ? 'error' : ''
          }`}
          value={inputs.phone}
          onChange={handleInputs}
          style={{ width: '45%', margin: '5px' }}
        />
        {!filterKindOfGroup ? (
          <p
            style={{ ...clickableStyles, margin: '0 0 20px 0' }}
            onClick={() => set_filterKindOfGroup(true)}
          >
            Seleccionar tipo de grupo
          </p>
        ) : (
          <div>
            <div
              className="Registration__input"
              style={{
                padding: '0 25px 0 10px',
                width: '45%',
                margin: '5px',
                display: 'inline-block',
              }}
            >
              {formSelect({
                label: 'Tipo de Registro',
                style: { width: '100%', minWidth: '100%' },
                name: 'kindgroup',
                value: inputs.kindgroup,
                errorInputs,
                options: kindgrouplist,
              })}
            </div>
            {formSelect({
              label: 'Categoría',
              name: 'activityName',
              style: { width: '45%', margin: '5px' },
              value: inputs.activityName || '',
              errorInputs,
              options: categoriesgrouplist,
            })}
          </div>
        )}
        {localStorage.getItem('userToken') && (
          <>
            {!createAGroupAction ? (
              <p
                style={{ ...clickableStyles, margin: '0 0 20px 0' }}
                onClick={() => {
                  set_createAGroupAction(true);
                  setInputs({ ...inputs, action: 'Crear un grupo' });
                }}
              >
                ¿Vas a crear un grupo?
              </p>
            ) : (
              <div>
                {formSelect({
                  label: 'Acción',
                  name: 'action',
                  style: { width: '45%', margin: '5px' },
                  value: inputs.action,
                  errorInputs,
                  options: ['Ver grupos', 'Crear un grupo'],
                })}
              </div>
            )}
          </>
        )}

        <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
          {!loading && (
            <ReCAPTCHA
              style={{ margin: 'auto' }}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={captchaOnChange}
            />
          )}
        </div>
        <p style={{ color: 'red' }}>
          {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
        </p>
        <Button primary disabled={loading} onClick={(e) => enterReservation(e)}>
          Entrar
        </Button>
      </form>
    </>
  );
};

export default withRouter(GroupsLogs);
