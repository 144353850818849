export const questions = {
  prospect: [
    { text: '¡Saludos!', type: 'text' },
    {
      text: 'Queremos comenzar expresando nuestro agradecimiento por tomarte el tiempo para responder esta encuesta. Valoramos profundamente tu disposición y el deseo de servir en nuestra iglesia. Esta encuesta ofrece una oportunidad para conocerte mejor y explorar cómo Dios te está guiando en relación con tu interés en asumir un rol de liderazgo.',
      type: 'text',
    },
    {
      text: 'Queremos asegurarte que toda la información compartida se mantendrá en la más estricta confidencialidad. Nuestro objetivo es garantizar que quienes asumen roles de liderazgo estén preparados espiritual, emocional y relacionalmente para guiar a otros de manera efectiva.',
      type: 'text',
    },
    {
      text: 'Entendemos que algunos temas pueden ser personales o difíciles de abordar, pero se te anima a ser lo más honesto posible. No buscamos respuestas perfectas, sino una visión sincera que nos permita ayudarte en tu proceso de crecimiento.',
      type: 'text',
    },
    { text: 'A continuación, te invitamos a responder las siguientes preguntas:', type: 'text' },
    { text: 'Llena tu información', type: 'form', onlyInQuestions: true },
    {
      text: 'Estado Civil',
      type: 'input',
      id: 'last_questions_2',
    },
    {
      text: 'Tu Líder',
      type: 'title',
      onlyInQuestions: true,
    },
    {
      text: 'Nombre de tu lider',
      type: 'input',
      id: 'leader_name',
      onlyInQuestions: true,
    },
    {
      text: 'Teléfono de tu lider',
      type: 'input',
      kind: 'number',
      id: 'leader_phone',
      onlyInQuestions: true,
    },
    { text: 'Área Espiritual', type: 'title' },
    {
      text: '¿Cómo describirías tu relación con Dios en este momento?',
      type: 'textArea',
      id: 'spiritual_0',
    },
    {
      text: '¿Qué prácticas espirituales consideras esenciales en tu vida diaria?',
      type: 'textArea',
      id: 'spiritual_1',
    },
    {
      text: '¿En qué áreas espirituales consideras que has mostrado mayor madurez y en cuáles consideras que necesitas fortalecerte?',
      type: 'textArea',
      id: 'spiritual_2',
    },
    { text: 'Área Emocional', type: 'title' },
    {
      text: '¿Cómo describirías tu área emocional?, ¿cómo las manejas?',
      type: 'textArea',
      id: 'emotional_0',
    },
    {
      text: '¿De qué manera crees que tu vida emocional afecta tu capacidad para liderar a otros?',
      type: 'textArea',
      id: 'emotional_1',
    },
    {
      text: '¿Cómo trabajas en tu bienestar emocional?',
      type: 'textArea',
      id: 'emotional_2',
    },
    { text: 'Área Familiar', type: 'title' },
    {
      text: '¿Cómo describirías tu relación con tu familia y qué papel juegan ellos en tu vida?',
      type: 'textArea',
      id: 'familiar_0',
    },
    {
      text: '¿Cómo integras tu fe en las dinámicas familiares? ',
      type: 'textArea',
      id: 'familiar_1',
    },
    {
      text: '¿Crees que tienes el apoyo necesario de tu familia para asumir un rol de liderazgo en la iglesia?',
      type: 'textArea',
      id: 'familiar_2',
    },
    { text: 'Área Relacional', type: 'title' },
    {
      text: '¿Cómo son tus relaciones interpersonales? (con autoridades, personas de tu grupo, compañeros de trabajo o ministerio, entre otros.)',
      type: 'textArea',
      id: 'relational_0',
    },
    {
      text: '¿Cómo defines tu relación con tu líder?',
      type: 'textArea',
      id: 'relational_1',
    },
    {
      text: '¿Qué es lo que más te cuesta al relacionarte con otras personas?',
      type: 'textArea',
      id: 'relational_2',
    },
    { text: 'Área Sexual', type: 'title' },
    {
      text: '¿Cómo entiendes la sexualidad desde tu perspectiva de fe y cómo la manejas en tu vida personal?',
      type: 'textArea',
      id: 'sexual_0',
    },
    {
      text: '¿Qué importancia le das a la integridad sexual en tu vida y en el rol de liderazgo que aspiras?',
      type: 'textArea',
      id: 'sexual_1',
    },
    {
      text: '¿Tienes o has tenido una lucha sexual (pornografia, masturbación, infidelidad, fornicación, adulterio, homosexualidad, quebrantos sexuales, entre otros)?, ¿Cuál? ¿Hace cuanto? y cuál fue tu última recaída?',
      type: 'textArea',
      id: 'sexual_2',
    },
    { text: 'Para concluir, nos gustaría hacer las siguientes preguntas:', type: 'title' },
    {
      text: '¿Hay alguna área específica en la que sientas que necesitas seguir creciendo o recibir apoyo para ser un líder más efectivo?',
      type: 'textArea',
      id: 'last_questions_0',
    },
    {
      text: '¿Hay algún aspecto de tu carácter que te gustaría compartir y que no hayas mencionado anteriormente?',
      type: 'textArea',
      id: 'last_questions_1',
    },
    {
      text: 'Cuéntanos si el grupo que seas abrir es un grupo de generaciones: entre los 7 a 17 años.',
      type: 'select',
      options: ['Sí', 'No'],
      id: 'last_questions_3',
    },
    {
      text: 'Agradecemos profundamente tu sinceridad y el tiempo que has dedicado para responder a cada pregunta. El siguiente paso en el proceso es validar tus respuestas con el área de discipulado y con tu líder. Si continúas avanzando, se te programará una entrevista, ya sea de forma presencial o virtual, para completar el proceso.',
      type: 'text',
    },
    {
      text: 'Si estás de acuerdo con la información proporcionada, y con la entrevista que se realizará de forma presencial, o virtual con cámara encendida, por favor envía esta encuesta.',
      type: 'text',
    },
    {
      text: 'Si estás de acuerdo con la información proporcionada, por favor envía esta encuesta.',
      type: 'text',
    },
  ],
  prospect_leader: [
    {
      text: 'Líder, Apreciamos profundamente tu labor y liderazgo. Sabemos que tu experiencia y discernimiento han sido vitales para el crecimiento de la iglesia, y valoramos tu opinión en este proceso de liderazgo para tu discípulo.',
      type: 'text',
    },
    {
      text: 'Queremos asegurarte que todo lo que compartas se mantendrá en la más estricta confidencialidad. Nuestro objetivo es asegurar que quienes asumen roles de liderazgo estén preparados espiritualmente, emocionalmente, y relacionalmente para guiar a otros en su proceso.',
      type: 'text',
    },
    {
      text: 'Esta Encuesta es una oportunidad para conocer mejor a tu discípulo desde tu perspectiva. No estamos buscando respuestas perfectas, sino una visión honesta que nos permita apoyar al postulante en su crecimiento.',
      type: 'text',
    },
    {
      text: 'A continuación, te invitamos a responder las siguientes preguntas sobre tu discípulo:',
      type: 'text',
    },
    { text: 'Llena tu información', type: 'title' },
    { text: 'Cual es tu nombre completo', type: 'input', id: 'leader_name' },
    {
      text: 'Cual es tu teléfono',
      type: 'input',
      kind: 'number',
      id: 'leader_phone',
    },
    { text: 'Sobre tu discipulo', type: 'title' },
    {
      text: 'Cual es el nombre completo de tu discipulo',
      type: 'input',
      id: 'prospect_fullName',
      onlyInQuestions: true,
    },
    {
      text: 'Cual es el teléfono de tu discipulo',
      type: 'input',
      kind: 'number',
      id: 'prospect_phone',
      onlyInQuestions: true,
    },
    { text: 'Área Espiritual', type: 'title' },
    {
      text: '¿Cómo describiría el crecimiento espiritual de tu discípulo desde que comenzó contigo el discipulado?',
      type: 'textArea',
      id: 'spiritual_0',
    },
    {
      text: '¿En qué áreas espirituales considera que ha mostrado mayor madurez y en cuáles considera que necesita fortalecer?',
      type: 'textArea',
      id: 'spiritual_1',
    },
    { text: 'Área Emocional', type: 'title' },
    {
      text: '¿Cómo maneja sus emociones en momentos de estrés, presión, o dificultades personales? ¿Puedes compartir un ejemplo específico?',
      type: 'textArea',
      id: 'emotional_0',
    },
    {
      text: '¿Qué cambios significativos ha tenido en la manera en que maneja sus emociones desde que empezó el proceso de discipulado contigo?',
      type: 'textArea',
      id: 'emotional_1',
    },
    { text: 'Área Familiar', type: 'title' },
    {
      text: '¿Cómo describiría la relación de su discípulo con su familia?',
      type: 'textArea',
      id: 'familiar_0',
    },
    {
      text: '¿Cómo son sus relaciones interpersonales? (con autoridades, personas de su grupo, compañeros de trabajo o ministerio, entre otros.)',
      type: 'textArea',
      id: 'relational_0',
    },
    { text: 'Área Relacional', type: 'title' },
    {
      text: '¿Cómo maneja los conflictos interpersonales?',
      type: 'textArea',
      id: 'relational_1',
    },
    {
      text: '¿Cómo defines tu relación con tu discípulo?',
      type: 'textArea',
      id: 'relational_2',
    },
    { text: 'Área Sexual', type: 'title' },
    {
      text: '¿Sabes cómo está su vida sexual?',
      type: 'textArea',
      id: 'sexual_0',
    },
    {
      text: '¿Has evidenciado cuáles luchas sexuales tiene tu discípulo (pornografía, masturbación, infidelidad, fornicación, adulterio, homosexualidad, quebrantos sexuales, entre otros) y cómo lo has acompañado?',
      type: 'textArea',
      id: 'sexual_1',
    },
    {
      text: '¿Hay algo que crea que deba mejorarse o fortalecerse en el ámbito sexual para que esté preparado para un rol de liderazgo?',
      type: 'textArea',
      id: 'sexual_2',
    },
    { text: 'Para concluir, nos gustaría hacer las siguientes preguntas:', type: 'title' },
    {
      text: '¿Hay algún aspecto adicional sobre el carácter de tu discípulo que crees que deberíamos conocer y que no se ha abordado en las preguntas anteriores?',
      type: 'textArea',
      id: 'last_questions_0',
    },
    {
      text: 'Basándote en tus respuestas anteriores, ¿consideras que tu discípulo está preparado para avanzar en el proceso y asumir un rol de liderazgo de manera saludable?',
      type: 'select',
      options: ['Sí', 'No'],
      id: 'last_questions_1',
    },
    {
      text: '¿Porqué?',
      type: 'textArea',
      id: 'last_questions_2',
    },
    {
      text: 'Te agradecemos profundamente por tu sinceridad y el tiempo dedicado a responder cada pregunta. El siguiente paso en el proceso es validar tus respuestas con el área de discipulado. Si el postulante continúa avanzando en el proceso, se le enviará una encuesta adicional para completar la evaluación.',
      type: 'text',
    },
    {
      text: 'En caso contrario, te notificaremos sobre los próximos pasos a seguir.',
      type: 'text',
    },
  ],
};
