import { Button, Input } from 'semantic-ui-react';
import { Numpad } from '../../../../../components/helpers/numpad';

const StepParentIdentification = (props) => {
  const { loading, inputs, handleInputs, getInfo, setInputs } = props;

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  const setPressedIdentification = (key) => {
    if (key === 'del') {
      setInputs({ ...inputs, identification: Number(String(inputs.identification || '').slice(0, -1)) || '' });
      return;
    }
    if (key === 'clear') {
      setInputs({ ...inputs, identification: '' });
      return;
    }
    setInputs({ ...inputs, identification: Number(String(inputs.identification || '') + key) });
  };

  return (
    <>
      <div className="form">
        <label htmlFor="identification">Escribe la cedula del acudiente</label>
        <Input
          loading={loading}
          name={'identification'}
          id={'identification'}
          type="number"
          onChange={handleInputs}
          value={inputs.identification}
          onKeyPress={onEnter}
          placeholder="Cedula acudiente"
        />
        <Button onClick={() => getInfo()}>Ir</Button>
        <Numpad pressed={setPressedIdentification} />
      </div>
    </>
  );
};

export default StepParentIdentification;
