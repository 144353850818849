import md5 from 'md5';
import queryString from 'query-string';
import { useEffect } from 'react';

export const SendCheckoutInfo = (props) => {
  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  const {
    amount,
    currency = 'COP',
    identification = '',
    description,
    buyerEmail,
    buyerFullName,
  } = parsedQueryString;

  const ApiKey = '6qn7upp16lrjn2q0ecj5ede2uc',
    referenceCode = `${Math.random().toString(15).substring(2).substring(0, 10)}${identification}`,
    merchantId = '504372',
    accountId = '505329';

  useEffect(() => {
    if (
      amount &&
      description &&
      buyerEmail &&
      buyerFullName &&
      document.getElementById('payu-form')
    ) {
      const form = document.getElementById('payu-form');
      form.submit();
    }
  }, []);

  const signature = md5(
    ApiKey + '~' + merchantId + '~' + referenceCode + '~' + amount + '~' + currency
  );
  return (
    <div>
      <p>Transfiriendo a PayU para la donación, espera un momento.</p>
      <form
        method="post"
        id="payu-form"
        action="https://checkout.payulatam.com/ppp-web-gateway-payu/"
      >
        <input name="merchantId" type="hidden" value={merchantId} />
        <input name="accountId" type="hidden" value={accountId} />
        <input name="buyerFullName" type="hidden" value={buyerFullName} />
        <input name="description" type="hidden" value={description} />
        <input name="ApiKey" id="ApiKey" type="hidden" value={ApiKey} />
        <input name="referenceCode" type="hidden" value={referenceCode} />
        <input name="amount" type="hidden" value={amount} />
        <input name="currency" type="hidden" value={currency} />
        <input name="signature" type="hidden" value={signature} />
        <input name="test" type="hidden" value="0" />
        <input name="buyerEmail" type="hidden" value={buyerEmail} />
        <input name="Submit" type="submit" value="Ir" />
      </form>
    </div>
  );
};
