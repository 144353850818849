const GroupsComplianceForm = (props) => {
  const { input, selectkindofid } = props;
  return (
    <>
      <div style={{ padding: '20px 0' }}>
        <h4>DATOS PERSONALES</h4>
        <p>
          Yo {input({ input: 'fullName', placeholder: 'Nombre Completo' })}, con documento de
          identificación {selectkindofid()} No.{' '}
          {input({ input: 'identification', type: 'number', placeholder: 'Documento' })}, exonero de
          toda responsabilidad civil, legal y administrativa, a los organizadores, líderes,
          pastores, empleados, representante legal, de la IGLESIA COMUNIDAD CRISTIANA DE FE Y VIENTO
          FRESCO y a la misma institución de todos los daños, perjuicios que surjan por la
          participación en del seminario.
        </p>
        <p>
          Así mismo autorizo en caso de requerirse, que se me preste la atención de primeros
          auxilios.
        </p>
        <p style={{ fontWeight: 'bold' }}>Contacto de emergencia:</p>
        <p>Nombre {input({ input: 'emergencyFullName', placeholder: 'Nombre' })}</p>
        <p>Parentesco {input({ input: 'emergencyRelative', placeholder: 'Parentesco' })}</p>
        <p>Teléfono {input({ input: 'emergencyPhone', placeholder: 'Teléfono' })}</p>
        <p>
          Nombre del lider {input({ input: 'leaderFullName', placeholder: 'Nombre del lider' })}
        </p>
        <p>
          Teléfono del lider {input({ input: 'leaderPhone', placeholder: 'Teléfono del lider' })}
        </p>
      </div>
    </>
  );
};
export default GroupsComplianceForm;
