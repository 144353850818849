import React from 'react';
import { Button, Input, Modal } from 'semantic-ui-react';
import EventService from '../../../../../services/eventService';
import Swal from 'sweetalert2';
import { chPerm, formatCurrency } from '../../../../../components/helpers';

const PaymentInformation = (props) => {
  const {
    set_paymentInfoModal,
    paymentInfoModal,
    loading,
    set_loading,
    prevInputs,
    event,
    getAttendantInfo,
  } = props;
  const [inputs, setInputs] = React.useState({});

  const authTochange = chPerm(['manage_payments']);
  const prevPayInfo = Object.keys(event['AuditoriumGuests.payment']).length > 0;

  React.useEffect(() => {
    if (Object.keys(event['AuditoriumGuests.payment']).length > 0) {
      const { reference, amount } = event['AuditoriumGuests.payment'];
      setInputs({ ...inputs, reference, amount });
    }
  }, [event]);

  const sendInfo = () => {
    const { identification, phone } = prevInputs;

    const payment = {};
    payment.reference = inputs.reference;
    payment.amount = inputs.amount;

    set_loading(true);
    EventService.SetPaymentInfo({
      eventSlugname: event.eventSlugname,
      identification,
      phone,
      payment,
    })
      .then((response) => {
        set_loading(false);
        getAttendantInfo(identification);
        set_paymentInfoModal(false);
        Swal.fire(response.data.message);
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <>
      <Button secondary onClick={() => set_paymentInfoModal(true)} loading={loading}>
        Ver informacion del pago
      </Button>
      {paymentInfoModal && (
        <Modal open onClose={() => set_paymentInfoModal(false)}>
          <Modal.Header>Información del pago</Modal.Header>
          <Modal.Content>
            {prevPayInfo && (
              <div>
                <p style={{ fontWeight: 'bold', margin: '0px 0 0 0' }}>
                  Información del pago actual
                </p>
                <p style={{ margin: '0px 0 0 0' }}>
                  <strong>Referencia: </strong>
                  {event['AuditoriumGuests.payment'].reference}
                </p>
                {parseInt(event.paidEvent) >
                  parseInt(event?.['AuditoriumGuests.payment']?.amount) &&
                  event?.['AuditoriumGuests.payment']?.partialPaid && (
                    <p style={{ margin: '10px 0 10px 0' }}>
                      <strong>Costo Total: </strong>
                      {formatCurrency(parseInt(event.paidEvent))}
                      <br />
                      <strong>
                        Faltante:{' '}
                        {formatCurrency(
                          parseInt(event.paidEvent) -
                            parseInt(event?.['AuditoriumGuests.payment']?.amount || 0)
                        )}
                      </strong>
                    </p>
                  )}
                <p style={{ margin: '0 0 10px 0' }}>
                  <strong>Donado: </strong>
                  {formatCurrency(event['AuditoriumGuests.payment'].amount)}
                </p>
                {event['AuditoriumGuests.payment'].manual && (
                  <p style={{ margin: '0 0 10px 0' }}>
                    <strong>Pago registrado de forma manual</strong>
                  </p>
                )}
              </div>
            )}
            {authTochange && (
              <>
                <div>
                  <p style={{ fontWeight: 'bold' }}>
                    {prevPayInfo ? 'Actualizar' : 'Agregar'} información del pago
                  </p>
                </div>
                <Input
                  placeholder="Referencia de pago"
                  id="reference"
                  name={'reference'}
                  disabled={loading}
                  className={`${'reference'} `}
                  value={inputs.reference}
                  onChange={handleInputs}
                  style={{ width: '33%', margin: '5px' }}
                />
                <Input
                  placeholder="Valor pagado"
                  id="amount"
                  name={'amount'}
                  disabled={loading}
                  type="number"
                  className={`${'amount'} `}
                  value={inputs.amount}
                  onChange={handleInputs}
                  style={{ width: '33%', margin: '5px' }}
                />
                {!prevPayInfo && (
                  <p>
                    Al agregar información del pago se enviará una notificación a la dirección de
                    correo electrónico registrada.
                  </p>
                )}
              </>
            )}
            {!prevPayInfo && !authTochange && <p>No se encuentra información de pago</p>}
          </Modal.Content>
          <Modal.Actions>
            <Button disabled={loading} onClick={() => set_paymentInfoModal(false)}>
              Cerrar
            </Button>
            {authTochange && (
              <Button
                secondary
                disabled={!inputs.reference || !inputs.amount || loading}
                onClick={() => sendInfo()}
                loading={loading}
              >
                Registrar
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default PaymentInformation;
