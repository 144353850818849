import React from 'react';
import { Select, Checkbox, TextArea, Button } from 'semantic-ui-react';
import { textArea } from '../../../../components/helpers';
import crecerForms from '../../../../services/crecer';
import authService from '../../../../services/auth';
import Swal from 'sweetalert2';

const FormforTutors = (props) => {
  const [inputs, setInputs] = React.useState({ ...props.inputs });
  const [loaded, set_loaded] = React.useState(false);

  const currentUser = authService.getCurrentUser();

  const { allTutors, inputsLogin } = props;

  function setTutorReview() {
    set_loaded(true);

    crecerForms
      .setTutorReview({ ...inputs, ...inputsLogin })
      .then((response) => {
        if (response.data.success) {
          set_loaded(false);
          Swal.fire(response.data.message);
        } else {
          set_loaded(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  return (
    <div id="tutorForm">
      <h3 style={{ textAlign: 'center' }}>Formulario del tutor</h3>
      <div style={{ display: 'block' }}>
        <Checkbox
          label="Marcar con cita concertada"
          onChange={(a) => setInputs({ ...inputs, appointment: !inputs.appointment })}
          checked={inputs.appointment}
        />
        {textArea({
          TextArea,
          handler: handleInputs,
          label: 'Observaciones',
          name: 'observations',
          value: inputs.observations,
        })}
        {!inputs.contacted && (
          <Checkbox
            label="El estudiante no está deacuerdo con los resultados (repetir encuesta)"
            onChange={(a) => setInputs({ ...inputs, repeat: !inputs.repeat })}
            checked={inputs.repeat}
          />
        )}
        {!inputs.repeat && (
          <Checkbox
            label="Marcar con cita realizada"
            onChange={(a) => setInputs({ ...inputs, contacted: !inputs.contacted })}
            checked={inputs.contacted}
          />
        )}
      </div>

      {currentUser.profile === 1 && (
        <>
          <h3 style={{ textAlign: 'center', marginTop: '20px' }}>Formulario del administrador</h3>
          <div style={{ display: 'block' }}>
            <p style={{ margin: 0, fontWeight: 'bold' }}>Tutor del encuestado</p>
            <Select
              placeholder="Tutor asignado"
              onChange={selectHandle}
              value={parseInt(inputs.tutor_id)}
              name="tutor_id"
              options={allTutors}
            />
            <Checkbox
              label="Marcar este encuestado como tutor"
              onChange={(a) => setInputs({ ...inputs, tutor: !inputs.tutor })}
              checked={inputs.tutor}
            />
            {inputs.tutor && (
              <Checkbox
                label="Tutor sin limite de estudiantes"
                onChange={(a) => setInputs({ ...inputs, noLimitTutor: !inputs.noLimitTutor })}
                checked={inputs.noLimitTutor}
              />
            )}
          </div>
        </>
      )}
      <div style={{ display: 'block' }}>
        <Button disabled={loaded} primary onClick={(e) => setTutorReview(e)}>
          Enviar
        </Button>
      </div>
    </div>
  );
};

export default FormforTutors;
