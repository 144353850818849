import { css } from '@emotion/react';
import { Clase3Component } from './components/clase3QuestionsStyles';
import { Clase3AnswersComponent } from './components/clase3Answers';
import { AdminTableComponent } from './components/AdminTable';
import { BarcodeComponent } from './components/Barcode';
import { Button } from './components/Button';
import { CertificateComponent } from './components/Certificate';
import { HeaderComponent } from './components/Header';
import { RegistrationComponent } from './components/Registration';
import { TagComponent } from './components/Tag';
import { LayoutComponent } from './Layout';
import { GlobalComponent } from './Global';
import { NetworkComponent } from './components/network';
import { NewmemberComponent } from './components/newmember';
import { VolunteerRegistrationComponent } from './components/volunteerReg';
import { ReservationComponent } from './components/reservation';
import { NewGroupFormComponent } from './components/newGroupForm';
import { CounselingComponent } from './components/counseling';
import { GroupsRegistrationComponent } from './components/groupsRegistration';

export const AppStyles = css`
  ${AdminTableComponent}
  ${LayoutComponent}
  ${Clase3Component}
  ${Clase3AnswersComponent}
  ${BarcodeComponent}
  ${Button}
  ${CertificateComponent}
  ${HeaderComponent}
  ${RegistrationComponent}
  ${TagComponent}
  ${GlobalComponent}
  ${NetworkComponent}
  ${NewmemberComponent}
  ${VolunteerRegistrationComponent}
  ${ReservationComponent}
  ${NewGroupFormComponent}
  ${CounselingComponent}
  ${GroupsRegistrationComponent}
`;
