import React from 'react';
import { Input, Select, Button } from 'semantic-ui-react';
import { churchServices, formSelectService } from '../../components/helpers';
import eventService from '../../services/eventService';
import { GuestIdFormComp } from '../../assets/styles/components/CheckIn';

const GuestIdForm = (props) => {
  const { guestID, set_guestID, getAttendant, loadedData, errorData, text, parsedQueryString } =
    props;
  const [previousEventsNames, set_previousEventsNames] = React.useState(churchServices);
  const [loading, set_loading] = React.useState(false);

  const selectHandle = (a, { value, name }) => {
    props.history.push(`/checkin?event=${parsedQueryString.event}&eventSN=${value}`);
  };

  React.useEffect(() => {
    getPreviousEventNames();
  }, []);

  const getPreviousEventNames = () => {
    set_loading(true);
    const body = {
      postDays: 5,
    };
    eventService
      .getEventsNames(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  return (
    <GuestIdFormComp>
      {text && <h4 dangerouslySetInnerHTML={{ __html: text }} style={{ textAlign: 'center' }} />}
      <form onSubmit={(e) => getAttendant(e)} className="">
        {parsedQueryString.event === 'Auditorio_Principal' && (
          <div>
            {formSelectService({
              Select,
              label: 'Auditorio',
              name: 'eventSlugname',
              handler: selectHandle,
              value: parsedQueryString.eventSN,
              loading,
              style: { width: '100%' },
              options: previousEventsNames,
            })}
          </div>
        )}
        <Input
          autoFocus
          placeholder="Identificación"
          type="number"
          loading={loading}
          min={0}
          className="Registration__input Registration__input-id"
          value={guestID}
          style={{ width: '100%' }}
          onChange={(e) => set_guestID(e.target.value)}
        />
        {errorData && <p style={{ color: 'red', textAlign: 'center' }}>{errorData}</p>}
        <Button
          onClick={(e) => getAttendant(e)}
          type="submit"
          style={{ width: '100%' }}
          disabled={loadedData}
        >
          Ver
        </Button>
      </form>
    </GuestIdFormComp>
  );
};

export default GuestIdForm;
