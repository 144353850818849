import React from 'react';
import guestService from '../../services/guest';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CheckinURL } from '../helpers';
import queryString from 'query-string';
import AttendanceModal from './AttendanceModal';
import authService from '../../services/auth';

const CrecerCheckin = (props) => {
  const {
    CrecerConectarSteps,
    CrecerEncuentroSteps,
    CrecerBaptismSteps,
    CrecerVidaSteps,
    CrecerInfluenceSteps,
  } = props.Attendant;
  const [loadedData, set_loadedData] = React.useState(null);
  const [openModal, set_openModal] = React.useState('');
  const [loading, set_loading] = React.useState(false);
  const [changedDate, set_changedDate] = React.useState(new Date());

  const currentUser = authService.getCurrentUser();

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);
  const eventURL = CheckinURL(parsedQueryString.event);


  const crecerEvent = [
    {
      title: `Conectar`,
      canCreate: currentUser.profile,
      content: CrecerConectarSteps,
      event: `crecerConectar`,
    },
    {
      title: `Encuentro`,
      canCreate: currentUser.profile,
      content: CrecerEncuentroSteps,
      event: `crecerEncuentro`,
    },
    {
      title: `Vida`,
      canCreate: currentUser.profile,
      content: CrecerVidaSteps,
      event: `crecerVida`,
    },
    {
      title: `Influencia`,
      canCreate: currentUser.profile,
      content: CrecerInfluenceSteps,
      event: `crecerInfluence`,
    },
    {
      title: `Bautizo`,
      canCreate: currentUser.profile,
      content: CrecerBaptismSteps,
      event: `crecerBautizo`,
    },
  ];

  React.useEffect(() => {
    setTimeout(() => {
      set_loadedData(null);
    }, 5000);
  }, [loadedData]);

  function updateAttendance({ classNumber, event, today }) {
    let body = {
      guest_id: props.Attendant.id,
      date: changedDate || today,
      classNumber,
      event,
    };
    set_loading(true);

    guestService
      .updateGuestItems({
        body,
        eventURL,
      })
      .then((response) => {
        if (response.data.success) {
          props.set_Attendant(response.data.Attendant);
          set_loadedData('');
        }
        set_loading(false);
      })
      .catch((error) => {
        Swal.fire('Hubo un error');
        set_loadedData('');
        set_loading(false);
      });
  }

  async function createAttendance({ identification = props.Attendant.identification, event }) {
    let body = {
      identification,
      event,
      eventDate: changedDate,
    };

    function postAttendance({ body, eventURL }) {
      set_loading(true);
      return guestService
        .createAttendance({
          body,
          eventURL,
        })
        .then((response) => {
          if (response.data.success) {
            props.set_Attendant(response.data.Attendant);
            set_loadedData(response.data.message);
          }
          set_loading(false);
        })
        .catch((error) => {
          set_loadedData(error.response.data.message);
          set_loading(false);
        });
    }

    postAttendance({ body, eventURL });
  }

  return (
    <>
      <h2 className="CheckIn__subtitle">Asistencia de crecer</h2>
      {loadedData && <p style={{ color: 'red' }}>{loadedData}</p>}
      {crecerEvent.map((event) => (
        <AttendanceModal
        changedDate={changedDate}
          kind={'crecer'}
          loading={loading}
          set_changedDate={set_changedDate}
          title={event.title}
          event={event.event}
          openModal={openModal}
          loadedData={loadedData}
          content={event.content}
          canCreate={event.canCreate}
          eventDates={props.eventDates}
          set_openModal={set_openModal}
          updateAttendance={updateAttendance}
          createAttendance={createAttendance}
          resetForm={props.resetForm || function () {}}
        />
      ))}
    </>
  );
};
export default CrecerCheckin;
