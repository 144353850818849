import React from 'react';
import { Button } from 'semantic-ui-react';
import guestService from '../../../../../services/guest';
import Swal from 'sweetalert2';
import Form from '../../../../../components/form/Form';
import { errorInput } from '../../../../../components/helpers';
import UploadProfilePhoto from '../../../../../components/helpers/guests/upload_photo';

const StepParentCreation = (props) => {
  const [errorInputs, set_errorInputs] = React.useState({});
  const [image, set_image] = React.useState(null);
  const { inputs, handleInputs, set_step, set_loading, loading, getInfo } = props;

  const verifyForm = () => {
    let mandatoryInputs = ['name', 'lastName', 'identification', 'phone', 'email', 'gender'];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5,
        },
      });
    }

    if (inputs['phone'] && inputs['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: inputs['phone'].length >= 10,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const sendParentForm = () => {
    if (verifyForm()) return;

    const { name, lastName, phone, identification, email, birthdate, gender } = inputs;

    const data = new FormData();
    data.append('profile', image ? image : null);
    data.append('name', name);
    data.append('lastName', lastName);
    data.append('identification', identification);
    data.append('gender', gender);
    data.append('phone', phone);
    data.append('email', email);
    data.append('birthdate', birthdate);
    data.append('first_register', 'parentInGeneraciones');

    set_loading(true);
    guestService
      .createGuest(data)
      .then((response) => {
        if (response.data.message) Swal.fire(response.data.message);
        set_loading(false);
        if (response.data.success) {
          getInfo();
        }
      })
      .catch((error) => {
        set_loading(false);
      });
  };

  return (
    <>
      <div className="form registerParent">
        <div>
          {localStorage.getItem('userToken') ? (
            <p>
              No se encuentra nadie con la cédula escrita, por favor verificala y si es correcta,
              entonces crea el acudiente.
            </p>
          ) : (
            <p>
              No se encuentro un registro con la cédula escrita, por favor llena tus datos; en caso
              que la cedula esté mal escrita vuelve atrás.
            </p>
          )}
          <Button size="mini" onClick={() => set_step(1)}>
            Volver y corregir
          </Button>
        </div>
        <UploadProfilePhoto
          name={'image'}
          labelDescription={'una foto'}
          imageFile={image}
          errorInputs={errorInputs}
          filling={true}
          inputs={inputs}
          set_image={set_image}
        />
        <Form
          Attendant={inputs}
          showBirthdate
          handleInputs={handleInputs}
          errorInputs={errorInputs}
          showGender
        />
        <Button loading={loading} onClick={() => sendParentForm()}>
          Ir
        </Button>
      </div>
    </>
  );
};

export default StepParentCreation;
