import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Input, Button, Checkbox } from 'semantic-ui-react';
import { changeDocTitle } from '../../components/helpers';
import ReportButton from '../admin/ReportButton';
import queryString from 'query-string';
import membersForms from '../../services/membersForms';
import { NewVolunteersRowDef } from '../../components/common/adminTable/NewVolunteersRow';
import Table from '../../components/table/aggrid';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const AllNewVolunteers = (props) => {
  const [volunteers, set_volunteers] = React.useState([]);
  const [loaded, set_loaded] = React.useState(false);
  const [showVolunteer, set_showVolunteer] = React.useState(null);
  const [inputs, setInputs] = React.useState({});

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => getInfo(), [inputs.noVolunteer]);

  React.useEffect(() => {
    changeDocTitle('Futuros Voluntarios');
  }, []);

  const onEnter = (e) => {
    if (e.key === 'Enter') getInfo();
  };

  function getInfo(page = false) {
    set_loaded(false);

    const { noVolunteer } = inputs;

    let body = {
      keyword: inputs.keyword || parsedQueryString.keyword,
      noVolunteer,
    };

    membersForms
      .allNewVolunteers({ body })
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);
          props.history.push('?keyword=');
          set_volunteers(response.data.volunteers);
        } else {
          set_loaded(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(false);
      });
  }

  return (
    <section className="AdminTable">
      <>
        <div className="AdminTable__content">
          <div>
            <Link to="/admin?folder=volunteers">
              <Button primary>Atrás</Button>
            </Link>
            <Button primary onClick={() => getInfo()}>
              Recargar
            </Button>
            <Button secondary onClick={() => props.history.push('/nuevo-voluntario')}>
              Inscribir
            </Button>
          </div>
          <div style={{ display: 'block', width: '100%', margin: '20px  0' }}>
            <label
              style={{ display: 'block', margin: '35px 0 0 0', fontWeight: 'bold' }}
              htmlFor="keyword"
            >
              Buscar
            </label>
            <Input
              loading={!loaded}
              name={'keyword'}
              id={'keyword'}
              onChange={(e) => setInputs({ ...inputs, keyword: e.target.value })}
              value={inputs.keyword}
              onKeyPress={onEnter}
              style={{ width: '45%' }}
              placeholder="Buscar..."
            />
          </div>
        </div>
        <div style={{ display: 'block', width: '100%', margin: '20px  0' }}>
          <label
            style={{ display: 'block', margin: '15px 0 0 0', fontWeight: 'bold' }}
            htmlFor="noVolunteer"
          >
            Filtrar por aspirantes aún no voluntarios
          </label>
          <label style={{ padding: '0 15px 0 0' }} htmlFor="noVolunteer">
            Pendientes
          </label>
          <Checkbox
            label="Ver todos"
            id="noVolunteer"
            name="noVolunteer"
            checked={inputs.noVolunteer}
            toggle
            onClick={() => setInputs({ ...inputs, noVolunteer: !inputs.noVolunteer })}
          />
        </div>
        <p>Total en pantalla: {volunteers.length}</p>
        <ReportButton
          URL={`/experience/NewVolunteersReport?noVolunteer=${!!inputs.noVolunteer}`}
          fileName={`reporte_nuevos_voluntarios.xlsx`}
          event="Descargar Reporte"
        />
      </>
      <Table
        rowData={volunteers}
        rowHeight={90}
        columnDefs={NewVolunteersRowDef({
          set_showVolunteer,
          showVolunteer,
          getInfo,
        })}
        pagination={true}
        suppressDragLeaveHidesColumns={false}
      />
    </section>
  );
};

export default AllNewVolunteers;
