import React from 'react';
import reportService from '../../services/reportService';
import Swal from 'sweetalert2';
import { Button, Icon } from 'semantic-ui-react';

const ReportButton = (props) => {
  const [loading, set_loading] = React.useState(false);

  const { URL, fileName, event, filters } = props;

  function getAttendantInfo() {
    set_loading(true);

    reportService
      .getReport({ URL, fileName, body: filters })
      .then((response) => {
        set_loading(false);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  return (
    <Button onClick={() => getAttendantInfo()} disabled={loading}>
      <Icon secondary name="file alternate outline" /> {event}
    </Button>
  );
};

export default ReportButton;
