import { useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { avoCaptConstructor, errorInput, localhostname } from '../../helpers';
import queryString from 'query-string';
import { GuestLoginDashboardComponent } from './styles/GuestLoginStyles';

const GuestLogin = (props) => {
  const { getInfo } = props;
  const [errorInputs, set_errorInputs] = useState({});
  const [Inputs, set_Inputs] = useState({});
  const [loading, set_loading] = useState(false);

  let query = window.location.search;
  let parsedQueryString = queryString.parse(query);

  const enterReservation = async (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    set_loading(true);
    await getInfo(Inputs);
    set_loading(false);
  };

  const verifyForm = () => {
    let mandatoryInputs = ['identification', 'phone'];

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(Inputs.identification) !== parseInt(parsedQueryString.ac)
    ) {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: Inputs[input] },
      });
    });

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(Inputs.identification) !== parseInt(parsedQueryString.ac)
    ) {
      if (Inputs['captcha'] && Inputs['captcha'] !== 'null') {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: 'captcha',
            value: Inputs['captcha'].length >= 10,
          },
        });
      }
    }

    if (Inputs['identification'] && Inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: Inputs['identification'].length >= 5,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_Inputs({ ...Inputs, [name]: value });
  };

  function captchaOnChange(value) {
    set_Inputs({ ...Inputs, captcha: value });
  }

  return (
    <GuestLoginDashboardComponent>
      <div className="Registration">
        <h1>¡Bienvenido!</h1>
        <h4>Por favor ingresa tus datos para ingresar a tu perfil</h4>
        <form onSubmit={enterReservation} className="Registration__form">
          {Object.values(errorInputs).includes(true) && (
            <h4 className="Registration__error-message">Por favor verifica los campos en rojo.</h4>
          )}
          <Input
            onChange={handleInputs}
            name={'identification'}
            value={Inputs.identification}
            type="number"
            placeholder="Identificación"
            id="identification"
            className={`Registration__input ${errorInputs['identification'] ? 'error' : ''}`}
          />
          <Input
            placeholder="Celular"
            id="phone"
            name={'phone'}
            type="number"
            className={`Registration__input ${errorInputs['phone'] ? 'error' : ''}`}
            value={Inputs.phone}
            onChange={handleInputs}
          />
          <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
            {!loading && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                style={{ margin: 'auto' }}
                onChange={captchaOnChange}
              />
            )}
          </div>
          <p className="Registration__captcha-error">
            {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
          </p>
          <Button primary disabled={loading} type="submit">
            Entrar
          </Button>
        </form>
      </div>
    </GuestLoginDashboardComponent>
  );
};

export default GuestLogin;
