import React from 'react';
import { Button, Modal, Tab, Segment, Accordion, Checkbox } from 'semantic-ui-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { eventSlugnameTrim } from '../../helpers';
import groupService from '../../../services/groupService';
import { SingleDatePicker } from 'react-dates';
import Swal from 'sweetalert2';

const ViewGroupMembers = (props) => {
  const { group, setOpen, groupsLeaders, getInfo, activeMembers } = props;
  const [info, set_info] = React.useState({});
  const [createAttendance, set_createAttendance] = React.useState(false);
  const [couplesToAttendance, set_couplesToAttendance] = React.useState([]);
  const [focused, set_focused] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [attendanceDate, set_attendanceDate] = React.useState(null);

  React.useEffect(() => {
    let birthdays = [];
    let groups = [];

    if (activeMembers) {
      activeMembers?.forEach((member) => {
        if (member.male) {
          birthdays = [...birthdays, { date: member.male.birthdate, name: member.male.name }];
        }
        if (member.female) {
          birthdays = [...birthdays, { date: member.female.birthdate, name: member.female.name }];
        }
      });
    }
    if (groupsLeaders) {
      groupsLeaders.forEach((group) => {
        const { groupName, activityName, suspended, maleLeader, femaleLeader } = group;
        if (maleLeader) {
          groups = [
            ...groups,
            {
              groupName,
              leaderName: `${maleLeader.name} ${maleLeader.lastName}`,
              activityName,
              suspended,
            },
          ];
        }
        if (femaleLeader) {
          groups = [
            ...groups,
            {
              groupName,
              leaderName: `${femaleLeader.name} ${femaleLeader.lastName}`,
              activityName,
              suspended,
            },
          ];
        }
      });
    }
    set_info({ ...info, birthdays, groups });
  }, []);

  React.useEffect(() => {
    set_couplesToAttendance([]);
    set_attendanceDate(null);
  }, [createAttendance]);

  const birthdays = (
    <Tab.Pane>
      {info.birthdays &&
        info.birthdays
          .sort(
            (a, b) =>
              new Date((a.date || '').slice(5, 10)).getTime() -
              new Date((b.date || '').slice(5, 10)).getTime()
          )
          .map((member) => (
            <p>
              <strong>{member.name}</strong>:{' '}
              {member.date
                ? moment.tz(member.date, 'America/Bogota').format('DD [de] MMMM')
                : 'No registra'}
            </p>
          ))}
      {info.birthdays && info.birthdays.length === 0 && <p>Aún no tienes discipulos registrados</p>}
    </Tab.Pane>
  );

  const leaders = (
    <Tab.Pane>
      <p>Estos son los grupos liderados por tus discipulos</p>
      {info.groups &&
        info.groups.map((group) => (
          <Segment vertical>
            <p style={{ margin: 0 }}>
              <strong>Nombre del grupo:</strong> {group.groupName}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Lider:</strong> {group.leaderName}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Publico:</strong> {eventSlugnameTrim(group.activityName)}
            </p>
            <p style={{ margin: 0 }}>
              <strong>Estado:</strong> {group.suspended ? 'En suspensión' : 'Activo'}
            </p>
          </Segment>
        ))}
      {groupsLeaders && groupsLeaders.length === 0 && (
        <Segment vertical>No tienes discipulos que lideren un grupo</Segment>
      )}
    </Tab.Pane>
  );

  const attendance = activeMembers.map((member) => {
    return {
      key: member.id,
      title: `${member.male ? `${member.male.name} ${member.male.lastName}` : ''} - ${
        member.female ? `${member.female.name} ${member.female.lastName}` : ''
      } (${member.attendance ? member.attendance.length : 0})`,
      content: {
        content: (
          <>
            <p>
              <strong>Fechas:</strong>{' '}
              {member.attendance
                ? member.attendance.map((day) => <span>{day} </span>)
                : 'No registra asistencia'}
            </p>
          </>
        ),
      },
    };
  });

  const attendanceRegister = () => {
    let body = {
      attendanceDate: moment(attendanceDate).format('DD/MM/YYYY'),
      couplesToAttendance,
      group: group.id,
    };
    setLoading(true);

    groupService
      .attendanceRegister(body)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          Swal.fire('Asistencia actualizada');
          set_createAttendance(false);
          getInfo();
        } else {
          Swal.fire('Hubo un error');
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire('Hubo un error');
      });
  };

  const toggleAttendance = (id) => {
    let couplesToAttendanceArr = couplesToAttendance || [];
    if (couplesToAttendance.includes(id)) {
      couplesToAttendanceArr = couplesToAttendanceArr.filter((a) => a !== id);
    } else {
      couplesToAttendanceArr = [...couplesToAttendanceArr, id];
    }
    set_couplesToAttendance(couplesToAttendanceArr);
  };

  const createAttendanceModal = () => (
    <Modal open onClose={() => set_createAttendance(false)}>
      <Modal.Header>Añadir Asistencia</Modal.Header>
      <Modal.Content>
        <p style={{ color: 'red' }}>
          Selecciona la fecha y las personas o parejas para añadirle asistencia
        </p>
        <SingleDatePicker
          date={
            new Date(attendanceDate) === 'Invalid Date' || !attendanceDate
              ? null
              : moment(attendanceDate)
          }
          onDateChange={(newDate) => set_attendanceDate(newDate ? newDate._d : newDate)}
          focused={focused}
          onFocusChange={({ focused }) => set_focused(focused)}
          placeholder="dd/mm/aaaa"
          showDefaultInputIcon={true}
          numberOfMonths={1}
          displayFormat="DD/MM/YYYY"
          isOutsideRange={() => false}
          id="attendanceDate"
        />{' '}
        {new Date(attendanceDate) === 'Invalid Date' || !attendanceDate
          ? null
          : moment(attendanceDate).format('DD-MMMM-YYYY')}
        {activeMembers.map((member) => (
          <Checkbox
            label={`${member.male ? `${member.male.name} ${member.male.lastName}` : ''} - ${
              member.female ? `${member.female.name} ${member.female.lastName}` : ''
            }`}
            name={member.id}
            checked={couplesToAttendance.includes(member.id)}
            style={{ padding: '10px 10px', display: 'block' }}
            onClick={() => toggleAttendance(member.id)}
          />
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => set_createAttendance(false)}>Cerrar</Button>
        <Button
          secondary
          onClick={() => attendanceRegister()}
          loading={loading}
          disabled={!attendanceDate || couplesToAttendance.length === 0}
        >
          Añadir
        </Button>
      </Modal.Actions>
    </Modal>
  );

  const attendanceAccordion = (
    <Tab.Pane>
      <p style={{ margin: 0 }}>Asistencia de tu grupo por persona o pareja</p>
      <div style={{ textAlign: 'end' }}>
        <Button onClick={() => set_createAttendance(true)}>Añadir Asistencia</Button>
      </div>
      <Accordion panels={attendance} />
      {activeMembers && activeMembers.length === 0 && (
        <Segment vertical>Aún no tienes discipulos registrados</Segment>
      )}
    </Tab.Pane>
  );

  const panes = [
    {
      menuItem: 'Cumpleaños',
      render: () => birthdays,
    },
    { menuItem: 'Lideres', render: () => leaders },
    { menuItem: 'Asistencia', render: () => attendanceAccordion },
  ];

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open>
      <Modal.Header>Información de los miembros</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Tab panes={panes} />
          {createAttendance && createAttendanceModal()}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ViewGroupMembers;
