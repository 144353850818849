import React from 'react';
import { Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import generacionesService from '../../../../../../services/generacionesService';
import moment from 'moment';

export const printEntry = (kid, parent, date, category) => {
  const printQueueQuery = {
    category,
    date,
    code: Math.random().toString(36).substring(8),
    identification: kid?.identification,
    name: `${kid?.name} ${kid?.lastName}`,
    parentFullname: `${parent?.name} ${parent?.lastName}`,
    parentIdentification: parent?.identification,
    birthdate: kid?.birthdate,
    phone: parent?.phone,
    observations: kid?.aditionalinformation?.Nota,
  };
  window.open(
    `/checkin/print?${queryString.stringify(printQueueQuery, { sort: false })}`,
    '_blank'
  );
};

export const confirmReservationGeneraciones = (
  eventSlugname,
  getInfo,
  kid,
  parent,
  set_loading
) => {
  set_loading(true);
  generacionesService
    .ConfirmGeneracionesReservation({
      generaciones_guest_id: kid.id,
      eventSlugname,
    })
    .then((response) => {
      if (response.data.success) {
        const category =
          response?.data?.reserved?.['GeneracionesEventsGuests.generacionesCategory'] ||
          'generaciones';
        const date = moment
          .tz(response?.data?.reserved?.date, 'America/Bogota')
          .format('dddd DD [de] MMMM hh:mm a');
        printEntry(kid, parent, date, category);
        Swal.fire(response.data.message);
        getInfo();
      }
    })
    .catch((error) => {
      if (error.response) Swal.fire(error.response.data.message);
    });
};

const ConfirmReservationModal = (props) => {
  const [loading, set_loading] = React.useState(false);

  const { eventSlugname, getInfo, kid, parent } = props;

  return (
    <Button
      primary
      loading={loading}
      onClick={() =>
        confirmReservationGeneraciones(eventSlugname, getInfo, kid, parent, set_loading)
      }
      size="tiny"
    >
      Confirmar e imprimir escarapela
    </Button>
  );
};

export default ConfirmReservationModal;
