import React from 'react';
import CoupleGroupRegistration from './registration/groupRegistration';
import LeaderSelect from './registration/leaderSelect';
import GroupSummary from './registration/groupSummary';
import MemberLogGroup from './registration/memberLogGroup';

const GroupReservation = (props) => {
  const [Step, setStep] = React.useState('check');
  const [inputs, setInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);

  const stepRender = () => {
    if (Step === 'check') {
      return (
        <MemberLogGroup
          setInputs={setInputs}
          parsedQueryString={{}}
          inputs={inputs}
          next="person1"
          setStep={setStep}
          loading={loading}
          set_loading={set_loading}
        />
      );
    } else if (Step === 'person1') {
      return (
        <CoupleGroupRegistration
          setInputs={setInputs}
          inputs={inputs}
          person="person1"
          next="person2"
          Step={Step}
          setStep={setStep}
        />
      );
    } else if (Step === 'person2') {
      return (
        <CoupleGroupRegistration
          setInputs={setInputs}
          inputs={inputs}
          person="person2"
          next="selectLeaders"
          Step={Step}
          setStep={setStep}
        />
      );
    } else if (Step === 'selectLeaders') {
      return (
        <LeaderSelect setInputs={setInputs} inputs={inputs} next="summary" setStep={setStep} />
      );
    } else if (Step === 'summary') {
      return (
        <GroupSummary
          match={props.match}
          setInputs={setInputs}
          inputs={inputs}
          next="summary"
          setStep={setStep}
        />
      );
    }
  };

  return <div className="group_registration">{stepRender()}</div>;
};

export default GroupReservation;
