import styled from '@emotion/styled';

export const DashboardComponent = styled.div`
  background: #1d1d1b;
  height: auto;
  display: flex;
  flex-direction: column;

  .sidebar {
    width: 15%;
    background: #1d1d1b;
    padding: 20px;
    color: white;
    flex-shrink: 0;
    position: fixed;
    left: 0;
    height: 100%;
  }

  .containerImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 1px 2px 10px rgb(255 255 255 / 30%);
    border: 2px solid rgb(65, 65, 65);
    object-fit: cover;
    object-position: center;
  }

  .menuList {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      margin-bottom: 15px;
      text-align: center;
      width: 100%;

      a {
        display: block;
        padding: 10px;
        background-color: #444;
        border-radius: 15px;
        color: white;
        text-decoration: none;
        font-weight: 500;
        white-space: nowrap;

        &:hover {
          background-color: #666;
        }

        &.activePerfil {
          color: #10d8b4;
        }

        &.activeGrupos {
          color: #009700;
        }

        &.activeCrecer {
          color: #FC9133;
        }

        &.activeEventos {
          color: #00d1a4;
        }

        &.activeEncuestas {
          color: #00c9d0;
        }

        &.activeExperiencia {
          color: #10d8b4;
        }

        &.activePerfil,
        &.activeGrupos,
        &.activeCrecer,
        &.activeEventos,
        &.activeEncuestas,
        &.activeExperiencia {
          background-color: white;
        }
      }

      .icon {
        display: none; /* Oculta el icono en modo escritorio */
      }

      .text {
        display: block; /* Muestra el texto en modo escritorio */
      }
    }
  }

  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      height: 60px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 15px 15px 0px 0px;
      background-color: #262421;
      z-index: 1000;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 20px;
    }

    .menuList {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      padding: 0;
      margin: 0;
    }

    .menuList li {
      margin-bottom: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .menuList li a {
      padding: 5px;
      text-align: center;
      background: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .menuList li .icon {
      display: block; /* Muestra el icono en modo móvil */
      font-size: 24px; /* Ajusta el tamaño del icono según sea necesario */
      width: 40px; /* Ajusta el tamaño del círculo */
      height: 40px; /* Ajusta el tamaño del círculo */
      border-radius: 50%;
      background: linear-gradient(45deg, #ff0066, #ffcc00); /* Degradado lineal */
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      }

    .menuList li .text {
      display: none; /* Oculta el texto en modo móvil */
    }
  }
`;
