import { useState, useEffect } from 'react';
import { DashboardCrecerComponent } from './styles/DashboardCrecerComponent';
import membersProgressService from '../../../services/adminService';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import reportService from '../../../services/reportService';
import Swal from 'sweetalert2';

const Crecer = () => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(true);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('2024-06-01');
  const [endDate, setEndDate] = useState('2024-12-31');
  const [classesAttended, setClassesAttended] = useState('16');
  const [step, setStep] = useState('vida');
  const [stepAtt, setStepAtt] = useState('news');
  const [allPeople, setAllPeople] = useState([]);
  const [genders, setGenders] = useState([]);
  const [byClass, setByClass] = useState([]);
  const [allSteps, setAllSteps] = useState([]);
  const [filterByClass, setFilterByClass] = useState([false]);

  const steps = ['Todos', 'vida', 'influencia', 'encuentro', 'bautismo'];
  const stepsAttendance = ['news', 'vida', 'influencia', 'encuentro', 'bautismo'];
  const width = 600;
  const height = 400;
  let lines = 6;
  const lineThickness = 40;
  const baseWidth = 250;
  const spacing = baseWidth / lines / 2;

  let linesArray = [...Array(lines).keys()];

  const filterGenders = (attendants) => {
    const men = attendants.filter((attendant) => attendant.Guest.gender === 'Masculino').length;
    const women = attendants.filter((attendant) => attendant.Guest.gender === 'Femenino').length;
    const unknow = attendants.filter(
      (attendant) =>
        !attendant.Guest.gender ||
        (attendant.Guest.gender !== 'Masculino' && attendant.Guest.gender !== 'Femenino')
    ).length;
    const genders = [
      { name: 'Hombres', value: men },
      { name: 'Mujeres', value: women },
      { name: 'Desconocido', value: unknow },
    ];
    return genders;
  };

  const allCrecerAttendance = () => {
    if (step === 'Todos') {
      membersProgressService
        .getAllCrecerSteps({ startDate, endDate, classesAttended })
        .then((res) => {
          const bautismo = filterGenders(res.data.allSteps.bautismo);
          const conectar = filterGenders(res.data.allSteps.conectar);
          const encuentro = filterGenders(res.data.allSteps.encuentro);
          const influencia = filterGenders(res.data.allSteps.influencia);
          const vida = filterGenders(res.data.allSteps.vida);

          const allBautismo = [
            { name: 'Total Bautismo', value: res.data.allSteps.bautismo.length },
          ];
          const allConectar = [
            { name: 'Total Conectar', value: res.data.allSteps.conectar.length },
          ];
          const allEncuentro = [
            { name: 'Total Encuentro', value: res.data.allSteps.encuentro.length },
          ];
          const allVida = [{ name: 'Total Vida', value: res.data.allSteps.vida.length }];
          const allInfluencia = [
            { name: 'Total Influencia', value: res.data.allSteps.influencia.length },
          ];

          const classVida = [
            { name: 'Filtro clase', value: res.data.allStepsFilteredByClasses.vida.length },
          ];
          const classInfluencia = [
            { name: 'Filtro clase', value: res.data.allStepsFilteredByClasses.influencia.length },
          ];

          const all = [
            { name: 'Conectar', value: conectar, allAttendance: allConectar },
            { name: 'Encuentro', value: encuentro, allAttendance: allEncuentro },
            { name: 'Bautismo', value: bautismo, allAttendance: allBautismo },
            { name: 'Vida', value: vida, allAttendance: allVida, filter: classVida },
            {
              name: 'Influencia',
              value: influencia,
              allAttendance: allInfluencia,
              filter: classInfluencia,
            },
          ];

          setAllSteps(all);
          console.log(all);
        });
    } else {
      membersProgressService
        .getAllCrecerAttendance({ step, startDate, endDate, classesAttended })
        .then((response) => {
          const progressData = response.data || [];
          const attendants = progressData.attendants || [];
          const filterByClass = progressData.filteredByClasses || [];

          if (filterByClass.length !== 0) {
            setFilterByClass(true);
            const allByClass = [{ name: 'filtro por clase ', value: filterByClass.length }];

            setByClass(allByClass);
          } else {
            setFilterByClass(false);
          }

          const genders = filterGenders(attendants);
          setGenders(genders);

          //
          const allPeople = attendants.length;
          const processedData = [{ name: 'Total Personas', value: allPeople }];
          setAllPeople(processedData);

          console.log(progressData);
        })
        .catch((error) => {});
    }
  };

  const fetchData = () => {
    setLoading(true);
    setError(null);
    if (stepAtt === 'news') {
      membersProgressService
        .getNewMembersProgress({ startDate, endDate })
        .then((response) => {
          const progressData = response.data || {};

          const funnelData = [
            { name: 'Nuevos Miembros', value: progressData.newMembers?.length || 0 },
            { name: 'Conectar', value: progressData.conectar?.length || 0 },
            { name: 'Encuentro', value: progressData.encuentro?.length || 0 },
            { name: 'Bautismo', value: progressData.bautismo?.length || 0 },
            { name: 'Vida', value: progressData.vida?.length || 0 },
            { name: 'Influencia', value: progressData.influencia?.length || 0 },
          ];
          console.log(funnelData);

          setData(funnelData);
        })
        .catch((error) => {
          console.error('Error al obtener el progreso de los miembros:', error);
          setError('No se pudieron cargar los datos.');
        })
        .finally(() => {
          setLoading(false);
          setSearch(false);
        });
    } else {
      const step = stepAtt;
      membersProgressService
        .crecerProgress({ startDate, endDate, step })
        .then((res) => {
          console.log(res.data);
          const progressData = res.data;

          if (stepAtt === 'encuentro') {
            const funnelData = [
              { name: 'Encuentro', value: progressData.encuentro?.length || 0 },
              { name: 'Bautismo', value: progressData.bautismo?.length || 0 },
              { name: 'Vida', value: progressData.vida?.length || 0 },
              { name: 'Influencia', value: progressData.influencia?.length || 0 },
            ];
            setData(funnelData);
          }

          if (stepAtt === 'bautismo') {
            const funnelData = [
              { name: 'Bautismo', value: progressData.bautismo?.length || 0 },
              { name: 'Vida', value: progressData.vida?.length || 0 },
              { name: 'Influencia', value: progressData.influencia?.length || 0 },
            ];
            setData(funnelData);
          }

          if (stepAtt === 'vida') {
            const funnelData = [
              { name: 'Vida', value: progressData.vida?.length || 0 },
              { name: 'Influencia', value: progressData.influencia?.length || 0 },
            ];
            setData(funnelData);
          }

          if (stepAtt === 'influencia') {
            const funnelData = [
              { name: 'Influencia', value: progressData.influencia?.length || 0 },
            ];
            setData(funnelData);
          }
        })
        .finally(() => {
          setLoading(false);
          setSearch(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFetchData = () => {
    fetchData();
  };

  const handleAttendance = () => {
    allCrecerAttendance();
  };

  function getAttendantReport() {
    setLoading(true);

    let body = {
      startDate,
      endDate,
      step,
      classesAttended,
    };

    let URL = `/crecer/getAllCrecerAttendanceReports`;
    let fileName = `InformeAsistenciaCrecer_${step}.xlsx`;

    reportService
      .getReport({ URL, fileName, body })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        setLoading(false);
      });
  }

  const COLORS = ['#edd4be', '#242323', '#fc9133', '#8dfce6'];

  console.log(error);
  return (
    <DashboardCrecerComponent>
      <h2 style={{ color: '#009700', fontSize: '40px' }}>Crecer</h2>

      {/* Tabs para seleccionar entre "Nuevos Miembros" y "Todos" */}
      <div>
        <button
          onClick={() => setActiveTab(true)}
          style={{
            background: activeTab ? '#434343' : 'white',
            color: activeTab ? 'white' : '#434343',
            border: '1px solid #434343',
            padding: '10px',
            borderRadius: '5px',
            marginRight: '10px',
          }}
        >
          Asistencia
        </button>
        <button
          onClick={() => setActiveTab(false)}
          style={{
            background: !activeTab ? '#434343' : 'white',
            color: !activeTab ? 'white' : '#434343',
            border: '1px solid #434343',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          Reportes
        </button>
      </div>

      {/* Mostrando contenido según la pestaña seleccionada */}
      {activeTab && (
        <>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <label style={{ marginRight: '8px' }}>Paso:</label>
              <select
                value={stepAtt}
                onChange={(e) => {
                  setStepAtt(e.target.value);
                  setSearch(true);
                }}
                style={{
                  borderRadius: '10px',
                  border: '1px solid #242323',
                  padding: '5px',
                  width: '150px',
                }}
              >
                {stepsAttendance.map((step, index) => (
                  <option key={index} value={step}>
                    {step}
                  </option>
                ))}
              </select>
              <label style={{ marginRight: '8px' }}>Desde:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{ borderRadius: '10px', border: '1px solid #242323', padding: '5px' }}
              />

              <label style={{ marginRight: '8px' }}>Hasta:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ borderRadius: '10px', border: '1px solid #242323', padding: '5px' }}
              />

              <button
                style={{
                  border: '1px solid #242323',
                  borderRadius: '10px',
                  background: '#242323',
                  color: 'white',
                  padding: '10px 20px',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'box-shadow 0.2s ease, transform 0.2s ease',
                  fontSize: '16px',
                }}
                onMouseDown={(e) => {
                  e.target.style.boxShadow = 'none';
                  e.target.style.transform = 'translateY(2px)';
                }}
                onMouseUp={(e) => {
                  e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  e.target.style.transform = 'translateY(0)';
                }}
                onClick={handleFetchData}
              >
                Consultar
              </button>
            </div>
          </div>

          {loading && (
            <>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>Cargando ...</p>
              </div>
            </>
          )}
          {search && (
            <>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>Click en consultar</p>
              </div>
            </>
          )}
          {!loading && !search && (
            <>
              <div style={{ width: '100%' }}></div>
              {stepAtt === 'news' && (
                <>
                  <h3>Nuevos Miembros</h3>
                  <svg width={width} height={height}>
                    {linesArray.map((line, index) => {
                      const x1 = (width - baseWidth) / 2 + spacing * index;
                      const x2 = width - (width - baseWidth) / 2 - spacing * index;
                      const y = 50 + index * 50; // Espaciado vertical
                      const midX = (x1 + x2) / 2; // Posición horizontal del centro de la línea

                      return (
                        <g key={index}>
                          {/* Línea */}
                          <line
                            x1={x1}
                            y1={y}
                            x2={x2}
                            y2={y}
                            stroke="#242323"
                            strokeWidth={lineThickness}
                          />
                          {/* Texto centrado dentro de la línea */}
                          <text
                            x={midX} // Posicionar el texto en el centro de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="white" // Texto blanco para contrastar con la línea
                            fontSize="14"
                            textAnchor="middle" // Alinear el texto al centro horizontalmente
                            dominantBaseline="middle" // Centrar verticalmente en la línea
                          >
                            {data[index]['value']}
                          </text>
                          <text
                            x={x2 + 20} // Aumentar el espacio a 20px a la derecha de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="black"
                            fontSize="14"
                            textAnchor="start"
                          >
                            {data[index]['name']}
                          </text>
                        </g>
                      );
                    })}
                  </svg>
                </>
              )}

              {stepAtt === 'encuentro' && (
                <>
                  <h3>Encuentro</h3>
                  <svg width={width} height={height}>
                    {(linesArray = [...Array(4).keys()])}
                    {linesArray.map((line, index) => {
                      const x1 = (width - baseWidth) / 2 + spacing * index;
                      const x2 = width - (width - baseWidth) / 2 - spacing * index;
                      const y = 50 + index * 50; // Espaciado vertical
                      const midX = (x1 + x2) / 2; // Posición horizontal del centro de la línea

                      return (
                        <g key={index}>
                          {/* Línea */}
                          <line
                            x1={x1}
                            y1={y}
                            x2={x2}
                            y2={y}
                            stroke="#242323"
                            strokeWidth={lineThickness}
                          />
                          {/* Texto centrado dentro de la línea */}
                          <text
                            x={midX} // Posicionar el texto en el centro de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="white" // Texto blanco para contrastar con la línea
                            fontSize="14"
                            textAnchor="middle" // Alinear el texto al centro horizontalmente
                            dominantBaseline="middle" // Centrar verticalmente en la línea
                          >
                            {data[index]['value']}
                          </text>
                          <text
                            x={x2 + 20} // Aumentar el espacio a 20px a la derecha de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="black"
                            fontSize="14"
                            textAnchor="start"
                          >
                            {data[index]['name']}
                          </text>
                        </g>
                      );
                    })}
                  </svg>
                </>
              )}

              {stepAtt === 'bautismo' && (
                <>
                  <h3>Bautismo</h3>
                  <svg width={width} height={height}>
                    {(linesArray = [...Array(3).keys()])}
                    {linesArray.map((line, index) => {
                      const x1 = (width - baseWidth) / 2 + spacing * index;
                      const x2 = width - (width - baseWidth) / 2 - spacing * index;
                      const y = 50 + index * 50; // Espaciado vertical
                      const midX = (x1 + x2) / 2; // Posición horizontal del centro de la línea

                      return (
                        <g key={index}>
                          {/* Línea */}
                          <line
                            x1={x1}
                            y1={y}
                            x2={x2}
                            y2={y}
                            stroke="#242323"
                            strokeWidth={lineThickness}
                          />
                          {/* Texto centrado dentro de la línea */}
                          <text
                            x={midX} // Posicionar el texto en el centro de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="white" // Texto blanco para contrastar con la línea
                            fontSize="14"
                            textAnchor="middle" // Alinear el texto al centro horizontalmente
                            dominantBaseline="middle" // Centrar verticalmente en la línea
                          >
                            {data[index]['value']}
                          </text>
                          <text
                            x={x2 + 20} // Aumentar el espacio a 20px a la derecha de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="black"
                            fontSize="14"
                            textAnchor="start"
                          >
                            {data[index]['name']}
                          </text>
                        </g>
                      );
                    })}
                  </svg>
                </>
              )}

              {stepAtt === 'vida' && (
                <>
                  <h3>Vida</h3>
                  <svg width={width} height={height}>
                    {(linesArray = [...Array(2).keys()])}
                    {linesArray.map((line, index) => {
                      const x1 = (width - baseWidth) / 2 + spacing * index;
                      const x2 = width - (width - baseWidth) / 2 - spacing * index;
                      const y = 50 + index * 50; // Espaciado vertical
                      const midX = (x1 + x2) / 2; // Posición horizontal del centro de la línea

                      return (
                        <g key={index}>
                          {/* Línea */}
                          <line
                            x1={x1}
                            y1={y}
                            x2={x2}
                            y2={y}
                            stroke="#242323"
                            strokeWidth={lineThickness}
                          />
                          {/* Texto centrado dentro de la línea */}
                          <text
                            x={midX} // Posicionar el texto en el centro de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="white" // Texto blanco para contrastar con la línea
                            fontSize="14"
                            textAnchor="middle" // Alinear el texto al centro horizontalmente
                            dominantBaseline="middle" // Centrar verticalmente en la línea
                          >
                            {data[index]['value']}
                          </text>
                          <text
                            x={x2 + 20} // Aumentar el espacio a 20px a la derecha de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="black"
                            fontSize="14"
                            textAnchor="start"
                          >
                            {data[index]['name']}
                          </text>
                        </g>
                      );
                    })}
                  </svg>
                </>
              )}

              {stepAtt === 'influencia' && (
                <>
                  <h3>Influencia</h3>
                  <svg width={width} height={height}>
                    {(linesArray = [...Array(1).keys()])}
                    {linesArray.map((line, index) => {
                      const x1 = (width - baseWidth) / 2 + spacing * index;
                      const x2 = width - (width - baseWidth) / 2 - spacing * index;
                      const y = 50 + index * 50; // Espaciado vertical
                      const midX = (x1 + x2) / 2; // Posición horizontal del centro de la línea

                      return (
                        <g key={index}>
                          {/* Línea */}
                          <line
                            x1={x1}
                            y1={y}
                            x2={x2}
                            y2={y}
                            stroke="#242323"
                            strokeWidth={lineThickness}
                          />
                          {/* Texto centrado dentro de la línea */}
                          <text
                            x={midX} // Posicionar el texto en el centro de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="white" // Texto blanco para contrastar con la línea
                            fontSize="14"
                            textAnchor="middle" // Alinear el texto al centro horizontalmente
                            dominantBaseline="middle" // Centrar verticalmente en la línea
                          >
                            {data[index]['value']}
                          </text>
                          <text
                            x={x2 + 20} // Aumentar el espacio a 20px a la derecha de la línea
                            y={y + 5} // Alinear el texto verticalmente con la línea
                            fill="black"
                            fontSize="14"
                            textAnchor="start"
                          >
                            {data[index]['name']}
                          </text>
                        </g>
                      );
                    })}
                  </svg>
                </>
              )}
            </>
          )}
        </>
      )}

      {!activeTab && (
        <>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <label style={{ marginRight: '8px' }}>Paso:</label>
              <select
                value={step}
                onChange={(e) => setStep(e.target.value)}
                style={{
                  borderRadius: '10px',
                  border: '1px solid #242323',
                  padding: '5px',
                  width: '150px', // Ajusta el ancho si es necesario
                }}
              >
                {/* Mapeamos la lista de 'steps' para generar las opciones */}
                {steps.map((step, index) => (
                  <option key={index} value={step}>
                    {step} {/* Aquí se muestra el valor de cada opción */}
                  </option>
                ))}
              </select>

              <label style={{ marginRight: '8px' }}>Desde:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                style={{ borderRadius: '10px', border: '1px solid #242323', padding: '5px' }}
              />

              <label style={{ marginRight: '8px' }}>Hasta:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                style={{ borderRadius: '10px', border: '1px solid #242323', padding: '5px' }}
              />

              <label style={{ marginRight: '8px' }}>cant clase:</label>
              <input
                type="text"
                value={classesAttended}
                onChange={(e) => setClassesAttended(e.target.value)}
                style={{
                  borderRadius: '10px',
                  border: '1px solid #242323',
                  padding: '5px',
                  width: '80px',
                }}
              />

              <button
                style={{
                  border: '1px solid #242323',
                  borderRadius: '10px',
                  background: '#242323',
                  color: 'white',
                  padding: '10px 20px',
                  cursor: 'pointer',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  transition: 'box-shadow 0.2s ease, transform 0.2s ease',
                  fontSize: '16px',
                }}
                onMouseDown={(e) => {
                  e.target.style.boxShadow = 'none';
                  e.target.style.transform = 'translateY(2px)';
                }}
                onMouseUp={(e) => {
                  e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  e.target.style.transform = 'translateY(0)';
                }}
                onClick={handleAttendance}
              >
                Consultar
              </button>

              {step !== 'Todos' && (
                <button
                  style={{
                    border: '1px solid #242323',
                    borderRadius: '10px',
                    background: '#242323',
                    color: 'white',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.2s ease, transform 0.2s ease',
                    fontSize: '16px',
                  }}
                  onMouseDown={(e) => {
                    e.target.style.boxShadow = 'none';
                    e.target.style.transform = 'translateY(2px)';
                  }}
                  onMouseUp={(e) => {
                    e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                    e.target.style.transform = 'translateY(0)';
                  }}
                  onClick={getAttendantReport}
                >
                  Descargar
                </button>
              )}
            </div>
          </div>

          {step !== 'Todos' ? (
            <>
              <div style={{ display: 'flex' }}>
                <PieChart width={400} height={400}>
                  <Pie
                    data={genders}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  >
                    {genders.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>

                <PieChart width={400} height={400}>
                  <Pie
                    data={allPeople}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  >
                    {allPeople.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>

                {filterByClass && (
                  <PieChart width={400} height={400}>
                    <Pie
                      data={byClass}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      fill="#8884d8"
                      label
                    >
                      {byClass.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                )}
              </div>
            </>
          ) : (
            <div style={{ width: '100%', maxHeight: '530px', overflowY: 'auto' }}>
              <h3>Todos los pasos </h3>
              {allSteps.map((step, index) => (
                <div
                  key={index}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <h3>{step.name}</h3>
                  <PieChart width={400} height={400}>
                    <Pie
                      data={step.value}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      fill="#8884d8"
                      label
                    >
                      {step.value.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>

                  <PieChart width={400} height={400}>
                    <Pie
                      data={step.allAttendance}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={100}
                      fill="#8884d8"
                      label
                    >
                      {step.allAttendance.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>

                  {step.filter && (
                    <PieChart width={400} height={400}>
                      <Pie
                        data={step.filter}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label
                      >
                        {step.filter.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </DashboardCrecerComponent>
  );
};

export default Crecer;
