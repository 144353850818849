import proxybase from './proxybase';

class MembersForms {
  newMembers(body) {
    return proxybase
      .post(`/members/newMembers`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  newMembersReport(body) {
    return proxybase
      .post(`/members/newMembersReport`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateNewMembers(body) {
    return proxybase
      .put(`/members/newMembersReport`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAMember(body) {
    return proxybase
      .post(`/members/getAMember`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  guestLeaders(body) {
    return proxybase
      .post(`/members/guestLeaders`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  UpsertGuestLeaders(body) {
    return proxybase
      .post(`/members/UpsertGuestLeaders`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  counselingList(body) {
    return proxybase
      .post(`/members/counselingList`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  newCounseling(body) {
    return proxybase
      .post(`/members/newCounseling`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateCounseling(body) {
    return proxybase
      .post(`/members/updateCounseling`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  newVolunteersForm(body) {
    return proxybase
      .post(`/experience/newVolunteersForm`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  allNewVolunteers({ body }) {
    return proxybase
      .post(`/experience/allNewVolunteers`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  SDeleteNewVolunteersForm(body) {
    return proxybase
      .post(`/experience/SDeleteNewVolunteersForm`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const membersForms = new MembersForms();
export default membersForms;
