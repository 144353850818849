import { css } from '@emotion/react';

export const TagComponent = css`
  .Tag {
    display: inline-block;
    background-color: #efefef;
    color: #a3a3a3;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
    border-right: 0.5px solid #d3d3d3;
    &:last-child {
      border-right: none;
    }
    &:hover {
      background-color: #d3d3d3;
    }
    &--active,
    &--active:hover {
      background-color: #2596c2;
      color: #fff;
    }
  }
`;
