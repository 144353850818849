import { DashboardComponent } from './components/styles/DashboardStyles';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUsers,
  faSeedling,
  faCalendar,
  faPoll,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import Groups from './components/Groups';
import Crecer from './components/Crecer';
import Experiencia from './components/Experiencia';
import Financiero from './components/Financiero';
import { useEffect } from 'react';

const AdminDashboard = () => {
  const iconMapping = {
    perfil: faHome,
    grupos: faUsers,
    crecer: faSeedling,
    eventos: faCalendar,
    encuestas: faPoll,
    experiencia: faStar,
  };

  useEffect(() => {
    const appElement = document.querySelector('.App');
    if (appElement) {
      appElement.style.backgroundColor = '#1d1d1b'; // Cambia el fondo a negro
    }

    return () => {
      if (appElement) {
        appElement.style.backgroundColor = ''; // Restaura el fondo original
      }
    };
  }, []);
    
  return (
    <DashboardComponent>
      <Router>
        <div style={{ display: 'flex', height: '100vh'}}>
          <div className="sidebar">
            <ul className="menuList">
              <li>
                <NavLink exact to="/tablero/groups" activeClassName="activeExperiencia">
                  <span className="icon">
                    <FontAwesomeIcon icon={iconMapping.grupos} />
                  </span>
                  <span className="text">Grupos</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/tablero/crecer" activeClassName="activeExperiencia">
                  <span className="icon">
                    <FontAwesomeIcon icon={iconMapping.crecer} />
                  </span>
                  <span className="text">Crecer</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/tablero/experiencia" activeClassName="activeExperiencia">
                  <span className="icon">
                    <FontAwesomeIcon icon={iconMapping.experiencia} />
                  </span>
                  <span className="text">Experiencia</span>
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/tablero/finanzas" activeClassName="activeExperiencia">
                  <span className="icon">
                    <FontAwesomeIcon icon={iconMapping.perfil} />
                  </span>
                  <span className="text">Financiero</span>
                </NavLink>
              </li>
              {/* Comenta o elimina las líneas no necesarias */}
            </ul>
          </div>
          <div style={{ flex: 1, padding: '0px' }}>
            <Switch>
              <Route path="/tablero/groups" component={Groups} />
              <Route path="/tablero/crecer" component={Crecer} />
              <Route path="/tablero/experiencia" component={Experiencia} />
              <Route path="/tablero/finanzas" component={Financiero} />
              <Redirect exact from="/tablero" to="/tablero/groups" />
            </Switch>
          </div>
        </div>
      </Router>
    </DashboardComponent>
  );
};

const DashboardWithProvider = () => <AdminDashboard />;

export default DashboardWithProvider;
