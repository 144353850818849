import { css } from '@emotion/react';

export const Clase3AnswersComponent = css`
  .AdminTable {
    .crecerp1c3 {
      input {
        width: 100%;
      }
    }
    .results {
      padding: 20px 40px;
      h1 {
        text-align: center;
      }
      .info {
        width: 45%;
        display: inline-block;
        padding: 20px 00 0 0;
        vertical-align: top;
        p {
          margin: 5px 0;
          &.userdata {
            font-size: 25px;
            padding: 10px;
          }
        }
      }
      .content {
        padding: 50px 30px;
      }
      .parents {
        padding: 0 0 0 20px;
      }
      #tutorForm {
        margin: 20px 0 0 0;
        display: inline-block;
        background-color: whitesmoke;
        width: 45%;
        padding: 20px;
        text-align: center;
        textarea {
          display: block;
          width: 80%;
        }
        .ui.checkbox {
          margin: 30px 0 20px 0;
        }
      }
      .DISC {
        padding-left: 20px;
        h2 {
          margin: 30px 0 0 -20px;
        }
        h3 {
          margin: 10px 0;
          font-size: 63px;
        }
        strong {
          display: inline-block;
          margin: 25px 0;
        }
      }
      .spiritual {
        padding-left: 20px;
        border-top: 1px solid gray;
        margin-top: 30px;
        h2 {
          margin: 20px 0 0 -20px;
        }
        p {
          span {
            font-style: italic;
          }
        }
        h3 {
          b {
            font-weight: 300;
          }
        }
      }
      .connecting {
        border-top: 1px solid gray;
        padding-left: 20px;
        margin-top: 30px;
        h2 {
          margin: 20px 0 0 -20px;
        }
      }
    }
    @media only screen and (max-width: 900px) {
      .results {
        padding: 0;
        .info {
          width: 100%;
        }
        #tutorForm {
          width: 95%;
        }
        .content {
          padding: 0;
        }
      }
    }
  }
`;
