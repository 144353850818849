import React from 'react';
import Form from '../../../components/form/Form';
import Swal from 'sweetalert2';
import membersForms from '../../../services/membersForms';
import { Button, Input, Select, Checkbox } from 'semantic-ui-react';
import {
  churchMinistries,
  comunidadProcess,
  eventSlugnameTrim,
  formSelectService,
  localhostname,
} from '../../../components/helpers';
import ReCAPTCHA from 'react-google-recaptcha';

const NewVolunteersForm = (props) => {
  const [Attendant, set_Attendant] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [sent, set_sent] = React.useState(false);
  const [sending, set_sending] = React.useState(false);
  const [image, set_image] = React.useState(null);

  const verifyForm = () => {
    let mandatoryInputs = [
      'name',
      'lastName',
      'identification',
      'phone',
      'email',
      'gender',
      'birthdate',
      'crecerlevel',
      'civil',
      'ocupation',
    ];

    if (Attendant.haveAGroup) {
      mandatoryInputs = [...mandatoryInputs, 'leaderName', 'leaderPhone'];
    }

    if (window.location.hostname !== localhostname) {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let inputsWithErrors = {};

    mandatoryInputs.forEach((input) => {
      inputsWithErrors = {
        ...inputsWithErrors,
        [input]: !Boolean(Attendant[input]),
      };
    });

    if (Attendant['identification'] && Attendant['identification'].length <= 5) {
      inputsWithErrors = {
        ...inputsWithErrors,
        identification: !Boolean(Attendant?.identification?.length >= 5),
      };
    }

    if (Attendant['crecerlevel']) {
      inputsWithErrors = {
        ...inputsWithErrors,
        crecerlevel: !Boolean(Attendant['crecerlevel']?.length > 0),
      };
    }

    if (Attendant['phone'] && Attendant['phone'].length <= 10) {
      inputsWithErrors = {
        ...inputsWithErrors,
        phone: !Boolean(Attendant['phone'].length >= 10),
      };
    }

    inputsWithErrors = {
      ...inputsWithErrors,
      image: !image,
    };

    if (
      Attendant['captcha'] &&
      Attendant['captcha'] !== 'null' &&
      window.location.hostname !== localhostname
    ) {
      inputsWithErrors = {
        ...inputsWithErrors,
        captcha: !Boolean(Attendant['captcha'].length >= 10),
      };
    }

    set_errorInputs(inputsWithErrors);
    return Object.values(inputsWithErrors).includes(true);
  };

  let sendForm = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    set_sending(true);

    const data = new FormData();
    data.append('birthdate', Attendant.birthdate);
    data.append('civil', Attendant.civil);
    data.append('crecerlevel', Attendant.crecerlevel);
    data.append('email', Attendant.email);
    data.append('gender', Attendant.gender);
    data.append('identification', Attendant.identification);
    data.append('lastName', Attendant.lastName);
    data.append('leaderName', Attendant.leaderName || '');
    data.append('leaderPhone', Attendant.leaderPhone || '');
    data.append('name', Attendant.name);
    data.append('ocupation', Attendant.ocupation);
    data.append('phone', Attendant.phone);
    data.append('captcha', Attendant.captcha);
    data.append('profile', image ? image : null);

    membersForms
      .newVolunteersForm(data)
      .then((response) => {
        set_sending(false);
        if (response.data.success) {
          set_sent(true);
          Swal.fire(response?.data?.message);
        } else {
          Swal.fire(response?.data?.message || 'Hubo un error, por favor intenta mas tarde.');
        }
      })
      .catch((error) => {
        set_sending(false);
        Swal.fire(error?.response?.data?.message || 'Hubo un error, por favor intenta mas tarde.');
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_Attendant({ ...Attendant, [name]: value });
  };

  const captchaOnChange = (value) => set_Attendant({ ...Attendant, captcha: value });

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  return (
    <div className="new-member">
      <form className="Registration__form">
        {!sent ? (
          <>
            <h1>Formulario para servir en Experiencia</h1>
            <div style={{ margin: '10px 0' }}>
              <p>
                ¡Felicitaciones, has culminado con los dos primeros Pasos de Crecer, asistes un
                Grupo fielmente y estás listo para servir en Experiencia!
              </p>
              <p>
                Es una bendición que has dispuesto de este tiempo para que podamos servir juntos a
                Jesús. Nos encantaría que puedas llenar esta encuesta, conocerte más y saber
                aprovechar esos talentos que Dios depositó en ti. Creemos que es importante como
                personas creadas con propósito, estemos en el lugar correcto, y con las personas
                correctas.
              </p>
              <p>
                Recuerda contestar cada pregunta con total sinceridad, no existen respuestas buenas
                o malas.
              </p>
            </div>

            <h2 style={{ margin: '50px 0 0 0' }}>¡Queremos conocerte!</h2>
            <p>Llena el siguiente formulario</p>
            <Form
              Attendant={Attendant}
              handleInputs={handleInputs}
              errorInputs={errorInputs}
              showOcupation
              imageFile={image}
              set_image={set_image}
              moreInfo={() => (
                <div style={{ margin: '0 0 20px 0' }}>
                  {formSelectService({
                    Select,
                    handler: selectHandle,
                    label: 'Estado civil',
                    name: 'civil',
                    value: Attendant.civil || '',
                    errorInputs,
                    options: ['Union libre', 'Soltero', 'Casado', 'Separado', 'Viudo'],
                  })}
                </div>
              )}
              showGender
              showBirthdate
            />
            {false && (
              <>
                <h2 style={{ margin: '50px 0 0 0' }}>¿Donde quieres servir?</h2>
                <h3>Los equipos de Experiencia son: </h3>
                <div style={{ textAlign: 'start', margin: '0 25px' }}>
                  <p>
                    <strong>Anfitriones:</strong> El objetivo de este equipo es brindar una atención
                    que genere comodidad a cada una de las personas dentro del auditorio.
                    <br />
                    <strong>Apoyo Logístico:</strong> El objetivo de este equipo es velar por la
                    seguridad dentro y alrededor de la iglesia.
                    <br />
                    <strong>Audiovisuales:</strong> El objetivo de este equipo es apoyar en todo el
                    tema de multicámaras, luces, proyección y sonido.
                    <br />
                    <strong>Bienvenida:</strong> El objetivo de este equipo es crear una atmósfera
                    de expectativa, alegría y de bienvenida a cada persona que se dirige hacia la
                    iglesia.
                    <br />
                    <strong>Comunidad Social:</strong> Es el equipo que cubre los niños y
                    adolescentes de la Fundación Viento Fresco como entrenadores, mentores, aseo,
                    tutor materias, profesionales, médicos o nutricionistas.
                    <br />
                    <strong>Comunidad Music:</strong> Es el equipo que liderar nuestra Comunidad en
                    adoración a Dios a través de instrumentos y las voces.
                    <br />
                    <strong>Crecer (Tutores Online):</strong> Este es el equipo que apoya la
                    logística de las clases de Crecer presencialmente.
                    <br />
                    <strong>Generaciones:</strong> Este es el equipo encargado de servir y ministrar
                    a los niños y adolescentes de una manera impactante y divertida.
                    <br />
                    <strong>Infórmate:</strong> El objetivo de este equipo es orientar y ubicar a
                    las personas dándoles la información que requieran sobre las actividades de la
                    iglesia.
                    <br />
                    <strong>Primeros Auxilios:</strong> Este es el equipo que apoya todo el tema de
                    primeros auxilios y evacuación durante las reuniones del fin de semana y
                    eventos.
                    <br />
                    <strong>Sala de Experiencia:</strong> El objetivo de este equipo es brindar un
                    lugar cómodo, agradable y seguro a los voluntarios y los pastores que les
                    permita sentirse valorados mientras realizan su labor de servir.
                  </p>
                </div>
                <div>
                  <h3 style={{ margin: '35px 0 0 0' }}>¿Donde te gustaría servir?</h3>
                  <p style={{ margin: 0 }}>
                    <small>
                      <i>Puedes seleccionar varios</i>
                    </small>
                  </p>
                  <Select
                    placeholder="¿Donde te gustaría servir?"
                    onChange={selectHandle}
                    value={Attendant.serviceAreas || ''}
                    name={'serviceAreas'}
                    id={'serviceAreas'}
                    className={`Registration__input Registration__input-${'serviceAreas'} ${
                      errorInputs['serviceAreas'] ? 'error' : ''
                    }`}
                    fluid
                    multiple
                    selection
                    options={churchMinistries.map((opt) => {
                      return { key: opt, value: opt, text: opt };
                    })}
                  />
                </div>
              </>
            )}
            <h2 style={{ margin: '50px 0 0 0' }}>Tu proceso en Comunidad</h2>
            <div>
              <label htmlFor="crecerlevel">
                Cuéntanos, ¿Qué cursos has culminado de Comunidad Crecer?
              </label>
              <p style={{ margin: 0 }}>
                <small>
                  <i>Puedes seleccionar varios</i>
                </small>
              </p>
              <Select
                placeholder={'Cuéntanos, ¿Qué cursos has culminado de Comunidad Crecer? '}
                onChange={selectHandle}
                value={Attendant.crecerlevel || ''}
                name={'crecerlevel'}
                id={'crecerlevel'}
                className={`Registration__input Registration__input-${'crecerlevel'} ${
                  errorInputs['crecerlevel'] ? 'error' : ''
                }`}
                fluid
                multiple
                selection
                options={comunidadProcess.map((opt) => {
                  return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
                })}
              />
            </div>
            <div style={{ display: 'block', width: '100%', margin: '20px 0 50px 0' }}>
              <h3 style={{ display: 'block', margin: '35px 0 0 0' }}>
                ¿Estás conectado con un Grupo?
              </h3>
              <p>
                <small>Estás asistiendo a un Grupo de manera frecuente y tienes un Líder.</small>
              </p>
              <label htmlFor="haveAGroup" style={{ padding: '0 10px', cursor: 'pointer' }}>
                No asisto a un grupo
              </label>
              <Checkbox
                label="Si, asisito a un grupo"
                id="haveAGroup"
                name="haveAGroup"
                checked={Attendant.haveAGroup}
                toggle
                onClick={() => set_Attendant({ ...Attendant, haveAGroup: !Attendant.haveAGroup })}
              />
            </div>

            {Attendant.haveAGroup && (
              <>
                <h2 style={{ margin: '0' }}>Datos de Tu Líder</h2>
                <p style={{ margin: 0 }}>
                  <small>Por favor escribe el nombre completo y el celular de tu lider</small>
                </p>
                <div style={{ margin: '0 0 30px 0' }}>
                  <Input
                    placeholder={'Nombre del Líder'}
                    onChange={handleInputs}
                    value={Attendant.leaderName || ''}
                    name={'leaderName'}
                    id={'leaderName'}
                    className={`Registration__input Registration__input-${'leaderName'} ${
                      errorInputs['leaderName'] ? 'error' : ''
                    }`}
                  />
                  <Input
                    placeholder={'Telefono del Líder'}
                    onChange={handleInputs}
                    type="number"
                    value={Attendant.leaderPhone || ''}
                    name={'leaderPhone'}
                    id={'leaderPhone'}
                    className={`Registration__input Registration__input-${'leaderPhone'} ${
                      errorInputs['leaderPhone'] ? 'error' : ''
                    }`}
                  />
                </div>
              </>
            )}
            <p className="habeasdata">
              La IGLESIA COMUNIDAD CRISTIANA DE FE Y VIENTO FRESCO, con nombre distintivo: COMUNIDAD
              MDE, hará uso de la información personal suministrada por el voluntario, con fines de
              generar retroalimentación sobre toda su actividad durante su permanencia en la
              iglesia. El voluntario, se compromete a actualizarla cuando sea pertinente o a pedir
              que su información sea eliminada de nuestras bases de datos al momento que se
              encuentre desvinculado; según términos establecidos en la ley Habeas data 1581 de
              2012. Igualmente se autoriza el uso y tratamiento de fotos y videos con fines
              comunicativos, en nuestras diferentes plataformas digitales y comunicación interna de
              la iglesia.
            </p>
            <p className="habeasdata-normal">
              Al enviar aceptas el tratamiento de datos de Comunidad Cristiana de Fe y Viento
              Fresco.
              <br />
              <small>
                Si desea más información,{' '}
                <a
                  href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=49981"
                  target="_blank"
                  rel="noreferrer"
                >
                  puede dar click en TÉRMINOS Y CONDICIONES para ampliar la información.
                </a>
              </small>
            </p>
            <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
              <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA} onChange={captchaOnChange} />
            </div>
            <p style={{ color: 'red' }}>
              {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
            </p>
            {Object.values(errorInputs).includes(true) && (
              <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
            )}
            <Button onClick={(e) => sendForm(e)} primary disabled={sending}>
              Enviar
            </Button>
          </>
        ) : (
          <>
            <h1>¡Gracias {Attendant.name}!</h1>
            <p>Pronto nos estaremos comunicando contigo</p>
          </>
        )}
      </form>
    </div>
  );
};

export default NewVolunteersForm;
