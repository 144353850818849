import { Button } from 'semantic-ui-react';
import moment from 'moment';
import churchForms from '../../../services/churchForms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { deleteForm } from '../../helpers/index';

const ComunidadNetworkRow = (props) => {
  const { network, set_showComunidadNetwork, getInfo } = props;

  const { id, businessName, serviceOffered, published, createdAt, Guest } = network;
  const { identification, name, phone, lastName } = Guest;

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {name} {lastName}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{businessName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {serviceOffered.substring(0, 100)}
          {serviceOffered.length > 99 && ' (...)'}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY')}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{published ? 'Sí' : 'No'}</p>
      </td>
      <td>
        <Button primary onClick={() => set_showComunidadNetwork(id)}>
          Ver
        </Button>
      </td>
      <td>
        <Button
          onClick={() =>
            deleteForm({
              subtitle: businessName,
              Swal,
              func: ({ text, successAlert }) => {
                churchForms
                  .postComunidadNetwork({ deleted: text, id }, true)
                  .then((response) => {
                    if (response.data.success) {
                      successAlert();
                      getInfo();
                    }
                  })
                  .catch((error) => {
                    if (error.response) Swal.fire(error.response.data.message);
                  });
              },
            })
          }
        >
          Eliminar
        </Button>
      </td>
    </tr>
  );
};

export default ComunidadNetworkRow;
