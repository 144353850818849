import ParentsMatchChildrenRow from '../../../../../../components/common/adminTable/ParentsMatchChildrenRow';

const StepParentMatchChildrenTable = (props) => {
  const { children, parent, getInfo } = props;

  return (
    <table>
      <thead>
        <tr>
          <th>Foto</th>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Inscribir</th>
          <th>Editar</th>
        </tr>
      </thead>
      <tbody>
        {children.map((kid) => (
          <ParentsMatchChildrenRow getInfo={getInfo} kid={kid} parent={parent} />
        ))}
      </tbody>
    </table>
  );
};

export default StepParentMatchChildrenTable;
