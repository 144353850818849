import React from 'react';
import { Divider, Header, Segment, Card, Button } from 'semantic-ui-react';
import groupService from '../../../services/groupService';
import { weekdays } from '../../helpers';
import moment from 'moment';
import Swal from 'sweetalert2';

export const getGroupLeaders = (group) => {
  const maleLeader = group?.maleLeader;
  const maleLeaderName = `${maleLeader?.name} ${maleLeader?.lastName}`;

  const femaleLeader = group?.femaleLeader;
  const femaleLeaderName = `${femaleLeader?.name} ${femaleLeader?.lastName}`;

  let leaderName = '';
  if (maleLeader && femaleLeader) {
    leaderName = `${maleLeaderName} y ${femaleLeaderName}`;
  } else if (maleLeader) {
    leaderName = maleLeaderName;
  } else if (femaleLeader) {
    leaderName = femaleLeaderName;
  }
  return leaderName;
};

const LeaderSelect = (props) => {
  const { inputs, setInputs, setStep, next } = props;
  const [loading, set_loading] = React.useState(true);
  const [groups, set_groups] = React.useState([]);

  React.useEffect(() => {
    let body = {
      activityName: inputs.activityName,
      status: true,
      suspended: false,
    };
    groupService
      .getAllGroups(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          if (response.data.groups) set_groups(response.data.groups);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  }, []);

  const selectGroup = (group) => {
    Swal.fire({
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      title: `¿Está seguro en registrarse en el grupo: ${group.groupName}?`,
      text: `Los ${weekdays[group.dayOfWeek]} ${moment(group.time, 'HH:mm').format('hh:mm a z')}, ${
        group.online && !(group.address || group.neighborhood)
          ? 'virtual'
          : `en ${group.neighborhood}`
      }.`,
    }).then(async (result) => {
      if (result.value) {
        set_loading(true);
        let body = { ...inputs, group: group.id };
        groupService
          .saveGroupParticipant(body)
          .then((response) => {
            set_loading(false);
            if (response.data.success) {
              let info = {};
              if (response.data.groupMember && response.data.Attendants) {
                if (response.data.Attendants)
                  info = { ...info, Attendants: response.data.Attendants };
                info = { ...info, groupMember: response.data.groupMember };
                setInputs({ ...info });
                setStep(next);
              }
            } else {
              Swal.fire('Error', response.data.message, 'error');
            }
          })
          .catch((err) => {
            set_loading(false);
          });
      }
    });
  };

  const avaliableGroups = groups?.filter(
    (group) => group.maxCapacity === null || group.GroupMembers.length < group.maxCapacity
  );

  const allAvaliableGroups = () =>
    avaliableGroups?.map((group) => (
      <div key={group.id} className="ui card select">
        <Card.Content header={group.groupName} />
        <Card.Content>
          <p>
            {weekdays[group.dayOfWeek]} {moment(group.time, 'HH:mm').format('hh:mm a z')}
          </p>
          {(group.address || group.neighborhood) && (
            <p>
              <strong>Lugar de reunión: </strong>
              {group.address} - {group.neighborhood}
            </p>
          )}
          {group.observations && (
            <p>
              <strong>Observación: </strong>
              {group.observations}
            </p>
          )}
          <p>
            <strong>Líder{group?.maleLeader && group?.femaleLeader ? 'es' : ''}: </strong>
            {getGroupLeaders(group)}
          </p>
          {group.online && (
            <p>
              <strong>
                Reuniones virtuales {(group.address || group.neighborhood) && 'y presenciales'}
              </strong>
            </p>
          )}
        </Card.Content>
        <Card.Content onClick={() => selectGroup(group)} className="members">
          <span className="two">Seleccionar</span>
        </Card.Content>
      </div>
    ));

  return (
    <Segment className="selectGroup">
      <Header as="h2">Grupos disponibles</Header>
      <p>{inputs?.activityName?.replace(/_/g, ' ')}</p>
      <Divider />
      <div className="ui cards">
        {loading ? <p>Cargando</p> : allAvaliableGroups()}
        {avaliableGroups.length === 0 && !loading && (
          <p style={{ margin: '0 0 0 10px' }}>
            No se encontraron grupos en esta categoria,{' '}
            {inputs.activityName?.includes('_(') && 'puedes volver atrás para cambiarla, o '}
            si deseas, escribenos a nuestro sitio. <br />
            {inputs.activityName?.includes('_(') ? (
              <a href="https://www.comunidadmde.com/grupos" target="_blank" rel="noreferrer">
                https://www.comunidadmde.com/grupos
              </a>
            ) : (
              <a href="https://www.comunidadmde.com/pasoscrecer" target="_blank" rel="noreferrer">
                https://www.comunidadmde.com/pasoscrecer
              </a>
            )}
          </p>
        )}
      </div>
      <Divider />
      <div>{!loading && <Button onClick={() => setStep('person1')}>Atrás</Button>}</div>
    </Segment>
  );
};

export default LeaderSelect;
