import { Button } from 'semantic-ui-react';

const GuestRow = (props) => {
  const { guest } = props;

  const { identification, name, phone, lastName, ocupation, email } = guest;

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {name} {lastName}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{email}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{ocupation}</p>
      </td>
      <td>
        <Button onClick={() => props.history.push(`/perfil/crecer?identification=${identification}&phone=${phone}`)}>
          Crecer
        </Button>
      </td>
    </tr>
  );
};

export default GuestRow;
