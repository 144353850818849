import React from 'react';
import { eventSlugnameTrim, formInput } from '../../../../components/helpers';
import moment from '../../../../services/moment_instance';
import { Button, Modal, Input } from 'semantic-ui-react';
import Form from '../../../../components/form/Form';
import Swal from 'sweetalert2';
import membersForms from '../../../../services/membersForms';
import {
  ReservationExtraInfo,
  sendReservationHelper,
  verifyReservationForm,
} from '../../../church-members/church-service/reservation/components/reservationExtraInfo';

const AddAGuest = (props) => {
  const { event, getEvent } = props;
  const [modalOpen, set_modalOpen] = React.useState(false);
  const [inputs, set_inputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);
  const [errorInputs, set_errorInputs] = React.useState({});

  let age = (birthdate) => moment().diff(birthdate, 'years');

  const handleDropDown = ({ name, value }) => {
    set_inputs({ ...inputs, [name]: value });
  };

  const getAMember = (e) => {
    e.preventDefault();
    const { identification, phone } = inputs || {};

    if (!identification || !phone) return Swal.fire('Para buscar escribe la cedula y el celular');

    set_loading(true);

    membersForms
      .getAMember({ identification, phone })
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          if (response.data.finded && response.data.match) {
            set_inputs({
              ...inputs,
              ...response.data.guest,
            });
          } else {
            if (response.data.message) Swal.fire(response.data.message);
          }
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  };

  const sendInfo = () => {
    if (verifyReservationForm(event, () => event.eventSlugname, inputs, set_errorInputs)) {
      return;
    }
    set_loading(true);
    sendReservationHelper({
      inputs,
      event,
      eventSlugname: event.eventSlugname,
      special: true,
      eventSelected: event,
    })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_modalOpen(false);
          getEvent(event.id, event.eventSlugname);
          set_inputs({});
        }
      })
      .catch((err) => {
        set_modalOpen(false);
        set_loading(false);
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_inputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  return (
    <>
      <Button onClick={() => set_modalOpen(true)}>Agregar asistente</Button>
      <Modal open={modalOpen} onClose={() => set_modalOpen(false)}>
        <Modal.Header>Agregar asistente de manera manual</Modal.Header>

        <Modal.Content>
          {event && event.eventSlugname && (
            <h3 style={{ margin: '0' }}>{eventSlugnameTrim(event.eventSlugname)}</h3>
          )}
          {event && event.date && (
            <p style={{ padding: '10px 0 20px 0', margin: 0, fontSize: '24px' }}>
              <strong>
                {moment.tz(event.date, 'America/Bogota').format('dddd DD [de] MMMM hh:mm a')}
              </strong>
            </p>
          )}
          {Object.values(errorInputs)?.includes(true) && (
            <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
          )}
          <div>
            <Button loading={loading} disabled={loading} onClick={(e) => getAMember(e)}>
              Llenar información
            </Button>
            <p style={{ fontSize: '12px', margin: 0 }}>
              Ingresa el celular y cédula para traer la información que hay en el sistema asociados.
            </p>
          </div>
          {!!(age(inputs.birthdate) <= 14 && inputs.birthdate) && (
            <div>
              <p style={{ margin: '0' }}>Informacion para menores de 14 años</p>
              {formInput({
                Input,
                handler: handleInputs,
                label: 'Nombre del acudiente',
                name: 'parentFullname',
                value: inputs.parentFullname || '',
                errorInputs,
              })}
              {formInput({
                Input,
                handler: handleInputs,
                label: 'Cedula del acudiente',
                name: 'parentIdentification',
                value: inputs.parentIdentification || '',
                errorInputs,
                type: 'number',
              })}
            </div>
          )}
          <Form
            Attendant={inputs}
            userprofile={true}
            handleInputs={handleInputs}
            handleDropDown={handleDropDown}
            errorInputs={errorInputs}
            showOcupation
            showAge
            showGender
            moreInfo={() => (
              <ReservationExtraInfo
                inputs={inputs}
                handleInputs={handleInputs}
                selectHandle={selectHandle}
                errorInputs={errorInputs}
                defaulteventSlugname={() => event.eventSlugname}
                firstEventOfQuery={event}
              />
            )}
            showBirthdate
            loadedData={loading}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => set_modalOpen(false)}>Cerrar</Button>
          <Button primary onClick={sendInfo}>
            Agregar
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default AddAGuest;
