import { Button, Modal } from 'semantic-ui-react';
import GroupRow from '../../common/adminTable/GroupRow';
import styled from '@emotion/styled';

const PendMemTableComponent = styled('div')`
  @media only screen and (max-width: 600px) {
    padding: 0;
    .date {
      display: none;
    }
  }
`;

const PendingNewMembers = (props) => {
  const { setOpen, isCouples, getInfo, pendingMembers } = props;

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open>
      <Modal.Header>Personas interesadas en entrar</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <PendMemTableComponent className="AdminTable">
            <table>
              <thead>
                <tr>
                  <th className="date">Fecha inscripción</th>
                  {isCouples && (
                    <>
                      <th>Teléfono El</th>
                      <th>Nombre El</th>
                    </>
                  )}
                  <th>Teléfono {isCouples && 'Ella'}</th>
                  <th>Nombre {isCouples && 'Ella'}</th>
                  <th>Ver</th>
                </tr>
              </thead>
              <tbody>
                {pendingMembers.length ? (
                  pendingMembers.map((member) => (
                    <GroupRow
                      member={member}
                      getInfo={getInfo}
                      isCouples={isCouples}
                      isPendingNewMembers={true}
                    />
                  ))
                ) : (
                  <p>No tienes más personas pendientes</p>
                )}
              </tbody>
            </table>
          </PendMemTableComponent>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PendingNewMembers;
