import proxybase from './proxybase';

class churchForms {
  volunteerForm(body) {
    return proxybase
      .post(`/experience/volunteerForm`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getVolunteerForm({ body, serviceArea = '' }) {
    return proxybase
      .post(`/experience/allVolunteers/${serviceArea}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deleteVolunteerForm({ deleted, id }) {
    return proxybase
      .post(`/experience/deleteVolunteerForm`, { id, deleted })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  postComunidadNetwork(body, editing = false) {
    const url = editing ? `/social/UpdateComunidadNetwork` : `/social/comunidadNetwork`;
    const token = editing
      ? { headers: { Authorization: localStorage.getItem('userToken') || '' } }
      : {};
    return proxybase
      .post(url, body, { ...token })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getComunidadNetwork(body) {
    return proxybase
      .post(`/social/allComunidadNetwork`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const churchFormsInstance = new churchForms();
export default churchFormsInstance;
