import { css } from '@emotion/react';

export const ReservationComponent = css`
  .service {
    padding: 20px 50px;
    min-width: 80%;
    margin: 10px auto;
    background-color: white;
    border-radius: 10px;
    p {
      &.title {
        font-size: 35px;
        margin: 0 0 9px 0;
      }
      &.seattitle {
        font-size: 20px;
        font-weight: 100;
      }
      &.seatnumber {
        font-size: 46px;
        margin: -22px 0 4px 0;
        font-weight: 900;
      }
    }
    .barcode {
      svg {
        height: 100px;
      }
    }
    .Registration__form {
      margin-top: 20px;
    }
    &.teCuidaInfo {
      .boolean-questions {
        span.options {
          display: inline-block;
          width: 25%;
          vertical-align: top;
          .checkbox {
            label {
              padding: 0 24px;
            }
          }
        }
        label {
          display: inline-block;
          width: 75%;
          &.error {
            color: red;
          }
        }
        div {
          margin: 10px 0;
        }
        button {
          margin: 10px 0;
        }
      }
    }
    &.summary {
      background-color: white;
      text-align: center;
    }
    &.selectAService {
      .checks {
        text-align: center;
      }
      .dates {
        button {
          position: relative;
          width: 150px;
          height: 54px;
          .time {
            position: absolute;
            top: 12px;
            font-size: 18px;
          }
          .left,
          .center {
            position: absolute;
            font-size: 10px;
            bottom: 6px;
            font-weight: 100;
          }
          .center {
            left: 38px;
            bottom: 3px;
          }
        }
      }
    }
    @media only screen and (max-width: 800px) {
      h1,
      h2 {
        text-align: center;
      }
      .AdminTable {
        padding: 0;
      }
      width: 100%;
      margin: 0 auto;
      padding: 20px;
    }
  }
`;
