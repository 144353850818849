import { css } from '@emotion/react';

export const AdminTableComponent = css`
  .AdminTable {
    background-color: white;
    padding: 20px 40px;
    border-radius: 10px;
    min-width: 70%;
    &.__content {
      display: block;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }

    .leaderNameFilter {
      input {
        width: -webkit-fill-available;
      }
    }

    td,
    th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 0 6px;
    }

    tr:nth-child(even) {
      background-color: #dddddd;
    }
    @media only screen and (max-width: 900px) {
      padding: 10px;
      .active.tab {
        padding: 10px 0;
      }
    }
  }
`;
