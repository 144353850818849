import proxybase from './proxybase';

class ExperienceService {
  VolunteerLog(body) {
    return proxybase
      .post(`/experience/VolunteerLog`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAVolunteer(body) {
    return proxybase
      .post(`/experience/getAVolunteer`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  VolunteerServiceChecks(body) {
    return proxybase
      .post(`/experience/VolunteerServiceChecks`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  VolunteerServiceLog(body) {
    return proxybase
      .post(`/experience/VolunteerServiceLog`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  UpdateNewVolunteersForm(body) {
    return proxybase
      .post(`/experience/updateNewVolunteersForm`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAllVolunteerServiceLogs(body) {
    return proxybase
      .post(`/experience/getAllVolunteerServiceLogs`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  CancelVolunteerService(body) {
    return proxybase
      .post(`/experience/CancelVolunteerService`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const experienceService = new ExperienceService();
export default experienceService;
