import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import EditUsersModal from '../../../containers/admin/auth/EditUsers';
import { eventSlugnameTrim } from '../../helpers';

const UserRow = (props) => {
  const { user, previousEventsNames, getUsers } = props;
  const [openModal, set_openModal] = useState(false);

  const { name, allowed_events, email, status } = user;

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{email}</p>
      </td>
      <td style={{ maxWidth: '300px' }}>
        {status.includes('totalAccess') || status.includes('Auditorio_PrincipalAdmin') ? (
          <p>Todos</p>
        ) : (
          <p style={{ margin: '0' }}>
            {status.includes('Auditorio_Principal')
              ? allowed_events?.map((ev) => <p>{eventSlugnameTrim(ev)}</p>)
              : 'Ninguno'}
          </p>
        )}
      </td>
      <td>
        <Button onClick={() => set_openModal(true)}>Editar</Button>
        {openModal && (
          <EditUsersModal
            previousEventsNames={previousEventsNames}
            user={user}
            getUsers={getUsers}
            openModal={openModal}
            set_openModal={set_openModal}
          />
        )}
      </td>
    </tr>
  );
};

export default UserRow;
