import React from 'react';
import queryString from 'query-string';
import PublicCrecerAttendanceLogs from './publicFormCrecerAttendance';
import CrecerCheckin from '../eventsLogin/crecer';
import EventService from '../../services/eventService';
import { Button } from 'semantic-ui-react';
import Form from '../form/Form';
import guestService from '../../services/guest';
import { errorInput } from '../helpers';
import Swal from 'sweetalert2';
import SeminaryCheckin from '../eventsLogin/seminary';

const isActualizarPath = !!window.location.pathname.includes('actualizar');

const CrecerAttendance = (props) => {
  const [Step, setStep] = React.useState('check');
  const [inputs, setInputs] = React.useState({});
  const [eventDates, set_eventDates] = React.useState([]);
  const [showForm, set_showForm] = React.useState(false);
  const [loading, set_loading] = React.useState(false);
  const [errorInputs, set_errorInputs] = React.useState({});
  const [errorData, set_errorData] = React.useState(null);

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    setInputs({
      ...inputs,
      identification: parsedQueryString.identification,
      phone: parsedQueryString.phone,
    });
    getEventDates();
    set_showForm(isActualizarPath);
  }, []);

  React.useEffect(() => {
    if (showForm || isActualizarPath) {
      setInputs({ ...inputs, ...inputs.Attendant });
    }
  }, [showForm]);

  const getEventDates = () => {
    EventService.getDates()
      .then((response) => {
        if (response.data.success) {
          set_eventDates(response.data.dates);
        }
      })
      .catch((error) => {});
  };

  const getGuest = async () => {
    set_loading(true);
    await guestService
      .getGuest(`${inputs.identification}/${inputs.phone}`, `crecer/crecerAttendance`, {
        eventSlugname: 'crecerAttendance',
        captcha: inputs.captcha,
        ac: parsedQueryString.ac,
      })
      .then((response) => {
        if (response.data.success) {
          setInputs({ ...inputs, Attendant: response.data.Attendant });
          setStep('summary');
        }
        set_loading(false);
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  };

  const verifyForm = () => {
    const mandatoryInputs = ['name', 'lastName', 'identification', 'phone', 'email', 'gender'];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5,
        },
      });
    }

    if (inputs['phone'] && inputs['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: inputs['phone'].length >= 10,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  function updateAttendant(e) {
    e.preventDefault();
    if (verifyForm()) return;

    const { id, name, lastName, phone, identification, email, gender } = inputs;

    if (!name || !lastName || !phone || !identification) {
      set_errorData('Todos los campos son obligatorios');
      return;
    }
    set_loading(true);

    const body = { name, lastName, phone, identification, email, gender };

    (inputs.Attendant.id ? guestService.updateGuest(id, body) : guestService.createGuest(body))
      .then((response) => {
        if (response.data.success) {
          set_errorData(response.data.message);
          set_showForm(false);
          setInputs({
            ...inputs,
            Attendant: { ...inputs.Attendant, ...(response?.data?.updateFromUser || {}) },
          });
        }
        set_loading(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) set_errorData(error.response.data.message);
        set_loading(false);
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const handleDropDown = ({ name, value }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const stepRender = () => {
    if (Step === 'check') {
      return (
        <PublicCrecerAttendanceLogs
          setInputs={setInputs}
          inputs={inputs}
          leaders
          next="list"
          parsedQueryString={parsedQueryString}
          setStep={setStep}
          getGuest={getGuest}
          loading={loading}
          set_loading={set_loading}
        />
      );
    } else if (Step === 'summary') {
      return (
        inputs.Attendant && (
          <section className="AdminTable">
            <h1 className="CheckIn__title">Información</h1>
            {showForm || !inputs.Attendant.id ? (
              <div style={{ textAlign: 'center' }}>
                <Form
                  saveTag={'Enviar'}
                  Attendant={inputs}
                  showIdentification={false}
                  handleInputs={handleInputs}
                  handleDropDown={handleDropDown}
                  errorInputs={errorInputs}
                  sendInForm
                  showGender
                  createdAt
                  loadedData={loading}
                  submit={updateAttendant}
                />
                {errorData && <p style={{ color: 'red', textAlign: 'center' }}>{errorData}</p>}
                {!inputs.Attendant.id && (
                  <p style={{ margin: '20px 0', lineHeight: '16px' }}>
                    <small>
                      Al enviar este formulario autorizo con la firma y/o aprobación de este
                      documento el tratamiento y la protección de mis datos personales, con el fin
                      de que se me informe acerca de eventos y servicios de forma segura. Así mismo,
                      autorizo a la Iglesia Comunidad Cristiana de Fe y Viento Fresco, para que
                      realice sobre la información suministrada un tratamiento de información, el
                      cual consistirá, pero sin limitarse, en la recolección, uso, archivo y/o
                      supresión de la información suministrada. La Iglesia se compromete a realizar
                      la debida protección de los datos personales suministrados con base en la Ley
                      1581 de 2012 y sus decretos reglamentarios, respetando los derechos que los
                      titulares poseen y ofreciendo las herramientas necesarias para que estos se
                      garanticen.
                    </small>
                  </p>
                )}
              </div>
            ) : (
              <div className="info">
                {inputs.Attendant.photo && (
                  <div
                    className="profilePhoto"
                    style={{ backgroundImage: `url(${inputs.Attendant.photo})` }}
                  />
                )}
                <div className={`data`}>
                  <p>
                    <strong>Nombre:</strong>
                    {inputs.Attendant.name}&nbsp;{inputs.Attendant.lastName}&nbsp;
                  </p>
                  <p>
                    <strong>Cedula:</strong>
                    {inputs.Attendant.identification}&nbsp;
                  </p>
                  <p>
                    <strong>Celular:</strong>
                    {inputs.Attendant.phone}&nbsp;
                  </p>
                </div>
              </div>
            )}
            {(!showForm || !inputs.Attendant.id) && (
              <Button className="modify" size="mini" onClick={() => set_showForm(!showForm)}>
                Actualizar información
              </Button>
            )}
            {inputs.Attendant.CrecerConectarSteps && (
              <>
                <CrecerCheckin
                  Attendant={inputs.Attendant || {}}
                  location={props.location}
                  set_Attendant={(Attendant) => setInputs({ ...inputs, Attendant })}
                  eventDates={eventDates}
                />
                <SeminaryCheckin
                  Attendant={inputs.Attendant || {}}
                  location={props.location}
                  set_Attendant={(Attendant) => setInputs({ ...inputs, Attendant })}
                  eventDates={eventDates}
                />
              </>
            )}
          </section>
        )
      );
    } else {
      return <p>Cargando</p>;
    }
  };

  return <div className="group_registration">{stepRender()}</div>;
};

export default CrecerAttendance;
