import { useEffect, useState } from 'react';
import { Button, TextArea, Modal } from 'semantic-ui-react';
import Form from '../../../../../../components/form/Form';
import { errorInput, textArea } from '../../../../../../components/helpers';
import UploadProfilePhoto from '../../../../../../components/helpers/guests/upload_photo';
import Swal from 'sweetalert2';
import generacionesService from '../../../../../../services/generacionesService';

const NewUpdateChildren = (props) => {
  const [sending, set_sending] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorInputs, set_errorInputs] = useState({});
  const [image, set_image] = useState(null);
  const [inputs, setInputs] = useState({});

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  useEffect(() => {
    if (props.kid?.id) {
      setInputs({ ...props.kid });
    }
  }, [props]);

  const form_verification = () => {
    let mandatoryInputs = ['name', 'lastName', 'gender', 'birthdate'];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  function sendForm(e) {
    if (form_verification()) return;
    set_sending(true);

    const { name, lastName, identification, gender, birthdate, notes } = inputs;

    const data = new FormData();
    data.append('profile', image ? image : null);
    data.append('name', name);
    data.append('lastName', lastName);
    data.append('parent_guest_identification', props.parent.identification);
    data.append('gender', gender);
    data.append('birthdate', birthdate);
    data.append('notes', notes || '');
    data.append('guestId', props.kid?.id);

    if (identification) data.append('identification', identification);

    const ifSuccess = (response) => {
      set_sending(false);
      if (response.data.success) {
        set_image(null);
        setInputs({});
        props.getInfo();
        setOpen(false);
      }
    };

    const ifError = () => {
      set_sending(false);
    };

    if (props.kid?.id) {
      generacionesService
        .updateGeneracionesGuest(data)
        .then((response) => ifSuccess(response))
        .catch(() => ifError());
    } else {
      generacionesService
        .createGeneracionesGuest(data)
        .then((response) => ifSuccess(response))
        .catch(() => ifError());
    }
  }

  const deleteChildren = () => {
    Swal.fire({
      title: '¿Seguro que desea eliminar este niño?',
      subtitle: 'Puedes corregir la información',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        set_sending(true);
        const body = {
          id: props.kid.id,
          parent_guest_identification: props.parent.identification,
        };
        generacionesService
          .deleteGeneracionesGuest(body)
          .then((response) => {
            set_sending(false);
            if (response.data.success) {
              set_image(null);
              setInputs({});
              props.getInfo();
              setOpen(false);
            }
          })
          .catch((err) => {
            set_sending(false);
          });
      }
    });
  };

  return (
    <>
      <Button onClick={() => setOpen(!open)}>{props.kid?.id ? 'Editar' : 'Agregar'} niño</Button>

      {open && (
        <Modal size="small" className="generaciones" open={open} onClose={() => setOpen(false)}>
          <Modal.Header>{props.kid?.id ? 'Editar' : 'Agregar'} un niño</Modal.Header>
          <Modal.Content>
            <UploadProfilePhoto
              name={'image'}
              labelDescription={'una foto'}
              imageFile={image}
              errorInputs={errorInputs}
              filling={true}
              inputs={inputs}
              set_image={set_image}
            />
            <p style={{ margin: '0 22px' }}>
              <small>
                <i>
                  En Identificación, escribe la del niño, si no la tienes o no la sabes, deja el
                  campo vacío.
                </i>
              </small>
            </p>
            <Form
              Attendant={inputs}
              showBirthdate
              handleInputs={handleInputs}
              errorInputs={errorInputs}
              showGender
              showIdentification
              showAge
              showPhone={false}
              showEmail={false}
            />
            <div style={{ padding: '12px 0' }}>
              {textArea({
                TextArea,
                handler: handleInputs,
                label:
                  'Escribe cualquier nota importante del niño, como alergias, cosas que debemos tener en cuenta, etc.',
                name: 'notes',
                defaultValue: inputs.aditionalinformation?.Nota || '',
                errorInputs,
              })}
            </div>
          </Modal.Content>
          <Modal.Actions>
            {!!props.kid?.id && (
              <Button inverted loading={sending} color="red" onClick={() => deleteChildren()}>
                Eliminar
              </Button>
            )}
            <Button color="black" loading={sending} onClick={() => setOpen(false)}>
              Cerrar
            </Button>
            <Button loading={sending} primary onClick={() => sendForm()}>
              {props.kid?.id ? 'Editar' : 'Agregar'}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default NewUpdateChildren;
