import { useState } from 'react';
import { Button, Image } from 'semantic-ui-react';
import { Checkbox } from 'semantic-ui-react';
import {
  cancelThisWeekendService,
  thisWeekendService,
} from '../../../containers/experience/volunteersLogs/confirmationHelpers';
import VolunteerForm from '../../../containers/experience/volunteersLogs/VolunteerForm';
import { profileImage } from '../../helpers';
import { ShowGuestLeaders } from '../../../containers/church-members/memberLeaders/showLeaders';

const ExperienceTeamAreaRow = (props) => {
  const {
    loading,
    service,
    showImgs,
    showArea,
    showEdit,
    showLeaders,
    volunteer,
    showActive,
    updateData,
    mainInputs,
    set_loading,
    eventSlugname,
    volunteerReserved,
    volunteersRowChecks,
    getVolunteersByEvent,
    set_volunteersRowChecks,
  } = props;
  const { role, serviceArea, Guest, suspended } = volunteer;
  const { name, lastName, phone, identification } = Guest;
  const [editingVolunteer, set_editingVolunteer] = useState(false);
  const [openVolLeaders, setOpenVolLeaders] = useState(false);

  const checked = volunteersRowChecks?.some(
    (volunteerCheck) => volunteerCheck.volunteer_id === volunteer.id
  );
  return (
    <tr>
      <td>
        <Checkbox
          checked={checked}
          disabled={loading || suspended}
          onChange={() => {
            if (checked) {
              set_volunteersRowChecks((prev) =>
                prev.filter((volunteerCheck) => volunteerCheck.volunteer_id !== volunteer.id)
              );
            } else {
              set_volunteersRowChecks((prev) => [
                ...prev,
                { guest_id: Guest.id, volunteer_id: volunteer.id },
              ]);
            }
          }}
        />
      </td>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      {showImgs && (
        <td>
          <Image src={profileImage(Guest)} rounded size="small" />
        </td>
      )}
      <td>
        <p style={{ margin: '0' }}>
          {name}&nbsp;{lastName}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      {showArea && (
        <td>
          <p style={{ margin: '0' }}>{serviceArea}</p>
        </td>
      )}
      <td>
        <p style={{ margin: '0' }}>{role}</p>
      </td>
      {showLeaders && (
        <td>
          <ShowGuestLeaders
            Guest={Guest}
            buttonText="Ver"
            setOpenVolLeaders={setOpenVolLeaders}
            openVolLeaders={openVolLeaders}
            getInfo={getVolunteersByEvent}
            set_loading={set_loading}
            loading={loading}
          />
        </td>
      )}
      {showActive && (
        <td>
          <p style={{ margin: '0' }}>{suspended ? 'No' : 'Si'}</p>
        </td>
      )}
      {showEdit && (
        <td>
          <Button disabled={loading} onClick={() => set_editingVolunteer(true)}>
            Editar
          </Button>
          {editingVolunteer && (
            <VolunteerForm
              prevVolunteerInputs={{ ...volunteer?.Guest, ...volunteer }}
              set_showVolunteerForm={set_editingVolunteer}
              set_loading={set_loading}
              mainInputs={mainInputs}
              getInfo={updateData}
              loading={loading}
            />
          )}
        </td>
      )}
      <td>
        {volunteerReserved ? (
          <Button
            disabled={loading}
            onClick={() =>
              cancelThisWeekendService(
                [volunteer.id],
                service,
                eventSlugname,
                Guest,
                getVolunteersByEvent,
                set_loading
              )
            }
          >
            Cancelar
          </Button>
        ) : (
          <Button
            disabled={loading}
            onClick={() =>
              thisWeekendService(
                [{ guest_id: Guest.id, volunteer_id: volunteer.id }],
                service,
                eventSlugname,
                Guest,
                getVolunteersByEvent,
                set_loading
              )
            }
            primary
          >
            Confirmar
          </Button>
        )}
      </td>
    </tr>
  );
};

export default ExperienceTeamAreaRow;
