import { useState } from 'react';
import { Button, Input, Select } from 'semantic-ui-react';
import { errorInput } from '../../components/helpers';
import styled from '@emotion/styled';
import Swal from 'sweetalert2/dist/sweetalert2';

const DonationComp = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  width: 90%;
  padding: 20px 50px;
  .back {
    width: 100%;
  }
  h1 {
    font-size: 40px;
    padding: 20px 0;
    width: 100%;
    text-align: center;
  }
  .donation-form {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .info {
      width: 100%;
      margin: 0 0 20px 0;
      .subtitle {
        font-size: 22px;
        margin: 0;
      }
      .note {
        font-size: 12px;
      }
    }
    form {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      justify-content: center;
      div {
        width: 100%;
      }
      .ui.input {
        width: 100%;
        margin: 10px 0;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    .donation-form {
      width: 100%;
      form {
        width: 100%;
      }
    }
  }
`;

const DiezmosYOfrendas = (props) => {
  const [inputs, setInputs] = useState({
    type: 'Diezmo',
    currency: 'Pesos Colombianos',
  });
  const [errorInputs, set_errorInputs] = useState({});

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const verifyForm = () => {
    let mandatoryInputs = [
      'nombre_completo',
      'identification',
      'type',
      'email',
      'currency',
      'value',
      'email',
    ];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const submit = (e) => {
    e.preventDefault();
    if (verifyForm()) return Swal.fire('Error', 'Por favor llene todos los campos', 'error');
    const { nombre_completo, type, identification, value, email } = inputs;

    props.history.push(
      `/payucheckout?amount=${value}&description=${type}&buyerEmail=${email}&buyerFullName=${nombre_completo}&identification=${identification}`
    );
  };

  return (
    <DonationComp>
      <div className='back'>
        <Button secondary onClick={() => window.location.replace('https://comunidadmde.com/dar')}>
          Volver
        </Button>
      </div>
      <h1>Donaciones</h1>
      <div className="donation-form">
        <div className="info">
          <p className="subtitle">
            Cada campo del formulario debe ser respondido para pasar a la zona de donaciones
          </p>
          <p className="note">
            El certificado de donación se expedirá a nombre del tarjetahabiente
          </p>
        </div>
        <form>
          <div>
            <label htmlFor="nombre_completo" style={{ display: 'block' }}>
              Nombre completo:
            </label>
            <Input
              placeholder="Nombre completo"
              name={'nombre_completo'}
              value={inputs.nombre_completo}
              style={{ padding: 0 }}
              onChange={handleInputs}
              className={`Registration__input Registration__input-${'nombre_completo'} ${
                errorInputs['nombre_completo'] ? 'error' : ''
              }`}
            />
          </div>
          <div>
            <label htmlFor="identification" style={{ display: 'block' }}>
              Numero de identificacion:
            </label>
            <Input
              placeholder="Numero de identificacion"
              name={'identification'}
              type="number"
              value={inputs.identification}
              style={{ padding: 0 }}
              onChange={handleInputs}
              className={`Registration__input Registration__input-${'identification'} ${
                errorInputs['identification'] ? 'error' : ''
              }`}
            />
          </div>
          <div>
            <label htmlFor="email" style={{ display: 'block' }}>
              Email:
            </label>
            <Input
              placeholder="Email"
              type="email"
              name={'email'}
              value={inputs.email}
              style={{ padding: 0 }}
              onChange={handleInputs}
              className={`Registration__input Registration__input-${'email'} ${
                errorInputs['email'] ? 'error' : ''
              }`}
            />
          </div>
          <div>
            <label htmlFor="type" style={{ display: 'block' }}>
              Concepto:
            </label>
            <Select
              placeholder="Concepto"
              value={inputs.type}
              onChange={selectHandle}
              name="type"
              className={`Registration__input Registration__input-${'type'} ${
                errorInputs['type'] ? 'error' : ''
              }`}
              options={[
                'Diezmo',
                'Ofrenda',
                'Protemplo',
                'España',
                'Fundacion Viento Fresco',
                'Misiones',
                'Otros',
              ].map((a) => {
                return { key: a, value: a, text: a };
              })}
            />
          </div>
          {/* <div>
            <label htmlFor="currency" style={{ display: 'block' }}>
              Moneda:
            </label>
            <Select
              placeholder="Moneda"
              value={inputs.currency}
              onChange={selectHandle}
              name="currency"
              className={`Registration__input Registration__input-${'currency'} ${
                errorInputs['currency'] ? 'error' : ''
              }`}
              options={[
                'Pesos Colombianos',
                'Dólar Americano',
                'Peso Mexicano',
                'Nuevo Sol Perua',
                'Real Brasileño',
                'Peso Argentino',
              ].map((a) => {
                return { key: a, value: a, text: a };
              })}
            />
          </div> */}
          <div>
            <label htmlFor="value" style={{ display: 'block' }}>
              Valor a donar en {inputs.currency}:
            </label>
            <Input
              placeholder="Valor a donar"
              name={'value'}
              type="number"
              value={inputs.value}
              style={{ padding: 0 }}
              onChange={handleInputs}
              className={`Registration__input Registration__input-${'value'} ${
                errorInputs['value'] ? 'error' : ''
              }`}
            />
          </div>
          <div>
            <Button onClick={submit} primary>
              Ir a Donar
            </Button>
          </div>
        </form>
      </div>
    </DonationComp>
  );
};
export default DiezmosYOfrendas;
