import React from 'react';
import queryString from 'query-string';
import { formatCurrency } from '../../components/helpers';
import { Button } from 'semantic-ui-react';

const TransactionResponse = (props) => {
  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  const [paymentInfo, setPaymentInfo] = React.useState({});

  React.useEffect(() => {
    let estadoTx = '';
    if (parsedQueryString['transactionState'] === 4) {
      estadoTx = 'Transacción aprobada';
    } else if (parsedQueryString['transactionState'] === 6) {
      estadoTx = 'Transacción rechazada';
    } else if (parsedQueryString['transactionState'] === 104) {
      estadoTx = 'Error';
    } else if (parsedQueryString['transactionState'] === 7) {
      estadoTx = 'Transacción pendiente';
    } else {
      estadoTx = 'Transacción pendiente';
    }
    setPaymentInfo({ ...parsedQueryString, estadoTx });
  }, []);

  return (
    <div style={{ width: '80%', height: '90vh', padding: '20px 0 0 0' }}>
      <Button primary onClick={() => window.location.replace('https://comunidadmde.com/dar')}>
        Volver
      </Button>
      <center>
        <h3 style={{ fontSize: '32px', padding: '20px 0' }}>Resumen de su donación</h3>
      </center>
      <table
        cellSpacing="1"
        cellPadding="1"
        border="0"
        width="100%"
        style={{ borderCollapse: 'collapse' }}
      >
        <tr>
          <td width="50%">Estado de la transacción</td>
          <td width="50%">{paymentInfo.estadoTx}</td>
        </tr>
        <tr>
          <td>ID de la transacción</td>
          <td>{paymentInfo.transactionId}</td>
        </tr>
        <tr>
          <td>Referencia de la venta</td>
          <td>{paymentInfo.reference_pol}</td>
        </tr>
        <tr>
          <td>Referencia de la transacción</td>
          <td>{paymentInfo.referenceCode}</td>
        </tr>
        <tr>
          <td>Cus</td>
          <td>{paymentInfo.cus}</td>
        </tr>
        <tr>
          <td>Banco</td>
          <td>{paymentInfo.pseBank}</td>
        </tr>
        <tr>
          <td>Valor total</td>
          <td>{formatCurrency(paymentInfo.TX_VALUE)}</td>
        </tr>
        <tr>
          <td>Moneda</td>
          <td>{paymentInfo.currency}</td>
        </tr>
        <tr>
          <td>Descripción</td>
          <td>{paymentInfo.description?.replace(/_/g, ' ')}</td>
        </tr>
        <tr>
          <td>Entidad:</td>
          <td>{paymentInfo.lapPaymentMethod}</td>
        </tr>
      </table>
      <div style={{ margin: '20px 0' }}>
        {paymentInfo?.description?.includes('.') && (
          <Button
            secondary
            onClick={() =>
              props.history.push(
                `/servicio/reservacion?event=${
                  paymentInfo.description
                }&identification=${paymentInfo.referenceCode.substring(10, 60)}`
              )
            }
          >
            {paymentInfo.transactionState === 4 && 'Ver la credencial de entrada'}
            {paymentInfo.transactionState !== 7 &&
              paymentInfo.transactionState !== 4 &&
              'Reintentar la donación'}
          </Button>
        )}
        {paymentInfo?.description?.includes('.') && paymentInfo.transactionState === 7 && (
          <p>
            La transacción esta en proceso, cuando se confirme tu donación te enviaremos un correo
            con tu entrada.
          </p>
        )}
      </div>
    </div>
  );
};
export default TransactionResponse;
