import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import GroupView from './groupView';
import { clickableStyles, s } from '../../helpers';

const LeadersAdminAllGroups = (props) => {
  const { inputs } = props;
  const [groupNames, set_groupNames] = React.useState([]);
  const [groupSelected, set_groupSelected] = React.useState(null);

  React.useEffect(() => {
    const names = inputs.groups.map((group) => {
      return {
        key: group.id,
        value: group.id,
        text: `${group.activityName.replace(/_/g, ' ')} - ${group.groupName}`,
      };
    });
    set_groupNames(names);
    if (!groupSelected) set_groupSelected(names[0].key);
  }, [inputs.groups]);

  const selectHandle = (a, { value, name }) => set_groupSelected(value);

  const showGroupSelected = () => inputs.groups.filter((group) => group.id === groupSelected)[0];

  return (
    <section className="AdminTable" id="network">
      {!Boolean(localStorage.getItem('userToken')) && (
        <Button
          onClick={() =>
            window.location.replace(
              `/grupos/lider?phone=${props.inputs.phone}&identification=${props.inputs.identification}`
            )
          }
        >
          Atrás
        </Button>
      )}
      <h2 style={{ padding: '20px 0' }}>
        Bienvenido a tu{s(inputs.groups)} grupo{s(inputs.groups)}
      </h2>
      <p
        style={{ ...clickableStyles, textAlign: 'center', margin: '0 0 20px 0' }}
        onClick={() =>
          window.location.replace(
            `/grupos/lider?phone=${props.inputs.phone}&identification=${props.inputs.identification}&filterKindOfGroup=filter`
          )
        }
      >
        ¿Filtrar por tipo de grupo?
      </p>
      {inputs.groups.length > 1 && (
        <>
          <p style={{ margin: 0 }}>
            <small>Para cambiar, selecciona un grupo de la lista</small>
          </p>
          <Dropdown
            placeholder="Selecciona un grupo"
            onChange={selectHandle}
            fluid
            selection
            value={groupSelected}
            options={groupNames}
          />
        </>
      )}
      {showGroupSelected() && <GroupView {...props} group={showGroupSelected()} />}
    </section>
  );
};

export default LeadersAdminAllGroups;
