import { DashboardExpComponent } from './styles/DashboardExpComponent';

// src/components/Grupos.js
const Experiencia = () => {

  return (
    <DashboardExpComponent>
      <h2 style={{ color: '#009700', fontSize: '40px' }}>Experiencia</h2>
    </DashboardExpComponent>
  );
};

export default Experiencia;
