import GroupMemberModal from '../../groups/leadersView/groupMemberModal';
import moment from 'moment';

const GroupRow = (props) => {
  const { member, getInfo, isCouples, takeattendance, isPendingNewMembers } = props;

  const { female, male } = member;

  const attendance = (member) =>
    Object.entries(member).filter((f) => f[0].includes('class') && Boolean(f[1])) || [];

  return (
    <tr>
      {isPendingNewMembers && (
        <td className="date">
          <p style={{ margin: '0' }}>
            {moment.tz(member?.createdAt, 'America/Bogota').format('DD-MM-YYYY')}
          </p>
        </td>
      )}
      {male ? (
        <>
          <td>
            <p style={{ margin: '0' }}>{male.phone}</p>
          </td>
          <td>
            <p style={{ margin: '0' }}>
              <span>{male.name}</span> <span>{male.lastName}</span>
            </p>
          </td>
        </>
      ) : (
        isCouples && (
          <>
            <td> </td> <td> </td>
          </>
        )
      )}
      {female ? (
        <>
          <td>
            <p style={{ margin: '0' }}>{female.phone}</p>
          </td>
          <td>
            <p style={{ margin: '0' }}>
              <span>{female.name}</span> <span>{female.lastName}</span>
            </p>
          </td>
        </>
      ) : (
        isCouples && (
          <>
            <td> </td> <td> </td>
          </>
        )
      )}
      {takeattendance && (
        <td>
          <p style={{ margin: '0' }}>{attendance(member).length}</p>
        </td>
      )}
      <td>
        <GroupMemberModal
          takeattendance={takeattendance}
          isCouples={isCouples}
          member={member}
          getInfo={getInfo}
          isPendingNewMembers={isPendingNewMembers}
        />
      </td>
    </tr>
  );
};

export default GroupRow;
