import RegisterModule from './Home/RegisterModule';
import PublicPage from './Home/PublicPage';

const Home = (props) => {
  if (localStorage.getItem('userToken')) {
    return <RegisterModule {...props} />;
  } else {
    return <PublicPage {...props} />;
  }
};

export default Home;
