import { css } from '@emotion/react';

export const CertificateComponent = css`
  .blinded:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 48px;
    bottom: 0;
    background-color: #0000ff00;
    z-index: 1;
  }
`;
