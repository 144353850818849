import { Button, Image } from 'semantic-ui-react';
import { avoCaptConstructor, profileImage } from '../../helpers/index';

import VolunteerForm from '../../../containers/experience/volunteersLogs/VolunteerForm';
import { ShowGuestLeaders } from '../../../containers/church-members/memberLeaders/showLeaders';

export const volunteersRowDef = ({
  setOpenVolLeaders,
  openVolLeaders,
  showVolunteerForm,
  set_showVolunteerForm,
  loading,
  set_loading,
  getInfo,
  showImgs,
}) => {
  return [
    { headerName: 'Cedula', width: 120, field: 'identification' },
    ...(showImgs
      ? [
          {
            headerName: 'Image',
            width: 100,
            field: 'image',
            cellRenderer: (params) => (
              <Image src={profileImage(params.data)} rounded size="small" />
            ),
          },
        ]
      : []),
    { headerName: 'Nombres', width: 120, field: 'name' },
    { headerName: 'Apellidos', width: 150, field: 'lastName' },
    { headerName: 'Celular', field: 'phone', width: 150 },
    { headerName: 'Area de Servicio', field: 'serviceArea', width: 150 },
    {
      headerName: 'Lideres',
      field: 'leaders',
      width: 110,
      cellRenderer: (params) => (
        <ShowGuestLeaders
          Guest={params.data}
          buttonText="Ver"
          setOpenVolLeaders={(open) => {
            setOpenVolLeaders(open ? params.data.id : null);
          }}
          openVolLeaders={params.data.id === openVolLeaders}
          getInfo={getInfo}
          set_loading={set_loading}
          loading={loading}
        />
      ),
    },
    {
      headerName: 'Rol',
      field: 'role',
      width: 130,
      cellRenderer: (params) => (
        <p style={{ margin: '0' }}>
          {params.data?.role === 'Coordinador' ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`/experiencia/voluntarios?phone=${params.data.phone}&identification=${
                params.data.identification
              }&serviceArea=${params.data.serviceArea}&ac=${avoCaptConstructor(
                params.data.identification
              )}`}
            >
              <Button basic color="black" size="mini">
                {params.data?.role}
              </Button>
            </a>
          ) : (
            params.data?.role
          )}
        </p>
      ),
    },
    {
      headerName: 'Sección',
      field: 'section',
      width: 150,
      valueGetter: (val) => `${val.data?.section?.replace(/_/g, ' ')}`,
    },
    {
      headerName: 'Suspendido',
      field: 'suspended',
      width: 80,
      valueGetter: (val) => `${val.data?.suspended ? 'Si' : 'No'}`,
    },
    {
      headerName: 'Acciones',
      field: 'actions',
      width: 100,
      cellRenderer: (params) => (
        <>
          <Button disabled={loading} onClick={() => set_showVolunteerForm(params.data.id)}>
            Editar
          </Button>
          {showVolunteerForm === params.data.id && (
            <VolunteerForm
              prevVolunteerInputs={params.data}
              set_showVolunteerForm={set_showVolunteerForm}
              set_loading={set_loading}
              getInfo={getInfo}
              loading={loading}
            />
          )}
        </>
      ),
    },
  ];
};
