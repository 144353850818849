import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './routes/App';
import { Global, css } from '@emotion/react';
import { AppStyles } from './assets/styles/AppStyles';
import { localhostname } from './components/helpers';

if (window.location.hostname !== localhostname) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_RATE),
    replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_RATE),
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <>
    <Global
      styles={css`
        ${AppStyles}
      `}
    />
    <App />
  </>,
  document.getElementById('App')
);
