import { Button } from 'semantic-ui-react';
import moment from 'moment';

const NewMembersRow = (props) => {
  const { newMember, set_showNewMembers } = props;

  const { id, createdAt, contacted, Guest, callchecks } = newMember;

  const { name, lastName, phone } = Guest;
  return (
    <tr>
      <td>{moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY')}</td>
      <td>{moment.tz(createdAt, 'America/Bogota').format('hh:mm a')}</td>
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{lastName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{Boolean(contacted) ? 'Si' : 'No'}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>
          {Object.values(callchecks || {}).some((f) => f) ? 'Si' : 'No'}
        </p>
      </td>
      <td>
        <Button onClick={() => set_showNewMembers(id)}>Ver</Button>
      </td>
    </tr>
  );
};

export default NewMembersRow;
