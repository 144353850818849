import React from 'react';
import Paso1Clase3 from './Paso1Clase3';
import { Button, Input } from 'semantic-ui-react';
import Form from '../../../components/form/Form';
import { spiritualQuestions, discQuestions, sacredPathQuestions } from './clase3Questions';
import Swal from 'sweetalert2';
import crecerForms from '../../../services/crecer';
import {
  errorInput,
  uniqueElements2Arrays,
  countAnswers,
  formInput,
} from '../../../components/helpers';
import moment from 'moment';
import queryString from 'query-string';
import { InPersonStudentResults } from './inPersonStudentResults';
import { Margin, usePDF } from 'react-to-pdf';
import { habeasData } from '../../../components/helpers/habeasData';

const Clase3Spiritual = (props) => {
  const [student, set_student] = React.useState({});
  const [spiritualGiftAnswers, set_spiritualGiftAnswers] = React.useState({});
  const [DISCAnswers, set_DISCAnswers] = React.useState({});
  const [experienceWithGodAnswers, set_experienceWithGodAnswers] = React.useState([]);
  const [step, set_step] = React.useState(0);
  const [errorInputs, set_errorInputs] = React.useState({});
  const [answersLeft, set_answersLeft] = React.useState([]);
  const { toPDF, targetRef } = usePDF({
    method: 'save',
    page: { margin: Margin.MEDIUM },
    filename: 'resultados-crecer-conectar.pdf',
  });

  let query = props.location.search;
  let parsedQS = queryString.parse(query);

  React.useEffect(() => {
    if (props.repeating) set_student({ ...props.student });
  }, [props.student]);

  React.useEffect(() => {
    if (parsedQS?.classnum || parsedQS?.showans) set_student({ ...(parsedQS || {}) });
  }, [parsedQS?.classnum, parsedQS?.showans]);

  const age = (birthdate) => moment().diff(birthdate, 'years');

  React.useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (step === 5) {
      let DISC_letter = countAnswers(DISCAnswers)?.[0]?.letter;

      let body = {
        spiritualGiftAnswers,
        DISCAnswers,
        experienceWithGodAnswers,
        DISC_letter,
        ...student,
      };

      if (props.repeating) body = { ...body, repeated: true };

      crecerForms
        .CrecerP1C3Form(body)
        .then((response) => {
          if (response.data.success) {
            setTimeout(() => {
              set_step(6);
            }, 4000);
          } else {
            Swal.fire('Hubo un error, por favor intenta mas tarde.');
            set_step(1);
          }
        })
        .catch((error) => {
          Swal.fire('Hubo un error, por favor intenta mas tarde.');
          set_step(1);
        });
    }
  }, [step]);

  const verifyStepForm = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let mandatoryInputs = ['name', 'lastName', 'identification', 'phone', 'email'];

    if (parsedQS?.showans !== 'show') {
      mandatoryInputs = [...mandatoryInputs, 'country', 'birthdate', 'gender'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: student[input] },
      });
    });

    if (student['birthdate'] && age(student['birthdate']) < 18) {
      const conditionalMandInputs = ['parentName', 'parentPhone'];

      conditionalMandInputs.forEach((input) => {
        errorInputs = errorInput({
          errorInputs,
          input: { name: input, value: student[input] },
        });
      });
      if (age(student['birthdate']) < 16)
        Swal.fire({
          html: `
          <div>
          <h2>¡Disculpanos!</h2>
            No tienes edad suficiente para realizar este paso de crecer.
            <p>Pero te invitamos a que te unas a un grupo en este 
              <a target="_blank" href="https://docs.google.com/forms/d/1MnX_XMjBsHR2nN0JSDfBtiq3WK2H8V0CjrPnyCnZ28w/viewform?edit_requested=true">
                enlace
              </a>.
            </p>
          </div>
          `,
        });
    }

    if (student['identification'] && student['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: student['identification'].length >= 5,
        },
      });
    }

    if (student['phone'] && student['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: student['phone'].length >= 10,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const verifyStepOne = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorInputs = {};

    let answersLefts = uniqueElements2Arrays(
      discQuestions.map((q) => q.num),
      Object.keys(DISCAnswers).map((e) => parseInt(e))
    );
    set_answersLeft(answersLefts);

    errorInputs = errorInput({
      errorInputs,
      input: { name: 'DISCAnswers', value: !answersLefts.length },
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const verifyStepTwo = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorInputs = {};

    let answersLefts = uniqueElements2Arrays(
      spiritualQuestions.map((q) => q.num),
      Object.keys(spiritualGiftAnswers).map((e) => parseInt(e))
    );

    set_answersLeft(answersLefts);

    errorInputs = errorInput({
      errorInputs,
      input: { name: 'spiritualGiftAnswers', value: !answersLefts.length },
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const verifyStepThree = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let errorInputs = {};

    let answersLefts = uniqueElements2Arrays(
      sacredPathQuestions.map((q) => q.num),
      Object.keys(experienceWithGodAnswers).map((e) => parseInt(e))
    );

    set_answersLeft(answersLefts);

    errorInputs = errorInput({
      errorInputs,
      input: { name: 'experienceWithGodAnswers', value: !answersLefts.length },
    });
    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_student({ ...student, [name]: value });
  };

  const questionsLeft = () => {
    return (
      <p style={{ color: 'red' }}>
        Por favor llena la{answersLeft.length !== 1 && 's'} pregunta
        {answersLeft.length !== 1 && 's'}{' '}
        {answersLeft.map((answer) => (
          <span>{answer}, </span>
        ))}{' '}
        para continuar.
      </p>
    );
  };

  const moreInfo = () => {
    return (
      <>
        {age(student['birthdate']) < 18 && age(student['birthdate']) > 0 && (
          <>
            {formInput({
              Input,
              handler: handleInputs,
              label: 'Nombre del Acudiente',
              name: 'parentName',
              value: student['parentName'],
              errorInputs,
            })}
            {formInput({
              Input,
              handler: handleInputs,
              type: 'number',
              label: 'Telefono del Acudiente',
              name: 'parentPhone',
              value: student['parentPhone'],
              errorInputs,
            })}
          </>
        )}
        {parsedQS?.showans !== 'show' &&
          formInput({
            Input,
            handler: handleInputs,
            type: 'text',
            label: 'País',
            name: 'country',
            value: student['country'],
            errorInputs,
          })}
      </>
    );
  };

  return (
    <div className="Clase3">
      <div className="Questions">
        {parsedQS?.showans === 'show' && step === 6 && (
          <Button onClick={() => toPDF()}>Guardar resultados</Button>
        )}
        <div ref={targetRef} style={{ padding: '20px 30px' }}>
          <h1>
            {parsedQS?.classnum === 'clase4'
              ? 'Conectados Permanentemente'
              : 'Conectar con tu propósito'}
          </h1>
          {parsedQS?.campus && (
            <h3 className="clase">Campus {parsedQS?.campus}</h3>
          )}
          {parsedQS?.classnum === 'clase3' && (
            <h3 className="clase">Crecer Presencial - Clase 3</h3>
          )}
          {parsedQS?.classnum === 'clase4' && (
            <h3 className="clase">Crecer Presencial - Clase 4</h3>
          )}
          {step === 0 && (
            <div className="intro">
              <p>
                Dios te creó con un propósito específico. Hoy te ayudaremos a descubrir cómo su
                diseño te ayudará a revelar el camino para cumplir el propósito para el cual Dios te
                creó.
              </p>
              <p>
                {parsedQS?.classnum === 'clase4'
                  ? 'Este test analiza la forma cómo experimentas a Dios. Según tu personalidad y antecedentes espirituales, existen diferentes formas de conectarse con Dios.'
                  : 'A través de los siguientes tests vas a poder conocer un poco más tu personalidad y tus dones para acercarte de manera más efectiva a cumplir el propósito de Dios en tu vida.'}
              </p>
              <p>
                No hay respuestas correctas o incorrectas, recuerda que no es quien quieres ser,
                para obtener los mejores resultados es necesario que respondas con total sinceridad.
              </p>
              {parsedQS?.showans !== 'show' && (
                <p style={{ margin: 0, color: 'red' }}>
                  <strong>Nota importante:</strong> Son 3 diferentes encuestas, cuando termines, no
                  olvides presionar el botón azul que está al fondo de cada cuestionario en esta
                  misma pagina; no presiones el botón verde, hasta que hayas terminado todas las
                  encuestas y veas el mensaje de confirmación.
                </p>
              )}
              <Button primary onClick={() => set_step(1)}>
                Continua
              </Button>
            </div>
          )}
          {step === 1 && (
            <>
              <form className="Registration__form">
                <Form
                  Attendant={student}
                  showBirthdate={parsedQS?.showans !== 'show'}
                  handleInputs={handleInputs}
                  errorInputs={errorInputs}
                  showGender={parsedQS?.showans !== 'show'}
                  moreInfo={moreInfo}
                />
              </form>
              {Object.values(errorInputs).includes(true) && (
                <p style={{ color: 'red' }}>Por favor verifica los campos que faltan por llenar.</p>
              )}
              <Button
                primary
                onClick={() =>
                  verifyStepForm() ? set_step(1) : set_step(parsedQS?.classnum === 'clase4' ? 4 : 2)
                }
              >
                Continúa a la primera encuesta
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <h3>Conectar con tu personalidad</h3>
              <p style={{ textAlign: 'center', margin: 0 }}>
                Califica las siguientes afirmaciones, donde 5 es siempre, 4 es a menudo, 3 es a
                veces, 2 es rara vez y 1 es nunca.
              </p>
              <p style={{ textAlign: 'center', margin: 0, color: 'gray' }}>
                Recuerda que no hay respuestas correctas o incorrectas.
              </p>
              {errorInputs['DISCAnswers'] && questionsLeft()}
              <Paso1Clase3
                set_answers={set_DISCAnswers}
                answers={DISCAnswers}
                numberOptions={[5, 4, 3, 2, 1]}
                questions={discQuestions}
              />
              <Button primary onClick={() => (verifyStepOne() ? set_step(2) : set_step(3))}>
                Continúa a la siguiente encuesta
              </Button>
            </>
          )}
          {step === 3 && (
            <div className="disc">
              <h3>Conectar con tus dones espirituales</h3>
              {errorInputs['spiritualGiftAnswers'] && questionsLeft()}

              <p style={{ textAlign: 'center' }}>
                Califica las siguientes afirmaciones, donde 3 es casi siempre, 2 es algunas veces y
                1 casi nunca.
              </p>
              <Paso1Clase3
                set_answers={set_spiritualGiftAnswers}
                answers={spiritualGiftAnswers}
                numberOptions={[3, 2, 1]}
                questions={spiritualQuestions}
              />
              <Button
                primary
                onClick={() =>
                  verifyStepTwo() ? set_step(3) : set_step(parsedQS?.classnum === 'clase3' ? 5 : 4)
                }
              >
                Continua
              </Button>
            </div>
          )}
          {step === 4 && (
            <div className="disc">
              <h3>Conectados Permanentemente</h3>
              {errorInputs['experienceWithGodAnswers'] && questionsLeft()}

              <p style={{ textAlign: 'center' }}>
                Este test analiza la forma cómo experimentas a Dios. Según tu personalidad y
                antecedentes espirituales, existen diferentes formas de conectarse con Dios. Usa la
                escala de calificación a continuación para responder a cada pregunta / afirmación de
                acuerdo con lo que te identifiques (desde lo que es y no desde lo que te gustaría
                que fuera) y luego ingresa el valor numérico en el espacio en blanco:
              </p>
              <p style={{ textAlign: 'center' }}>
                3: Definitivamente verdadero - 2: Verdadero - 1: Algo cierto - 0: No es cierto
              </p>
              <Paso1Clase3
                set_answers={set_experienceWithGodAnswers}
                answers={experienceWithGodAnswers}
                numberOptions={[3, 2, 1, 0]}
                questions={sacredPathQuestions}
              />
              {habeasData}
              <Button primary onClick={() => (verifyStepThree() ? set_step(4) : set_step(5))}>
                Enviar las encuestas y finalizar
              </Button>
            </div>
          )}
          {step === 5 && (
            <div className="quoteSending">
              <h2>
                “¡Gracias por hacerme tan maravillosamente complejo! Tu fino trabajo es maravilloso,
                lo sé muy bien.” Salmo 139: 14 (NTV).
              </h2>
              <p>Enviando...</p>
            </div>
          )}
          {step === 6 &&
            (parsedQS?.showans === 'show' ? (
              <InPersonStudentResults
                DISCAnswers={DISCAnswers}
                experienceWithGodAnswers={experienceWithGodAnswers}
                spiritualGiftAnswers={spiritualGiftAnswers}
                parsedQS={parsedQS}
                student={student}
              />
            ) : (
              <>
                <div className="quoteSending">
                  <h2>¡Pronto te contactaremos para darte los resultados!</h2>
                  <p>
                    <small>
                      Muy pronto estaremos en contacto contigo para compartirte los resultados de tu
                      encuesta.
                    </small>
                  </p>
                  <p>¡Enviado!</p>
                </div>
                <span onClick={() => set_step(1)} style={{ display: 'none' }}>
                  .
                </span>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Clase3Spiritual;
