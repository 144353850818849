import React from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';
import groupService from '../../../services/groupService';
import Swal from 'sweetalert2';
import { avoCaptConstructor } from '../../helpers';

const GroupMemberModal = (props) => {
  const { member, getInfo, isCouples, isPendingNewMembers = false } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const deleteGroupMember = async () => {
    const { value: text } = await Swal.fire({
      title: `¿Seguro que desea ${isPendingNewMembers ? 'no aceptar' : 'sacar'} a esta persona?`,
      text: 'Escribe la razón',
      input: 'text',
      inputPlaceholder: 'Escribe la razón',
      inputAttributes: {
        'aria-label': 'razon',
      },
      showCancelButton: true,
    });

    if (text) {
      setLoading(true);
      groupService
        .GroupMemberDelete({ id: member.id, group: member.group, statusObservation: text })
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            getInfo();
            setOpen(false);
            Swal.fire(response.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) Swal.fire(err.response.data.message);
        });
    }
  };

  const acceptGroupMember = async () => {
    Swal.fire({
      title: '¿Seguro que desea ingresar a esta persona a su grupo?',
      showCancelButton: true,
      confirmButtonText: 'Si, ingresar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        setLoading(true);
        groupService
          .GroupMemberAccept({ id: member.id, group: member.group, statusObservation: 'Ingresado' })
          .then((response) => {
            setLoading(false);
            if (response.data.success) {
              getInfo();
              setOpen(false);
              Swal.fire(response.data.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            if (err.response) Swal.fire(err.response.data.message);
          });
      }
    });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>Ver</Button>}
    >
      <Modal.Content>
        <Modal.Description>
          {member.male && (
            <>
              <Header as="h2">Él</Header>
              <p>
                <b>
                  {member.male.name} {member.male.lastName}
                </b>
              </p>
              <p>
                Identificación: <b>{member.male.identification}</b>
              </p>
              {member.male.email && (
                <p>
                  Email: <b>{member.male.email}</b>
                </p>
              )}
              {member.male.phone && (
                <p>
                  Celular: <b>{member.male.phone}</b>
                </p>
              )}
              {member.male.ocupation && (
                <p>
                  Ocupación: <b>{member.male.ocupation}</b>
                </p>
              )}
              <p>
                Cumpleaños: <b>{member.male.birthdate}</b>
              </p>
              <a
                href={`/crecer/asistencia?identification=${member.male.identification}&phone=${
                  member.male.phone
                }&ac=${avoCaptConstructor(member.male.identification)}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button>Ver progreso de Crecer</Button>
              </a>
            </>
          )}
          {member.female && (
            <>
              <Header as="h2">Ella</Header>
              <p>
                <b>
                  {member.female.name} {member.female.lastName}
                </b>
              </p>
              <p>
                Identificación: <b>{member.female.identification}</b>
              </p>
              {member.female.email && (
                <p>
                  Email: <b>{member.female.email}</b>
                </p>
              )}
              {member.female.phone && (
                <p>
                  Celular: <b>{member.female.phone}</b>
                </p>
              )}
              {member.female.ocupation && (
                <p>
                  Ocupación: <b>{member.female.ocupation}</b>
                </p>
              )}
              <p>
                Cumpleaños: <b>{member.female.birthdate}</b>
              </p>
              <a
                href={`/crecer/asistencia?identification=${member.female.identification}&phone=${
                  member.female.phone
                }&ac=${avoCaptConstructor(member.female.identification)}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button>Ver progreso de Crecer</Button>
              </a>
            </>
          )}
          {isPendingNewMembers && (
            <Button basic color="black" onClick={() => acceptGroupMember()}>
              Ingresar
            </Button>
          )}
          <Button basic color="red" onClick={() => deleteGroupMember()}>
            {isPendingNewMembers ? 'Rechazar' : 'Sacar del grupo'}
          </Button>
          {isCouples && (
            <>
              <Header as="h2">Pareja</Header>
              <p>
                Tipo de unión: <b>{member.uniontype}</b>
              </p>
            </>
          )}
          <Button disabled={loading} onClick={() => setOpen(false)} secondary>
            Cerrar
          </Button>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default GroupMemberModal;
