import styled from '@emotion/styled';

export const GeneracionesParentComponent = styled.div`
  .form {
    padding: 10px 0 0 0;
    label {
      display: block;
      &.profilePhoto {
        margin: 22px auto;
        cursor: pointer;
      }
    }
    &.registerParent {
      text-align: center;
    }
  }
  .parentMatch {
    position: relative;
    .acudiente {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0 0 0;
      h3,
      p {
        margin: 0;
      }
      h2 {
        padding-top: 10px;
      }
      .profilePhoto {
        margin: 0;
      }
      .info-acudiente {
        padding: 0 0 0 20px;
        .ui.button {
          margin: 10px 0 0 0;
        }
      }
      .form-acudiente {
        width: 100%;
      }
    }
    .children {
      padding: 20px 0 0 0;
    }
    .back {
      top: 0;
      background-color: white;
      &.bottom {
        text-align: end;
        margin: 10px 0 0 0;
        position: sticky;
        bottom: 0;
      }
    }
  }
  .profilePhoto {
    margin: 0px 0 22px 20px;
  }
  .birthdate {
    margin: 0 22px;
  }
`;
