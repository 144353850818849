import React from 'react';
import Button from '../components/common/Button';
import authService from '../services/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import { localhostname } from '../components/helpers';

const Login = (props) => {
  const [inputs, setInputs] = React.useState({});
  const [errorData, set_errorData] = React.useState(null);
  const [loading, set_loading] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      set_errorData(null);
    }, 3000);
  }, [errorData]);

  React.useEffect(() => {
    if (localStorage.getItem('userToken')) {
      authService.logout();
    }
  }, []);

  function login(e) {
    const { email, password, captcha } = inputs;

    if (!captcha && window.location.hostname !== localhostname) {
      set_errorData('Completa el captcha');
      return;
    }
    if (!email || !password) {
      set_errorData('Todos los campos son obligatorios');
      return;
    }
    set_loading(true);
    authService
      .login({ email, password, captcha })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          localStorage.setItem('userToken', response.data.token);
        }
      })
      .catch((error) => {
        if (error.response) set_errorData(error.response.data.message);
        set_loading(false);
      });
  }

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <form onSubmit={(e) => login(e)} className="Registration__form">
      <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>Inicia Sesión</label>
      <input
        placeholder="Usuario"
        name={'email'}
        className="Registration__input Registration__input-id"
        value={inputs.email}
        onChange={handleInputs}
        style={{ width: '33%', margin: '5px' }}
      />

      <input
        style={{ width: '33%', margin: '5px' }}
        onChange={handleInputs}
        name={'password'}
        value={inputs.password}
        placeholder="Contraseña"
        type="password"
        className="Registration__input Registration__input-email"
      />
      {errorData && (
        <label style={{ display: 'block', padding: '20px 0', color: 'red' }}>{errorData}</label>
      )}
      <div style={{ margin: '20px auto' }} className={'captcha'}>
        {!loading && (
          <ReCAPTCHA
            style={{ margin: 'auto' }}
            sitekey={process.env.REACT_APP_RECAPTCHA}
            onChange={captchaOnChange}
          />
        )}
      </div>
      <Button text="Inicia" disabled={loading} onClick={(e) => login(e)} />
    </form>
  );
};
export default Login;
