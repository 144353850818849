import { Image } from 'semantic-ui-react';
import NewUpdateChildren from '../../../containers/church-members/church-service/generaciones/parentsMatch/StepParentMatch/new-updateChildren';
import RegisterToAService from '../../../containers/church-members/church-service/generaciones/parentsMatch/StepParentMatch/RegisterToAService';
import { profileImage } from '../../helpers/index';
import queryString from 'query-string';

const ParentsMatchChildrenRow = (props) => {
  const { kid, parent, getInfo } = props;
  const { name, lastName } = kid;

  let query = window.location.search;
  let parsedQueryString = queryString.parse(query);

  return (
    <tr>
      <td>
        <Image src={profileImage(kid)} rounded size="small" />
      </td>
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{lastName}</p>
      </td>
      <td>
        <RegisterToAService
          getInfo={getInfo}
          parent={parent}
          kid={kid}
          preOpen={parsedQueryString?.genKidId === kid.id}
        />
      </td>
      <td>
        <NewUpdateChildren parent={parent} kid={kid} getInfo={getInfo} />
      </td>
    </tr>
  );
};

export default ParentsMatchChildrenRow;
