import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ExperienceTeamCheckRowDef } from '../../components/common/adminTable/ExperienceTeamCheckRow';
import experienceService from '../../services/experienceService';
import { Tab, Select, Button, Input } from 'semantic-ui-react';
import { churchMinistries, formSelectService, scrollUp } from '../../components/helpers';
import eventService from '../../services/eventService';
import styled from '@emotion/styled';
import { VolunteersBirthdaysModal } from './components/volunteersBirthdays';
import Table from '../../components/table/aggrid';

const ScrollUpComponent = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  .button {
    width: 20px;
    border-radius: 50%;
    height: 60px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
`;

const ConfirmedVolunteersCheck = (props) => {
  const [volunteersByEvent, set_volunteersByEvent] = useState([]);
  const [inputs, setInputs] = useState({ eventSlugname: 'Auditorio_Principal' });
  const [previousEventsNames, set_previousEventsNames] = useState([]);
  const [loading, set_loading] = useState([]);
  const [showImgs, set_showImgs] = useState(true);
  const [showCedNRole, set_showCedNRole] = useState(false);
  const gridApi = useRef(null);

  const eventSlugname = inputs.eventSlugname;

  useEffect(() => {
    getVolunteersByEvent();
  }, [eventSlugname]);

  useEffect(() => {
    getPreviousEventNames();
  }, []);

  useEffect(() => getVolunteersByEvent(), [inputs.serviceArea]);

  useEffect(() => gridApi?.current?.api?.setQuickFilter(inputs.keyword), [inputs.keyword]);

  const getVolunteersByEvent = () => {
    set_loading?.(true);

    const { eventSlugname, serviceArea } = inputs;

    experienceService
      .getAllVolunteerServiceLogs({
        eventSlugname,
        serviceArea,
        startDate: moment().tz('America/Bogota').startOf('day')?.toDate(),
        endDate: moment().tz('America/Bogota').endOf('day')?.toDate(),
      })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_volunteersByEvent(response.data.volunteersByEvent);
        }
      })
      .catch((err) => {
        set_loading?.(false);
      });
  };

  const getPreviousEventNames = () => {
    set_loading(true);
    eventService
      .getEventsNames({ postDays: 3 })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') getVolunteersByEvent();
  };

  const cleanFilters = () => {
    let newInputs = { ...inputs, keyword: '' };
    if (inputs.serviceArea) {
      newInputs = { ...newInputs, serviceArea: '' };
    }
    setInputs({ ...newInputs, serviceArea: '' });
  };

  const listOfVolunteersWithBdDte = () => {
    const volunteers = [];
    volunteersByEvent.forEach((service) => {
      service.EventVolunteers.forEach((data) => {
        volunteers.push(data.Volunteer);
      });
    });
    return volunteers;
  };

  const uniqueVolunteers = useCallback((eventVolunteers) =>
    eventVolunteers?.reduce((acc, volunteer) => {
      if (!acc.some((vol) => vol?.Volunteer?.guest_id === volunteer?.Volunteer?.guest_id)) {
        acc.push(volunteer);
      }
      return acc;
    }, [])
  );

  return (
    <section className="AdminTable">
      <h2>Voluntarios confirmados</h2>
      <div>
        {formSelectService({
          Select,
          label: 'Proximo evento a servir',
          name: 'eventSlugname',
          handler: (a, { value }) => {
            if (value === 'Servicio_del_fin_de_semana') value = 'Auditorio_Principal';
            setInputs({ ...inputs, eventSlugname: value });
          },
          value: eventSlugname,
          options: ['Servicio_del_fin_de_semana', ...previousEventsNames],
        })}
        <div>
          <Select
            placeholder="Selecciona el area para consultar."
            style={{
              display: 'inline-block',
              margin: ' 7px 0',
              width: '45%',
            }}
            onChange={selectHandle}
            name="serviceArea"
            loading={loading}
            value={inputs.serviceArea}
            options={[
              { key: '', value: '', text: 'Todos' },
              ...churchMinistries.sort().map((a) => {
                return { key: a, value: a, text: a };
              }),
            ]}
          />
        </div>
        <div>
          <Button primary onClick={() => set_showImgs(!showImgs)}>
            {showImgs ? 'Ocultar' : 'Mostrar'} fotos
          </Button>
          <Button primary onClick={() => set_showCedNRole(!showCedNRole)}>
            {showCedNRole ? 'Ocultar' : 'Mostrar'} cedula y rol
          </Button>
          <VolunteersBirthdaysModal
            daysRange={7}
            volunteers={listOfVolunteersWithBdDte()}
            onOpen={cleanFilters}
            showArea
          />
        </div>
      </div>
      <div style={{ margin: '20px 0 0 0' }}>
        <Tab
          panes={[
            ...volunteersByEvent.map((service) => {
              const totalSnack =
                uniqueVolunteers(service?.EventVolunteers)?.filter((volunteer) => volunteer.snack).length || 0;
              const totalAttendance =
                uniqueVolunteers(service?.EventVolunteers)?.filter((volunteer) => volunteer.attendance).length || 0;

              return {
                menuItem: moment(service?.date).format('dddd, DD [de] MMM'),
                render: () => (
                  <Tab.Pane attached={false} key={service.id}>
                    <table style={{ width: '40%', textAlign: 'center' }}>
                      <thead>
                        <tr>
                          <th>Total voluntarios</th>
                          <th>Con entrada</th>
                          <th>Con refrigerio</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{uniqueVolunteers(service?.EventVolunteers)?.length}</td>
                          <td>{totalAttendance}</td>
                          <td>{totalSnack}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Input
                      loading={loading}
                      name={'keyword'}
                      onChange={(e) => setInputs({ ...inputs, keyword: e.target.value })}
                      value={inputs.keyword}
                      onKeyPress={onEnter}
                      style={{ width: '45%', margin: '20px 0' }}
                      placeholder="Buscar..."
                    />
                    <Button color="black" onClick={cleanFilters}>
                      Limpiar
                    </Button>
                    <Table
                      rowData={uniqueVolunteers(service?.EventVolunteers)}
                      rowHeight={90}
                      tableRef={gridApi}
                      columnDefs={ExperienceTeamCheckRowDef({
                        loading,
                        service,
                        eventSlugname,
                        getVolunteersByEvent,
                        set_loading,
                        showImgs,
                        showCedNRole,
                        showArea: true,
                      })}
                      defaultCol={{
                        suppressMovable: true,
                      }}
                    />
                  </Tab.Pane>
                ),
              };
            }),
          ]}
        />
        <ScrollUpComponent>
          <Button color="black" onClick={scrollUp}>
            <span>↑</span>
          </Button>
        </ScrollUpComponent>
      </div>
    </section>
  );
};

export default ConfirmedVolunteersCheck;
