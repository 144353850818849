import moment from 'moment';
import { Button } from 'semantic-ui-react';

export const prospectLeadersRowDef = ({ set_showProspectForm, loading }) => {
  return [
    {
      headerName: 'Fecha',
      width: 140,
      field: 'createdAt',
      cellRenderer: (params) => (
        <>{moment.tz(params?.data?.createdAt, 'America/Bogota').format('DD-MM-YYYY')}</>
      ),
    },
    {
      headerName: 'Nombres',
      width: 220,
      field: 'prospect_fullName',
      cellRenderer: (params) =>
        params?.data?.Guest?.name
          ? `${params?.data?.Guest?.name} ${params?.data?.Guest?.lastName}`
          : params?.data?.prospect_fullName,
    },
    { headerName: 'Lider', field: 'leader_name', width: 140 },
    {
      headerName: 'Llenó aspirante',
      field: 'guest_id',
      width: 140,
      cellRenderer: (params) => <>{params?.data?.guest_id ? 'Si' : 'No'}</>,
    },
    {
      headerName: 'Asignado',
      field: 'assigned',
      width: 140,
      cellRenderer: (params) => <>{params?.data?.assigned ? 'Si' : 'No'}</>,
    },
    {
      headerName: 'Entrevistado',
      field: 'interviewed',
      width: 140,
      cellRenderer: (params) => <>{params?.data?.interviewed ? 'Si' : 'No'}</>,
    },
    {
      headerName: 'Ver',
      field: 'actions',
      width: 135,
      cellRenderer: (params) => (
        <Button size="tiny" disabled={loading} onClick={() => set_showProspectForm(params?.data?.id)}>
          Ver
        </Button>
      ),
    },
  ];
};
