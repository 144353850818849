import React from 'react';
import { Button, Select } from 'semantic-ui-react';
import EventService from '../../../../services/eventService';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import {
  formSelectService,
  sortObjects,
  generacionesServices,
} from '../../../../components/helpers';
import { DateRangePicker } from 'react-dates';
import moment from '../../../../services/moment_instance';
import generacionesService from '../../../../services/generacionesService';
import GeneracionesChurchServiceGuests from './GeneracionesChurchServiceGuests';
import { renderMonthElement } from '../../../../components/form/Form';

const GeneracionesServicesTable = (props) => {
  const [loading, set_loading] = React.useState(false);
  const [services, set_services] = React.useState({});
  const [inputs, setInputs] = React.useState({});
  const [serviceAttendance, set_serviceAttendance] = React.useState([]);
  const [clickedEvent, set_clickedEvent] = React.useState({});
  const [handleDate, set_handleDate] = React.useState({
    startDate: moment().startOf('day').isoWeekday(1),
    endDate: moment().endOf('day').isoWeekday(8),
  });
  const [focused, set_focused] = React.useState({});
  const [previousEventsNames, set_previousEventsNames] = React.useState([]);

  React.useEffect(() => {
    getPreviousEventNames();
  }, []);

  const getPreviousEventNames = () => {
    const body = {
      prevDays: 1,
      postDays: 1,
    };
    set_loading(true);
    EventService.getEventsNames(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  let query = window.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    setInputs({
      ...inputs,
      generacionesCategory: parsedQueryString.category ? parsedQueryString.category : undefined,
      eventSlugname: parsedQueryString.eventSlugname
        ? parsedQueryString.eventSlugname
        : 'Auditorio_Principal',
    });
  }, []);

  const getDate = (date) => moment.tz(date, 'America/Bogota').format('YYYY-MM-DD');

  React.useEffect(() => {
    getEvents();
  }, [inputs.eventSlugname]);

  React.useEffect(() => {
    if (inputs.startDate && inputs.endDate) {
      getEvents();
    }
  }, [inputs.startDate, inputs.endDate]);

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const getEvents = () => {
    const { eventSlugname, startDate, endDate } = inputs;
    set_loading(true);
    let body = {
      eventSlugname,
      startDate: startDate || handleDate.startDate.toDate(),
      endDate: endDate || handleDate.endDate.toDate(),
    };

    EventService.getAuditoriumEvents(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          let events = {};
          sortObjects(response.data.events, 'date', 'asc').forEach((event) => {
            events[getDate(event.date)] = [...(events[getDate(event.date)] || []), event];
          });
          set_services(events);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const getEvent = (id) => {
    const { generacionesCategory } = inputs;

    if (!generacionesCategory) return Swal.fire('Selecciona una categoria de generaciones');

    props.history.push({
      search: `?category=${generacionesCategory}`,
    });

    set_loading(true);
    generacionesService
      .GetGeneracionesAttendance({ id, generacionesCategory })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          if (response.data.event.length) {
            set_serviceAttendance(response.data.event);
          } else {
            Swal.fire('No hay niños registrados a este servicio');
          }
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const handleDateChange = (startDate, endDate) => {
    setInputs({
      ...inputs,
      startDate: startDate ? startDate.startOf('day')._d.getTime() : null,
      endDate: endDate ? endDate.endOf('day')._d.getTime() : null,
    });
    set_handleDate({ startDate, endDate });
  };

  const serviceDate = (date) => moment.tz(date, 'America/Bogota').format('dddd DD [de] MMMM');

  return (
    <div className="service selectAService">
      <h1
        style={{
          display: 'block',
          padding: '20px',
          textAlign: 'center',
          fontSize: '45px',
        }}
      >
        Servicios de generaciones
      </h1>
      {serviceAttendance.length === 0 ? (
        <>
          <div style={{ padding: '20px 0 0 0' }}>
            {formSelectService({
              Select,
              label: 'Auditorio',
              name: 'eventSlugname',
              handler: selectHandle,
              value: inputs.eventSlugname || '',
              options: previousEventsNames,
            })}
          </div>
          <div>
            {formSelectService({
              Select,
              label: 'Categoria',
              name: 'generacionesCategory',
              handler: selectHandle,
              value: inputs.generacionesCategory || '',
              options: generacionesServices,
            })}
          </div>
          <div style={{ padding: '20px 0 0 0' }}>
            <DateRangePicker
              startDatePlaceholderText="Fecha Inicio"
              endDatePlaceholderText="Fecha Fin"
              startDate={handleDate.startDate}
              startDateId="startDateRange"
              minimumNights={0}
              endDate={handleDate.endDate}
              endDateId="endDateRange"
              onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
              focusedInput={focused}
              onFocusChange={(focused) => set_focused(focused)}
              isOutsideRange={() => false}
              renderMonthElement={renderMonthElement}
            />
          </div>
          <label
            style={{
              display: 'block',
              padding: '20px 0px 20px 0px',
              fontSize: '25px',
            }}
          >
            Selecciona el servicio que quieres visualizar
          </label>
          {!loading && Object.keys(services).length === 0 && (
            <p>
              No hay servicios creados
              {inputs.startDate && inputs.endDate
                ? ' en las fechas seleccionadas.'
                : ' esta semana.'}
            </p>
          )}
          {loading && <p>Cargando servicio...</p>}
          <div className="dates">
            {Object.keys(services).map((date) => (
              <>
                <p style={{ padding: '20px 0 0 0' }}>{serviceDate(date)}</p>
                {services[date].map((service) => (
                  <Button
                    primary
                    disabled={loading}
                    onClick={(e) => {
                      getEvent(service.id);
                      set_clickedEvent(service);
                    }}
                  >
                    <div className="time">
                      {moment.tz(service.date, 'America/Bogota').format('hh:mm a')}
                    </div>
                  </Button>
                ))}
              </>
            ))}
          </div>
        </>
      ) : (
        <GeneracionesChurchServiceGuests
          set_serviceAttendance={set_serviceAttendance}
          serviceAttendance={serviceAttendance}
          clickedEvent={clickedEvent}
          generacionesCategory={inputs.generacionesCategory}
          history={props.history}
        />
      )}
    </div>
  );
};

export default GeneracionesServicesTable;
