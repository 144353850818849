import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Icon } from 'semantic-ui-react';
import { chPerm } from '../components/helpers';
import queryString from 'query-string';
import CrecerReport from '../components/crecer/crecerReport';
import SeminaryReport from '../components/crecer/seminaryReport';

const AdminPanel = (props) => {
  const [currentFolder, set_currentFolder] = React.useState('');

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    props.history.push(`?folder=${currentFolder || ''}`);
  }, [currentFolder]);

  React.useEffect(() => {
    set_currentFolder(parsedQueryString.folder || '');
  }, []);

  return (
    <section className="admin">
      <div>
        {chPerm(['crecer']) && (
          <Modal
            closeIcon
            onClose={() => set_currentFolder('')}
            open={currentFolder === 'crecer'}
            trigger={
              <Button primary onClick={() => set_currentFolder('crecer')}>
                <Icon name="folder" /> Crecer
              </Button>
            }
          >
            <Modal.Header>
              <Icon name="folder open" /> Crecer
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {!chPerm(['campus_rionegro'], true) && (
                  <Link to="/event-dates">
                    <Button secondary>
                      <Icon name="configure" /> Fechas de crecer
                    </Button>
                  </Link>
                )}
                <Link to="/admin/crecer/1/encuestas">
                  <Button secondary>
                    <Icon name="archive" /> Crecer P1 Encuestas
                  </Button>
                </Link>
                {chPerm(['crecerAdmin']) && <CrecerReport />}
                {chPerm(['seminaryAdmin']) && <SeminaryReport />}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        {chPerm([
          'Auditorio_PrincipalAdmin',
          'volunteers',
          'newMembers',
          'experienceCoord',
          'eventAdmin',
          'generaciones',
        ]) && (
          <Modal
            closeIcon
            onClose={() => set_currentFolder('')}
            open={currentFolder === 'experience'}
            trigger={
              <Button primary onClick={() => set_currentFolder('experience')}>
                <Icon name="folder" /> Experiencia
              </Button>
            }
          >
            <Modal.Header>
              <Icon name="folder open" /> Experiencia
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {chPerm(['newMembers', 'experienceCoord']) && (
                  <Link to="/new-members">
                    <Button secondary>
                      <Icon name="archive" /> Conexión
                    </Button>
                  </Link>
                )}
                {chPerm(['experienceCoord', 'eventAdmin', 'generaciones']) && (
                  <Link to="/church-services">
                    <Button secondary>
                      <Icon name="archive" /> Servicios y Eventos
                    </Button>
                  </Link>
                )}
                {chPerm(['generaciones', 'experienceCoord']) && (
                  <Link to="/admin/generaciones">
                    <Button secondary>
                      <Icon name="archive" /> Generaciones
                    </Button>
                  </Link>
                )}
                {chPerm(['volunteers']) && (
                  <Button primary onClick={() => set_currentFolder('volunteers')}>
                    <Icon name="folder" /> Voluntarios
                  </Button>
                )}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        {chPerm(['groups', 'relacionessanas']) && (
          <Modal
            closeIcon
            onClose={() => set_currentFolder('')}
            open={currentFolder === 'groups'}
            trigger={
              <Button primary onClick={() => set_currentFolder('groups')}>
                <Icon name="folder" /> Grupos
              </Button>
            }
          >
            <Modal.Header>
              <Icon name="folder open" /> Grupos
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <div>
                  {chPerm(['groups', 'relacionessanas']) && (
                    <Link to="/admin/groups/Grupo_Seminario">
                      <Button secondary>
                        <Icon name="archive" /> Seminarios
                      </Button>
                    </Link>
                  )}
                  {chPerm(['groups']) && (
                    <>
                      <Link to="/admin/groups/Equipo_de_Líderes">
                        <Button secondary>
                          <Icon name="archive" /> Equipos
                        </Button>
                      </Link>

                      <Link to="/admin/groups/Grupo_Discipulado">
                        <Button secondary>
                          <Icon name="archive" /> Grupos
                        </Button>
                      </Link>
                    </>
                  )}
                </div>
                <div>
                  {chPerm(['groupsAdmin'], true) && (
                    <Link to="/admin/prospect-leaders">
                      <Button secondary>
                        <Icon name="archive" /> Aspirantes
                      </Button>
                    </Link>
                  )}
                  {chPerm(['admin']) && (
                    <Link to="/guest-leaders">
                      <Button secondary>
                        <Icon name="archive" /> Lideres y Discipulos
                      </Button>
                    </Link>
                  )}
                </div>
                {chPerm(['groups', 'relacionessanas']) && (
                  <Link to="/admin/groups/compliances">
                    <Button secondary>
                      <Icon name="archive" />
                      Acuerdos de Confidencialidad
                    </Button>
                  </Link>
                )}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        {chPerm(['admin']) && (
          <Modal
            closeIcon
            onClose={() => set_currentFolder('')}
            open={currentFolder === 'platform'}
            trigger={
              <Button primary onClick={() => set_currentFolder('platform')}>
                <Icon name="folder" /> Plataforma
              </Button>
            }
          >
            <Modal.Header>
              <Icon name="folder open" /> Plataforma
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {chPerm(['admin']) && (
                  <Link to="/all-guests">
                    <Button secondary>
                      <Icon name="archive" /> Buscar Personas
                    </Button>
                  </Link>
                )}
                {chPerm(['users']) && (
                  <Link to="/all-users">
                    <Button secondary>
                      <Icon name="archive" /> Ver Usuarios
                    </Button>
                  </Link>
                )}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        {chPerm(['network', 'counseling']) && (
          <Modal
            closeIcon
            onClose={() => set_currentFolder('')}
            open={currentFolder === 'network'}
            trigger={
              <Button primary onClick={() => set_currentFolder('network')}>
                <Icon name="folder" /> Social
              </Button>
            }
          >
            <Modal.Header>
              <Icon name="folder open" /> Social
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {chPerm(['network']) && (
                  <Link to="/all-network">
                    <Button secondary>
                      <Icon name="archive" /> Red Empresas
                    </Button>
                  </Link>
                )}
                {chPerm(['counseling']) && (
                  <Link to="/counseling">
                    <Button secondary>
                      <Icon name="archive" /> Consejería
                    </Button>
                  </Link>
                )}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        )}
        <Modal
          closeIcon
          onClose={() => set_currentFolder('')}
          open={currentFolder === 'volunteers'}
        >
          <Modal.Header>
            <Icon name="folder open" /> Experiencia / Voluntarios
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Link to="/all-new-volunteers">
                <Button secondary>
                  <Icon name="archive" /> Aspirantes
                </Button>
              </Link>
              <Link to="/all-volunteers">
                <Button secondary>
                  <Icon name="archive" /> Voluntarios
                </Button>
              </Link>
              <Link to="/all-confirmed-volunteers">
                <Button secondary>
                  <Icon name="archive" /> Eventos
                </Button>
              </Link>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    </section>
  );
};

export default AdminPanel;
