import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import styled from '@emotion/styled';
import { useRef } from 'react';
import { localeAgGridSP } from './localeAgGrid';
import { customComparator } from './helpers';

const AGGridWrapper = styled.div`
  width: 100%;
  height: 100%;
  .ag-root-wrapper {
    min-height: 115vh;
  }
  .ag-root {
    min-height: 100vh;
  }
  button.ui.button.btn-grid {
    margin: 0px;
    height: 50px;
    padding: 1px 16px;
  }
  .ag-paging-panel {
    z-index: 100;
    background-color: white;
  }
`;
const Table = (props) => {
  const {
    columnDefs,
    rowData,
    defaultCol,
    rowHeight,
    tableRef,
    defaultCellStyles,
    onGridReady,
    suppressDragLeaveHidesColumns = true,
    pagination = false,
  } = props;
  const gridApi = useRef(null);
  let gridRef = tableRef || gridApi;

  const defaultColDef = {
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    sortable: true,
    cellStyle: {
      fontSize: '18px',
      whiteSpace: 'initial',
      lineHeight: 'normal',
      ...(defaultCellStyles || {}),
    },
    comparator: customComparator,
    ...(defaultCol || {}),
  };
  
  return (
    <AGGridWrapper className="ag-theme-alpine" style={{ width: '100%', minHeight: '50vh' }}>
      <AgGridReact
        rowHeight={rowHeight}
        defaultColDef={defaultColDef}
        suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
        columnDefs={columnDefs}
        ref={gridRef}
        rowData={rowData}
        onGridReady={onGridReady}
        pagination={pagination}
        localeText={localeAgGridSP}
      />
    </AGGridWrapper>
  );
};
export default Table;
