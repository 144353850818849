import proxybase from './proxybase';

class GeneracionesService {
  GeneracionesParentMatch(body) {
    return proxybase
      .post(`/generaciones/GeneracionesParentMatch`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  GetGeneracionesAttendance(body) {
    return proxybase
      .post(`/generaciones/GetGeneracionesAttendance`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createGeneracionesGuest(body) {
    return proxybase
      .post(`/generaciones/createGeneracionesGuest`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateGeneracionesGuest(body) {
    return proxybase
      .post(`/generaciones/updateGeneracionesGuest`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  MakeAGeneracionesReservation(body) {
    return proxybase
      .post(`/generaciones/MakeAGeneracionesReservation`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deleteGeneracionesGuest(body) {
    return proxybase
      .post(`/generaciones/SDeleteGeneracionesGuest`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  CancelGeneracionesReservation(body) {
    return proxybase
      .post(`/generaciones/CancelGeneracionesReservation`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  ConfirmGeneracionesReservation(body) {
    return proxybase
      .post(`/generaciones/ConfirmGeneracionesReservation`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const GeneracionesServiceInstance = new GeneracionesService();
export default GeneracionesServiceInstance;
