// src/components/Grupos.js
const Events = () => {
  return (
    <div style={{ width: '100%',
      height: '100%',
      padding: '20px',
      boxSizing: 'border-box',
      backgroundColor: '#fafdff',
      borderRadius: '20px'}}>
      <h2>Eventos</h2>
      <p>Proximamente</p>
    </div>
  );
};

export default Events;
