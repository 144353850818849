import { useState } from 'react';
import { Button, Modal, Select } from 'semantic-ui-react';
import { eventSlugnameTrim } from '../../../components/helpers';
import authService from '../../../services/auth';

const EditUsersModal = (props) => {
  const { set_openModal, user, previousEventsNames, getUsers } = props;
  const { id, status, allowed_events } = user;

  const [inputs, setInputs] = useState({ allowed_events });

  const saveUser = () => {
    authService
      .editUser({ ...inputs, id })
      .then(() => {
        getUsers();
        set_openModal(false);
      })
      .catch((error) => {
        console.log(`An error occurred trying to edit the user ${error}.`);
      });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <Modal
      open
      onClose={() => set_openModal(false)}
      size="large"
      style={{ backgroundColor: 'white' }}
    >
      <Modal.Header style={{ color: 'black' }}>{'Editar usuario'}</Modal.Header>
      <Modal.Content scrolling style={{ height: '600px' }}>
        <Modal.Description style={{ color: 'black' }}>
          Usuario: {user.email}
          {status.includes('Auditorio_Principal') &&
          (!status.includes('totalAccess') || !status.includes('Auditorio_PrincipalAdmin')) ? (
            <div>
              <label htmlFor="allowed_events">
                {`Seleccione los eventos que puede acceder el usuario`}
              </label>
              <Select
                placeholder={'Seleccione los eventos que puede acceder el usuario'}
                onChange={selectHandle}
                value={inputs.allowed_events || []}
                name={'allowed_events'}
                id={'allowed_events'}
                fluid
                multiple
                selection
                options={previousEventsNames?.map((opt) => ({
                  key: opt,
                  value: opt,
                  text: eventSlugnameTrim(opt),
                }))}
              />
            </div>
          ) : (
            <p>
              {status.includes('totalAccess') || status.includes('Auditorio_PrincipalAdmin')
                ? 'Este usuario tiene acceso a ver todos los eventos'
                : 'Este usuario no tiene acceso a ver los eventos'}
            </p>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secundary onClick={() => set_openModal('')}>
          Volver
        </Button>
        <Button primary onClick={saveUser}>
          Guardar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EditUsersModal;
