import { DISCResults, showSpiritualGifts } from '../../../components/crecer/crecerUserAnswers';
import { countAnswers } from '../../../components/helpers';
import { sacredPath, spiritualGifts } from './clase3Questions';
import moment from 'moment';

export const InPersonStudentResults = (props) => {
  const { parsedQS, spiritualGiftAnswers, DISCAnswers, experienceWithGodAnswers, student } = props;

  return (
    <div>
      <p className="userdata">
        Encuesta de{' '}
        <strong>
          {student?.name} {student?.lastName}
        </strong>
      </p>
      <p className="userdata">
        Fecha de la encuesta: <strong>{moment.tz('America/Bogota').format('DD-MM-YYYY')}</strong>
      </p>
      <p className="userdata">
        <i>Encuesta elaborada en Crecer Presencial</i>
      </p>
      {parsedQS?.classnum === 'clase3' && (
        <>
          {DISCAnswers &&
            !!Object.keys(DISCAnswers).length &&
            DISCResults(countAnswers(DISCAnswers))}
          {spiritualGiftAnswers && !!Object.keys(spiritualGiftAnswers).length && (
            <div className="spiritual">
              <h2>Los dones espirituales que Dios te ha dado </h2>
              {showSpiritualGifts(spiritualGifts, countAnswers(spiritualGiftAnswers), 0)}
              {showSpiritualGifts(spiritualGifts, countAnswers(spiritualGiftAnswers), 1)}
              {showSpiritualGifts(spiritualGifts, countAnswers(spiritualGiftAnswers), 2)}
              {showSpiritualGifts(spiritualGifts, countAnswers(spiritualGiftAnswers), 3)}
            </div>
          )}
        </>
      )}
      {parsedQS?.classnum === 'clase4' &&
        experienceWithGodAnswers &&
        Object.keys(experienceWithGodAnswers).length && (
          <div className="connecting">
            <h2>La forma como te conectas con Dios </h2>
            {showSpiritualGifts(sacredPath, countAnswers(experienceWithGodAnswers), 0)}
            {showSpiritualGifts(sacredPath, countAnswers(experienceWithGodAnswers), 1)}
            {showSpiritualGifts(sacredPath, countAnswers(experienceWithGodAnswers), 2)}
          </div>
        )}
    </div>
  );
};
