import styled from '@emotion/styled';
import Header from './Header';

const AppLayoutContainer = styled.section`
  @import '@fortawesome/fontawesome-free/css/all.css';
  .Content__wrapper {
    ${(props) => (props.fullScreen ? 'display: block' : '')}
  }
`;

const Layout = ({ children, fullScreen = false }) => (
  <AppLayoutContainer className="App" fullScreen={fullScreen}>
    <Header />
    <main className="Content__wrapper">{children}</main>
  </AppLayoutContainer>
);

export default Layout;
