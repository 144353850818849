import Swal from 'sweetalert2';
import experienceService from '../../../services/experienceService';
import moment from 'moment';

export const thisWeekendService = (
  volunteers,
  service,
  eventSlugname,
  guest,
  getInfo,
  set_loading
) => {
  if (service?.date) {
    Swal.fire({
      title: `Confirmando para ${(eventSlugname === 'Auditorio_Principal'
        ? 'Servicio_del_fin_de_semana'
        : eventSlugname
      ).replace(/_/g, ' ')}`,
      text: `Confirmando a ${
        volunteers.length === 1 ? `${guest?.name} ${guest?.lastName}` : volunteers.length
      } para servir el ${moment(service?.date).format('dddd, DD/MMM/YYYY HH:mm:ss')}`,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No, volver',
    }).then((result) => {
      if (result.value) {
        set_loading(true);
        experienceService
          .VolunteerServiceLog({
            volunteers,
            eventSlugname,
            event_id: service?.id,
          })
          .then((response) => {
            set_loading(false);
            if (response.data.success) {
              getInfo();
              Swal.fire({
                title: '¡Confirmados!',
                text: response.data.message,
                icon: 'success',
                confirmButtonText: 'Ok',
              });
            } else {
              Swal.fire({
                title: 'Error',
                text: response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
              });
            }
          })
          .catch((error) => {
            set_loading(false);
            console.log(error);
          });
      }
    });
  } else {
    Swal.fire('Selecciona una fecha');
  }
};

export const cancelThisWeekendService = (
  volunteerIds,
  service,
  eventSlugname,
  guest,
  getInfo,
  set_loading
) => {
  if (service?.date) {
    Swal.fire({
      title: `Cancelar servicio para ${(eventSlugname === 'Auditorio_Principal'
        ? 'Servicio_del_fin_de_semana'
        : eventSlugname
      ).replace(/_/g, ' ')}`,
      text: `Cancelar participacion de ${
        volunteerIds.length === 1 ? `${guest?.name} ${guest?.lastName}` : volunteerIds.length
      } para el ${moment(service?.date).format('dddd, DD/MMM/YYYY HH:mm:ss')}`,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No, volver',
    }).then((result) => {
      if (result.value) {
        set_loading(true);
        experienceService
          .CancelVolunteerService({
            event_id: service?.id,
            volunteerIds,
            eventSlugname,
          })
          .then((response) => {
            set_loading(false);
            if (response.data.success) {
              getInfo();
              Swal.fire({
                title: '¡Listo!',
                text: 'Cancelacion confirmada',
                icon: 'success',
                confirmButtonText: 'Ok',
              });
            } else {
              Swal.fire(response.data.message);
            }
          })
          .catch((error) => {
            set_loading(false);
            console.log(error);
          });
      }
    });
  } else {
    Swal.fire('Selecciona una fecha');
  }
};

export const checkAttendanceToVolunteer = (
  volunteerId,
  service,
  eventSlugname,
  guest,
  getInfo,
  set_loading
) => {
  if (service?.date) {
    Swal.fire({
      title: `Marcar entrada`,
      text: `Marcando entrada a ${guest?.name} ${guest?.lastName}`,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No, volver',
    }).then((result) => {
      if (result.value) {
        set_loading(true);
        experienceService
          .VolunteerServiceChecks({
            volunteerId,
            eventSlugname,
            attendance: new Date(),
            event_id: service?.id,
          })
          .then((response) => {
            set_loading(false);
            if (response.data.success) {
              getInfo();
            } else {
              Swal.fire(response.data.message);
            }
          })
          .catch((error) => {
            set_loading(false);
            console.log(error);
          });
      }
    });
  }
};

export const checkSnackToVolunteer = (
  volunteerId,
  service,
  eventSlugname,
  guest,
  getInfo,
  set_loading
) => {
  if (service?.date) {
    Swal.fire({
      title: `Marcar refrigerio`,
      text: `Marcando refrigerio a ${guest?.name} ${guest?.lastName}`,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No, volver',
    }).then((result) => {
      if (result.value) {
        set_loading(true);
        experienceService
          .VolunteerServiceChecks({
            event_id: service?.id,
            volunteerId,
            eventSlugname,
            snack: new Date(),
          })
          .then((response) => {
            set_loading(false);
            if (response.data.success) {
              getInfo();
            } else {
              Swal.fire(response.data.message);
            }
          })
          .catch((error) => {
            set_loading(false);
            console.log(error);
          });
      }
    });
  }
};
