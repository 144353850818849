import { Button, Image } from 'semantic-ui-react';
import moment from 'moment';
import { boolSiNo, profileImage } from '../../helpers';
import NewVolunteerModal from '../../../containers/volunteer/VolunteerNewAdminModal';

export const getVolunteerAreas = (volunteer) =>
  volunteer?.map((volunteer) => volunteer?.serviceArea);

export const NewVolunteersRowDef = ({ getInfo, set_showVolunteer, showVolunteer }) => [
  {
    headerName: 'Fecha llenado',
    field: 'createdAt',
    cellRenderer: (val) => moment.tz(val.data?.createdAt, 'America/Bogota').format('DD-MM-YYYY'),
    width: 100,
  },
  {
    headerName: 'Datos personales',
    children: [
      {
        headerName: 'Foto',
        field: 'picture',
        cellRenderer: (val) => <Image src={profileImage(val.data)} rounded size="small" />,
        width: 100,
      },
      {
        headerName: 'Nombre',
        field: 'name',
        cellRenderer: (val) => `${val.data?.name} ${val.data?.lastName}`,
        width: 180,
      },
      {
        headerName: 'Telefono',
        field: 'phone',
        columnGroupShow: 'open',
        width: 100,
      },
      {
        headerName: 'Ocupacion',
        field: 'ocupation',
        columnGroupShow: 'open',
        width: 100,
      },
    ],
  },
  {
    headerName: 'Info voluntario',
    children: [
      {
        headerName: 'Entrenamiento',
        field: 'training',
        cellRenderer: (val) => boolSiNo(val.data?.training),
        width: 100,
      },
      {
        headerName: 'Aprobacion Lider',
        field: 'leadercheck',
        width: 150,
      },
      {
        headerName: 'Voluntario',
        field: 'volunteer',
        cellRenderer: (val) => (
          <p style={{ margin: '0' }}>
            {!!val?.data?.Volunteers?.length
              ? getVolunteerAreas(val?.data?.Volunteers)?.join(', ')
              : 'No'}
          </p>
        ),
        width: 100,
      },
    ],
  },
  {
    headerName: 'Ver',
    field: 'seemore',
    cellRenderer: (val) => (
      <>
        {showVolunteer === val?.data?.id && (
          <NewVolunteerModal
            NewVolunteer={val?.data}
            set_showVolunteer={set_showVolunteer}
            getInfo={getInfo}
          />
        )}
        <Button primary onClick={() => set_showVolunteer(val?.data?.id)}>
          Ver
        </Button>
      </>
    ),
    width: 150,
  },
];
