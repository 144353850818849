import { useEffect, useState } from 'react';
import { Button, Modal, Accordion } from 'semantic-ui-react';
import groupService from '../../../services/groupService';
import GroupRow from '../../../components/common/adminTable/GroupRow';
import { avoCaptConstructor } from '../../../components/helpers';
import ReportButton from '../../admin/ReportButton';

const PendingGroupMembers = (props) => {
  const { setOpen, isCouples } = props;
  const [loading, set_loading] = useState(true);
  const [pendingMembers, set_pendingMembers] = useState([]);

  const getInfo = () => {
    groupService
      .PendingGroupPeople()
      .then((response) => {
        if (response.data.success) {
          if (response.data.pendingGroups) {
            let panels = [];
            response.data.pendingGroups.forEach((group) => {
              panels = [
                ...panels,
                {
                  key: group.id,
                  title: `${group.groupName} - ${`${group.activityName.split('_').join(' ')}`}`,
                  content: {
                    content: (
                      <>
                        <div style={{ textAlign: 'end', padding: '0 0 10px 0' }}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`/grupos/lider?phone=${
                              group?.maleLeader
                                ? group?.maleLeader?.phone
                                : group?.femaleLeader?.phone
                            }&identification=${
                              group?.maleLeader
                                ? group?.maleLeader?.identification
                                : group?.femaleLeader?.identification
                            }&activityName=${group?.activityName}&ac=${
                              group?.maleLeader
                                ? avoCaptConstructor(group?.maleLeader?.identification)
                                : avoCaptConstructor(group?.femaleLeader?.identification)
                            }`}
                          >
                            <Button>Ver como lider</Button>
                          </a>
                        </div>
                        <div className="AdminTable">
                          <h3>Líderado por: </h3>
                          {!!group?.maleLeader && (
                            <p>
                              <span style={{ fontWeight: 'bolder' }}>
                                {group?.maleLeader.name} {group?.maleLeader.lastName}
                              </span>{' '}
                              <span>{group?.maleLeader.phone}</span>
                            </p>
                          )}
                          {!!group?.femaleLeader && (
                            <p>
                              <span style={{ fontWeight: 'bolder' }}>
                                {group?.femaleLeader.name} {group?.femaleLeader.lastName}
                              </span>{' '}
                              <span>{group?.femaleLeader.phone}</span>
                            </p>
                          )}
                          <table>
                            <thead>
                              <tr>
                                <th>Fecha inscripción</th>
                                {isCouples && (
                                  <>
                                    <th>Teléfono El</th>
                                    <th>Nombre El</th>
                                  </>
                                )}
                                <th>Teléfono {isCouples && 'Ella'}</th>
                                <th>Nombre {isCouples && 'Ella'}</th>
                                {group.takeattendance && <th>Asistencia</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {group?.GroupMembers?.map((member) => (
                                <GroupRow
                                  member={member}
                                  getInfo={getInfo}
                                  isCouples={isCouples}
                                  editing
                                  isPendingNewMembers={true}
                                  takeattendance={false}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ),
                  },
                },
              ];
            });
            set_loading(false);
            set_pendingMembers(panels);
          }
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open>
      <Modal.Header>Personas pendientes por evaluar ingreso a un grupo</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {loading ? <p>Cargando</p> : <Accordion panels={pendingMembers} />}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ReportButton
          URL={`/groups/get-groups-members-report`}
          fileName={`Personas pendientes grupos.xlsx`}
          filters={{ pendingMembers: true }}
          event="Descargar reporte"
        />
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PendingGroupMembers;
