import { Header, Modal, Table } from 'semantic-ui-react';

const TutorsResume = (props) => {
  const { rawTutors, set_showTutorsResume, showTutorsResume, selectHandle } = props;

  const countRows = (students, element, bool = true) => students.filter((e) => e[element] === bool);

  return (
    <Modal open={showTutorsResume} onClose={() => set_showTutorsResume(false)} centered={false}>
      <Modal.Header>Resumen de los tutores</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header></Header>
          <Table basic="very" celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Tutor
                  <Header as="h4">
                    <Header.Content>
                      <Header.Subheader>Click para ver los estudiantes </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell>Sin citar</Table.HeaderCell>
                <Table.HeaderCell>Citados</Table.HeaderCell>
                <Table.HeaderCell>Contactados</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rawTutors.map((tutor) => (
                <Table.Row>
                  <Table.Cell
                    onClick={(a) => {
                      selectHandle(a, { value: tutor.id, name: 'tutor_id' });
                      set_showTutorsResume(false);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <Header as="h4">
                      <Header.Content>
                        {tutor.name} {tutor.lastName}
                        <Header.Subheader>
                          Letra dominante: {tutor.Crecer1stClass3rds[0].DISC_letter.toUpperCase()}
                          {tutor.Crecer1stClass3rds[0].noLimitTutor ? '- SL' : ''}
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {
                      countRows(tutor.Crecer1stClass3rds[0].tutor_students, 'appointment', false)
                        .length
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {countRows(tutor.Crecer1stClass3rds[0].tutor_students, 'appointment').length}
                  </Table.Cell>
                  <Table.Cell>
                    {countRows(tutor.Crecer1stClass3rds[0].tutor_students, 'contacted').length}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default TutorsResume;
