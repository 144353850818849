import { Button, Modal } from 'semantic-ui-react';

const AdditionalInfoShow = (props) => {
  const { set_addInfoModal, aditionalinformation } = props;

  const objectToString = (obj) =>
    Object.entries(obj).reduce(
      (text, el) => (text += `-${el[0].replace(/_/g, ' ')}: <strong>${el[1]}</strong> <br/>`),
      ''
    );

  return (
    <Modal open onClose={() => set_addInfoModal(false)}>
      <Modal.Header>Informacion adicional de reserva</Modal.Header>
      <Modal.Content>
        {aditionalinformation.length !== 0 && (
          <div dangerouslySetInnerHTML={{ __html: objectToString(aditionalinformation) }} />
        )}
        <Modal.Actions>
          <Button secondary onClick={() => set_addInfoModal(false)}>
            Cerrar
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default AdditionalInfoShow;
