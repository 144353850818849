import styled from '@emotion/styled';

const NumpadComp = styled('div')`
  * {
    margin: 0;
    padding: 0;
    font: 71%/1.5 Verdana, Sans-Serif;
    background: #eee;
  }
  #container {
    margin: 100px auto;
    width: 760px;
  }
  #keyboard {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  #keyboard li {
    float: left;
    margin: 0 5px 5px 0;
    width: 60px;
    height: 60px;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    background: #fff;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
  }
  .capslock,
  .tab,
  .left-shift,
  .clearl,
  .switch {
    clear: left;
  }
  #keyboard .tab,
  #keyboard .delete {
    width: 70px;
  }
  #keyboard .capslock {
    width: 80px;
  }
  #keyboard .return {
    width: 90px;
  }
  #keyboard .left-shift {
    width: 70px;
  }

  #keyboard .switch {
    width: 90px;
  }
  #keyboard .rightright-shift {
    width: 109px;
  }
  .lastitem {
    margin-right: 0;
  }
  .uppercase {
    text-transform: uppercase;
  }
  #keyboard .space {
    float: left;
    width: 556px;
  }
  #keyboard .switch,
  #keyboard .space,
  #keyboard .return {
    font-size: 16px;
  }
  .on {
    display: none;
  }
  #keyboard li:hover {
    position: relative;
    top: 1px;
    left: 1px;
    border-color: #e5e5e5;
    cursor: pointer;
  }
`;

export const Numpad = (props) => {
  const onPress = (e) => {
    props.pressed(e?.target.id);
  };
  return (
    <NumpadComp>
      <ul id="keyboard">
        <li id={1} onClick={onPress} className="letter">
          1
        </li>
        <li id={2} onClick={onPress} className="letter">
          2
        </li>
        <li id={3} onClick={onPress} className="letter">
          3
        </li>
        <li id={4} onClick={onPress} className="letter clearl">
          4
        </li>
        <li id={5} onClick={onPress} className="letter">
          5
        </li>
        <li id={6} onClick={onPress} className="letter">
          6
        </li>
        <li id={7} onClick={onPress} className="letter clearl">
          7
        </li>
        <li id={8} onClick={onPress} className="letter ">
          8
        </li>
        <li id={9} onClick={onPress} className="letter">
          9
        </li>
        <li id={0} onClick={onPress} className="letter clearl">
          0
        </li>
        <li id={'del'} onClick={onPress} className="delete lastitem">
          {'<'}
        </li>
        <li id={'clear'} onClick={onPress} className="delete lastitem">
          {'<<'}
        </li>
      </ul>
    </NumpadComp>
  );
};
