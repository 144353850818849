import { Button, Modal } from 'semantic-ui-react';
import RedComunidadEmpresa from '../../containers/RedComunidadEmpresa';

const ComunidadNetworkRegisterFilled = (props) => {
  const { inputs, set_showComunidadNetwork } = props;

  return (
    <Modal open={true} onClose={() => set_showComunidadNetwork(null)}>
      <Modal.Content>
        <Modal.Description>
          <Button onClick={() => set_showComunidadNetwork(null)} secondary>
            Cerrar
          </Button>
          <RedComunidadEmpresa
            editing
            inputs={inputs}
            set_showComunidadNetwork={set_showComunidadNetwork}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ComunidadNetworkRegisterFilled;
