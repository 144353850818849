import { Input, Select, Checkbox, TextArea } from 'semantic-ui-react';
import {
  eventSlugnameTrim,
  generateTimeArray,
  textArea,
  chPerm,
  kindgrouplist,
} from '../../helpers';

const NewGroupForm = (props) => {
  const {
    inputs,
    categoriesgrouplist,
    comunidadProcess,
    weekdays,
    handleCheckers,
    loading,
    handleInputs,
    formSelect,
    errorInputs,
    selectHandle,
    isCouples,
    setInputs,
    editing,
    kindgroup,
  } = props;

  const selectHandleOnline = (a, { value, name }) => {
    if (value === 1) setInputs({ ...inputs, address: null, neighborhood: null, [name]: !!value });
    else selectHandle(a, { value, name });
  };
  const leaderGender = () => {
    if (inputs.gender) return inputs.gender;
    if (inputs.maleLeader && inputs.maleLeader.gender) return inputs.maleLeader.gender;
    if (inputs.femaleLeader && inputs.femaleLeader.gender) return inputs.femaleLeader.gender;
  };
  const onlineText = (online, address) => {
    if ((online && address) || online === 2) return 2;
    if (online) return 1;
    if (online === false) return 0;
  };

  return (
    <div className="newGroupForm">
      <div>
        <div>
          <Checkbox
            label="¿Esta recibiendo personas?"
            name="status"
            checked={inputs.status}
            toggle
            className={`${'status'} ${errorInputs['status'] ? 'error' : ''}`}
            onClick={() => handleCheckers('status')}
          />
          - {inputs.status ? 'Si' : 'No'}
        </div>
        {!(inputs.activityName && inputs.activityName.includes('Parejas')) && !editing && (
          <div className="selectorCheck">
            <label
              style={{
                cursor: 'pointer',
                fontWeight: inputs.coupleleadered ? 'normal' : 'bold',
                padding: '0 10px',
              }}
              htmlFor="coupleleadered"
            >
              Un lider
            </label>
            <Checkbox
              label="Liderado en pareja"
              name="coupleleadered"
              style={{ fontWeight: inputs.coupleleadered ? 'bold' : 'normal' }}
              id="coupleleadered"
              checked={inputs.coupleleadered}
              toggle
              className={`${'coupleleadered'} ${errorInputs['coupleleadered'] ? 'error' : ''}`}
              onClick={() => handleCheckers('coupleleadered')}
            />
          </div>
        )}
        {chPerm(['groups']) && (
          <div className="selectorCheck">
            <label
              style={{
                cursor: 'pointer',
                fontWeight: inputs.suspended ? 'normal' : 'bold',
                padding: '0 10px',
              }}
              htmlFor="suspended"
            >
              Activo
            </label>
            <Checkbox
              label="En suspensión"
              name="suspended"
              style={{ fontWeight: inputs.suspended ? 'bold' : 'normal' }}
              id="suspended"
              checked={inputs.suspended}
              toggle
              className={`${'suspended'} ${errorInputs['suspended'] ? 'error' : ''}`}
              onClick={() => handleCheckers('suspended')}
            />
          </div>
        )}
      </div>
      <div className="selectorCheck">
        <Select
          placeholder="Modalidad"
          onChange={selectHandleOnline}
          name="online"
          value={onlineText(inputs.online, inputs.address)}
          options={['Presencial', 'Solo Online', 'Alternancia'].map((t, i) => {
            return { key: i, value: i, text: t };
          })}
          className={`Registration__input Registration__input-${'online'} ${
            errorInputs['online'] ? 'error' : ''
          }`}
        />
      </div>

      {formSelect({
        label: 'Tipo',
        name: 'kindgroup',
        value: inputs.kindgroup || kindgroup,
        errorInputs,
        disabled: !chPerm(['groups']),
        options: kindgrouplist,
      })}

      {formSelect({
        label: 'Categoría',
        name: 'activityName',
        value: inputs.activityName || '',
        errorInputs,
        disabled: categoriesgrouplist.length <= 0,
        options: categoriesgrouplist,
      })}

      <Select
        placeholder="Dia de la semana"
        style={{
          display: 'inline-block',
        }}
        onChange={selectHandle}
        name="dayOfWeek"
        value={inputs.dayOfWeek}
        options={weekdays.map((day, i) => {
          return { key: i, value: i, text: day };
        })}
        className={`Registration__input Registration__input-${'dayOfWeek'} ${
          errorInputs['dayOfWeek'] ? 'error' : ''
        }`}
      />
      <Select
        placeholder="Hora"
        style={{
          display: 'inline-block',
        }}
        value={inputs.time ? inputs.time.substring(0, 5) : inputs.time}
        onChange={selectHandle}
        loading={loading}
        name="time"
        className={`Registration__input Registration__input-${'time'} ${
          errorInputs['time'] ? 'error' : ''
        }`}
        options={generateTimeArray().map((a) => {
          return { key: a, value: a, text: a };
        })}
      />
      {inputs.activityName?.includes('._') && (
        <Input
          placeholder="Máximo de participantes"
          name={'maxCapacity'}
          value={inputs.maxCapacity}
          onChange={handleInputs}
          type={'number'}
          className={`Registration__input Registration__input-${'maxCapacity'} ${
            errorInputs['maxCapacity'] ? 'error' : ''
          }`}
        />
      )}
      {!inputs.activityName?.includes('._') && (
        <Input
          placeholder="Nombre para identificar el grupo"
          name={'groupName'}
          value={inputs.groupName}
          onChange={handleInputs}
          className={`Registration__input Registration__input-${'groupName'} ${
            errorInputs['groupName'] ? 'error' : ''
          }`}
        />
      )}
      {(!inputs.online || onlineText(inputs.online, inputs.address) === 2) && (
        <>
          <Input
            placeholder="Dirección del grupo"
            name={'address'}
            value={inputs.address}
            onChange={handleInputs}
            className={`Registration__input Registration__input-${'address'} ${
              errorInputs['address'] ? 'error' : ''
            }`}
          />
          <Input
            placeholder="Barrio"
            name={'neighborhood'}
            value={inputs.neighborhood}
            onChange={handleInputs}
            className={`Registration__input Registration__input-${'neighborhood'} ${
              errorInputs['neighborhood'] ? 'error' : ''
            }`}
          />
        </>
      )}
      {!isCouples &&
        formSelect({
          label: 'Genero del lider',
          name: 'gender',
          style: { width: '45%' },
          value: inputs.gender,
          errorInputs,
          options: ['Masculino', 'Femenino'],
        })}
      <div>
        {textArea({
          TextArea,
          label: 'Escribe una observación de tu grupo',
          name: 'observations',
          value: inputs.observations,
          handler: handleInputs,
          style: { width: '80%', margin: '0 auto' },
        })}
      </div>
      {(isCouples || leaderGender() === 'Masculino') && !inputs.activityName?.includes('._') && (
        <>
          <h3>Informacion de el lider</h3>
          <div>
            <label htmlFor="maleCrecerlevel">
              {`Seleccione los pasos que ha realizado el lider ${isCouples ? 'hombre' : ''}`}
            </label>
            <Select
              placeholder={`Seleccione los pasos que ha realizado el lider ${
                isCouples ? 'hombre' : ''
              }`}
              onChange={selectHandle}
              value={inputs.maleCrecerlevel || []}
              name={'maleCrecerlevel'}
              id={'maleCrecerlevel'}
              className={`Registration__input Registration__input-${'maleCrecerlevel'} ${
                errorInputs['maleCrecerlevel'] ? 'error' : ''
              }`}
              fluid
              multiple
              selection
              options={comunidadProcess.map((opt) => {
                return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
              })}
            />
          </div>
          <div>
            <label htmlFor="maleLeader" style={{ display: 'block' }}>
              Lider de el lider
            </label>
            <Input
              placeholder="Nombre del lider"
              name={'maleLeaderFullName'}
              value={inputs.maleLeaderFullName}
              defaultValue={inputs?.maleLeader?.GuestLeader?.leaderFullName}
              onChange={handleInputs}
              className={`Registration__input Registration__input-${'maleLeaderFullName'} ${
                errorInputs['maleLeaderFullName'] ? 'error' : ''
              }`}
            />
            <Input
              placeholder="Teléfono del lider"
              name={'maleLeaderPhone'}
              value={inputs.maleLeaderPhone}
              defaultValue={inputs?.maleLeader?.GuestLeader?.leaderPhone}
              onChange={handleInputs}
              className={`Registration__input Registration__input-${'maleLeaderPhone'} ${
                errorInputs['maleLeaderPhone'] ? 'error' : ''
              }`}
            />
          </div>
        </>
      )}
      {(isCouples || leaderGender() === 'Femenino') && !inputs.activityName?.includes('._') && (
        <>
          <h3>Informacion de la lider</h3>
          <div>
            <label htmlFor="femaleCrecerlevel">
              {`Seleccione los pasos que ha realizado la lider ${isCouples ? 'mujer' : ''}`}
            </label>
            <Select
              placeholder={`Seleccione los pasos que ha realizado la lider ${
                isCouples ? 'mujer' : ''
              }`}
              onChange={selectHandle}
              value={inputs.femaleCrecerlevel || []}
              name={'femaleCrecerlevel'}
              id={'femaleCrecerlevel'}
              className={`Registration__input Registration__input-${'femaleCrecerlevel'} ${
                errorInputs['femaleCrecerlevel'] ? 'error' : ''
              }`}
              fluid
              multiple
              selection
              options={comunidadProcess.map((opt) => {
                return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
              })}
            />
          </div>
          <div>
            <label htmlFor="femaleLeader" style={{ display: 'block' }}>
              Lider de la lider
            </label>
            <Input
              placeholder="Nombre del lider"
              name={'femaleLeaderFullName'}
              value={inputs.femaleLeaderFullName}
              defaultValue={inputs?.femaleLeader?.GuestLeader?.leaderFullName}
              onChange={handleInputs}
              className={`Registration__input Registration__input-${'femaleLeaderFullName'} ${
                errorInputs['femaleLeaderFullName'] ? 'error' : ''
              }`}
            />
            <Input
              placeholder="Teléfono del lider"
              name={'femaleLeaderPhone'}
              value={inputs.femaleLeaderPhone}
              defaultValue={inputs?.femaleLeader?.GuestLeader?.leaderPhone}
              onChange={handleInputs}
              className={`Registration__input Registration__input-${'femaleLeaderPhone'} ${
                errorInputs['femaleLeaderPhone'] ? 'error' : ''
              }`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NewGroupForm;
