import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import moment from 'moment';
import EventService from '../../../../services/eventService';
import Swal from 'sweetalert2';
import { chPerm, errorInput, renderNumberOfTimes } from '../../../../components/helpers';
import CreateServiceForm from './createServiceForm';

const ChurchServiceDetails = (props) => {
  const [open, setOpen] = React.useState(false);
  const [editEventModalInputs, setEditEventModalInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);
  const [errorInputs, set_errorInputs] = React.useState({});

  const serviceDate = moment.tz(props.date, 'America/Bogota').format('dddd DD [de] MMMM [de] YYYY');

  const verifyForm = () => {
    const mandatoryInputs = ['capacity', 'date', 'time', 'eventSlugname'];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: editEventModalInputs[input] },
      });
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const deleteEvent = (id) => {
    Swal.fire({
      title: '¿Seguro que desea eliminar este servicio?',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        set_loading(true);
        EventService.deleteEvent({ id })
          .then((response) => {
            set_loading(false);
            if (response.data.success) {
              props.getEvents();
              Swal.fire(response.data.message);
            }
          })
          .catch((err) => {
            set_loading(false);
          });
      }
    });
  };

  const editEventModal = () => {
    return (
      <Modal open onClose={() => setEditEventModalInputs({})}>
        <Modal.Header>Modificar evento</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <CreateServiceForm
              inputs={editEventModalInputs}
              setInputs={setEditEventModalInputs}
              previousEventsNames={props.previousEventsNames}
              errorInputs={errorInputs}
              loading={loading}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => editEvent()} loading={loading} disabled={loading}>
            Modificar
          </Button>
          <Button onClick={() => setEditEventModalInputs({})} loading={loading} disabled={loading}>
            Cerrar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const editEvent = () => {
    if (verifyForm()) return;
    set_loading(true);

    const event_id = editEventModalInputs.id;
    const { numReqFields, confirmDataInfo, summaryInfo } = editEventModalInputs;

    let extraInfo = [];
    if (numReqFields && editEventModalInputs.extraInfo) {
      renderNumberOfTimes(numReqFields).forEach((i) => {
        extraInfo.push({
          label: editEventModalInputs[`extraInfo_${i}`],
          name: editEventModalInputs[`extraInfo_${i}`].replace(/ /g, '_') + `_${i}`,
          mandatory: Boolean(editEventModalInputs[`extraInfo_${i}_required`]),
        });
      });
    }

    let body = {
      ...editEventModalInputs,
      event_id,
      date: `${moment(editEventModalInputs.date).format('YYYY-MM-DD')} ${
        editEventModalInputs.time
      } -05:00`,
      additionalinformation: { extraInfo, confirmDataInfo, summaryInfo },
    };

    EventService.editEvent(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          Swal.fire(response.data.message);
          setEditEventModalInputs({});
          props.getEvents();
        }
      })
      .catch((err) => {
        Swal.fire(err.response.data.message);
      });
  };

  const timeName = (date) => moment.tz(date, 'America/Bogota').format('hh:mm a');

  const openEditEventModal = (service) => {
    let extraInfoInputs = {};
    (service?.additionalinformation?.extraInfo || []).forEach((extra, index) => {
      extraInfoInputs = {
        ...extraInfoInputs,
        ['extraInfo_' + (index + 1)]: extra.name
          .substring(0, extra.name.length - 2)
          .replace(/_/g, ' '),
        ['extraInfo_' + (index + 1) + '_required']: extra.mandatory,
      };
    });
    setEditEventModalInputs({
      ...service,
      ...(service.additionalinformation || {}),
      extraInfo: !!service?.additionalinformation?.extraInfo?.length,
      numReqFields: service?.additionalinformation?.extraInfo?.length || 0,
      time: moment.tz(service.date, 'America/Bogota').format('HH:mm'),
      sendmail: !!(service?.emailData?.templateId || service?.emailData?.listId),
      ...extraInfoInputs,
    });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open && chPerm(['Auditorio_PrincipalAdmin'])}
      trigger={<p style={{ padding: '20px 0 0 0', cursor: 'pointer' }}>{serviceDate}</p>}
    >
      <Modal.Header>{serviceDate}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {props.services.map((service) => (
            <div>
              <strong>Hora:</strong> {timeName(service.date)} <strong>Capacidad:</strong>{' '}
              {service.capacity}{' '}
              <span
                onClick={() => deleteEvent(service.id)}
                style={{
                  color: 'red',
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                }}
              >
                Eliminar
              </span>
              {' - '}
              {editEventModalInputs.id === service.id && editEventModal()}
              <span
                onClick={() => openEditEventModal(service)}
                style={{
                  color: 'blue',
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                }}
              >
                Modificar
              </span>
            </div>
          ))}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} loading={loading} disabled={loading}>
          Cerrar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ChurchServiceDetails;
