import { css } from '@emotion/react';

export const NetworkComponent = css`
  #network {
    .main-buttons {
      text-align: center;
    }
    &.comunidad-network {
      background-color: white;
      padding: 20px;
      margin: 35px;
      border-radius: 10px;
      .cards {
        i {
          font-size: 15px;
          margin: 0 5px 5px 0;
        }
        .description {
          font-size: 15px;
        }
      }
    }
    h2 {
      text-align: center;
    }
    p.salute {
      font-style: italic;
      font-size: 17px;
      margin: 0;
      color: rgb(126, 126, 126);
      &:first-of-type {
        margin-top: 10px;
      }
      &:last-of-type {
        margin-bottom: 10px;
      }
    }
    .options {
      p {
        margin: 5px 0;
      }
      > div {
        width: 48%;
        display: inline-block;
        padding: 0 5px;
        &.catalog {
          width: 90%;
        }
      }
      .search-query {
        margin-bottom: 20px;
        > div {
          width: 98%;
        }
      }
    }
  }

  .volunteer-registration#company {
    &.editing {
      width: 92%;
      padding: 0px;
      margin: 32px;
      .cancel {
        margin: 20px auto;
      }
    }
    header {
      h2 {
        text-align: center;
        display: block;
        font-size: 30px;
        width: 100%;
      }
    }
    .salute {
      font-style: italic;
      font-size: 18px;
      margin: 15px 34px;
      text-align: justify;
      color: #4a4a4a;
      &:last-of-type {
        margin-bottom: 34px;
      }
    }
    .Registration__form {
      padding: 10px;
    }
    .Registration__input-serviceOffered {
      width: 100%;
    }
    p.titles {
      text-align: center;
      margin: 30px 0 15px 0;
      font-weight: 800;
    }

    .comunidadMemeber {
      width: 92%;
      margin: auto;
    }
    .actions {
      margin: 30px 0;
      button {
        display: block;
        margin: auto;
      }
    }
  }
  #transversal {
    textarea {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 8px;
      width: 92%;
      display: block;
      margin: auto;
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;
