import { Button, Modal } from 'semantic-ui-react';
import { formatCurrency } from '../../../../../components/helpers';

const PendingPaymentModal = (props) => {
  const { cancelReservation, loading, eventSlugname, inputs } = props;
  const onClose = () => window.open('https://comunidadmde.com/');

  const { paidEvent, email, name, lastName, identification } = inputs;

  const totalDonation = Math.abs(parseInt(paidEvent)) || 0;

  const donationNotComplete =
    parseInt(inputs.paidEvent) > parseInt(inputs?.['AuditoriumGuests.payment']?.amount || 0) &&
    parseInt(inputs?.['AuditoriumGuests.payment']?.amount || 0) > 0;

  let donationToMake = totalDonation;
  if (inputs.partialPaid) {
    donationToMake = parseInt(totalDonation / (inputs.partialPaid ? 2 : 1));
  }
  if (donationNotComplete) {
    donationToMake =
      parseInt(inputs.paidEvent) - parseInt(inputs?.['AuditoriumGuests.payment']?.amount || 0);
  }

  return (
    <>
      <Modal open onClose={onClose}>
        <Modal.Header>¡Gracias!</Modal.Header>
        <Modal.Content>
          <h1>{eventSlugname?.replace(/_/g, ' ')}</h1>
          <div style={{ margin: '20px 30px' }}>
            {donationNotComplete ? (
              <p style={{ margin: '20px 0 0 0' }}>
                Gracias por pre-registrarte, <br />
                Tienes un saldo ya pagado de:{' '}
                {formatCurrency(inputs?.['AuditoriumGuests.payment']?.amount)}.
                <br />
                Tienes una donación pendiente de <strong>{formatCurrency(donationToMake)}</strong>.
                Por favor realiza el pago restante para concluir la reservación, haciendo clic en el
                boton de abajo.
              </p>
            ) : (
              <p style={{ margin: '20px 0 0 0' }}>
                Tu donación aún sige en proceso. <br />
                Si aún no haz hecho ninguna de manera exitosa, debes realizar una nueva donación.
              </p>
            )}
            {donationNotComplete && (
              <div style={{ margin: '20px 0', textAlign: 'center' }}>
                <Button
                  secondary
                  onClick={() =>
                    props.history.push(
                      `/payucheckout?amount=${donationToMake}&description=${eventSlugname}&buyerEmail=${email}&buyerFullName=${name} ${lastName}&identification=${identification}`
                    )
                  }
                >
                  Ir a donar
                </Button>
              </div>
            )}
          </div>
        </Modal.Content>
        {!parseInt(inputs?.['AuditoriumGuests.payment']?.amount || 0) > 0 && (
          <Modal.Actions>
            <Button disabled={loading} onClick={(e) => cancelReservation(e, true)}>
              Realizar una nueva donación
            </Button>
            <Button disabled={loading} onClick={(e) => cancelReservation(e)}>
              Cancelar intento
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    </>
  );
};

export default PendingPaymentModal;
