const CounselingDocuments = (props) => {
  const { Attendant, signComponent } = props;

  return (
    <>
      <p className="habeasdata normal">Consentimiento Informado</p>
      <p className="habeasdata">
        Yo, {Attendant.Guest ? Attendant.Guest.name : Attendant.name}{' '}
        {Attendant.Guest ? Attendant.Guest.lastName : Attendant.lastName}, identificado con C.C.No.{' '}
        {Attendant.Guest ? Attendant.Guest.identification : Attendant.identification}, manifiesto de
        manera libre, consciente, voluntaria y asesorada que autorizo expresamente al consejero
        espiritual, para que active las rutas de seguimiento médico, psicológico y/ó jurídico a que
        hubiere lugar una vez escuche el relato de los hechos que haré libre de todo apremio de
        juramento.
      </p>
      <p className="habeasdata">
        Manifiesto que recibí una explicación clara y completa del objeto del proceso de orientación
        espiritual y el propósito de su realización. También recibí información sobre la filmación y
        la forma en que se utilizarán los resultados.
      </p>
      <p className="habeasdata">
        Hago constar que he leído y entendido en su totalidad este documento, por lo que en
        constancia firmo y acepto su contenido.
      </p>
      <div className="sign">
        {signComponent('user') && <>{signComponent('user')}</>}
        {signComponent('coun') && <>{signComponent('coun')}</>}
        {signComponent('pro') && <>{signComponent('pro')}</>}
      </div>
      <p className="habeasdata small">
        <strong>Aviso Legal:</strong> La información contenida en este documento, será para el uso
        exclusivo de la Iglesia Comunidad Cristiana de Fé, quien será responsable por su custodia y
        conservación; toda vez que contiene información de carácter confidencial o privilegiada.
        Esta información no podrá ser reproducida total o parcialmente, salvo exigencia de autoridad
        judicial competente, previo el lleno de requisitos normativos que excepcionen la salvaguarda
        prevista en el art. 385 del código de procedimieto penal y demás disposiciones concordantes.
      </p>
    </>
  );
};

export default CounselingDocuments;
