import { Button, Checkbox } from 'semantic-ui-react';
import { eventSlugnameTrim } from '../../../../components/helpers';
import moment from '../../../../services/moment_instance';

const ReservationStepSelectAService = (props) => {
  const { inputs, setInputs, defaulteventSlugname, isAudPpal, loading, services, sendReservation } =
    props;

  const nextStep = (service) => {
    setInputs({ ...inputs, service, date: service.date }, (newState) => {
      if (newState.service && !newState.reserved)
        sendReservation({ eventSelected: newState.service });
    });
  };

  const includesServiceId = (serviceId) =>
    inputs.multipleServices ? inputs.multipleServices.includes(serviceId) : false;

  const addvolunteerService = (serviceId) => {
    let multipleServices = inputs.multipleServices;
    let service = inputs.service;

    if (includesServiceId(serviceId)) {
      multipleServices = inputs.multipleServices.filter((v) => v !== serviceId);
    } else {
      multipleServices = [...new Set([...(inputs.multipleServices || []), serviceId])];
      if (serviceId === inputs?.service?.id) service = { ...service, id: null };
    }
    setInputs({ ...inputs, multipleServices, service });
  };

  const volunteerAttendtoService = (serviceId) => {
    let multipleServices = inputs.multipleServices;
    let service = inputs.service;

    if (includesServiceId(serviceId))
      multipleServices = inputs.multipleServices.filter((v) => v !== serviceId);

    setInputs({
      ...inputs,
      multipleServices,
      service: { ...service, id: serviceId },
    });
  };

  const age = (birthdate) => moment().diff(birthdate, 'years');
  const isMultipleEvents =
    Object.values(services)
      .flat()
      .findIndex((event) => event.multipleEvents) >= 0;

  return (
    <div className="service selectAService">
      <h2>Ya casi terminamos {inputs.name}</h2>
      <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
        {inputs.volunteer || isMultipleEvents
          ? `Por último, selecciona ${
              isAudPpal ? 'las reuniones en las' : 'los eventos en los'
            } que quieres ${inputs.volunteer ? 'servir' : 'participar'}`
          : `Por último, selecciona la ${isAudPpal ? 'reunión' : 'hora del evento'} a ${
              isAudPpal ? 'la' : 'el'
            } que vas a asistir`}
      </label>
      {Boolean(inputs.serviceArea) &&
        age(inputs.birthdate) >= 12 &&
        defaulteventSlugname() !== 'Celebracion_de_Navidad' && (
          <div className="checks">
            <Checkbox
              label={`¿Estas programado para servir ${isAudPpal ? '' : 'en'} este ${
                isAudPpal ? 'fin de semana' : 'evento'
              }? - ${inputs.volunteer ? 'Si' : 'No'}`}
              name="volunteer"
              checked={inputs.volunteer}
              toggle
              onClick={() => setInputs({ ...inputs, volunteer: !inputs.volunteer })}
            />
          </div>
        )}
      <p style={{ textAlign: 'center', fontWeight: 'bold', margin: 0 }}>
        Reservando para: {eventSlugnameTrim(defaulteventSlugname())}
      </p>
      {loading && <p>Cargando tu servicio...</p>}
      {!loading && Object.keys(services).length === 0 && (
        <p style={{ textAlign: 'center' }}>
          No hay {isAudPpal ? 'reuniones' : 'eventos'} disponibles para{' '}
          {eventSlugnameTrim(defaulteventSlugname())}.
        </p>
      )}
      {Boolean(inputs.volunteer) && (
        <p style={{ margin: '20px 0 0 0', maxWidth: '60%' }}>
          <strong>Importante:</strong> Recuerda que esta opción SOLO es valida si vas a servir como
          voluntario este {isAudPpal ? 'fin de semana' : 'evento'}, si SOLO vas a asistir desmarca
          esta casilla y selecciona uno de los servicios.
        </p>
      )}
      <div className="dates">
        {Object.keys(services).map((date) => (
          <>
            <p style={{ padding: '20px 0 0 0' }}>
              {moment
                .tz(date, 'America/Bogota')
                .format(
                  defaulteventSlugname()?.includes('Crecer:_PASO_1') ? 'dddd' : 'dddd DD [de] MMMM'
                )}
            </p>
            {services[date].map((service) =>
              inputs.volunteer || isMultipleEvents ? (
                <div key={service.id}>
                  <Checkbox
                    label={moment.tz(service.date, 'America/Bogota').format('hh:mm a')}
                    name="volunteer"
                    checked={includesServiceId(service.id)}
                    toggle
                    onClick={() => addvolunteerService(service.id)}
                  />
                  {inputs.volunteer && (
                    <Checkbox
                      label="Asistir"
                      name="volunteer"
                      checked={service.id === (inputs.service && inputs.service.id)}
                      style={{ padding: '0 10px' }}
                      onClick={() => volunteerAttendtoService(service.id)}
                    />
                  )}
                </div>
              ) : (
                <Button primary disabled={loading} onClick={(e) => nextStep(service)}>
                  <div className="time">
                    {moment.tz(service.date, 'America/Bogota').format('hh:mm a')}
                  </div>
                  {!defaulteventSlugname()?.includes('recer:_PASO_1') && (
                    <div className="left">
                      Quedan{' '}
                      {Boolean(service.capacity) &&
                        Boolean(service.total) &&
                        parseInt(service.capacity) - parseInt(service.total)}
                      {service.total === 0 && parseInt(service.capacity)} cupos
                    </div>
                  )}
                </Button>
              )
            )}
          </>
        ))}
      </div>
      {Boolean(inputs.volunteer || isMultipleEvents) && (
        <Button
          disabled={
            !inputs.multipleServices ||
            (inputs.multipleServices && inputs.multipleServices.length === 0) ||
            loading
          }
          loading={loading}
          primary
          onClick={() => sendReservation({})}
          style={{ margin: '20px 0' }}
        >
          Finalizar registro
        </Button>
      )}
    </div>
  );
};

export default ReservationStepSelectAService;
