import React from 'react';
import guestService from '../../services/guest';
import GuestIdForm from './guestIdForm';
import GuestData from './guestData';
import queryString from 'query-string';
import { CheckinURL } from '../../components/helpers';
import EventService from '../../services/eventService';

const CheckIn = (props) => {
  const [guestID, set_guestID] = React.useState('');
  const [Attendant, set_Attendant] = React.useState({});
  const [loadedData, set_loadedData] = React.useState(false);
  const [eventDates, set_eventDates] = React.useState([]);
  const [errorData, set_errorData] = React.useState(null);

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  React.useEffect(() => {
    if (errorData !== null) {
      setTimeout(() => {
        set_errorData(null);
      }, 1800);
    }
  }, [errorData]);

  function resetForm() {
    set_errorData(null);
    set_Attendant({});
    props.history.push(
      `/checkin?event=${parsedQueryString.event}&eventSN=${parsedQueryString.eventSN}`
    );
  }

  React.useEffect(() => {
    if (parsedQueryString.id) {
      set_loadedData(true);
      set_guestID(parsedQueryString.id);
      getAttendantInfo(parsedQueryString.id);
    }
    getEventDates();
  }, []);

  function getAttendant(e) {
    e.preventDefault();
    if (!guestID) {
      set_errorData('No puede estar vacío');
      return;
    }
    set_loadedData(true);
    getAttendantInfo(guestID);
  }

  function getAttendantInfo(id) {
    if (!parsedQueryString.event) {
      props.history.push(`/`);
    }
    const eventURL = CheckinURL(parsedQueryString.event);

    guestService
      .getGuest(id, eventURL, { eventSlugname: parsedQueryString.eventSN })
      .then((response) => {
        set_loadedData(false);
        if (response.data.success) {
          set_guestID('');
          set_Attendant(response.data.Attendant);
        } else {
          set_errorData('No se encontro el usuario');
        }
      })
      .catch((error) => {
        if (error.response) set_errorData(error.response.data.message);
        set_loadedData(false);
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_Attendant({ ...Attendant, [name]: value });
  };

  const handleDropDown = ({ name, value }) => {
    set_Attendant({ ...Attendant, [name]: value });
  };

  const getEventDates = () => {
    EventService.getDates()
      .then((response) => {
        if (response.data.success) {
          set_eventDates(response.data.dates);
        }
      })
      .catch((error) => {
        if (error.response) set_errorData(error.response.data.message);
        set_loadedData(false);
      });
  };

  return (
    <>
      {Attendant.name ? (
        <GuestData
          Attendant={Attendant}
          handleInputs={handleInputs}
          resetForm={resetForm}
          errorData={errorData}
          eventDates={eventDates}
          set_errorData={set_errorData}
          set_Attendant={set_Attendant}
          location={props.location}
          handleDropDown={handleDropDown}
          getAttendantInfo={getAttendantInfo}
        />
      ) : (
        <GuestIdForm
          guestID={guestID}
          set_guestID={set_guestID}
          getAttendant={getAttendant}
          history={props.history}
          loadedData={loadedData}
          parsedQueryString={parsedQueryString}
          errorData={errorData}
        />
      )}
    </>
  );
};

export default CheckIn;
