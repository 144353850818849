import React from 'react';
import { Select, Button } from 'semantic-ui-react';
import {
  weekdays,
  leaderCategories,
  groupCategories,
  seminary,
  comunidadProcess,
  eventSlugnameTrim,
} from '../../../components/helpers';
import NewGroupForm from './newGroupForm';

const CreateNewGroup = (props) => {
  const { inputs, setInputs, isCouples, setStep } = props;
  const [errorInputs, set_errorInputs] = React.useState({});

  React.useEffect(() => {
    setInputs({ ...inputs, status: true });
  }, []);

  const verifyForm = () => {
    let mandatoryInputs = ['activityName', 'time'];

    if (!inputs.activityName?.includes('._')) {
      mandatoryInputs = [...mandatoryInputs, 'groupName'];
      if (!inputs.femaleCrecerlevel && !inputs.maleCrecerlevel) {
        mandatoryInputs = [...mandatoryInputs, 'femaleCrecerlevel', 'maleCrecerlevel'];
      }
    }

    if (inputs.activityName?.includes('._')) {
      mandatoryInputs = [...mandatoryInputs, 'maxCapacity'];
    }

    if (!inputs.online) {
      mandatoryInputs = [...mandatoryInputs, 'address'];
    }

    let inputsWithErrors = {};

    inputsWithErrors = {
      ...inputsWithErrors,
      dayOfWeek: typeof inputs['dayOfWeek'] !== 'number',
    };

    mandatoryInputs.forEach((input) => {
      inputsWithErrors = {
        ...inputsWithErrors,
        [input]: !inputs[input],
      };
    });

    set_errorInputs(inputsWithErrors);
    return Object.values(inputsWithErrors).includes(true);
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCheckers = (name) => setInputs({ ...inputs, [name]: !inputs[name] });

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const formSelect = ({ name, label, value, style = {}, options, handler = selectHandle }) => (
    <Select
      style={style}
      placeholder={label}
      className={errorInputs[name] ? 'error' : ''}
      onChange={handler}
      value={value}
      name={name}
      options={options.map((opt) => {
        return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
      })}
    />
  );

  let categoriesgrouplist = groupCategories;

  if (inputs.kindgroup === 'Grupo_Seminario') {
    categoriesgrouplist = seminary;
  } else if (inputs.kindgroup === 'Equipo_de_Líderes') {
    categoriesgrouplist = leaderCategories;
  }

  const next = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    setStep('fillLeader');
  };

  return (
    <form onSubmit={(e) => next(e)} className="Registration__form">
      <h2>{'Crear'} grupo</h2>
      <NewGroupForm
        isCouples={isCouples}
        setInputs={setInputs}
        inputs={inputs}
        handleInputs={handleInputs}
        selectHandle={selectHandle}
        formSelect={formSelect}
        categoriesgrouplist={categoriesgrouplist}
        comunidadProcess={comunidadProcess}
        weekdays={weekdays}
        handleCheckers={handleCheckers}
        errorInputs={errorInputs}
      />
      <Button onClick={(e) => next(e)}>Continuar</Button>
    </form>
  );
};
export default CreateNewGroup;
