import { css } from '@emotion/react';

export const CounselingComponent = css`
  .counseling {
    width: 80%;
    &.printing {
      width: 100%;
      p.habeasdata {
        &.normal {
          padding: 10px 0 0 0;
        }
      }
      .signature {
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
      }
    }
    h1 {
      color: #2185d0;
      font-size: 2.5em;
      padding: 20px 0;
    }
    h2 {
      margin: 30px 0 25px 0;
    }
    [name='gender'] {
      margin: 0 9px 0 21px;
    }
    #transversal {
      margin: 20px 0;
      display: inline-block;
    }
    textarea {
      min-width: 250px !important;
      max-width: 300px !important;
      max-height: 250px !important;
      min-height: 100px !important;
    }
    p.habeasdata {
      font-size: 17px;
      padding: 0 30px;
      text-align: justify;
      &.small {
        font-size: 12px;
      }
      &.normal {
        font-size: 20px;
        text-align: center;
      }
    }
    .checks {
      margin: 20px 12%;
      text-align: start;
    }
    .sign {
      text-align: center;
      p {
        margin: 0;
        &:last-child {
          padding: 0 0 20px 0;
        }
      }
    }

    @media only screen and (max-width: 750px) {
      .checks {
        margin: 20px 10px;
      }
      #transversal {
        display: block;
        width: 100%;
      }
    }

    @media only screen and (max-width: 750px) {
      width: 95%;
    }
  }
`;
