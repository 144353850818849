const BlankLoader = () => (
  <div className="Zero">
    <div className="loaderGrid">
      <p className="loadtext">Cargando una conexión segura</p>
      <div class="Loader"></div>
    </div>
  </div>
);

export default BlankLoader;
