import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Input, Select } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { withRouter } from 'react-router-dom';
import {
  churchMinistries,
  sortArray,
  errorInput,
  avoCaptConstructor,
  localhostname,
} from '../../../components/helpers';

const VolunteerLog = (props) => {
  const { inputs, setInputs, getInfo, parsedQueryString, set_loading, loading, handleInputs } =
    props;
  const [errorInputs, set_errorInputs] = React.useState({});

  const verifyForm = () => {
    let mandatoryInputs = ['identification', 'phone', 'serviceArea'];

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(inputs['identification']) !== parseInt(parsedQueryString.ac)
    ) {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(inputs['identification']) !== parseInt(parsedQueryString.ac)
    ) {
      if (inputs['captcha'] && inputs['captcha'] !== 'null') {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: 'captcha',
            value: inputs['captcha'].length >= 10,
          },
        });
      }
    }

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  React.useEffect(() => {
    if (inputs.identification && inputs.phone && parsedQueryString.ac)
      enterReservation({ preventDefault: () => {} });
  }, [inputs.identification]);

  const enterReservation = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    set_loading(true);
    getInfo();
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value });
  }
  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  return (
    <>
      <h2 style={{ textAlign: 'center' }}>¡Bienvenido!</h2>
      <form onSubmit={(e) => enterReservation(e)} className="Registration__form">
        <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
          Escribe tus datos
        </label>
        {Object.values(errorInputs).includes(true) && (
          <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
        )}
        <Input
          style={{ width: '45%' }}
          onChange={handleInputs}
          name={'identification'}
          value={inputs.identification}
          type="number"
          placeholder="Identificación"
          id="identification"
          className={`Registration__input Registration__input-${'identification'} ${
            errorInputs['identification'] ? 'error' : ''
          }`}
        />
        <Input
          placeholder="Celular"
          id="phone"
          name={'phone'}
          type="number"
          className={`Registration__input Registration__input-${'phone'} ${
            errorInputs['phone'] ? 'error' : ''
          }`}
          value={inputs.phone}
          onChange={handleInputs}
          style={{ width: '45%', margin: '5px' }}
        />
        <div>
          <Select
            placeholder={'Area de Servicio'}
            className={errorInputs?.serviceArea ? 'error' : ''}
            onChange={selectHandle}
            style={{ margin: '0 0 20px 0' }}
            value={inputs?.serviceArea}
            name={'serviceArea'}
            options={sortArray(churchMinistries).map((a) => ({
              key: a,
              value: a,
              text: a,
            }))}
          />
        </div>
        <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
          {!loading && (
            <ReCAPTCHA
              style={{ margin: 'auto' }}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={captchaOnChange}
            />
          )}
        </div>
        <p style={{ color: 'red' }}>
          {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
        </p>
        <Button primary disabled={loading} onClick={(e) => enterReservation(e)}>
          Entrar
        </Button>
      </form>
    </>
  );
};

export default withRouter(VolunteerLog);
