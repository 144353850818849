import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import Form from '../../../components/form/Form';
import {
  chPerm,
  churchMinistries,
  deleteForm,
  errorInput,
  experienceVolunteersSections,
  formInput,
} from '../../../components/helpers';
import { Input, Select, Modal, Button, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import membersForms from '../../../services/membersForms';
import Swal from 'sweetalert2';
import churchForms from '../../../services/churchForms';

const VolunteerForm = (props) => {
  const {
    getInfo,
    loading,
    mainInputs,
    set_loading,
    set_showVolunteerForm,
    prevVolunteerInputs = {},
  } = props;
  const [inputs, setInputs] = React.useState({
    ...prevVolunteerInputs,
    section: prevVolunteerInputs?.section || mainInputs?.section,
    serviceArea: prevVolunteerInputs?.serviceArea || mainInputs?.serviceArea,
  });
  const [errorInputs, set_errorInputs] = React.useState({});
  const [duplicate, set_duplicate] = React.useState(false);
  const [image, set_image] = React.useState(null);

  let age = (birthdate) => moment().diff(birthdate, 'years');

  const handleCheckers = (name) => setInputs({ ...inputs, [name]: !inputs[name] });

  const verifyForm = () => {
    let mandatoryInputs = [
      'name',
      'lastName',
      'email',
      'gender',
      'birthdate',
      'identification',
      'phone',
      'section',
      'role',
      'serviceArea',
    ];

    if (age(inputs.birthdate) <= 14) {
      mandatoryInputs = [...mandatoryInputs, 'parentIdentification', 'parentFullname'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    if (inputs?.identification && inputs?.identification?.length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs?.identification.length >= 5,
        },
      });
    }

    if (inputs?.phone && inputs?.phone?.length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: inputs?.phone.length >= 10,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs)?.includes(true);
  };

  const nextStep = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    set_loading(true);

    const data = new FormData();

    if (!duplicate && inputs.id) data.append('id', inputs.id);
    data.append('birthdate', inputs.birthdate);
    data.append('email', inputs.email);
    data.append('gender', inputs.gender);
    data.append('identification', inputs.identification);
    data.append('lastName', inputs.lastName);
    data.append('name', inputs.name);
    data.append('ocupation', inputs.ocupation || '');
    data.append('phone', inputs.phone);
    data.append('profile', image || '');
    data.append('role', inputs.role);
    data.append('section', inputs.section);
    data.append('serviceArea', inputs.serviceArea);
    data.append('suspended', !!inputs.suspended);
    data.append('tshirtsize', inputs.tshirtsize || '');

    churchForms
      .volunteerForm(data)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          Swal.fire(response.data.message);
          set_showVolunteerForm(null);
          getInfo?.();
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error && error.response.data) {
          Swal.fire(error.response.data.message);
        }
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const handleDropDown = ({ name, value }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  const getAMember = (e) => {
    e.preventDefault();
    const { identification, phone } = inputs || {};

    if (!identification || !phone) return Swal.fire('Para buscar escribe la cedula y el celular');

    set_loading(true);

    membersForms
      .getAMember({ identification, phone })
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          if (response.data.finded && response.data.match) {
            setInputs({
              ...inputs,
              ...response.data.guest,
            });
          } else {
            if (response.data.message) Swal.fire(response.data.message);
          }
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  };

  const deleteVolunteer = () => {
    return (
      <Button
        color="red"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          deleteForm({
            subtitle: inputs.name,
            Swal,
            func: async ({ text, successAlert }) => {
              await churchForms
                .deleteVolunteerForm({ id: inputs.id, deleted: text })
                .then((response) => {
                  if (response.data.success) {
                    successAlert();
                    getInfo();
                    set_showVolunteerForm(null);
                  } else {
                    Swal.fire(response.data.message);
                  }
                })
                .catch((error) => {
                  if (error.response) Swal.fire(error.response.data.message);
                });
            },
          });
        }}
      >
        Eliminar
      </Button>
    );
  };

  const moreInfo = () => {
    return (
      <>
        <div className="selectorCheck" style={{ margin: '25px 0' }}>
          <label
            style={{
              cursor: 'pointer',
              fontWeight: inputs.suspended ? 'normal' : 'bold',
              padding: '0 10px',
            }}
            htmlFor="suspended"
          >
            Activo
          </label>
          <Checkbox
            label="Suspendido"
            name="suspended"
            style={{ fontWeight: inputs.suspended ? 'bold' : 'normal' }}
            id="suspended"
            checked={inputs.suspended}
            toggle
            className={`${'suspended'} ${errorInputs['suspended'] ? 'error' : ''}`}
            onClick={() => handleCheckers('suspended')}
          />
        </div>
        <div>
          <p style={{ margin: '0' }}>Talla de camisa</p>
          {formInput({
            Input,
            handler: handleInputs,
            label: 'Talla de camisa',
            name: 'tshirtsize',
            value: inputs.tshirtsize || '',
            errorInputs,
          })}
        </div>
        <div>
          <p style={{ margin: 0, color: errorInputs?.serviceArea ? 'red' : '' }}>Area de servcio</p>
          <Select
            placeholder={'Area de servcio'}
            className={errorInputs?.serviceArea ? 'error' : ''}
            onChange={selectHandle}
            style={{ margin: '0 0 20px 0' }}
            value={inputs?.serviceArea}
            disabled={!chPerm(['volunteers'])}
            name={'serviceArea'}
            options={churchMinistries.sort().map((a) => {
              return { key: a, value: a, text: a };
            })}
          />
          <p style={{ margin: 0, color: errorInputs?.role ? 'red' : '' }}>Rol del voluntario</p>
          <Select
            placeholder={'Rol del voluntario'}
            className={errorInputs?.role ? 'error' : ''}
            onChange={selectHandle}
            style={{ margin: '0 0 20px 0' }}
            value={inputs?.role}
            disabled={!chPerm(['volunteers']) && mainInputs?.role !== 'Coordinador'}
            name={'role'}
            options={[
              ...(chPerm(['volunteers']) ? ['Coordinador'] : []),
              ...(mainInputs?.role === 'Coordinador' || chPerm(['volunteers'])
                ? ['Encargado', 'Encargado jr']
                : []),
              'Voluntario',
            ].map((a) => {
              return { key: a, value: a, text: a };
            })}
          />
          <p style={{ margin: 0, color: errorInputs?.section ? 'red' : '' }}>Seccion</p>
          <Select
            placeholder={'Seccion'}
            className={errorInputs?.section ? 'error' : ''}
            onChange={selectHandle}
            style={{ margin: '0 0 20px 0' }}
            value={inputs?.section}
            disabled={!chPerm(['volunteers']) && mainInputs?.role !== 'Coordinador'}
            name={'section'}
            options={[
              ...(chPerm(['volunteers']) ? ['Pendientes'] : []),
              ...experienceVolunteersSections(inputs.serviceArea),
            ].map((a) => {
              return { key: a, value: a, text: a?.replace(/_/g, ' ') };
            })}
          />
          <div>{!!inputs.id && !duplicate && deleteVolunteer()}</div>
          <div>
            {!!inputs.id && !duplicate && chPerm(['volunteers']) && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  set_duplicate(true);
                }}
                color="black"
              >
                Duplicar
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal open onClose={() => set_showVolunteerForm(null)}>
      <Modal.Content>
        <Modal.Description>
          <form onSubmit={(e) => nextStep(e)} className="Registration__form">
            <h2>{!!inputs.id ? 'Modificar' : 'Llena'} los datos del voluntario</h2>
            {Object.values(errorInputs)?.includes(true) && (
              <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
            )}
            <div>
              <Button loading={loading} disabled={loading} onClick={(e) => getAMember(e)}>
                Llenar información
              </Button>
              <p style={{ fontSize: '12px', margin: 0 }}>
                Ingresa el celular y cédula para traer la información que hay en el sistema
                asociados.
              </p>
            </div>
            {!!(age(inputs.birthdate) <= 14 && inputs.birthdate) && (
              <div>
                <p style={{ margin: '0' }}>Informacion para menores de 14 años</p>
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: 'Nombre del acudiente',
                  name: 'parentFullname',
                  value: inputs.parentFullname || '',
                  errorInputs,
                })}
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: 'Cedula del acudiente',
                  name: 'parentIdentification',
                  value: inputs.parentIdentification || '',
                  errorInputs,
                  type: 'number',
                })}
              </div>
            )}
            <Form
              saveTag={duplicate ? 'Duplicar' : 'Guardar'}
              Attendant={inputs}
              userprofile={true}
              handleInputs={handleInputs}
              handleDropDown={handleDropDown}
              errorInputs={errorInputs}
              sendInForm
              showOcupation
              showAge
              showGender
              moreInfo={moreInfo}
              showBirthdate
              loadedData={loading}
              image={image}
              set_image={set_image}
              submit={(e) => nextStep(e)}
            />
            <div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  set_showVolunteerForm(null);
                }}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default VolunteerForm;
