import proxybase from './proxybase';

class seminaryForms {
  createAttendance(body = {}) {
    return proxybase
      .post(`/seminaries/createSeminaryAttendance`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  setSeminaryAttendance(body) {
    return proxybase
      .post(`/seminaries/setSeminaryAttendance`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getSeminaryAttendant(body) {
    return proxybase
      .post(`/seminaries/setTutorReview`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const seminaryFormsInstance = new seminaryForms();
export default seminaryFormsInstance;
