import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import groupService from '../../../services/groupService';
import { Select, Input, Button } from 'semantic-ui-react';
import authService from '../../../services/auth';
import GroupsComplianceRow from '../../../components/common/adminTable/groupsComplianceRow';
import ComplianceFilled from './ComplianceFilled';

const AllGroupCompliances = (props) => {
  const [compliances, set_compliances] = React.useState([]);
  const [loaded, set_loaded] = React.useState(false);
  const [showCompliance, set_showCompliance] = React.useState(null);
  const [inputs, setInputs] = React.useState({ docName: 'proyectomatrimonio' });
  const currentUser = authService.getCurrentUser();
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);

  let groups = [
    {
      type: 'couple',
      key: 'liderproyectomatrimonio',
      value: 'liderproyectomatrimonio',
      text: 'Lideres Proyecto Matrimonio',
    },
    {
      type: 'couple',
      key: 'proyectomatrimonio',
      value: 'proyectomatrimonio',
      text: 'Asistentes Proyecto Matrimonio',
    },
    {
      type: 'couple',
      key: 'lideramoryrespeto',
      value: 'lideramoryrespeto',
      text: 'Lideres Amor y Respeto',
    },
    {
      type: 'couple',
      key: 'amoryrespeto',
      value: 'amoryrespeto',
      text: 'Asistentes Amor y Respeto',
    },
    {
      type: 'single',
      key: 'liderrelacionessanas',
      value: 'liderrelacionessanas',
      text: 'Líder Relaciones Sanas',
    },
    {
      type: 'single',
      key: 'relacionessanas',
      value: 'relacionessanas',
      text: 'Asistente Relaciones Sanas',
    },
  ];

  React.useEffect(() => {
    getInfo();
  }, [inputs.docName]);

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  function getInfo(page = false) {
    set_loaded(false);
    set_endPage(false);

    let body = {
      keyword: inputs.keyword,
      currentPage,
      docName: inputs.docName,
    };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    groupService
      .allComplianceForms(body)
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);
          if (page) set_compliances([...compliances, ...response.data.compliances]);
          else set_compliances(response.data.compliances);
          if (response.data.compliances.length === 0) {
            set_endPage(true);
          }
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(true);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const parejaGroupSelected = () => {
    const finded = groups.find((a) => a.value === inputs.docName);
    if (finded) return finded?.type === 'couple';
    else return {};
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  return (
    <section className="AdminTable">
      {!showCompliance && (
        <>
          <div className="AdminTable__content">
            <div>
              <Button onClick={() => props.history.goBack()}>Atrás</Button>
              <Button onClick={() => getInfo()}>Recargar</Button>
            </div>
            <Input
              loading={!loaded}
              name={'keyword'}
              onChange={(e) => setInputs({ ...inputs, keyword: e.target.value })}
              value={inputs.keyword}
              onKeyPress={onEnter}
              style={{ width: '45%' }}
              placeholder="Buscar..."
            />
            <Select
              placeholder="Selecciona el acuerdo para consultar."
              style={{
                display: 'inline-block',
                margin: ' 7px 0',
                width: '45%',
              }}
              value={inputs.docName}
              onChange={selectHandle}
              name="docName"
              loading={!loaded}
              options={groups}
            />
          </div>
          <p>Total en pantalla: {compliances.length}</p>
        </>
      )}
      {
        <div>
          <table>
            {!showCompliance && (
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Cedula</th>
                  <th>Nombre</th>
                </tr>
              </thead>
            )}
            <tbody>
              {compliances.map((compliance) => (
                <>
                  {showCompliance === compliance.id && (
                    <ComplianceFilled
                      inputs={compliance}
                      docName={inputs.docName}
                      set_showCompliance={set_showCompliance}
                      checkCouple={parejaGroupSelected()}
                    />
                  )}
                  {!showCompliance && (
                    <GroupsComplianceRow
                      compliance={compliance}
                      getInfo={getInfo}
                      set_showCompliance={set_showCompliance}
                      currentUser={currentUser}
                      parejaGroupSelected={parejaGroupSelected}
                    />
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      }
      {!showCompliance && (
        <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
          <Button loading={!loaded} disabled={endPage} onClick={() => loadMore()}>
            Cargar más
          </Button>
        </div>
      )}
    </section>
  );
};

export default AllGroupCompliances;
