import React from 'react';
import QRCode from 'qrcode.react';
import { Button } from 'semantic-ui-react';
import EventService from '../../../../services/eventService';
import Swal from 'sweetalert2';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import { avoCaptConstructor, eventSlugnameTrim } from '../../../../components/helpers';
import PresentingBabies from './event-modals/presentingBabies';
import moment from '../../../../services/moment_instance';
import PendingPaymentModal from './event-modals/PendingPayment';
import ReactMarkdown from 'react-markdown';

const ReservationSummary = (props) => {
  const { inputs, setInputs, isAudPpal, parsedQueryString, defaulteventSlugname } = props;
  const [loading, set_loading] = React.useState(false);
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [reserveBabies, set_reserveBabies] = React.useState(false);

  let printable = React.createRef();

  const cancelReservation = (e, retry) => {
    e.preventDefault();
    const { identification, phone, eventSlugname } = inputs;

    Swal.fire({
      title: retry ? '¿Reintentar reserva?' : '¿Seguro que desea cancelar la reserva?',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        set_loading(true);
        EventService.cancelReservation({
          cancelled: retry ? 'retry-payment' : 'user',
          identification,
          phone,
          eventSlugname,
        })
          .then((response) => {
            if (response.data.success) {
              Swal.fire(response.data.message);
              if (retry) {
                props.history.push(
                  `/servicio/reservacion?phone=${phone}&identification=${identification}&ac=${avoCaptConstructor(
                    identification
                  )}&eventSlugname=${eventSlugname}`
                );
                props.history.go()
              } else {
                props.history.push(`/servicio/reservacion?eventSlugname=${eventSlugname}`);
                props.history.go()
              }
            } else {
              Swal.fire(response.data.message);
            }
          })
          .catch((error) => {
            if (error.response) Swal.fire(error.response.data.message);
          });
      }
    });
  };

  const printButton = () => (
    <ReactToPrint trigger={() => <Button>Imprimir</Button>} content={() => printable.current} />
  );

  const reservationId = () => {
    if (inputs['AuditoriumGuests.id']) return inputs['AuditoriumGuests.id'];
    if (inputs.reserved['AuditoriumGuests.id']) return inputs.reserved['AuditoriumGuests.id'];
    if (inputs.reserved) return inputs.reserved.id;
    return 'N/A';
  };

  if (
    inputs.paidEvent &&
    (Object.keys(inputs?.['AuditoriumGuests.payment'] || {})?.length === 0 ||
      (parseInt(inputs.paidEvent) > parseInt(inputs?.['AuditoriumGuests.payment']?.amount) &&
        inputs?.['AuditoriumGuests.payment']?.partialPaid))
  ) {
    return (
      <PendingPaymentModal
        cancelReservation={cancelReservation}
        eventSlugname={defaulteventSlugname()}
        inputs={inputs}
        history={props.history}
        setInputs={setInputs}
      />
    );
  }
  return (
    <div className="service summary">
      <div ref={printable} style={{ textAlign: 'center', padding: '50px' }}>
        <p className="title">
          ¡Bienvenid{inputs.gender === 'Femenino' ? 'a' : 'o'} {inputs.name}!
        </p>
        <QRCode
          value={`${
            process.env.REACT_APP_HOSTNAME
          }/checkin?event=Auditorio_Principal&eventSN=${defaulteventSlugname()}&id=${
            inputs.identification
          }`}
        />
        <div className="barcode">
          <Barcode value={inputs.identification} />
        </div>
        <p>
          <strong>Codigo de reserva:</strong> {reservationId()}
        </p>

        <p>
          <strong>Reserva para: {eventSlugnameTrim(defaulteventSlugname())}</strong>
        </p>
        {inputs?.allReservations?.length > 1 ||
        (inputs?.allReservations?.length === 1 &&
          inputs?.allReservations?.[0]?.['AuditoriumGuests.volunteer']) ? (
          <>
            {inputs.photo && (
              <div
                className="profilePhoto"
                style={{ backgroundImage: `url(${inputs.photo})`, margin: '20px auto' }}
              />
            )}
            {inputs.allReservations.map((a) => (
              <>
                <p
                  style={{
                    margin: '5px 0 0 0',
                    fontSize: '15px',
                  }}
                >
                  {a['AuditoriumGuests.volunteer'] ? (
                    <strong>Entrada como voluntario</strong>
                  ) : (
                    <strong>Entrada como participante</strong>
                  )}
                </p>
                <p style={{ margin: 0 }}>
                  {a['AuditoriumGuests.id']}
                  {' - '}
                  {moment.tz(a.date, 'America/Bogota').format('dddd DD [de] MMMM - hh:mm a')}
                </p>
              </>
            ))}
          </>
        ) : (
          <p>
            {moment
              .tz(inputs.date, 'America/Bogota')
              .format(
                defaulteventSlugname()?.includes('Crecer:_PASO_1')
                  ? 'dddd hh:mm a'
                  : 'dddd DD [de] MMMM - hh:mm a'
              )}
          </p>
        )}
        {Object.keys(inputs['AuditoriumGuests.payment'] || {}).length > 0 && (
          <div>
            <p style={{ fontWeight: 'bold', margin: '0' }}>Referencia de pago</p>
            <p style={{ margin: '0' }}>{inputs['AuditoriumGuests.payment'].reference}</p>
          </div>
        )}
        {isAudPpal && (
          <div>
            {moment().diff(inputs.birthdate, 'years') < 14 && (
              <p>
                <strong>Acudiente:</strong>{' '}
                {inputs['AuditoriumGuests.parentFullname'] || inputs.parentFullname}
              </p>
            )}
          </div>
        )}
        {!isPrinting &&
          (inputs.presentingBabies || defaulteventSlugname() === 'Presentacion_de_Bebes') &&
          (parsedQueryString.presentingBabies ||
            defaulteventSlugname() === 'Presentacion_de_Bebes') && (
            <Button
              disabled={loading}
              secondary
              style={{ background: 'red' }}
              onClick={() => set_reserveBabies(!reserveBabies)}
            >
              ¿Vas a presentar un niño?
            </Button>
          )}
        {reserveBabies && (
          <PresentingBabies
            reserveBabies={reserveBabies}
            set_reserveBabies={set_reserveBabies}
            loading={loading}
            prevInputs={inputs}
            setPrevInputs={setInputs}
            set_loading={set_loading}
          />
        )}
        {inputs?.additionalinformation?.summaryInfo && (
          <div style={{ margin: '30px 0' }}>
            <ReactMarkdown className="line-break">
              {inputs?.additionalinformation?.summaryInfo}
            </ReactMarkdown>
          </div>
        )}
        {!inputs?.additionalinformation?.summaryInfo && (
          <p style={{ textAlign: 'start', padding: '20px' }}>
            <small style={{ display: 'block' }}>
              Lleva esta confirmación impresa o en tu celular, puedes volver a obtenerla ingresando
              a este enlace y escribiendo tu cedula y telefono.
            </small>
            <small style={{ display: 'block' }}>
              Si no vas a asistir recuerda cancelar esta reservación.
            </small>
            <small style={{ display: 'block' }}>
              Recuerda llegar con 30 minutos de anticipación.
            </small>
            <small style={{ display: 'block' }}>
              Recuerda que esta prohibido el ingreso de bebidas y mascotas al auditorio principal.
            </small>
          </p>
        )}
      </div>
      <div className="actions" style={{ margin: '10px 0' }}>
        <Button disabled={loading} secondary onClick={(e) => cancelReservation(e)}>
          Cancelar reserva
        </Button>
        <span
          onClick={() => {
            setIsPrinting(true);
            setTimeout(() => {
              setIsPrinting(false);
            }, 1000);
          }}
        >
          {printButton()}
        </span>
      </div>
    </div>
  );
};

export default ReservationSummary;
