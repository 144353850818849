import { useContext } from 'react';
import { DashboardProfileComponent } from './styles/DashboardProfileComponent';
import { GuestDashboardContext } from './guestDashboardContext';

const Profile = () => {
  const { guest } = useContext(GuestDashboardContext);

  return (
    <DashboardProfileComponent>
      <p className="greeting">Hola, {guest.name}!</p>
      <h2 style={{ fontSize: '35px' }}>
        Estos son tus <span className="profile-text">Datos</span>
      </h2>
      <br></br>
      <div className="columnsProfile">
        <div className="columnProfile">
          <div className="profile-image-container">
            <div className="image-wrapper">
              <img src={guest.photo} alt="Profile" className="profile-image" />
            </div>
          </div>
          <div className="profile-field">
            <label>Nombres</label>
            <div className="field-value name">{guest.name}</div>
          </div>
          <div className="profile-field">
            <label>Apellidos</label>
            <div className="field-value">{guest.lastName}</div>
          </div>
          <div className="profile-field">
            <label>ID</label>
            <div className="field-value">{guest.identification}</div>
          </div>
        </div>

        <div className="columnProfile">
          <br></br>
          <div className="profile-field">
            <label>Correo Electrónico</label>
            <div className="field-value">{guest.email}</div>
          </div>
          <div className="profile-field">
            <label>Teléfono</label>
            <div className="field-value">{guest.phone}</div>
          </div>
          <div className="profile-field">
            <label>Cumpleaños</label>
            <div className="field-value">{guest.birthdate}</div>
          </div>
          <div className="profile-field">
            <label>Líder</label>
            <div className="field-value">{guest?.GuestLeader?.leaderFullName}</div>
          </div>
          <div className="profile-field">
            <label>Teléfono del Líder</label>
            <div className="field-value">{guest?.GuestLeader?.leaderPhone}</div>
          </div>
        </div>

        <div className="columnProfile">
        </div>
      </div>
      
    </DashboardProfileComponent>
  );
};

export default Profile;
