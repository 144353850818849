import { css } from '@emotion/react';

export const NewmemberComponent = css`
  .new-member {
    width: 80%;
    .Registration__form {
      h1 {
        color: #2185d0;
        font-size: 2.5em;
      }
      h2 {
        margin: 30px 0 25px 0;
      }
      .welcome-video {
        margin: 25px auto;
        & > div {
          margin: auto;
        }
      }
      #transversal {
        margin: 20px 0;
        display: inline-block;
        width: 40%;
      }
      textarea {
        min-width: 250px !important;
        max-width: 300px !important;
        max-height: 250px !important;
        min-height: 100px !important;
      }
      p.habeasdata {
        font-size: 15px;
      }
      .checks {
        width: 65%;
        margin: 20px 12%;
        text-align: start;
      }
      @media only screen and (max-width: 900px) {
        .welcome-video {
          div {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      @media only screen and (max-width: 600px) {
        .checks {
          width: 85%;
        }
        #transversal {
          display: block;
          width: 100%;
        }
      }
    }
    @media only screen and (max-width: 750px) {
      width: 95%;
    }
  }
`;
