import React from 'react';
import { Button } from 'semantic-ui-react';
import Swal from 'sweetalert2';
import generacionesService from '../../../../../../services/generacionesService';

const CancelReservationModal = (props) => {
  const [loading, set_loading] = React.useState(false);

  const cancelReservation = (e) => {
    e.preventDefault();
    const { eventSlugname, getInfo } = props;
    const { name, lastName, id } = props.kid;

    Swal.fire({
      title: `¿Cancelar la reserva de ${name} ${lastName}?`,
      showCancelButton: true,
      confirmButtonText: 'Si, cancelar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        set_loading(true);
        generacionesService
          .CancelGeneracionesReservation({
            generaciones_guest_id: id,
            eventSlugname,
          })
          .then((response) => {
            if (response.data.success) {
              Swal.fire(response.data.message);
              getInfo();
            }
          })
          .catch((error) => {
            if (error.response) Swal.fire(error.response.data.message);
          });
      }
    });
  };

  return (
    <Button loading={loading} onClick={cancelReservation} size="tiny">
      Cancelar el registro
    </Button>
  );
};

export default CancelReservationModal;
