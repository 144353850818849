import moment from 'moment';
/*import { Button } from 'semantic-ui-react';*/

const GroupsComplianceRow = (props) => {
  const { compliance/*, set_showCompliance*/ } = props;

  const {/* id,*/ fullName, identification, createdAt } =
    compliance;

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>
          {moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY, h:mm:ss a')}
        </p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{fullName}</p>
      </td>
     {/* <td>
        <Button primary onClick={() => set_showCompliance(id)}>
          Ver
        </Button>
      </td>*/}
    </tr>
  );
};

export default GroupsComplianceRow;
