import { Input, Select, Checkbox, Button } from 'semantic-ui-react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {
  churchServices,
  formSelectService,
  renderNumberOfTimes,
  generateTimeArray,
} from '../../../../components/helpers';
import MarkdownTextarea from '../../../../components/common/markdown-textarea';
import { useState } from 'react';
import moment from '../../../../services/moment_instance';
import NamePersonalization from './namePersonalization';

const CreateServiceForm = (props) => {
  const { inputs, setInputs, errorInputs, loading, previousEventsNames } = props;
  const [focused, set_focused] = useState(null);
  const { date, capacity } = inputs;

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };
  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };
  const allChurchServices = previousEventsNames || churchServices;
  return (
    <>
      <Input
        style={{ width: '33%', margin: '5px 5px 5px 0' }}
        onChange={handleInputs}
        name={'capacity'}
        value={capacity}
        type="number"
        placeholder="Capacidad máxima"
        min={0}
        id="capacity"
        className={`Registration__input Registration__input-${'capacity'} ${
          errorInputs['capacity'] ? 'error' : ''
        }`}
      />

      <Select
        placeholder="Hora"
        style={{
          display: 'inline-block',
          width: '35%',
          margin: '7px',
        }}
        value={inputs.time}
        onChange={selectHandle}
        name="time"
        className={`Registration__input Registration__input-${'time'} ${
          errorInputs['time'] ? 'error' : ''
        }`}
        options={generateTimeArray().map((a) => {
          return { key: a, value: a, text: a };
        })}
      />
      <div style={{ padding: '0 0 0 20px' }}>
        {allChurchServices.some((s) => s === inputs.eventSlugname) ? (
          formSelectService({
            Select,
            label: 'Auditorio',
            name: 'eventSlugname',
            handler: selectHandle,
            value: inputs.eventSlugname || '',
            options: allChurchServices,
          })
        ) : (
          <>
            {inputs.eventSlugname?.replace?.(/_/g, ' ')}{' '}
            <Button
              inverted
              size="mini"
              color="red"
              onClick={() => setInputs({ ...inputs, eventSlugname: allChurchServices[0] })}
            >
              Borrar
            </Button>
          </>
        )}
        <NamePersonalization setInputs={setInputs} inputs={inputs} />
      </div>
      <div
        style={{
          display: 'inline-block',
          padding: '0 0 0 20px',
          margin: '7px',
        }}
      >
        <label style={{ display: 'block' }} htmlFor={'date'}>
          Fecha
        </label>{' '}
        <SingleDatePicker
          date={new Date(date) === 'Invalid Date' || !date ? null : moment(date)}
          onDateChange={(newDate) =>
            handleInputs({
              target: {
                value: newDate ? newDate._d : newDate,
                name: 'date',
              },
            })
          }
          focused={focused}
          onFocusChange={({ focused }) => set_focused(focused)}
          placeholder="dd/mm/aaaa"
          showDefaultInputIcon={true}
          numberOfMonths={1}
          displayFormat="DD/MM/YYYY"
          isOutsideRange={() => false}
          id="date"
          className={`Registration__input Registration__input-${'date'} ${
            errorInputs['date'] ? 'error' : ''
          }`}
        />{' '}
        {new Date(date) === 'Invalid Date' || !date ? null : moment(date).format('DD-MMMM-YYYY')}
      </div>
      {(inputs?.eventSlugname || '').includes('.') && (
        <>
          <Input
            style={{ width: '33%', margin: '5px 5px 5px 0', display: 'block' }}
            onChange={handleInputs}
            name={'paidEvent'}
            min={0}
            value={inputs.paidEvent}
            type="number"
            placeholder="Costo del evento"
            id="paidEvent"
            className={`Registration__input Registration__input-${'paidEvent'} ${
              errorInputs['paidEvent'] ? 'error' : ''
            }`}
          />
          <div style={{ padding: '10px 20px' }} className="md-fullWidth">
            <Checkbox
              label="Se puede pagar en dos partes"
              name="allowPartialPayments"
              checked={inputs.allowPartialPayments}
              toggle
              onClick={() =>
                setInputs({ ...inputs, allowPartialPayments: !inputs.allowPartialPayments })
              }
            />
            {inputs.allowPartialPayments && (
              <p>Le permitirá al usuario escoger entre 1 o 2 pagos</p>
            )}
          </div>
        </>
      )}
      <div style={{ padding: '10px 20px' }} className="md-fullWidth">
        <Checkbox
          label="¿Se envia correo de confirmacion?"
          name="sendmail"
          checked={inputs.sendmail}
          toggle
          onClick={() => setInputs({ ...inputs, sendmail: !inputs.sendmail })}
        />
        {inputs.sendmail && (
          <div style={{ padding: '10px 20px' }} className="md-fullWidth">
            <Input
              style={{ width: '25%', margin: '5px 5px 5px 0' }}
              onChange={handleInputs}
              name={'templateId'}
              min={0}
              value={inputs.templateId}
              defaultValue={inputs?.emailData?.templateId}
              type="number"
              placeholder="Id de plantilla"
              id="templateId"
            />
            <Input
              style={{ width: '25%', margin: '5px 5px 5px 0' }}
              onChange={handleInputs}
              name={'listId'}
              min={0}
              value={inputs.listId}
              defaultValue={inputs?.emailData?.listId}
              type="number"
              placeholder="Id de lista"
              id="listId"
            />
          </div>
        )}
      </div>
      <div style={{ padding: '10px 20px' }} className="md-fullWidth">
        <Checkbox
          label="Se pedirá información extra"
          name="extraInfo"
          checked={inputs.extraInfo}
          toggle
          onClick={() => setInputs({ ...inputs, extraInfo: !inputs.extraInfo })}
        />
        {inputs.extraInfo && <p>Abajo podras especificar cuales son los campos</p>}
      </div>
      {(inputs?.eventSlugname || '') !== 'Presentacion_de_Bebes' && (
        <div style={{ padding: '10px 20px' }} className="md-fullWidth">
          <Checkbox
            label="Se presentarán bebés en este servicio"
            name="presentingBabies"
            checked={inputs.presentingBabies}
            toggle
            onClick={() => setInputs({ ...inputs, presentingBabies: !inputs.presentingBabies })}
          />
        </div>
      )}
      <div style={{ padding: '10px 20px' }} className="md-fullWidth">
        <Checkbox
          label="Se recibe donación voluntaria"
          name="volunteerDonation"
          checked={inputs.volunteerDonation}
          toggle
          onClick={() => setInputs({ ...inputs, volunteerDonation: !inputs.volunteerDonation })}
        />
      </div>
      <div style={{ padding: '10px 20px' }} className="md-fullWidth">
        <Checkbox
          label="Tiene certificado"
          name="certificated"
          checked={inputs.certificated}
          toggle
          onClick={() => setInputs({ ...inputs, certificated: !inputs.certificated })}
        />
        {inputs.certificated && (
          <p>Recuerda verificar que está montado el certificado de este evento</p>
        )}
      </div>
      <div style={{ padding: '10px 20px' }} className="md-fullWidth">
        <Checkbox
          label="¿Inscripciones cerradas?"
          name="closed"
          checked={inputs.closed}
          toggle
          onClick={() => setInputs({ ...inputs, closed: !inputs.closed })}
        />
        {inputs.closed && (
          <p>
            Esto significa que el evento no recibe inscripciones mientras esta opción este activa.
          </p>
        )}
      </div>
      {!(inputs?.eventSlugname || '').includes('.') && (
        <>
          {!inputs.multipleEvents && (
            <div style={{ padding: '10px 20px' }} className="md-fullWidth">
              <Checkbox
                label="¿Una sola inscripción para la serie de eventos consecutivos?"
                name="oneForManyEvents"
                checked={inputs.oneForManyEvents}
                toggle
                onClick={() =>
                  setInputs({
                    ...inputs,
                    oneForManyEvents: !inputs.oneForManyEvents,
                    multipleEvents: false,
                  })
                }
              />
              {inputs.oneForManyEvents && <p>Recuerda crear varios eventos consecutivos</p>}
            </div>
          )}
          {!inputs.oneForManyEvents && (
            <div style={{ padding: '10px 20px' }} className="md-fullWidth">
              <Checkbox
                label="Se pueden elegir varios dias/eventos de asistencia"
                name="multipleEvents"
                checked={inputs.multipleEvents}
                toggle
                onClick={() =>
                  setInputs({
                    ...inputs,
                    multipleEvents: !inputs.multipleEvents,
                    oneForManyEvents: false,
                  })
                }
              />
              {inputs.multipleEvents && <p>Recuerda crear varios eventos consecutivos</p>}
            </div>
          )}
        </>
      )}

      <MarkdownTextarea
        loading={loading}
        inputs={inputs}
        name={'confirmDataInfo'}
        label={'Información preliminar'}
        handleInputs={handleInputs}
        errorInputs={errorInputs}
        showHelpers={false}
      />
      <MarkdownTextarea
        loading={loading}
        inputs={inputs}
        name={'summaryInfo'}
        label={'Información en confirmación'}
        handleInputs={handleInputs}
        errorInputs={errorInputs}
      />
      {inputs.extraInfo && (
        <div>
          <p style={{ margin: '20px 0 0 0', fontWeight: 'bold' }}>Numero de campos extra</p>
          <Input
            style={{ width: '33%', margin: '5px 5px 5px 0' }}
            onChange={handleInputs}
            name={'numReqFields'}
            value={inputs.numReqFields}
            min={0}
            type="number"
            placeholder="Numero de campos extra"
            id="numReqFields"
          />
          <p style={{ margin: '20px 0 0 0', fontWeight: 'bold' }}>Campos extra</p>
          {inputs.numReqFields <= 0 && (
            <p>Escribe la cantidad de campos extras que se solicitará llenar al usuario</p>
          )}
          {renderNumberOfTimes(inputs.numReqFields).map((iteration) => (
            <div key={iteration}>
              <Input
                style={{ width: '33%', margin: '5px 5px 5px 0' }}
                onChange={handleInputs}
                name={`extraInfo_${iteration}`}
                value={inputs[`extraInfo_${iteration}`]}
                placeholder={`Nombre del Campo`}
                id={`extraInfo_${iteration}`}
              />
              {Boolean(inputs[`extraInfo_${iteration}`]) && (
                <Checkbox
                  label={'Hacer ' + inputs[`extraInfo_${iteration}`] + ' obligatorio?'}
                  name={`extraInfo_${iteration}_required`}
                  checked={inputs[`extraInfo_${iteration}_required`]}
                  toggle
                  onClick={() =>
                    setInputs({
                      ...inputs,
                      [`extraInfo_${iteration}_required`]:
                        !inputs[`extraInfo_${iteration}_required`],
                    })
                  }
                />
              )}
            </div>
          ))}
        </div>
      )}
      {Object.values(errorInputs).includes(true) && (
        <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
      )}
    </>
  );
};

export default CreateServiceForm;
