import React from 'react';
import Swal from 'sweetalert2';
import GenerateCertificate from '../../components/certificate/gencertificate';
import StandardCertificateIMG from '../../components/certificate/StandardCertificateIMG';
import EventService from '../../services/eventService';

const EventCertificate = (props) => {
  const [step, setStep] = React.useState(0);
  const [inputs, setInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);
  const [previousEventsNames, set_previousEventsNames] = React.useState([]);

  React.useEffect(() => {
    getPreviousEventNames();
  }, []);

  React.useEffect(() => {
    if (step === 1) {
      const { identification, eventSlugname } = inputs;
      const body = { identification, eventSlugname };

      set_loading(true);

      EventService.GenCertificate(body)
        .then((response) => {
          set_loading(false);
          const { success, certificate, message } = response.data;
          if (success) {
            if (certificate) {
              setInputs({ ...inputs, ...certificate, ...(certificate.styles || {}) });
              return setStep(2);
            }
          }
          Swal.fire(
            `${
              message || 'No se encontró'
            }, si crees que es un error por favor escribenos a experiencia@fepaisa.com`
          );
          setStep(0);
        })
        .catch((err) => {
          set_loading(false);
        });
    }
  }, [step]);

  const getPreviousEventNames = () => {
    const body = {
      prevDays: 50,
      certificated: true,
    };
    set_loading(true);
    EventService.getEventsNames(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  if (step === 0 || step === 1) {
    return (
      <GenerateCertificate
        setStep={setStep}
        inputs={inputs}
        title="Busca el certificado del evento"
        setInputs={setInputs}
        history={props.history}
        loading={loading}
        previousEventsNames={previousEventsNames}
      />
    );
  } else if (step === 2) {
    return <StandardCertificateIMG inputs={inputs} certificateIMG={inputs.imageURL} />;
  } else return <p>error por favor escribenos a experiencia@fepaisa.com</p>;
};
export default EventCertificate;
