import { Form, Checkbox } from 'semantic-ui-react';

const Paso1Clase3 = (props) => {
  const { set_answers, answers, numberOptions, questions } = props;

  const setQuestion = (answer) => {
    let newAnswers = { ...answers, [answer.num]: answer };
    set_answers(newAnswers);
  };

  let checkboxQuestion = (question, number) => (
    <Checkbox
      radio
      label={number}
      name={question.num}
      value={number}
      checked={answers[question.num] ? answers[question.num].grade === number : null}
      onChange={() =>
        setQuestion({
          letter: question.letter,
          grade: number,
          num: question.num,
        })
      }
    />
  );

  return (
    <Form>
      {questions.map((question) => (
        <Form.Field key={question.num}>
          {question.sec && <h3>{question.sec}</h3>}
          <span className="question">
            {question.num}. {question.question}
          </span>
          <span className="options">
            {numberOptions.map((number) => checkboxQuestion(question, number))}
          </span>
        </Form.Field>
      ))}
    </Form>
  );
};

export default Paso1Clase3;
