import Swal from 'sweetalert2';
import guestService from '../../../services/guest';
import eventService from '../../../services/eventService';
import ExperienceService from '../../../services/experienceService';
import groupService from '../../../services/groupService';
import { avoCaptConstructor } from '../../helpers';

export const getGuest = async ({ identification, phone, captcha, ac, setGuest, setCrecer }) => {
  await guestService
    .getGuest(`${identification}/${phone}`, `crecer/crecerAttendance`, {
      eventSlugname: 'crecerAttendance',
      captcha,
      ac,
    })
    .then((response) => {
      if (response.data.success) {
        setGuest({ ...response.data.Attendant });
      }
    })
    .catch((error) => {
      if (error.response) Swal.fire(error.response.data.message);
    });
};

export const getCrecerDates = async ({ setCrecerDates }) => {
  eventService
    .getDates()
    .then((response) => {
      if (response.data.success) {
        setCrecerDates(response.data.dates);
      }
    })
    .catch((error) => {
      if (error.response) Swal.fire(error.response.data.message);
    });
};

export const getAVolunteer = async ({ identification, phone, setExperienceVolunteer }) => {
  const body = {
    identification,
    phone,
  };
  ExperienceService.getAVolunteer(body)
    .then((response) => {
      if (response.data.success) {
        setExperienceVolunteer(response.data.volunteer);
      }
    })
    .catch((error) => {
      if (error.response) Swal.fire(error.response.data.message);
    });
};

export const getGuestGroup = async ({ identification, phone, setGuestGroup }) => {
  const body = {
    identification,
    phone,
    ac: avoCaptConstructor(identification)
  };
  groupService
    .getGroupAttendant(body)
    .then((response) => {
      if (response.data.success) {
        setGuestGroup(response.data);
      }
    })
    .catch((error) => {
      if (error.response) Swal.fire(error.response.data.message);
    });
};

export const getGuestGroups = async ({ identification, phone, setGuestGroups }) => {
  const body = {
    identification,
    phone
  };
  groupService
    .getAllGroups(body)
    .then((response) => {
      if (response.data.success) {
        setGuestGroups(response.data.groups);
      }
    })
    .catch((error) => {
      if (error.response) Swal.fire(error.response.data.message);
    });
};