import React from 'react';
import { countAnswers, scrollUp } from '../helpers';
import { spiritualGifts, DISC, sacredPath } from '../../containers/crecer/paso1/clase3Questions';
import { Button } from 'semantic-ui-react';
import FormforTutors from '../../containers/crecer/paso1/tutors/formForTutors';
import moment from 'moment';
import { Margin, usePDF } from 'react-to-pdf';

const getInterpretation = (source, answers, position) =>
  source.find((a) => a.letter === answers[position].letter);

const discLetter = (letter) => {
  if (letter === 'd') return `D - Determinante`;
  if (letter === 'i') return `I - Influyente`;
  if (letter === 's') return `S - Sereno`;
  if (letter === 'c') return `C - Consciente`;
  return '-';
};

export const showSpiritualGifts = (source, answers, position) => (
  <>
    <h3>{getInterpretation(source, answers, position)?.gift}</h3>
    <span>
      <strong>Total</strong>: {answers ? answers?.[position]?.grade : ''}
    </span>
    <p
      dangerouslySetInnerHTML={{
        __html: getInterpretation(source, answers, position)?.text,
      }}
    />
  </>
);

export const DISCResults = (DISCAnswers) => {
  return (
    <div className="DISC">
      <h2>1. La forma como te conectas con las personas </h2>
      <h3>{discLetter(DISCAnswers[0]?.letter)}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: DISC?.find((a) => a.letter[0] === DISCAnswers[0].letter)?.text,
        }}
      />
      <h3>
        {DISCAnswers?.[0]?.letter}/{DISCAnswers[1]?.letter}
      </h3>
      <h2>
        {discLetter(DISCAnswers?.[0]?.letter)} / {discLetter(DISCAnswers?.[1]?.letter)}
      </h2>
      <p
        dangerouslySetInnerHTML={{
          __html: DISC?.find(
            (a) =>
              a?.letter?.[0] === DISCAnswers?.[0]?.letter && a?.letter[1] === DISCAnswers[1]?.letter
          ).text,
        }}
      />
    </div>
  );
};

const CrecerP1C3UserAnswers = (props) => {
  const [spiritualGiftAnswers, set_spiritualGiftAnswers] = React.useState(null);
  const [experienceWithGodAnswers, set_experienceWithGodAnswers] = React.useState(null);
  const [DISCAnswers, set_DISCAnswers] = React.useState(null);
  const { answer, set_showAnswers, allTutors, inputsLogin } = props;
  const { toPDF, targetRef } = usePDF({
    method: 'save',
    page: { margin: Margin.MEDIUM },
    filename: 'resultados-crecer-conectar.pdf',
  });

  React.useEffect(() => {
    set_spiritualGiftAnswers(countAnswers(answer.Crecer1stClass3rds[0].spiritualGiftAnswers));
    set_DISCAnswers(countAnswers(answer.Crecer1stClass3rds[0].DISCAnswers));
    set_experienceWithGodAnswers(
      countAnswers(answer.Crecer1stClass3rds[0].experienceWithGodAnswers)
    );
  }, [answer]);

  React.useEffect(() => {
    scrollUp();
  }, []);

  return (
    <div className="results">
      <Button
        primary
        compact
        onClick={() => set_showAnswers(null)}
        style={{ display: 'block', margin: '10px 0' }}
      >
        Ir Atrás
      </Button>
      <div className="info">
        <p className="userdata">
          Encuesta de{' '}
          <strong>
            {answer.name} {answer.lastName}
          </strong>
        </p>
        <p className="userdata">
          Edad: <strong>{moment().diff(answer.birthdate, 'years')}</strong>
        </p>
        <p className="userdata">
          Genero: <strong>{answer.gender}</strong>
        </p>
        {answer.Crecer1stClass3rds[0].parentName && answer.Crecer1stClass3rds[0].parentPhone && (
          <div className="parents">
            <p>
              <strong>Acudiente</strong>
            </p>
            <p className="userdata">
              Nombre Acudiente: <strong>{answer.Crecer1stClass3rds[0].parentName}</strong>
            </p>
            <p className="userdata">
              Telefono Acudiente: <strong>{answer.Crecer1stClass3rds[0].parentPhone}</strong>
            </p>
          </div>
        )}

        <p className="userdata">
          Telefono: <strong>{answer.phone}</strong>
        </p>
        <p className="userdata">
          Identificación: <strong>{answer.identification}</strong>
        </p>
        <p className="userdata">
          Email: <strong>{answer.email}</strong>
        </p>
        {answer.Crecer1stClass3rds[0].country && (
          <p className="userdata">
            País: <strong>{answer.Crecer1stClass3rds[0].country}</strong>
          </p>
        )}

        <p className="userdata">
          Fecha de la encuesta:{' '}
          <strong>
            {moment
              .tz(answer.Crecer1stClass3rds[0].createdAt, 'America/Bogota')
              .format('DD-MM-YYYY')}
          </strong>
        </p>
      </div>

      <FormforTutors
        inputs={answer.Crecer1stClass3rds[0]}
        allTutors={allTutors}
        inputsLogin={inputsLogin}
      />

      <Button onClick={() => toPDF()}>Guardar resultados</Button>
      <div ref={targetRef} className="printable content">
        <h1>Encuestas de Conectar</h1>

        {DISCAnswers && Object.keys(DISCAnswers).length && DISCResults(DISCAnswers)}

        {experienceWithGodAnswers && Object.keys(experienceWithGodAnswers).length && (
          <div className="connecting">
            <h2>2. La forma como te conectas con Dios </h2>
            {showSpiritualGifts(sacredPath, experienceWithGodAnswers, 0)}
            {showSpiritualGifts(sacredPath, experienceWithGodAnswers, 1)}
            {showSpiritualGifts(sacredPath, experienceWithGodAnswers, 2)}
          </div>
        )}

        {spiritualGiftAnswers && Object.keys(spiritualGiftAnswers).length && (
          <div className="spiritual">
            <h2>3. Los dones espirituales que Dios te ha dado </h2>
            {showSpiritualGifts(spiritualGifts, spiritualGiftAnswers, 0)}
            {showSpiritualGifts(spiritualGifts, spiritualGiftAnswers, 1)}
            {showSpiritualGifts(spiritualGifts, spiritualGiftAnswers, 2)}
            {showSpiritualGifts(spiritualGifts, spiritualGiftAnswers, 3)}
          </div>
        )}
      </div>
    </div>
  );
};
export default CrecerP1C3UserAnswers;
