import { Checkbox } from 'semantic-ui-react';
import { formatCurrency } from '../../../../../components/helpers';

export const PartialPayment = (props) => {
  const { event, inputs, setInputs, totalDonation } = props;

  if (
    !!(Math.abs(parseInt(event.paidEvent)) || 0) &&
    event?.allowPartialPayments &&
    !event['volunteerDonation']
  ) {
    return (
      <div style={{ padding: '10px 20px' }} className="md-fullWidth">
        <Checkbox
          label="¿Pagar en 2 partes?"
          name="partialPaid"
          checked={inputs.partialPaid}
          toggle
          onClick={() => setInputs({ ...inputs, partialPaid: !inputs.partialPaid })}
        />
        {inputs.partialPaid && (
          <p style={{ padding: '10px 0 0 0' }}>
            El costo total es de <strong>{formatCurrency(totalDonation)}</strong>.<br />
            Al seleccionar esta opción la donación se hará en{' '}
            <strong>dos partes iguales de {formatCurrency(totalDonation / 2)}</strong>; <br />
            una aqui al donar la primera vez y la última volviendo a esta pantalla.
            <br />
            Recuerda que debes pagar la segunda parte para que tu entrada quede totalmente
            reservada.
          </p>
        )}
      </div>
    );
  }
  return <></>;
};
