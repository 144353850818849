import React from 'react';
import Button from '../../../../components/common/Button';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import crecerForms from '../../../../services/crecer';
import P1C3Answers from '../../../../components/crecer/P1C3Answers';

const TutorsAllP1C3Answers = (props) => {
  const [allAnswers, set_allAnswers] = React.useState([]);
  const [loaded, set_loaded] = React.useState(null);
  const [showAnswers, set_showAnswers] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [inputsLogin, setInputsLogin] = React.useState({});
  const [errorData, set_errorData] = React.useState('');
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);

  React.useEffect(() => {
    if (showAnswers === null) getInfo();
  }, [showAnswers]);

  React.useEffect(() => {
    getInfo();
  }, [inputs.tutor_id, inputs.contacted, inputs.appointment]);

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  function getInfo(page = false) {
    if (!inputsLogin.tutor_identification || !inputsLogin.tutor_phone) return;

    set_loaded(false);
    set_endPage(false);

    let filters = {};
    Object.entries(inputs)
      .filter((input) => input[1] != null)
      .forEach((element) => {
        filters[element[0]] = element[1];
      });

    let body = { filter: { ...filters }, ...inputsLogin, currentPage };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    crecerForms
      .getTutorsCrecerP1C3Form(body)
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);

          if (page && body.currentPage > 1)
            set_allAnswers([...allAnswers, ...response.data.Answers]);
          else set_allAnswers(response.data.Answers);

          if (response.data.Answers.length === 0) set_endPage(true);
        } else {
          set_loaded(null);
          set_errorData(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleLoginInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputsLogin({ ...inputsLogin, [name]: value });
  };

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <section className="AdminTable">
      {loaded === null ? (
        <form onSubmit={(e) => getInfo(e)} className="Registration__form">
          <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
            Login Tutores
          </label>
          <input
            placeholder="Cedula"
            min={0}
            type="number"
            name={'tutor_identification'}
            className="Registration__input Registration__input-id"
            value={inputsLogin.tutor_identification}
            onChange={handleLoginInputs}
            style={{ width: '33%', margin: '5px' }}
          />

          <input
            style={{ width: '33%', margin: '5px' }}
            onChange={handleLoginInputs}
            name={'tutor_phone'}
            value={inputsLogin.tutor_phone}
            placeholder="Teléfono"
            min={0}
            type="number"
            className="Registration__input Registration__input-tutor_identification"
          />
          {errorData && (
            <label style={{ display: 'block', padding: '20px 0', color: 'red' }}>{errorData}</label>
          )}
          <Button text="Entrar" disabled={loaded === false} onClick={(e) => getInfo(e)} />
        </form>
      ) : (
        <P1C3Answers
          loadMore={loadMore}
          endPage={endPage}
          inputsLogin={inputsLogin}
          showAnswers={showAnswers}
          getInfo={getInfo}
          handleInputs={handleInputs}
          selectHandle={selectHandle}
          loaded={loaded}
          inputs={inputs}
          allTutors={[]}
          allAnswers={allAnswers}
          {...props}
          set_showAnswers={set_showAnswers}
        />
      )}
    </section>
  );
};

export default TutorsAllP1C3Answers;
