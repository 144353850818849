import logo from '../../assets/static/comunidad_blanco.webp';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import authService from '../../services/auth';
import { clickableStyles } from '../helpers';

const Header = () => (
  <header className="header" id="nav">
    <Link to={'/'}>
      <img className="header__img" src={logo} alt="Logo Comunidad" />
    </Link>
    {!window.location.pathname.includes('dashboard') && <p
      style={{ ...clickableStyles, margin: '0 0 0 auto' }}
      onClick={() => authService.logout()}
      className="logout"
    >
      {localStorage.getItem('userToken') ? <span>Salir</span> : <span>Login</span>}
    </p>}
  </header>
);

export default Header;
