import React from 'react';
import { errorInput, selectArea, textArea, formInput } from '../components/helpers';
import { Button, Input, Select, TextArea } from 'semantic-ui-react';
import churchForms from '../services/churchForms';
import Swal from 'sweetalert2';
import Form from '../components/form/Form';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { habeasData } from '../components/helpers/habeasData';

const RedComunidadEmpresa = (props) => {
  const [inputs, setInputs] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [sending, set_sending] = React.useState(false);
  const [sent, set_sent] = React.useState(false);
  const [set_loadedData] = React.useState(false);

  React.useEffect(() => {
    if (props.editing) setInputs({ ...props.inputs.Guest, ...props.inputs });
  }, [props.inputs]);

  const form_verification = () => {
    const mandatoryInputs = [
      'name',
      'lastName',
      'identification',
      'phone',
      'email',
      'serviceOffered',
      'businessName',
      'comunidadMemeber',
      'businessPhone',
      'leaderPhone',
      'leaderName',
      'captcha',
    ];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    if (!props.editing && (inputs.comunidadMemeber === false || inputs.comunidadMemeber === 'No')) {
      Swal.fire(
        'Lo sentimos, la publicación en este directorio es un servicio exlusivo para miembros de Comunidad'
      );
      errorInputs = errorInput({
        errorInputs,
        input: { name: 'comunidadMemeber', value: false },
      });
    }

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5,
        },
      });
    }

    if (inputs['phone'] && inputs['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: inputs['phone'].length >= 10,
        },
      });
    }

    if (inputs['captcha'] && inputs['captcha'] !== 'null') {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'captcha',
          value: inputs['captcha'].length >= 10,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value });
  }

  function sendForm(e) {
    if (form_verification()) return;
    set_sending(true);

    set_loadedData(true);
    churchForms
      .postComunidadNetwork({ ...inputs }, props.editing)
      .then((response) => {
        if (response.data.success) {
          Swal.fire('Enviado correctamente');
          setInputs({});
          set_sent(true);
          if (props.editing) props.set_showComunidadNetwork(null);
        } else {
          Swal.fire('Hubo un error, por favor intenta mas tarde.');
        }
        set_sending(false);
      })
      .catch((error) => {
        set_sending(false);
        set_loadedData(false);
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const {
    businessName,
    serviceOffered,
    productDescription,
    facebook,
    instagram,
    businessPhone,
    twitter,
    leaderPhone,
    leaderName,
  } = inputs;

  return (
    <div className={`volunteer-registration ${props.editing && 'editing'}`} id="company">
      <div className="inner">
        <header>
          {props.editing ? (
            <h2>Editar: {businessName}</h2>
          ) : (
            <h2>Directorio de Empresas, Vendedores y Servicios</h2>
          )}
        </header>
        {sent ? (
          <>
            <h3 style={{ textAlign: 'center' }}>¡Enviado!</h3>
            <p style={{ textAlign: 'center' }}>
              Gracias, la informacion ha sido enviada con exito, pronto será revisada y procesada
              para su publicación.
            </p>
            <p style={{ textAlign: 'center' }}>¡Hecha un vistazo a las empresas publicadas!</p>
            <div style={{ textAlign: 'center' }}>
              <Link to="/catalogo-red-empresas">
                <Button secondary>Directorio empresarial</Button>
              </Link>
            </div>
          </>
        ) : (
          <>
            {!props.editing && (
              <>
                <p className="salute">
                  La Iglesia Comunidad Cristiana de Fe y Viento Fresco solo está refiriendo una base
                  de datos de emprendedores, pero en ningún caso los está recomendando, ni se hace
                  responsable de ninguna transacción comercial, laboral o civil entre los mismos.
                </p>
                <p className="salute">
                  Oramos a Dios para que entre los emprendedores crezcan las buenas relaciones y
                  obtengas bendiciones en todo, basados en Gálatas 6:10 “Así que, según tengamos
                  oportunidad, hagamos bien a todos, y mayormente a los de la familia de la fe”.
                </p>
              </>
            )}
            <p className="titles">Información del propietario</p>
            <form className="Registration__form">
              <Form
                Attendant={inputs}
                handleInputs={handleInputs}
                errorInputs={errorInputs}
                showDate
              />
              {formInput({
                Input,
                handler: handleInputs,
                label: 'Nombre del lider',
                name: 'leaderName',
                value: leaderName,
                errorInputs,
              })}

              {formInput({
                Input,
                handler: handleInputs,
                label: 'Teléfono del lider',
                type: 'number',
                name: 'leaderPhone',
                value: leaderPhone,
                errorInputs,
              })}
            </form>
            <div className="comunidadMemeber">
              {selectArea({
                Select,
                options: ['Seleccione', 'Si', 'No'],
                label: '¿Se congrega en Comunidad MDE?',
                name: 'comunidadMemeber',
                value:
                  inputs.comunidadMemeber === true || inputs.comunidadMemeber === 'Si'
                    ? 'Si'
                    : inputs.comunidadMemeber === false || inputs.comunidadMemeber === 'No'
                    ? 'No'
                    : null,
                errorInputs,
                handler: selectHandle,
              })}
            </div>

            <div className="Registration__form">
              <p className="titles">Información de la empresa</p>
              {formInput({
                errorInputs,
                Input,
                handler: handleInputs,
                label: 'Nombre de la empresa',
                name: 'businessName',
                value: businessName,
              })}
              {formInput({
                errorInputs,
                Input,
                handler: handleInputs,
                type: 'number',
                label: 'Telefono de la empresa',
                name: 'businessPhone',
                value: businessPhone,
              })}
              {formInput({
                errorInputs,
                Input,
                handler: handleInputs,
                label: 'Facebook',
                name: 'facebook',
                value: facebook,
              })}
              {formInput({
                errorInputs,
                Input,
                handler: handleInputs,
                label: 'Instagram',
                name: 'instagram',
                value: instagram,
              })}
              {formInput({
                errorInputs,
                Input,
                handler: handleInputs,
                label: 'Twitter',
                name: 'twitter',
                value: twitter,
              })}
            </div>
            <p className="titles">Información del producto o servicio</p>

            {formInput({
              errorInputs,
              Input,
              handler: handleInputs,
              label: 'Servicio o Producto que ofrece',
              name: 'serviceOffered',
              value: serviceOffered,
            })}
            {textArea({
              TextArea,
              label: 'Descripción o detalles de su producto o servicio.',
              name: 'productDescription',
              handler: handleInputs,
              value: productDescription,
              errorInputs,
            })}

            <div className="actions">
              {props.editing ? (
                <>
                  <p>Publicado</p>
                  {selectArea({
                    Select,
                    options: ['Seleccione', 'Si', 'No'],
                    label: 'Publicado',
                    name: 'published',
                    value: inputs.published || inputs.published === 'Si' ? 'Si' : 'No',
                    errorInputs,
                    handler: selectHandle,
                  })}
                  <Button
                    onClick={() => props.set_showComunidadNetwork(null)}
                    secondary
                    className="cancel"
                    disabled={sending}
                  >
                    Cerrar
                  </Button>
                </>
              ) : (
                <>
                  {Object.values(errorInputs).includes(true) && (
                    <h4 style={{ color: 'red' }}>
                      Por favor verifica los campos obligatorios en rojo.
                    </h4>
                  )}
                  {habeasData}
                </>
              )}
              <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA} onChange={captchaOnChange} />
              </div>
              <p style={{ color: 'red', textAlign: 'center' }}>
                {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
              </p>

              <Button onClick={() => sendForm()} primary disabled={sending}>
                Enviar
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default RedComunidadEmpresa;
