const GuestLeadersRow = (props) => {
  const { guestLeader } = props;
  const { Guest, leaderFullName, leaderPhone } = guestLeader;
  const { name, lastName, phone, identification } = Guest;

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{lastName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{leaderFullName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{leaderPhone}</p>
      </td>
    </tr>
  );
};

export default GuestLeadersRow;
