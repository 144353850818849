import { css } from '@emotion/react';

export const Button = css`
  .Button {
    background-color: #2596c2;
    border-radius: 0.6rem;
    border: 0;
    color: #fff;
    outline: none;
    padding: 0.5rem 1rem;
    font-weight: 700;
    font-size: inherit;
    cursor: pointer;
    transition: 300ms;
    width: 100%;
    max-width: 15rem;
    margin: 15px 0 15px 0;
    text-align: center;

    &:hover {
      background-color: #222;
    }

    &:disabled {
      background-color: #d3d3d3;
      color: #a3a3a3;
    }

    &--tag {
      max-width: 50%;
    }
  }
`;
