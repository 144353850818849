import { css } from '@emotion/react';

export const NewGroupFormComponent = css`
  .newGroupForm {
    .ui.selection,
    .ui.input {
      width: 46%;
    }
    .ui.selection {
      margin: 10px;
    }
    .selectorCheck {
      width: 100%;
      margin: 20px 0;
    }
  }
`;
