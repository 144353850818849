import React from 'react';
import { Button, Input, Modal } from 'semantic-ui-react';
import EventService from '../../../../../services/eventService';
import Swal from 'sweetalert2';

const PresentingBabies = (props) => {
  const { set_reserveBabies, loading, set_loading, prevInputs, setPrevInputs } = props;
  const [inputs, setInputs] = React.useState({ ...prevInputs });

  const presentingBabiesSend = () => {
    const { identification, phone, eventSlugname } = inputs;

    const aditionalinformation = {};
    aditionalinformation[`Nombre_de_el_bebe`] = inputs[`Nombre_de_el_bebe`];
    aditionalinformation[`Nombre_de_${condGenderParent()}`] =
      inputs[`Nombre_de_${condGenderParent()}`];
    aditionalinformation[`Telefono_de_${condGenderParent()}`] =
      inputs[`Telefono_de_${condGenderParent()}`];

    set_loading(true);
    EventService.AddAditionalInfo({
      identification,
      phone,
      eventSlugname,
      aditionalinformation,
    })
      .then((response) => {
        set_loading(false);
        Swal.fire(response.data.message);
        set_reserveBabies(false);
        setPrevInputs({ ...inputs, 'AuditoriumGuests.aditionalinformation': aditionalinformation });
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const condGenderParent = () => {
    if (inputs.gender === 'Masculino') return 'la_mama';
    return 'el_papa';
  };

  return (
    <Modal open onClose={() => set_reserveBabies(false)}>
      <Modal.Header>Presentar un niño</Modal.Header>
      <Modal.Content>
        <p style={{ color: 'red' }}>Escribe los datos del niño que vas a presentar.</p>{' '}
        <Input
          placeholder="Nombre del niño"
          id="Nombre_de_el_bebe"
          name={'Nombre_de_el_bebe'}
          disabled={loading}
          className={`Registration__input Registration__input-${'Nombre_de_el_bebe'} ${''}`}
          value={inputs.Nombre_de_el_bebe}
          onChange={handleInputs}
          style={{ width: '33%', margin: '5px' }}
        />
        {!inputs['AuditoriumGuests.aditionalinformation']?.Nombre_de_el_bebe && (
          <div>
            <Input
              placeholder={`Nombre de ${condGenderParent().replace(/_/g, ' ')}`}
              id={`Nombre_de_${condGenderParent()}`}
              name={`Nombre_de_${condGenderParent()}`}
              disabled={loading}
              className={`Registration__input Registration__input-Nombre_de_${condGenderParent()}`}
              value={inputs[`Nombre_de_${condGenderParent()}`]}
              onChange={handleInputs}
              style={{ width: '33%', margin: '5px' }}
            />
            <Input
              type="number"
              placeholder={`Telefono de ${condGenderParent().replace(/_/g, ' ')}`}
              id={`Telefono_de_${condGenderParent()}`}
              name={`Telefono_de_${condGenderParent()}`}
              disabled={loading}
              className={`Registration__input Registration__input-Telefono_de_${condGenderParent()}`}
              value={inputs[`Telefono_de_${condGenderParent()}`]}
              onChange={handleInputs}
              style={{ width: '33%', margin: '5px' }}
            />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading} secondary onClick={() => set_reserveBabies(false)}>
          Cancelar
        </Button>
        <Button
          secondary
          disabled={
            !inputs.Nombre_de_el_bebe ||
            ((!inputs[`Telefono_de_${condGenderParent()}`] ||
              !inputs[`Nombre_de_${condGenderParent()}`]) &&
              !inputs['AuditoriumGuests.aditionalinformation']?.Nombre_de_el_bebe)
          }
          onClick={() => presentingBabiesSend()}
        >
          Registrar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PresentingBabies;
