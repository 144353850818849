import { Button, Modal } from 'semantic-ui-react';
import { ManuallyUpdateGuestLeaders } from '../../volunteer/components/manuallyUpdateGuestLeaders';
import { chPerm } from '../../../components/helpers';

export const ShowGuestLeaders = (props) => {
  const { Guest, setOpenVolLeaders, openVolLeaders, buttonText, getInfo, set_loading, loading } =
    props;
  const { GuestLeader, name, lastName } = Guest || {};
  return (
    <Modal
      onClose={() => setOpenVolLeaders(null)}
      onOpen={() => setOpenVolLeaders(true)}
      open={openVolLeaders}
      trigger={<Button size="tiny">{buttonText}</Button>}
    >
      <Modal.Header>
        Lider de {name}&nbsp;{lastName}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            <strong>Nombre:</strong> {GuestLeader?.leaderFullName}
            <br />
            <strong>Telefono:</strong> {GuestLeader?.leaderPhone}
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {chPerm(['volunteers']) && (
          <ManuallyUpdateGuestLeaders
            GuestLeader={GuestLeader}
            guest={Guest}
            getInfo={getInfo}
            set_loading={set_loading}
            loading={loading}
          />
        )}
        <Button onClick={() => setOpenVolLeaders(false)}>Cerrar</Button>
      </Modal.Actions>
    </Modal>
  );
};
