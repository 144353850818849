import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Button } from 'semantic-ui-react';
import UserRow from '../../../components/common/adminTable/userRow';
import authService from '../../../services/auth';
import EventService from '../../../services/eventService';

const AllUsers = (props) => {
  const [allUsers, set_allUsers] = React.useState([]);
  const [loading, set_loading] = React.useState(true);
  const [previousEventsNames, set_previousEventsNames] = React.useState([]);

  React.useEffect(() => {
    getUsers();
    getPreviousEventNames();
  }, []);

  function getUsers() {
    set_loading(true);

    authService
      .getAllUsers()
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_allUsers(response.data.allUsers);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  const getPreviousEventNames = () => {
    set_loading(true);
    EventService.getEventsNames()
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => props.history.goBack()}>Atrás</Button>
        <h2>Directorio de usuarios de App Comunidad MDE</h2>
      </div>

      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Usuario</th>
            <th>Eventos Autorizados</th>
          </tr>
        </thead>
        <tbody>
          {allUsers.map((user) => (
            <UserRow
              getUsers={getUsers}
              user={user}
              previousEventsNames={previousEventsNames}
              history={props.history}
            />
          ))}
          {loading && <p>Cargando</p>}
        </tbody>
      </table>
    </section>
  );
};

export default AllUsers;
