const Button = (props) => {
  const { text, disabled, onClick, type } = props;
  return (
    <button className="Button" type={type || 'button'} disabled={disabled} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
