import React from 'react';
import { Button, Modal, TextArea, Header, Input, Select } from 'semantic-ui-react';
import { textArea, formInput, chPerm } from '../../../components/helpers';
import moment from 'moment';
import membersForms from '../../../services/membersForms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ReactToPrint from 'react-to-print';
import CounselingDocuments from './counselingOfficialDoc';
import SignatureCanvas from 'react-signature-canvas';

const CounselingModal = (props) => {
  const {
    set_showCounselings,
    showCounselings,
    counseling,
    getInfo,
    counselingProfessionalFields,
  } = props;
  const [inputs, setInputs] = React.useState({});
  const [seeMore, set_seeMore] = React.useState(false);
  const [loading, set_loading] = React.useState(false);
  let printable = React.createRef();
  let canvasSign = {};
  let canvasProfessionalSign = {};
  let canvasCounselorSign = {};
  const counselingInfo = counseling || {};

  React.useEffect(() => {
    const { contacted, tutorObservations } = counselingInfo;
    setInputs({ contacted, tutorObservations });
  }, [counseling]);

  React.useEffect(() => {
    if (counselingInfo.sign) {
      let sign = JSON.parse(counselingInfo.sign);
      canvasSign.fromData([sign]);
      canvasSign.off();
    }
    if (counselingInfo.professionalSign) {
      let professionalSign = JSON.parse(counselingInfo.professionalSign);
      canvasProfessionalSign.fromData([professionalSign]);
      canvasProfessionalSign.off();
    }
    if (counselingInfo.counselorSign) {
      let counselorSign = JSON.parse(counselingInfo.counselorSign);
      canvasCounselorSign.fromData([counselorSign]);
      canvasCounselorSign.off();
    }
  }, [inputs]);

  function updateCounseling() {
    set_loading(true);
    let body = {
      ...inputs,
      id: counselingInfo.id,
      professionalSign:
        canvasProfessionalSign.props && !canvasProfessionalSign.isEmpty()
          ? JSON.stringify(canvasProfessionalSign.toData().flat())
          : null,
      counselorSign:
        canvasCounselorSign.props && !canvasCounselorSign.isEmpty()
          ? JSON.stringify(canvasCounselorSign.toData().flat())
          : null,
    };

    membersForms
      .updateCounseling(body)
      .then((response) => {
        if (response.data.success) {
          Swal.fire('Guardado satisfatoriamente');
          set_showCounselings(null);
          getInfo();
        }
        set_loading(false);
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  const signComponent = (sign, toSign) => {
    const canvasProps = { width: 300, height: 200, className: 'sigCanvas' };
    if (sign === 'user')
      return (
        <div className="signature">
          <SignatureCanvas
            penColor="black"
            ref={(ref) => {
              canvasSign = ref;
            }}
            canvasProps={canvasProps}
          />
          <p>Firma Usuario</p>
          <p className="name">
            <strong>{counseling.Guest.name}</strong> <strong>{counseling.Guest.lastName}</strong>
          </p>
          <p>{counseling.Guest.identification}</p>
        </div>
      );
    if (sign === 'pro' && (counselingInfo.professionalSign || toSign))
      return (
        <div className="signature">
          <SignatureCanvas
            penColor="black"
            ref={(ref) => {
              canvasProfessionalSign = ref;
            }}
            canvasProps={canvasProps}
          />
          <p>Firma del Profesional</p>
          <p className="name">
            <strong>{counselingInfo.professionalName}</strong>
          </p>
        </div>
      );
    if (sign === 'coun' && (counselingInfo.counselorSign || toSign))
      return (
        <div className="signature">
          <SignatureCanvas
            penColor="black"
            ref={(ref) => {
              canvasCounselorSign = ref;
            }}
            canvasProps={canvasProps}
          />
          <p>Firma de persona que atendió la solicitúd</p>
          <p className="name">
            <strong>{counselingInfo.counselorName}</strong>
          </p>
        </div>
      );
    return null;
  };

  return (
    <Modal open={showCounselings === counseling.id} onClose={() => set_showCounselings(null)}>
      <Modal.Content>
        <Modal.Description>
          <Header>Formulario de consejeria de {counseling.Guest.name}</Header>
          <div className="counseling">
            <p>
              <strong>Nombre: </strong>
              {counseling.Guest.name}
            </p>
            <p>
              <strong>Apellido: </strong>
              {counseling.Guest.lastName}
            </p>
            <p>
              <strong>Celular: </strong>
              {counseling.Guest.phone}
            </p>
            <p>
              <strong>Asiste a un grupo: </strong>
              {Boolean(counselingInfo.attendingGroup) ? 'Si' : 'No'}
            </p>
            {!seeMore && (
              <Button size="mini" onClick={() => set_seeMore(!seeMore)}>
                Ver todo
              </Button>
            )}
            {seeMore && (
              <>
                <p>
                  <strong>Cedula: </strong>
                  {counseling.Guest.identification}
                </p>
                <p>
                  <strong>Dirección: </strong>
                  {counselingInfo.address}
                </p>
                <p>
                  <strong>E-Mail: </strong>
                  {counseling.Guest.email}
                </p>
                <p>
                  <strong>Fecha de creación: </strong>
                  {moment.tz(counselingInfo.createdAt, 'America/Bogota').format('DD-MM-YYYY')}
                </p>
              </>
            )}
            <p>
              <strong>Motivo de consejería: </strong>
              {counselingInfo.counselingReason ? counselingInfo.counselingReason : '(No escribió)'}
            </p>
            <p>
              <strong>Motivo de Oración: </strong>
              {counselingInfo.toPray ? counselingInfo.toPray : '(No escribió)'}
            </p>
          </div>
          <div className="form-tutor">
            <p style={{ margin: '20px 0 0 0' }}>
              <strong>Escribe las observaciones de la llamada: </strong>
            </p>
            <label htmlFor="counselorObservations">
              Observaciones de la persona que atendió la solicitúd
            </label>
            {textArea({
              TextArea,
              handler: handleInputs,
              type: 'text',
              label: 'Observaciones del consejero',
              name: `counselorObservations`,
              style: { margin: 0 },
              value: inputs[`counselorObservations`] || counselingInfo.counselorObservations,
            })}
            <div style={{ padding: '20px 0' }}>
              <label style={{ display: 'block' }} htmlFor="professionalField">
                Profesional asignado al usuario
              </label>
              <Select
                placeholder="Profesional asignado"
                onChange={selectHandle}
                value={inputs[`professionalField`] || counselingInfo.professionalField}
                name="professionalField"
                id="professionalField"
                options={[
                  { key: null, value: null, text: 'Ninguno' },
                  ...counselingProfessionalFields.map((opt) => {
                    return { key: opt, value: opt, text: opt };
                  }),
                ]}
              />
            </div>
            {!signComponent('coun') ? (
              <div style={{ padding: '20px 0' }}>
                <p>Firma de persona que atendió la solicitúd</p>
                {signComponent('coun', true)}
                {formInput({
                  Input,
                  handler: handleInputs,
                  label: 'Nombre del que atendió la solicitúd',
                  name: 'counselorName',
                  value: inputs.counselorName || '',
                })}
              </div>
            ) : (
              <p>Ya firmado por el que atendió la solicitúd</p>
            )}
            {(Boolean(
              inputs[`professionalObservations`] || counselingInfo.professionalObservations
            ) ||
              chPerm(['profesionalCounselor'], true)) && (
              <>
                <label htmlFor="profesionalCounselor">Observaciones del profesional</label>
                {textArea({
                  TextArea,
                  handler: handleInputs,
                  disabled: !chPerm(['profesionalCounselor'], true),
                  style: { margin: 0 },
                  type: 'text',
                  label: 'Observaciones del profesional',
                  name: `professionalObservations`,
                  value:
                    inputs[`professionalObservations`] || counselingInfo.professionalObservations,
                })}
              </>
            )}
            <div style={{ display: 'none' }}>
              <div ref={printable} className="counseling printing">
                <CounselingDocuments
                  Attendant={{ ...counseling, ...counselingInfo }}
                  signComponent={signComponent}
                  errorInputs={{}}
                />
              </div>
            </div>
            {chPerm(['profesionalCounselor'], true) && (
              <>
                {!signComponent('pro') ? (
                  <div style={{ padding: '20px 0' }}>
                    <p>Firma del Profesional</p>
                    {signComponent('pro', true)}
                    {formInput({
                      Input,
                      handler: handleInputs,
                      label: 'Nombre del profesional',
                      name: 'professionalName',
                      value: inputs.professionalName || '',
                    })}
                  </div>
                ) : (
                  <p>Ya firmado por el profesional</p>
                )}
              </>
            )}
            {signComponent('pro') && !chPerm(['profesionalCounselor'], true) && (
              <p>Ya firmado por el profesional</p>
            )}
            <div style={{ margin: '20px 0' }}>
              <Button primary loading={loading} onClick={() => updateCounseling()}>
                Enviar
              </Button>
              <ReactToPrint
                trigger={() => <Button primary>Imprimir documento</Button>}
                content={() => printable.current}
              />
              <Button loading={loading} onClick={() => set_showCounselings(null)}>
                Cerrar
              </Button>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
export default CounselingModal;
