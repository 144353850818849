import React from 'react';
import Button from '../../components/common/Button';
import AnswersP1C3Row from '../../components/common/adminTable/P1C3AnswerRow';
import { Select, Input } from 'semantic-ui-react';
import CrecerP1C3UserAnswers from './crecerUserAnswers';
import TutorsResume from './tutorsResume';
import { Link } from 'react-router-dom';

const P1C3Answers = (props) => {
  const {
    showAnswers,
    getInfo,
    selectHandle,
    loaded,
    endPage,
    allAnswers,
    inputsLogin,
    handleInputs,
    inputs,
    set_showAnswers,
    allTutors,
    getAttendantInfo,
    loadMore,
    rawTutors,
  } = props;

  const [showTutorsResume, set_showTutorsResume] = React.useState(false);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  return (
    <>
      {showTutorsResume && (
        <TutorsResume
          showTutorsResume={showTutorsResume}
          rawTutors={rawTutors}
          selectHandle={selectHandle}
          set_showTutorsResume={set_showTutorsResume}
        />
      )}
      {!showAnswers && (
        <div className="AdminTable__content">
          <Link to="/admin?folder=crecer">
            <Button text={'Atrás'} />
          </Link>
          <Button text={'Buscar'} onClick={() => getInfo()} />
          <div>
            <Input
              loading={!loaded}
              name={'keyword'}
              onChange={handleInputs}
              value={inputs.keyword}
              className={'crecerp1c3'}
              style={{
                display: 'inline-block',
                width: '45%',
                margin: ' 7px 0',
              }}
              onKeyPress={onEnter}
              placeholder="Buscar..."
            />
            <Select
              placeholder="Filtrar por citado"
              style={{
                display: 'inline-block',
                width: '45%',
                margin: ' 7px 0',
              }}
              onChange={selectHandle}
              loading={!loaded}
              name="appointment"
              options={[
                { key: null, value: null, text: 'Todos' },
                { key: 'Ya citado', value: true, text: 'Ya citado' },
                { key: 'No citado', value: false, text: 'No citado' },
              ]}
            />
            <Select
              placeholder="Filtrar por cita realizada"
              style={{
                display: 'inline-block',
                width: '45%',
                margin: ' 7px 0',
              }}
              onChange={selectHandle}
              loading={!loaded}
              name="contacted"
              options={[
                { key: null, value: null, text: 'Todos' },
                { key: 'Ya realizo cita', value: true, text: 'Ya realizo cita' },
                { key: 'No ha realizado cita', value: false, text: 'No ha realizado cita' },
              ]}
            />

            {localStorage.getItem('userToken') && (
              <>
                <Select
                  placeholder="Filtrar por nombre de tutor"
                  style={{
                    display: 'inline-block',
                    width: '45%',
                    margin: ' 7px 0',
                  }}
                  onChange={selectHandle}
                  name="tutor_id"
                  value={inputs.tutor_id}
                  loading={!loaded}
                  options={[{ key: null, value: null, text: 'Todos' }, ...allTutors]}
                />
                <Select
                  placeholder="Mostrar solo tutores"
                  style={{
                    display: 'inline-block',
                    width: '45%',
                    margin: ' 7px 0',
                  }}
                  onChange={selectHandle}
                  loading={!loaded}
                  name="tutor"
                  options={[
                    { key: null, value: null, text: 'Todos' },
                    { key: 'Solo tutores', value: true, text: 'Solo tutores' },
                    { key: 'Solo no tutores', value: false, text: 'Solo no tutores' },
                  ]}
                />
                <Select
                  placeholder="Filtrar por encuestas presenciales"
                  style={{
                    display: 'inline-block',
                    width: '45%',
                    margin: ' 7px 0',
                  }}
                  defaultValue={false}
                  onChange={selectHandle}
                  loading={!loaded}
                  name="in_person"
                  options={[
                    { key: null, value: null, text: 'Todos' },
                    { key: 'Solo presencial', value: true, text: 'Solo presencial' },
                    { key: 'Solo online', value: false, text: 'Solo online' },
                  ]}
                />
                <Button onClick={() => getAttendantInfo()} text="Descargar con filtros" />
                <Button onClick={() => set_showTutorsResume(true)} text="Ver resumen de tutores" />
              </>
            )}
          </div>
        </div>
      )}
      <div>
        {!showAnswers && <p>Total en pantalla: {allAnswers.length}</p>}
        <table>
          {!showAnswers && (
            <thead>
              <tr>
                <th>Cedula</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Celular</th>
                <th>¿Citado?</th>
                <th>¿Cita Realizada?</th>
                {localStorage.getItem('userToken') && <th>Tutor</th>}
                <th>Ver</th>
              </tr>
            </thead>
          )}
          <tbody>
            {allAnswers.map((Answer, i) => (
              <>
                {showAnswers === Answer.id && (
                  <CrecerP1C3UserAnswers
                    answer={Answer}
                    allTutors={allTutors}
                    inputsLogin={inputsLogin}
                    set_showAnswers={set_showAnswers}
                  />
                )}
                {!showAnswers && (
                  <AnswersP1C3Row
                    P1C3Answers={Answer}
                    allTutors={allTutors}
                    set_showAnswers={set_showAnswers}
                  />
                )}
              </>
            ))}
          </tbody>
        </table>
        {!showAnswers && (
          <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
            <Button
              disabled={endPage || !loaded}
              onClick={() => loadMore()}
              text={loaded ? 'Cargar más' : 'Cargando...'}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default P1C3Answers;
