import { css } from '@emotion/react';

export const Clase3Component = css`
  .Clase3 {
    width: 90%;
    padding: 20px;
    background-color: white;
    h1,
    h2,
    h3 {
      text-align: center;
      padding: 10px;
    }
    p {
      padding: 10px;
    }
    .Questions {
      h3.clase {
        margin: 0;
      }
      .intro {
        p {
          margin-bottom: 0;
          font-size: 25px;
          &:nth-child(even) {
            color: rgb(77, 77, 77);
          }
        }
        button {
          margin: 20px auto;
          display: block;
        }
      }
      .quoteSending {
        h2 {
          padding: 41px 126px;
          font-weight: 100;
          font-size: 41px;
        }
        p {
          text-align: center;
          font-size: 30px;
          line-height: 27px;
          > small {
            color: gray;
          }
        }
      }
      .disc {
        .question {
          width: 60%;
        }
      }
      form {
        padding: 20px;
        .field {
          padding: 13px;
          margin: 0;
          &:nth-child(even) {
            background-color: rgb(241, 241, 241);
          }
        }
        span {
          display: inline-block;
          &.question {
            width: 70%;
            font-size: 22px;
          }
          &.options {
            label {
              padding: 4px 25px;
              font-size: 23px;
              &:before,
              &:after {
                width: 22px;
                height: 22px;
              }
            }
          }
        }
      }
    }

    @media only screen and (max-width: 1280px) {
      .Questions {
        form {
          &.ui.form {
            .field {
              .question {
                width: 55%;
              }
            }
          }
        }
        .quoteSending {
          h2 {
            padding: 5px;
            font-size: 30px;
          }
        }
      }
    }

    @media only screen and (max-width: 900px) {
      padding: 0px;
      .Questions {
        form {
          &.ui.form {
            padding: 0px;
            .field {
              padding: 10px 0px;
              .question {
                padding: 15px;
                width: 100%;
              }
              .options {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
`;
