import React from 'react';
import { Accordion, Select, Button, Checkbox, Input } from 'semantic-ui-react';
import groupService from '../../../services/groupService';
import GroupRow from '../../../components/common/adminTable/GroupRow';
import ViewCoupleGroup from './view-couple-group';
import {
  leaderCategories,
  groupCategories,
  seminary,
  eventSlugnameTrim,
  avoCaptConstructor,
} from '../../../components/helpers';
import PendingGroupMembers from './pendingGroupMembers';
import Swal from 'sweetalert2';
import ReportButton from '../ReportButton';
import { Link } from 'react-router-dom';

const AdminGroups = (props) => {
  const [loading, set_loading] = React.useState(false);
  const [leadingCouples, set_leadingCouples] = React.useState([]);
  const [createOpen, set_createOpen] = React.useState(false);
  const [pendingMembersOpen, set_pendingMembersOpen] = React.useState(false);
  const [inputs, setInputs] = React.useState({ noFilter: false });
  const [openEditGroupModal, setOpenEditGroupModal] = React.useState(false);

  const params = props.match.params;
  let kindgrouplist = groupCategories;

  if ((inputs.kindgroup || params.kind) === 'Grupo_Seminario') {
    kindgrouplist = seminary;
  } else if ((inputs.kindgroup || params.kind) === 'Equipo_de_Líderes') {
    kindgrouplist = leaderCategories;
  }

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  React.useEffect(() => {
    getInfo();
  }, [inputs.activityName, inputs.noFilter]);

  const isCouples =
    (inputs.activityName && inputs.activityName.includes('Parejas')) || inputs.coupleleadered;

  const getInfo = () => {
    const { activityName, noFilter, leaderNameFilter } = inputs;
    const { kind } = params;
    set_leadingCouples([]);
    if (activityName) {
      set_loading(true);
      let body = { activityName, noFilter, kind, leaderNameFilter };
      groupService
        .getAllGroupsByLeader(body)
        .then((response) => {
          if (response.data.success) {
            let panels = [];
            if (response.data.groups) {
              response.data.groups.forEach((group) => {
                panels = [
                  ...panels,
                  {
                    key: group.id,
                    title: `${group.groupName} ${
                      Boolean(inputs.noFilter) ? `- ${group.activityName.split('_').join(' ')}` : ''
                    }`,
                    content: {
                      content: (
                        <>
                          <div style={{ textAlign: 'end', padding: '0 0 10px 0' }}>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`/grupos/lider?phone=${
                                group.maleLeader ? group.maleLeader.phone : group.femaleLeader.phone
                              }&identification=${
                                group.maleLeader
                                  ? group.maleLeader.identification
                                  : group.femaleLeader.identification
                              }&activityName=${group.activityName}&kindgroup=${params.kind}&ac=${
                                group.maleLeader
                                  ? avoCaptConstructor(group.maleLeader.identification)
                                  : avoCaptConstructor(group.femaleLeader.identification)
                              }`}
                            >
                              <Button>Ver como lider</Button>
                            </a>
                            <ViewCoupleGroup
                              getInfo={getInfo}
                              editing
                              open={openEditGroupModal}
                              setOpen={(a) => setOpenEditGroupModal(a)}
                              editingInputs={group}
                              kindgrouplist={kindgrouplist}
                            />
                          </div>
                          <table>
                            <thead>
                              <tr>
                                {isCouples && (
                                  <>
                                    <th>Teléfono El</th>
                                    <th>Nombre El</th>
                                  </>
                                )}
                                <th>Teléfono {isCouples && 'Ella'}</th>
                                <th>Nombre {isCouples && 'Ella'}</th>
                                {group.takeattendance && <th>Asistencia</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {group?.GroupMembers?.filter?.((member) => !member.pending)?.map(
                                (member) => (
                                  <GroupRow
                                    member={member}
                                    getInfo={getInfo}
                                    isCouples={isCouples}
                                    editing
                                    takeattendance={false}
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        </>
                      ),
                    },
                  },
                ];
              });
            }
            set_leadingCouples(panels);
            set_loading(false);
          }
        })
        .catch((error) => {
          set_loading(false);
          if (error.response) Swal.fire(error.response.data.message);
        });
    }
  };

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const formSelect = ({ name, label, value, options, handler = selectHandle }) => (
    <Select
      placeholder={label}
      onChange={handler}
      value={value}
      name={name}
      options={options.map((opt) => {
        return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
      })}
    />
  );

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Link to="/admin?folder=groups">
          <Button>Atrás</Button>
        </Link>
        <h2>{eventSlugnameTrim(params.kind)}</h2>
        <div style={{ padding: '30px 0 0 0' }}>
          <div className="options">
            {!inputs.noFilter && (
              <div className="search-query">
                {formSelect({
                  label: 'Categoría',
                  name: 'activityName',
                  loading: loading,
                  value: inputs.activityName || '',
                  options: kindgrouplist,
                })}
              </div>
            )}
            <Input
              loading={loading}
              name={'leaderNameFilter'}
              onChange={handleInputs}
              value={inputs.leaderNameFilter}
              className={'leaderNameFilter'}
              onKeyPress={onEnter}
              placeholder="Buscar por nombre del lider"
            />
            <div style={{ padding: '5px 0 20px 20px' }} className="md-fullWidth">
              <Checkbox
                label="Mostrar todas las categorias"
                name="noFilter"
                checked={inputs.noFilter}
                toggle
                onClick={() =>
                  setInputs({
                    ...inputs,
                    noFilter: !inputs.noFilter,
                    activityName: inputs.activityName ? inputs.activityName : kindgrouplist[0],
                  })
                }
              />
            </div>
          </div>
          <div style={{ textAlign: 'start' }}>
            <Button onClick={() => set_createOpen(!createOpen)}>Crear Grupo</Button>
            {createOpen && (
              <ViewCoupleGroup
                setOpen={set_createOpen}
                open
                editingInputs={{ activityName: inputs.activityName }}
                getInfo={getInfo}
                kindgrouplist={kindgrouplist}
              />
            )}
            {params.kind === 'Grupo_Discipulado' && (
              <Button color="black" onClick={() => set_pendingMembersOpen(!pendingMembersOpen)}>
                Ver personas pendientes
              </Button>
            )}
            <div>
              <ReportButton
                URL={`/groups/report`}
                fileName={`Grupos CCF.xlsx`}
                event="Descarga reporte grupos"
              />
              <ReportButton
                URL={`/groups/get-groups-members-report`}
                fileName={`Miembros de Grupos CCF.xlsx`}
                filters={{ allActiveMembers: true }}
                event="Descargar reporte de miembros"
              />
            </div>
            {pendingMembersOpen && (
              <PendingGroupMembers setOpen={set_pendingMembersOpen} isCouples={isCouples} />
            )}
          </div>
        </div>
      </div>
      <Accordion panels={leadingCouples} />
      {Array.isArray(leadingCouples) && leadingCouples.length === 0 && !loading && (
        <p>No se encuentra información con estos criterios de búsqueda.</p>
      )}
      {loading && <p>Cargando</p>}
    </section>
  );
};

export default AdminGroups;
