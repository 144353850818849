import React from 'react';
import { Button, Checkbox, Input, Modal } from 'semantic-ui-react';

const NamePersonalization = (props) => {
  const { setInputs, inputs } = props;
  const [open, setOpen] = React.useState(false);
  const [paidEventName, setPaidEventName] = React.useState(false);

  const handleEventNameInput = (event) => {
    let value = event?.target?.value?.replace(/ /g, '_')?.replace(/\./g, '');
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button size="mini">Personalizar nombre</Button>}
    >
      <Modal.Header>Personalizar nombre de evento</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Input
            style={{ margin: '0 10px 0 0' }}
            onChange={handleEventNameInput}
            name={'eventSlugname'}
            value={inputs?.eventSlugname?.replace?.(/_/g, ' ')}
            placeholder="Nombre del evento"
            id="eventSlugname"
          />
          <div style={{ padding: '10px 20px' }} className="md-fullWidth">
            <Checkbox
              label="Evento de paga"
              name="paidEventName"
              checked={paidEventName}
              toggle
              onClick={() => setPaidEventName((prev) => !prev)}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cancelar</Button>
        <Button
          content="Personalizar"
          primary
          onClick={() => {
            setOpen(false);
            setInputs({
              ...inputs,
              eventSlugname: `${inputs.eventSlugname.replace(/\./g, '')}${
                paidEventName ? '.' : ''
              }`,
            });
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default NamePersonalization;
