import React from 'react';
import { Modal, Button, Select } from 'semantic-ui-react';
import {
  weekdays,
  comunidadProcess,
  eventSlugnameTrim,
  groupCategories,
  seminary,
  leaderCategories,
} from '../../../components/helpers';
import NewGroupForm from '../../../components/groups/leadersView/newGroupForm';
import groupService from '../../../services/groupService';
import Swal from 'sweetalert2';
import CoupleGroupRegistration from '../../../components/groups/registration/groupRegistration';
import ChangeLeadersModal from './changeLeadersModal';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

export const coupleKindOfGroupByCategory = (category = '') => {
  if (category.includes('_(')) {
    return 'Grupo_Discipulado';
  }
  if (category.includes('._')) {
    return 'Grupo_Seminario';
  }
  return 'Equipo_de_Líderes';
};

const ViewCoupleGroup = (props) => {
  const { editing, editingInputs, getInfo, open, setOpen } = props;
  const [inputs, setInputs] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);
  const [Step, setStep] = React.useState('create');

  React.useEffect(() => {
    setInputs({ ...(editingInputs || {}) });
  }, [editingInputs]);

  const isCouples =
    (inputs.activityName && inputs.activityName.includes('Parejas')) || inputs.coupleleadered;

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  const params = props.match.params;

  let kindgroup =
    inputs.kindgroup ||
    params.kind ||
    parsedQueryString.kindgroup ||
    coupleKindOfGroupByCategory(inputs.activityName);

  let categoriesgrouplist = groupCategories;
  if (kindgroup === 'Grupo_Seminario') {
    categoriesgrouplist = seminary;
  } else if (kindgroup === 'Equipo_de_Líderes') {
    categoriesgrouplist = leaderCategories;
  }

  const verifyForm = () => {
    let mandatoryInputs = ['activityName', 'time'];

    if (!inputs.activityName?.includes('._')) {
      mandatoryInputs = [...mandatoryInputs, 'groupName'];
      if (!inputs.femaleCrecerlevel && !inputs.maleCrecerlevel) {
        mandatoryInputs = [...mandatoryInputs, 'femaleCrecerlevel', 'maleCrecerlevel'];
      }
    }

    if (inputs.activityName?.includes('._')) {
      mandatoryInputs = [...mandatoryInputs, 'maxCapacity'];
    }

    if (!inputs.online) {
      mandatoryInputs = [...mandatoryInputs, 'address'];
    }

    let inputsWithErrors = {};

    inputsWithErrors = {
      ...inputsWithErrors,
      dayOfWeek: typeof inputs['dayOfWeek'] !== 'number',
    };

    mandatoryInputs.forEach((input) => {
      inputsWithErrors = {
        ...inputsWithErrors,
        [input]: !inputs[input],
      };
    });

    set_errorInputs(inputsWithErrors);
    return Object.values(inputsWithErrors).includes(true);
  };

  React.useEffect(() => {
    if (Step === 'send') {
      send();
    }
  }, [Step]);

  const deleteGroup = () => {
    Swal.fire({
      title: `¿Seguro que desea eliminar el grupo?`,
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar',
      cancelButtonText: 'Volver',
    }).then((result) => {
      if (result.value) {
        setInputs({ ...inputs, deleted: 'adminDelete' });
      }
    });
  };

  React.useEffect(() => {
    if (inputs.deleted) {
      send();
    }
  }, [inputs.deleted]);

  const onErrorStep = () => setStep(isCouples ? 'person1 person2' : 'person1');

  const send = (e) => {
    if (e) e.preventDefault();
    if (verifyForm()) return onErrorStep();

    if (
      isCouples &&
      inputs.person1 &&
      inputs.person2 &&
      inputs.person1.gender === inputs.person2.gender
    )
      return Swal.fire('Los lideres deben ser de genero diferente.');

    set_loading(true);
    if (editing) {
      groupService
        .editGroup(inputs)
        .then((response) => {
          if (response.data.success) {
            if (response.data) Swal.fire(response.data.message);
            setOpen(false);
            if (parsedQueryString.event) {
              window.location.replace(
                `/servicio/reservacion?event=${parsedQueryString.event}&id=event&phone=${parsedQueryString.phone}&identification=${parsedQueryString.identification}`
              );
            } else {
              getInfo();
            }
          } else {
            if (response.data) Swal.fire(response.data.message);
            onErrorStep();
          }
          set_loading(false);
        })
        .catch((error) => {
          if (error.response) Swal.fire(error.response.data.message);
          onErrorStep();
          set_loading(false);
        });
    } else {
      groupService
        .createGroup(inputs)
        .then((response) => {
          if (response.data.success) {
            getInfo();
            setOpen(false);
            if (response.data) Swal.fire(response.data.message);
          } else {
            if (response.data) Swal.fire(response.data.message);
            onErrorStep();
          }
          set_loading(false);
        })
        .catch((error) => {
          if (error.response) Swal.fire(error.response.data.message);
          onErrorStep();
          set_loading(false);
        });
    }
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCheckers = (name) => setInputs({ ...inputs, [name]: !inputs[name] });

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const formSelect = ({
    name,
    label,
    value,
    disabled = false,
    options = [],
    handler = selectHandle,
  }) => {
    return (
      <Select
        placeholder={label}
        onChange={handler}
        value={value}
        name={name}
        disabled={disabled}
        className={`Registration__input Registration__input-${name} ${
          errorInputs[name] ? 'error' : ''
        }`}
        options={options?.map((opt) => {
          return { key: opt, value: opt, text: eventSlugnameTrim(opt) };
        })}
      />
    );
  };

  return (
    <>
      <Button primary={parsedQueryString.event} onClick={() => setOpen(true)}>
        {editing ? 'Ver/Editar' : 'Crear'} Grupo
      </Button>
      {open && (
        <Modal onClose={() => setOpen(false)} closeIcon open>
          <Modal.Header>{editing ? 'Ver/Editar' : 'Crear'} grupo</Modal.Header>
          <form onSubmit={(e) => send(e)} className="Registration__form">
            {editing && (
              <>
                <h3>Líder{Boolean(isCouples) && 'es'}</h3>
                {!!inputs.maleLeader && (
                  <p>
                    <span style={{ fontWeight: 'bolder' }}>
                      {inputs.maleLeader.name} {inputs.maleLeader.lastName}
                    </span>{' '}
                    <span>{inputs.maleLeader.identification}</span>
                  </p>
                )}
                {!!inputs.femaleLeader && (
                  <p>
                    <span style={{ fontWeight: 'bolder' }}>
                      {inputs.femaleLeader.name} {inputs.femaleLeader.lastName}
                    </span>{' '}
                    <span>{inputs.femaleLeader.identification}</span>
                  </p>
                )}
              </>
            )}
            <NewGroupForm
              inputs={inputs}
              editing={editing}
              handleInputs={handleInputs}
              selectHandle={selectHandle}
              formSelect={formSelect}
              setInputs={setInputs}
              categoriesgrouplist={categoriesgrouplist}
              kindgroup={kindgroup}
              comunidadProcess={comunidadProcess}
              weekdays={weekdays}
              handleCheckers={handleCheckers}
              loading={loading}
              errorInputs={errorInputs}
              isCouples={isCouples}
            />
            {!editing && (
              <>
                <CoupleGroupRegistration
                  inputs={inputs}
                  setInputs={setInputs}
                  loading={loading}
                  setStep={setStep}
                  person="person1"
                  next={isCouples ? 'person1 person2' : 'send'}
                  strictNext
                  leaderView
                />

                {isCouples && Step.includes('person2') && (
                  <CoupleGroupRegistration
                    inputs={inputs}
                    loading={loading}
                    setInputs={setInputs}
                    setStep={setStep}
                    person="person2"
                    next={'send'}
                    strictNext
                    leaderView
                  />
                )}
              </>
            )}
          </form>
          <div style={{ padding: '20px 0', textAlign: 'center' }}>
            {editing && (
              <>
                <ChangeLeadersModal getInfo={getInfo} isCouples={isCouples} groupId={inputs.id} />
                {localStorage.getItem('userToken') && (
                  <Button
                    basic
                    color="red"
                    content="Red"
                    loading={loading}
                    onClick={(e) => deleteGroup(e)}
                  >
                    Eliminar
                  </Button>
                )}
                <Button secondary loading={loading} onClick={(e) => send(e)}>
                  Guardar{parsedQueryString.event && ' y continuar registro'}
                </Button>
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

ViewCoupleGroup.defaultProps = {
  kindgroup: [],
};

export default withRouter(ViewCoupleGroup);
