const discQuestions = [
  { num: 1, letter: 'd', question: 'Soy, asertivo, exigente y decisivo.' },
  { num: 2, letter: 'd', question: 'Disfruto Realizar múltiples tareas al mismo tiempo.' },
  { num: 3, letter: 'd', question: 'Me siento cómodo en un ambiente de constantes retos. ' },
  {
    num: 4,
    letter: 'd',
    question: 'Pienso primero en las tareas a realizar, por encima de otros o de mí mismo',
  },
  { num: 5, letter: 'd', question: 'Me motivan los logros y la autoridad. ' },
  { num: 6, letter: 'i', question: 'Disfruto influenciar e inspirar otras personas.' },
  { num: 7, letter: 'i', question: 'Soy, optimista acerca de otros' },
  { num: 8, letter: 'i', question: 'Con frecuencia soy “el alma de la fiesta” ' },
  { num: 9, letter: 'i', question: 'Pienso en motivar las personas' },
  { num: 10, letter: 'i', question: 'Estoy motivado por el reconocimiento y la aprobación. ' },
  { num: 11, letter: 's', question: 'Prospero en lugares coherentes.' },
  { num: 12, letter: 's', question: 'Prefiero ser enfático en lugar de solo generalizar.' },
  { num: 13, letter: 's', question: 'Disfruto socializarme en grupos pequeños.' },
  { num: 14, letter: 's', question: 'Prefiero ser miembro de un equipo.' },
  { num: 15, letter: 's', question: 'Me motiva la estabilidad y la aprobación. ' },
  { num: 16, letter: 'c', question: 'Con frecuencia evito tomar decisiones de alto riesgo. ' },
  { num: 17, letter: 'c', question: 'Me motivan las directrices, tareas y especificaciones. ' },
  { num: 18, letter: 'c', question: 'Con frecuencia tengo razón sobre las cosas.' },
  { num: 19, letter: 'c', question: 'Cumplo mejor normas que están claramente definidas.' },
  { num: 20, letter: 'c', question: 'Me motiva la calidad y la corrección.' },
];

const spiritualGifts = [
  {
    letter: 'a',
    gift: 'Administración',
    text: 'El don de la administración es la habilidad sobrenatural de organizar múltiples tareas y grupos de personas para cumplir tareas específicas. <span>Lucas 14: 28-30; Hechos 6: 1-7; 1 Corintios 12: 28.</span>',
  },
  {
    letter: 'b',
    gift: 'Plantación de iglesias',
    text: 'Es el don para incursionar en nuevas iglesias y ministerios a través de la plantación, supervisión, entrenamiento y discipulado de personas. <span>Hechos 15: 22- 35; 1 Corintios 12: 28; 2 Corintios 12:12; Gálatas 2: 7- 10; Efesios 4: 11-14.</span>',
  },
  {
    letter: 'c',
    gift: 'Artesanía',
    text: 'El don de la artesanía es la habilidad sobrenatural de planear, construir y trabajar con tus propias manos en el ambiente constructivo de cumplir múltiples aplicaciones ministeriales. <span>Éxodo 30:22, 31:3-11, 2 Crónicas 34:9-13, Hechos 18:2-3.</span>',
  },
  {
    letter: 'd',
    gift: 'Discernimiento',
    text: 'El don del discernimiento es la capacidad divina para identificar espiritualmente falsedad y distinguir entre las intenciones y motivaciones correctas y negativas. <span>Mateo 16: 21- 23; Hechos 5:1-11, 16: 16- 18; 1 Corintios 12: 10; 1 Juan 4: 1-6.</span>',
  },
  {
    letter: 'e',
    gift: 'Evangelismo',
    text: 'El don del evangelismo es la capacidad y el llamado sobrenatural de compartir las buenas noticias de Jesús.  <span>Hechos 8: 5-6, 8: 26-40, 14: 21; 21:8; Efesios 4: 11- 14.</span>',
  },
  {
    letter: 'f',
    gift: 'Animar',
    text: 'El don de animar es la capacidad divina para retar y fortalecer a otros a través de palabras sustentadas en la Escritura.<span>Hechos 14: 22; Romanos 12: 8; 1 Timoteo 4: 13; Hebreos 10: 24-25</span>',
  },
  {
    letter: 'g',
    gift: 'Fe',
    text: 'El don de la fe es la expectativa sobrenatural de creer en Dios por las cosas que no se ven, y que cumplirá todo lo que ha prometido. <span>Hebreos 11; Romanos 4: 18-21; 1 Corintios 12:9.</span>',
  },
  {
    letter: 'h',
    gift: 'Dar',
    text: 'El don de Dar es la habilidad sobrenatural de producir abundancia para diezmar y ofrendar con el propósito de expandir el Reino de Dios en la tierra.  <span>Marcos 12: 41-44; Romanos 12: 8; 2 Corintios 8: 1-7, 9: 2-7</span>',
  },
  {
    letter: 'i',
    gift: 'Sanidad',
    text: ' El don de la sanidad es la fortaleza divina de actuar como intermediario de la fe, orador e imposición de manos para traer sanidad tanto física, mental y psicológicamente. <span>Hechos 3: 1-10; 9: 32-35; 28: 7-10; 1 Corintios 12:9, 28.</span>',
  },
  {
    letter: 'j',
    gift: 'Servicio',
    text: 'El don de servicio es la capacidad sobrenatural de trabajar como apoyo para el cumplimiento de tareas grandes o pequeñas en el ministerio cristiano.<span>Marcos 15: 40-41; Hechos 9: 36, Romanos 16: 1- 2; 1 Corintios 12: 28, Hechos 6: 1-7 </span>',
  },
  {
    letter: 'k',
    gift: 'Hospitalidad',
    text: 'El don de la Hospitalidad es la fortaleza divina de crear ambientes cálidos y acogedores en lugares como la casa, la oficina o la iglesia. <span>Hechos 16: 14-15; Romanos 12: 13; Hebreos 13:1-2; 1 Pedro 4:9</span>',
  },
  {
    letter: 'l',
    gift: 'Oración',
    text: 'El don de la oración es la fortaleza sobrenatural para pararse en la brecha y orar por alguien, algo o algún lugar, creyendo por resultados definitivos <span>Hebreos 7: 25; Colosenses 1: 9-12, 4:12-13; Santiago 5: 14-16.  </span>',
  },
  {
    letter: 'm',
    gift: 'Conocimiento',
    text: 'El don de conocimiento es la fortaleza divina de entender y traer claridad a situaciones y circunstancias sustentadas por una palabra del Señor <span>Hechos 5:1-11; 1 Corintios 12:8; Colosenses 2:2-3</span>',
  },
  {
    letter: 'n',
    gift: 'Liderazgo',
    text: 'El don del liderazgo es la habilidad divina de influenciar personas en niveles de liderazgo mientras se les dirige y se les enfoca en la visión general, amplia. <span>Romanos 12:8; 1Timoteo 3: 1-13, 5:17; Hebreos 13:17</span>',
  },
  {
    letter: 'ñ',
    gift: 'Compasión',
    text: 'El don de la compasión es la habilidad sobrenatural de sentir empatía y cuidado por aquellos que han sido heridos y tomar la iniciativa de ayudarlos. <span>Mateo 9: 35-36; Marcos 9:41; Romanos 12:8; 1 Tesalonicenses 5:14. </span>',
  },
  {
    letter: 'o',
    gift: 'Milagros',
    text: 'El don de los milagros es la fortaleza divina o la habilidad para alterar los resultados naturales de la vida de una manera sobrenatural a través de la oración, la fe y la dirección divina. <span>Hechos 9:36-42, 19:11-12, 20: 7-12; Romanos 15: 18-19; 1 Corintios 12: 10, 28.</span>',
  },
  {
    letter: 'p',
    gift: 'Misiones',
    text: 'El don de las misiones es la habilidad sobrenatural de alcanzar a otros más allá de tu cultura y/o nacionalidad, en la mayoría de casos viviendo en una cultura o nación específica. <span>Hechos 8:4, 13:2-3, 22:21; Romanos 10:5. </span>',
  },
  {
    letter: 'q',
    gift: 'Música / adoración',
    text: 'El don de la alabanza/adoración es la fortaleza divina para cantar, danzar o interpretar algún instrumento principalmente para conducir a otros a adorar al Señor.<span>Deuteronomio 31:22; 1 Samuel 16:16; 1 Crónicas 16:41-42; 2 Crónicas 5:12-13, 34:12; Salmo 150.</span>',
  },
  {
    letter: 'r',
    gift: 'Pastor',
    text: 'El don de pastor es la divina habilidad de cuidar las necesidades personales de otros por medio del fomento y la reparación de problemas de la vida. <span>Juan 10:1-18; Efesios 4:11-14; 1 Timoteo 3: 1-7; 1 Pedro 5: 1-3.</span>',
  },
  {
    letter: 's',
    gift: 'Profecía',
    text: 'El don de la profecía es la fortaleza divina de hablar confiadamente y traer claridad a la verdad bíblica y doctrinal, en algunos casos prediciendo el plan de Dios.  <span>Hechos 2: 37-40, 7: 51-53, 26: 24-29; 1 Corintios 14: 1-4; 1 Tesalonicenses 1:5</span>',
  },
  {
    letter: 't',
    gift: 'Gobernar',
    text: 'EL don de gobernar es la habilidad sobrenatural para servir y tener influencia política para el bien del pueblo. <span> Daniel 1:20, Ester 4:14, Mateo 20:26. </span>',
  },
  {
    letter: 'u',
    gift: 'Enseñanza',
    text: 'El don de la enseñanza es la fortaleza divina para estudiar y aprender de las Escrituras principalmente para contribuir a la comprensión y el crecimiento de otros cristianos. <span>Hechos 18:24-28; 20, 20:20-21, 1 Corintios 12:28; Efesios 4: 11-14.</span>',
  },
  {
    letter: 'v',
    gift: 'Idiomas desconocidos',
    text: 'El don de idiomas desconocidos (en el griego glosolalia, también conocido como el don de hablar en lenguas) es la capacidad o habilidad de orar en un lenguaje celestial para fortalecerse a sí mismo y hablar con Dios. El don de idiomas desconocidos es a menudo acompañado por la interpretación y debe ser utilizado apropiadamente. <span>Corintios 14:1-14; Hechos 2:1-13; 1 Corintios 12: 10.</span>',
  },
  {
    letter: 'w',
    gift: 'Sabiduría',
    text: 'El don de la sabiduría es la fuerza o habilidad divina para aplicar las verdades de la Escritura de una manera práctica, produciendo un resultado fructífero además del reflejo del carácter de Jesucristo.<span> Hechos 6:3,10; 1 Corintios 2: 6-13, 12:8.</span>',
  },
];

const spiritualQuestions = [
  { letter: 'a', num: 1, question: 'Disfruto organizar servicios y eventos.' },
  { letter: 'b', num: 2, question: 'Disfruto ser un pionero en nuevos proyectos.' },
  { letter: 'c', num: 3, question: 'Trabajar con mis propias manos es divertido para mí.' },
  { letter: 'd', num: 4, question: 'Puedo discernir cuando alguien no es sincero.' },
  { letter: 'e', num: 5, question: 'Oro por los necesitados cada día.' },
  { letter: 'f', num: 6, question: 'Animar y retar a otros es una prioridad en mi vida.' },
  { letter: 'g', num: 7, question: 'Creo que el Señor proveerá mis necesidades diarias.' },
  {
    letter: 'h',
    num: 8,
    question: 'Ayudar a otros a través de las finanzas es extremadamente importante para mí.',
  },
  { letter: 'i', num: 9, question: 'Aprovecho cada oportunidad para orar por los enfermos.' },
  { letter: 'j', num: 10, question: 'Disfruto servir en pequeñas tareas que otros evitan.' },
  {
    letter: 'k',
    num: 11,
    question: 'Recibir personas en mi hogar es algo que realizo con frecuencia.',
  },
  { letter: 'l', num: 12, question: 'Disfruto pasar tiempo orando por otros.' },
  { letter: 'm', num: 13, question: 'La educación es muy importante para mi vida.' },
  {
    letter: 'n',
    num: 14,
    question: 'Mi enfoque se encuentra en motivar a otros para que se involucren más.',
  },
  { letter: 'ñ', num: 15, question: 'Me duele ver a otros afligidos.' },
  { letter: 'o', num: 16, question: 'Creo que Dios quiere hacer milagros a través de mí.' },
  { letter: 'p', num: 17, question: 'Disfruto compartir el evangelio con otras personas.' },
  {
    letter: 'q',
    num: 18,
    question: 'He dedicado tiempo para mejorar mi voz y/o manejo de algún instrumento.',
  },
  {
    letter: 'r',
    num: 19,
    question: 'Cuidar de una persona emocionalmente herida es una prioridad en mi vida.',
  },
  {
    letter: 's',
    num: 20,
    question: 'En ocasiones Dios me muestra el gran destino que hay en otras personas.',
  },
  {
    letter: 't',
    num: 21,
    question: 'Tengo un celo por la defensa de los principios y valores cristianos en la sociedad.',
  },
  { letter: 'u', num: 22, question: 'Me gusta crear diagramas y/o bosquejos de la biblia.' },
  { letter: 'v', num: 23, question: 'Dios me ha usado para interpretar idiomas desconocidos.' },
  { letter: 'w', num: 24, question: 'Disfruto leer el libro de Proverbios.' },
  { letter: 'a', num: 25, question: 'Me apasiona coordinar cada detalle.' },
  { letter: 'b', num: 26, question: 'Disfruto supervisar y entrenar otras personas.' },
  { letter: 'c', num: 27, question: 'Me considero un artesano o artesana. ' },
  {
    letter: 'd',
    num: 28,
    question: 'Siento cuando hay situaciones que son espiritualmente malsanas.',
  },
  { letter: 'e', num: 29, question: 'Me inquieto por ver personas llegando a Cristo.' },
  { letter: 'f', num: 30, question: 'Procuro ser cuidadoso y amoroso.' },
  { letter: 'g', num: 31, question: 'Creer en Dios para que cumpla grandes cosas, me emociona.' },
  { letter: 'h', num: 32, question: 'Busco formas de dar más allá del diezmo.' },
  { letter: 'i', num: 33, question: 'Creo que los milagros y sanidades ocurren hoy en día.' },
  { letter: 'j', num: 34, question: 'Ayudar a los demás es uno de mis mayores logros.' },
  { letter: 'k', num: 35, question: 'Crear un ambiente cálido es muy importante para mí.' },
  {
    letter: 'l',
    num: 36,
    question: 'Siento carga por las situaciones que le suceden al mundo y busco orar por ellas.',
  },
  {
    letter: 'm',
    num: 37,
    question: 'Las personas constantemente me buscan para que les enseñe más acerca de Dios.',
  },
  { letter: 'n', num: 38, question: 'Tomo liderazgo en situaciones cuando es necesario.' },
  { letter: 'ñ', num: 39, question: 'Soy sensible ante las dificultades de los demás.' },
  { letter: 'o', num: 40, question: 'Frecuentemente suceden milagros cuando estoy cerca.' },
  {
    letter: 'p',
    num: 41,
    question: 'Vivir en otros países para difundir el evangelio me emociona.',
  },
  { letter: 'q', num: 42, question: 'Deseo servir a Dios a través de la alabanza.' },
  { letter: 'r', num: 43, question: 'Disfruto conectar, cuidar y discipular a otros.' },
  {
    letter: 's',
    num: 44,
    question: 'Tengo facilidad para confrontar y ayudar un amigo si se encuentra en pecado.',
  },
  {
    letter: 't',
    num: 45,
    question:
      'Tengo ideas y/o proyectos aplicables en espacios políticos que pueden traer bienestar a mi ciudad.',
  },
  {
    letter: 'u',
    num: 46,
    question:
      'Comparto verdades bíblicas con otros con el deseo de que sean de bendición para sus vidas.',
  },
  { letter: 'v', num: 47, question: 'Me gusta orar en idiomas desconocidos (lenguas) cada día.' },
  { letter: 'w', num: 48, question: 'Cuando estudio las escrituras, Dios me revela cosas únicas.' },
  {
    letter: 'a',
    num: 49,
    question: 'Crear una lista de tareas me es más fácil y disfruto cumplirla.',
  },
  {
    letter: 'b',
    num: 50,
    question: 'Me llama la atención los ministerios que tienen que ver con crear nuevas iglesias.',
  },
  { letter: 'c', num: 51, question: 'Construir algo con mis propias manos es muy gratificante.' },
  {
    letter: 'd',
    num: 52,
    question: 'Puedo determinar con precisión dificultades o problemas antes que otros.',
  },
  { letter: 'e', num: 53, question: 'Disfruto compartir el evangelio con un completo extraño.' },
  { letter: 'f', num: 54, question: 'Busco maneras de ser influyente para otras personas.' },
  {
    letter: 'g',
    num: 55,
    question: 'Confío plenamente que Dios tiene control de cada situación en mi vida.',
  },
  { letter: 'h', num: 56, question: 'Obtener más dinero significa que puedo ofrendar más.' },
  {
    letter: 'i',
    num: 57,
    question: 'Dios me ha usado para traer sanidad a aquellos que están enfermos.',
  },
  { letter: 'j', num: 58, question: 'Me incomoda cuando alguien no quiere participar.' },
  {
    letter: 'k',
    num: 59,
    question: 'Con frecuencia tengo facilidad de hacer sentir a la gente como en casa.',
  },
  { letter: 'l', num: 60, question: 'La gente normalmente me describe como un intercesor.' },
  {
    letter: 'm',
    num: 61,
    question: 'Disfruto escudriñar la Biblia y ayudar a otros a entenderla.',
  },
  { letter: 'n', num: 62, question: 'Delego responsabilidades para poder cumplir tareas.' },
  { letter: 'ñ', num: 63, question: 'Me motiva ayudar a los menos afortunados.' },
  {
    letter: 'o',
    num: 64,
    question: 'Tengo un hambre constante por ver el poder milagroso de Dios.',
  },
  { letter: 'p', num: 65, question: 'Me enfoco especialmente en alcanzar el mundo para Cristo.' },
  {
    letter: 'q',
    num: 66,
    question: 'Me llena de satisfacción dirigir a otros en la alabanza.(instrumentos o vocal).',
  },
  { letter: 'r', num: 67, question: 'Disfruto acompañar a aquellos en momentos de dificultad.' },
  {
    letter: 's',
    num: 68,
    question: 'Disfruto tomar apuntes de una apasionante y honesta prédica.',
  },
  {
    letter: 't',
    num: 69,
    question:
      'Le pido al Señor que me permita ser un instrumento para cambiar la ciudad a través de la política.',
  },
  { letter: 'u', num: 70, question: 'Me gusta enseñar la Biblia en formas prácticas y creativas.' },
  {
    letter: 'v',
    num: 71,
    question: 'Orar en el Espíritu es importante y un gran reto en mi vida.',
  },
  {
    letter: 'w',
    num: 72,
    question:
      'Cuando atravieso un momento de dificultad trato de tomar decisiones sabias y acertadas.',
  },
];

const sacredPath = [
  {
    letter: 'a',
    gift: 'NATURISTA',
    titleText: 'Apreciando la belleza de la creación de Dios.',
    text: `
        <p><b>PERSONAJE BÍBLICO:</b> “Abraham fue un naturista”</p>
        <br/>
        <p>Génesis 15: 5 <br/>
        "Luego el Señor lo llevó afuera y le dijo: Mira hacia el cielo y cuenta las estrellas, a ver si puedes. ¡Así de numerosa será tu descendencia!”</p>
        <p>ESCRITURA PARA MEDITAR:<br/>
         </p>
         <p> Salmo 19: 1 <br/>
         “Los cielos cuentan la gloria de Dios, el firmamento proclama la obra de sus manos”. </p>
        <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
        <p>Busca un lugar al aire libre al que puedas ir regularmente para encontrarte solo con Dios.</p>        
    `,
  },
  {
    letter: 'b',
    gift: 'ENTUSIASTA',
    titleText: 'Se conecta con Dios a través de la celebración.',
    text: `
    <p><b>PERSONAJE BÍBLICO:</b> “David era un entusiasta”</p>
    <br/>
    <p>1 Crónicas 13: 8 <br/>
        Y David y todo Israel celebraban ante Dios con todas sus fuerzas, con canciones y liras, arpas, panderetas, tambores y trompetas.</p>
    <p>ESCRITURA PARA MEDITAR:<br/>
    (2 Samuel 6:22) NVI
     </p>
     <p> 2 Samuel 6:22<br/>
     “y me rebajaré más todavía, hasta humillarme completamente. Sin embargo, esas mismas esclavas de quienes hablas me rendirán honores”. 
     </p>
    <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
    <p>Para conectarse con Dios en su tiempo diario con el Señor, imagine que está en la iglesia entrando en su parte favorita del servicio de adoración.
    </p>        
`,
  },
  {
    letter: 'c',
    gift: 'INTELECTUALISTA',
    titleText: 'Obteniendo ideas sobre Dios y la vida con él.',
    text: `
    <p><b>PERSONAJE BÍBLICO:</b> "Salomón fue un intelectualista"</p>
    <br/>
    <p>1 Reyes 3: 5-13 <br/>
    En Gabaón, el Señor se le apareció a Salomón en un sueño por la noche, y Dios dijo: "Pregunta qué te daré". Y Salomón dijo: “Has mostrado un gran y firme amor a tu siervo David mi padre, porque él anduvo delante de ti en fidelidad, en rectitud y en rectitud de corazón hacia ti. Y le has guardado este gran y firme amor y le has dado un hijo para que se siente en su trono este día. Y ahora, Señor, Dios mío, has hecho rey a tu siervo en lugar de David mi padre, aunque yo no soy más que un niño pequeño. No sé cómo salir o entrar. Y tu siervo está en medio de tu pueblo a quien has elegido, un gran pueblo, demasiados para ser contados o contados por multitud. Dale a tu siervo una mente comprensiva para gobernar a tu pueblo, para que yo pueda discernir entre el bien y el mal, porque ¿quién puede gobernar a este tu gran pueblo? Le agradó al Señor que Salomón hubiera preguntado esto. Y Dios le dijo: "Porque has pedido esto, y no te has pedido larga vida o riquezas o la vida de tus enemigos, sino que te has pedido comprensión para discernir lo que es correcto, he aquí, ahora lo hago de acuerdo a tu palabra. He aquí, te doy una mente sabia y perspicaz, para que nadie como tú haya estado antes que tú y nadie como tú se levante después de ti. También te doy lo que no has pedido, tanto riquezas como honor, para que ningún otro rey se compare contigo todos tus días.
    1 Reyes 3: 5-13
    

        </p>
    <p>ESCRITURA PARA MEDITAR:<br/>
    (Proverbios 4: 7)
     </p>
     <p> Proverbios 4: 7
     <br/>
     "El primer paso para ser sabio es tomar la decisión de adquirir sabiduría. Así que usa todo lo que tengas para obtener sabiduría y la conseguirás". 

     </p>
    <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
    <p>Lee la Biblia pero tratando de entender quién es Dios y la forma en que se relaciona con su pueblo. Esto te ayudará a conectarte con el corazón de Dios, así como a obtener conocimiento acerca de Él.
    </p>        
`,
  },
  {
    letter: 'd',
    gift: 'TRADICIONALISTA',
    titleText: 'Siguiendo tradiciones y prácticas espirituales de larga tradición.',
    text: `
    <p><b>PERSONAJE BÍBLICO:</b> “La Nación de Israel”</p>
    <br/>
    <p>Génesis 12: 1-3<br/>
    Ahora el Señor le dijo a Abram: “Vete de tu país y de tu parentela y de la casa de tu padre a la
    tierra que te mostraré. Y haré de ti una gran nación, y te bendeciré y haré que tu nombre sea
    grandioso, para que seas una bendición. Bendeciré a los que te bendigan, y al que te
    deshonre, maldeciré, y en ti serán bendecidas todas las familias de la tierra ”

</p>
    <p>ESCRITURA PARA MEDITAR:<br/>
    (Hebreos 13: 7-8)
     </p>
     <p> (Hebreos 13: 7-8)
     <br/>
     “Piensen en los líderes que les anunciaron el mensaje de Dios, pues ellos confiaron siempre en Dios. Piensen mucho en ellos y sigan su ejemplo. Jesucristo nunca cambia: es el mismo ayer, hoy y siempre”. 
     Hebreos 13: 7-8
     </p>
    <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
    <p>• Memoriza las Escrituras
    </p>        
`,
  },
  {
    letter: 'e',
    gift: 'MINIMALISTA',
    titleText: 'Abstenerse de las comodidades para hacer más espacio para Dios.',
    text: `
    <p><b>PERSONAJE BÍBLICO:</b> "Los nazareos eran minimalistas"</p>
    <br/>
    <p>Números 6: 1-2 <br/>
    “ Entonces el Señor le dijo a Moisés: «Da al pueblo de Israel las siguientes instrucciones: si alguien del pueblo, sea hombre o mujer, hace el voto especial de nazareo, consagrándose al Señor de manera especial”
    Joel 1:14</p>
    <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
    <p>Pasa tiempo con Dios temprano en la mañana o tarde en la noche: escoge el momento donde te sientas más tranquilo.

    
    </p>        
`,
  },
  {
    letter: 'f',
    gift: 'EXPERIENCIALISTA',
    titleText: 'Experimentar a Dios con sus sentidos físicos o imaginación.',
    text: `
    <p><b>PERSONAJE BÍBLICO:</b> "Ezequiel fue un experiencialista"</p>
    <br/>
    <p>Ezequiel 37: 1-9
    <br/>
    La mano del Señor estaba sobre mí, y él me sacó en el Espíritu del Señor y me puso en el medio del valle Estaba lleno de huesos. Y él me guió entre ellos, y he aquí, había muchos en la superficie del valle, y he aquí, estaban muy secos. Y él me dijo: "Hijo de hombre, ¿pueden vivir estos huesos?" Y le respondí: "Oh Señor Dios, ya sabes". Luego me dijo: “Profetiza sobre estos huesos, y diles: Oh huesos secos, escucha la palabra del Señor. Así dice el Señor Dios a estos huesos: He aquí, haré que entre aire en ti y vivirás. Y pondré nervios sobre ti, y haré que la carne venga sobre ti, y te cubra de piel, y te dé aliento, y vivirás, y sabrás que yo soy el Señor. Así que profeticé como se me ordenó. Y mientras profetizaba, hubo un sonido, y he aquí, un traqueteo, y los huesos se unieron, hueso a hueso. Y miré, y he aquí, había tendones sobre ellos, y carne había venido sobre ellos, y la piel los había cubierto. Pero no había aliento en ellos. Luego me dijo: «Profetiza en el aliento; profetiza, hijo de hombre, y di al aliento: Así dice el Señor Dios: Ven de los cuatro vientos, oh aliento, y respira sobre estos muertos, para que puedan vivir ".
    Ezequiel 37: 1-9
</p>
    <p>ESCRITURA PARA MEDITAR:<br/>
    (Hechos 17:28)
     </p>
     <p> Hechos 17:28
     <br/>
     “Él nos da poder para vivir y movernos, y para ser lo que somos. Así lo dice uno de los poetas de este país: “Realmente somos hijos de Dios.” 
     
     </p>
    <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
    <p>Crea un espacio de adoración para ti que involucre tus sentidos: enciende una vela, pinta un cuadro,
    decora, expone artículos significativos, una cruz.
    • ¡Ve a Israel!
    
    
    </p>        
`,
  },
  {
    letter: 'g',
    gift: 'COMPASIONISTA',
    titleText: 'Ofrecer la compasión de Dios a aquellos que sufren o luchan.',
    text: `
    <p><b>PERSONAJE BÍBLICO:</b> "El buen samaritano era compasionista"</p>
    <br/>
    
    <p>Lucas 10 <br/>
    <b>Mateo 25: 35-40</b>
    Jesús respondió con una historia:
    —Un hombre judío bajaba de Jerusalén a Jericó y fue atacado por ladrones. Le quitaron la ropa, le pegaron y lo dejaron medio muerto al costado del camino.
    31 »Un sacerdote pasó por allí de casualidad, pero cuando vio al hombre en el suelo, cruzó al otro lado del camino y siguió de largo. 32 Un ayudante del templo[d] pasó y lo vio allí tirado, pero también siguió de largo por el otro lado.
    33 »Entonces pasó un samaritano despreciado y, cuando vio al hombre, sintió compasión por él. 34 Se le acercó y le alivió las heridas con vino y aceite de oliva, y se las vendó. Luego subió al hombre en su propio burro y lo llevó hasta un alojamiento, donde cuidó de él. 35 Al día siguiente, le dio dos monedas de plata[e] al encargado de la posada y le dijo: “Cuida de este hombre. Si los gastos superan esta cantidad, te pagaré la diferencia la próxima vez que pase por aquí”.
    36 »Ahora bien, ¿cuál de los tres te parece que fue el prójimo del hombre atacado por los bandidos? —preguntó Jesús.
    37 El hombre contestó:
    —El que mostró compasión.
    Entonces Jesús le dijo:
    —Así es, ahora ve y haz lo mismo.
    Lucas 10: 30- 37
    
    </p>
    <p>ESCRITURA PARA MEDITAR:<br/>
    “Porque tenía hambre y me diste comida, Tenía sed y me diste de beber, era un extraño y me acogiste, estaba desnudo y me vestiste, estaba enfermo y me visitaste, estaba en prisión y viniste a mí ''. Entonces los justos le responderán, diciendo: 'Señor, ¿cuándo te vimos hambriento y alimentado, o sediento y te dimos de beber? ¿Y cuándo te vimos extraño y te recibimos, o desnudo y te vestimos? ¿Y cuándo te vimos enfermo o en la cárcel y te visitamos? Y el Rey les responderá: "En verdad, te digo que, como lo hiciste con uno de estos mis hermanos más pequeños, me lo hiciste a mí".
    Mateo 25: 35-40
    
    </p>

    <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
    <p>Considera servir en el ministerio en algunos de los proyectos que integran Comunidad Ciudad.

    </p>        
`,
  },
  {
    letter: 'h',
    gift: 'ACTIVISTA',
    titleText: 'Comprometerse activamente con otros para servir a los pobres y necesitados.',
    text: `
    <p><b>PERSONAJE BÍBLICO:</b> "Esther fue una activista"</p>
    <br/>
    <p>Esther 4:16 <br/>
    “Ve, reúne a todos los judíos que se encuentran en Susa, y ayuna en mi nombre, y no comas ni
    bebas durante tres días, noche o día. Mis jóvenes y yo también ayunaremos como tú. Entonces
    iré al rey, aunque sea contra la ley, y si perezco, pereceré ”.
    
</p>
    (Miqueas 6: 8) NVI
     </p>
     <p> Miqueas 6: 8
     <br/>
     Él te ha mostrado, oh mortal, lo que es bueno. ¿Y qué requiere el Señor de ti? Actuar con justicia y amar la misericordia y caminar humildemente con tu Dios.
     </p>
    <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
    <p>Busca en tu corazón y encuentra la carga que Dios ya ha puesto dentro de ti y luego pregúntale a Dios qué te ha llamado a hacer al respecto.</p>        
`,
  },
  {
    letter: 'i',
    gift: 'INTIMISTA ',
    titleText: 'Conectando con Dios a través de la intimidad',
    text: `
    <p><b>PERSONAJE BÍBLICO:</b> "María de Betania era una intimista"</p>
    <br/>
    <p>Lucas 7: 44-48 <br/>
    “Luego se volvió hacia la mujer y le dijo a Simón: “¿Ves a esta mujer? Entré en tu casa; no me diste agua para mis pies, pero ella me ha mojado los pies con las lágrimas y las ha limpiado con el pelo. No me besaste, pero desde el momento en que entré no ha dejado de besarme los pies. No me ungiste la cabeza con aceite, pero ella me ha ungido los pies con ungüento. Por eso te digo que sus pecados, que son muchos, son perdonados, porque ella amaba mucho. Pero el que se perdona poco, ama poco ”. Y él le dijo: "Tus pecados te son perdonados".
</p>
    <p>ESCRITURA PARA MEDITAR:<br/>
     </p>
     <p> Salmo 103: 17
     <br/>
     “Pero el amor del Señor permanece para siempre con los que le temen. ¡Su salvación se extiende a los hijos de los hijos” 
     </p>
    <p><b>ACTIVIDADES PRÁCTICAS:</b><br/></p>
    <p>Aparta un momento para pasar tiempo a solas con Jesús en un lugar tranquilo, para que puedas oírlo hablar.
    </p>        
`,
  },
];

const sacredPathQuestions = [
  {
    letter: 'a',
    num: 1,
    sec: 'Me siento más cercano a Dios cuando:',
    question: 'Estoy rodeado de la belleza de la naturaleza.',
  },
  { letter: 'b', num: 2, question: 'Canto canciones de alabanza a Dios.' },
  { letter: 'c', num: 3, question: 'Aprendo algo nuevo sobre Dios o la vida con él.' },
  {
    letter: 'd',
    num: 4,
    question: 'Práctico tradiciones espirituales de mi familia o de Comunidad.',
  },
  { letter: 'e', num: 5, question: 'Me niego a los placeres de la vida y oro.' },
  { letter: 'f', num: 6, question: 'Puedo ver, escuchar o tocar a Dios de alguna manera.' },
  { letter: 'g', num: 7, question: 'Cuido a alguien que está sufriendo.' },
  {
    letter: 'h',
    num: 8,
    question: 'Me uno a otros para defender la causa de los pobres y necesitados.',
  },
  {
    letter: 'i',
    num: 9,
    question: 'Estoy tranquilo y solo, enfocado solo en la presencia de Dios. ',
  },
  {
    letter: 'a',
    num: 10,
    sec: 'Para ser espiritualmente renovado necesito:',
    question: 'Pasar tiempo por fuera disfrutando de la creación de Dios. ',
  },
  {
    letter: 'b',
    num: 11,
    question: 'Celebrar a Dios y su amor con música y canciones de adoración.',
  },
  {
    letter: 'c',
    num: 12,
    question: ' Leer la Biblia y pensar profundamente en lo que Dios está diciendo.',
  },
  {
    letter: 'd',
    num: 13,
    question: 'Reconectar con la historia o los símbolos de mi tradición espiritual. ',
  },
  {
    letter: 'e',
    num: 14,
    question: 'Ayunar comida, redes o simplificar mi vida de alguna otra manera. ',
  },
  {
    letter: 'f',
    num: 15,
    question:
      'Experimentar a Dios de una manera tangible (Ej: recibir la cena del Señor, arrodillarse en el altar, encender una vela u otro).',
  },
  {
    letter: 'g',
    num: 16,
    question:
      'Sentir cómo su compasión fluye a través de mí hacia otra persona (Ej: cuando escuchas, animas u oras por otra persona).',
  },
  {
    letter: 'h',
    num: 17,
    question:
      'Ser parte de una causa para promover el cuidado de aquellos que son menos afortunados que yo.',
  },
  { letter: 'i', num: 18, question: 'Contemplar en silencio el amor de Dios.' },
  {
    letter: 'a',
    num: 19,
    sec: 'Es especialmente útil para mí conectarme con Dios a través de:',
    question: 'Apreciar las maravillas que Dios ha creado.',
  },
  {
    letter: 'b',
    num: 20,
    question: 'Expresar de manera eufórica agradecimiento y alabanza a Dios. ',
  },
  { letter: 'c', num: 21, question: 'Estudiar la Biblia cuidadosamente.' },
  {
    letter: 'd',
    num: 22,
    question: 'Usar un plan de lectura o devocional u oraciones proporcionadas por mi Comunidad.',
  },
  { letter: 'e', num: 23, question: 'Manteniendo mi vida simple y ordenada.' },
  {
    letter: 'f',
    num: 24,
    question:
      'Imaginarme en una historia del evangelio, una obra de arte religioso o una película espiritual. ',
  },
  { letter: 'g', num: 25, question: 'Apoyar a un amigo que está pasando por un momento difícil.' },
  {
    letter: 'h',
    num: 26,
    question:
      'Ayudar a un vecino enfermo, proporcionar comida a los hambrientos o ministrar a los ancianos, abusados ​​o encarcelados.',
  },
  {
    letter: 'i',
    num: 27,
    question:
      ' Meditar en las Escrituras o en un libro devocional que me lleva a una intimidad más profunda con Dios.',
  },
  {
    letter: 'a',
    num: 28,
    sec: 'Me beneficio especialmente de oradores y autores que me ayudan a',
    question: 'Apreciar la belleza de Dios y las maravillas de su creación.',
  },
  { letter: 'b', num: 29, question: 'Agradecer y alabar a Dios con alegría' },
  { letter: 'c', num: 30, question: 'Comprender nuevas cosas sobre mi vida con Dios.' },
  { letter: 'd', num: 31, question: 'Recordar las historias y acciones de héroes de la biblia.' },
  {
    letter: 'e',
    num: 32,
    question: 'Ser disciplinado para hacer más espacio en mi vida y mi alma para Dios.',
  },
  { letter: 'f', num: 33, question: `Experimentar el "toque" de la presencia de Dios en mí.` },
  {
    letter: 'g',
    num: 34,
    question: 'Ser más efectivo a la hora de cuidar a las personas con dificultades. ',
  },
  { letter: 'h', num: 35, question: 'Defiende la justicia e impacta a los marginados' },
  {
    letter: 'i',
    num: 36,
    question: 'Amar a Dios todo el tiempo en el lugar secreto de mi corazón. ',
  },
];

const DISC = [
  {
    letter: ['d'],
    text: `
    <p>• Directos y decisivos.</p>
    <p>• Son tomadores de riesgos y solucionadores de problemas.</p>
    <p>• Están más preocupados por completar las tareas satisfactoriamente que aquellos que dependen de la aprobación de la gente para realizar sus tareas.</p>
    <p>• Aunque su motivación interna tiende a hacerlos insensibles a los que les rodean no tienen miedo de desafiar el status quo y prosperan cuando se trata de desarrollar nuevas cosas.</p>
    <p>• Necesitan disciplina para sobresalir y responden a la confrontación.</p>
    <p>• El mayor temor de un “D” es sentirse usados o que alguien se aproveche de ellos.</p>
    <p>• Sus debilidades incluyen una aversión a la rutina, una tendencia a sobrepasar la autoridad, una naturaleza argumentativa y el hábito de asumir muchas responsabilidades.</p>
    <p>• Sus fortalezas incluyen dar un alto valor al tiempo y utilizan su pensamiento innovador para realizar tareas difíciles y conquistar desafíos.</p>
    <strong>DESARROLLANDO TU PERSONALIDAD</strong>
    <p><b>Cada personalidad posee fortalezas y es importante entender cómo establecer un balance entre tus habilidades naturales y las de otras personas. La siguiente información te brindará aquellas áreas en las que podrás enfocarte al trabajar en conjunto con otras personas.</b></p>
    <p>Las personalidades con esta letra suelen ser Dominantes.</p>
    <p>Direccionan, orientan procesos, son decisivos, son organizados, desean superar expectativas y hablan con franqueza.</p>
    <p>• Escuchan atentamente a otros.</p>
    <p>• Apoya a otros miembros del grupo.</p>
    <p>• Invierte en relaciones personales.</p>
    <p>• Balance entre tendencias controlantes y dominantes.</p>
    `,
  },
  {
    letter: ['d', 'i'],
    text: `
    <p>• Son personas curiosas; tienen en alto valor terminar sus tareas, no dejan nada a medias, ponen su énfasis en el resultado final y trabajan duro para alcanzar sus metas.</p>
    <p>• Son personas más determinantes que inspiradoras. Sus altas expectativas y estándares para sí mismos y los que les rodean normalmente hace que causen un gran impacto, motivando a otros a seguirlos. </p>
    <p>• Tienen intereses muy determinados y pueden distraerse al asumir demasiados proyectos.</p>
    <p>• A menudo necesitan concentrarse, Priorizar y simplemente hacer todo a un ritmo razonable.</p>
    <p>• Debido a que los “D / I” s prosperan en la actividad y elmovimiento progresivo, les gusta lograr tareas a través de un gran número de personas.</p>
    <p>• Josué (Josué 1), Noé (Génesis 6-9), Sara (Génesis 16, 1 Pedro 3: 6).</p>`,
  },
  {
    letter: ['d', 's'],
    text: `
    <p>• Conquistadores con una alta capacidad de perseverancia.</p>
    <p>• Son más activos que pasivos, pero poseen una especie de calma, sensibilidad y estabilidad que los convierte en buenos líderes.</p>
    <p>• Parecen estar enfocados en la gente, pero pueden ser dominantes y decisivos cuando se trata de tareas y planificación de proyectos. Se esfuerzan por lograr los objetivos con feroz determinación que con frecuencia proviene de una fuerte motivación interna.</p>
    <p>• Podrían mejorar al aplicar un pensamiento contemplativo, conservador y dedicar más tiempo cultivando relaciones.</p>
    <p>• Daniel (Daniel 1-6), Job (Job 1: 5, Santiago 5:11), Marta (Lucas 10: 38-42).</p>
    <p></p>`,
  },
  {
    letter: ['d', 'c'],
    text: `
    <p>• Son retadores, pueden ser estudiantes determinados o críticos desafiantes.</p>
    <p>• Estar a cargo es importante para ellos, sin embargo les importa poco lo que piensan los demás, su enfoque radica en el cumplimiento de la tarea.</p>
    <p>• Tienen una gran capacidad de previsión para evaluar todas las vías con el fin encontrar la mejor solución ante una situación. Prefieren trabajar solos.</p>
    <p>• Aunque temen al fracaso y la falta de influencia, están motivados por desafíos y pueden ser a menudo excelentes administradores. Pueden beneficiarse de aprender a relajarse y prestar más atención a las personas.</p>
    <p>• Malaquías (Malaquías4), Natán (2 Samuel 12: 1-13), Nahúm (Nahúm 1-3)</p>
    `,
  },
  {
    letter: ['i'],
    text: `
    <p>• Inspirador y entusiasta; optimista, impulsivo y emocional, tienden a resolver creativamente los problemas y son excelentes motivadores.</p>
    <p>• A menudo tienen un gran número de amigos, pero pueden llegar a preocuparse más por la aprobación y la popularidad que con obtener resultados.</p>
    <p>• El mayor temor es el rechazo, pero prosperan cuando se trata de motivar a los demás. Su sentido del humor positivo suele ser de ayuda para negociar conflictos. • Pueden ser desatentos a los detalles y les cuesta escuchar a otros.</p>
    <p>• Pueden ser grandes pacificadores y compañeros eficaces cuando controlan sus sentimientos y minimizan su deseo de entretener y ser el centro de atención.</p>
    <p>• Valoran mucho el tacto humano y la conexión.</p>
    <strong>DESARROLLANDO TU PERSONALIDAD</strong>
    <p><b>Cada personalidad posee fortalezas y es importante entender cómo establecer un balance entre tus habilidades naturales y las de otras personas. La siguiente información te brindará aquellas áreas en las que podrás enfocarte al trabajar en conjunto con otras personas.</b></p>
    <p>Las personalidades con esta letra suelen ser Influyentes, ingeniosos, tolerantes, desean superar expectativas y guían personas. </p>
    <p>Está atento de tareas que deben cumplirse.</p>
    <p>• Tiene balance entre sus emociones, palabras y acciones.</p>
    <p>• Recuerda detalles analizados y hechos.</p>
    <p>• Es paciente con otros cuando es necesario.</p>
    <p>• Escucha atentamente en vez de solo hablar.</p>
    <p>• Toma decisiones cuidadosamente en vez de decisiones impulsivas.</p>`,
  },
  {
    letter: ['i', 'd'],
    text: `
    <p>• Son persuasivos; extrovertidos y enérgicos.</p>
    <p>• Disfrutan estar rodeados de grandes grupos de personas y usan su poder de influencia para lograr el respeto y convencer a la gente a seguir su ejemplo. </p>
    <p>• A veces pueden ser vistos como inquietos y nerviosos, pero esto proviene de su necesidad de ser parte de retos que requieren variedad, libertad y movilidad.</p>
    <p>• Los “I / D” podrían beneficiarse de aprender a mirar antes de cruzar y pasar más tiempo en buscar calma. Tienden a ser líderes inspiradores y saben cómo obtener resultados a través de la gente.</p>
    <p>• Juan el Bautista (Lucas 3), Pedro (Mateo 16 y 26, Hechos 3), Rebeca (Génesis 24)</p>`,
  },
  {
    letter: ['i', 's'],
    text: `
    <p>• Consejeros influyentes que aman a la gente.</p>
    <p>• No es una sorpresa que la gente los ame.</p>
    <p>• Viven para complacer, servir y tienden a ser buenos oyentes.</p>
    <p>• Dar una buena apariencia y animar a otros es importante para ellos, tal como cumplir una labor y serobediente.</p>
    <p>• A menudo carecen en el área de organización y pueden preocuparse más por las personas involucradas que por las tareas que se les ha asignado.</p>
    <p>• Sea como centro del escenario o detrás de escena, trabajan con igual eficacia y brillan cuando se trata de influir y ayudar a los demás.</p>
    <p>• Bernabé (Hechos 4, 9,11-15), Eliseo (1 Reyes 19, 2 Reyes 2-3), Nicodemo (Juan 3, 7, 19)</p>`,
  },
  {
    letter: ['i', 'c'],
    text: `
    <p>• Inspirador pero cauteloso; son excelentes comunicadores a través de la combinación de la observación y aprecio a la gente.</p>
    <p>• Sobresalen al buscar maneras de mejorar la producción.</p>
    <p>• Tienden a ser impacientes y críticos, pueden ser demasiado persuasivos y demasiado consumidos por el deseo de ganar.</p>
    <p>• Les gusta trabajar en un entorno seguro, y podrían beneficiarse al probar cosas nuevas y preocuparsemenos por lo que otros piensan de ellos.</p>
    <p>• Este tipo de personalidad a menudo posee un don para enseñar; Son generalmente confiables cuando se trata de prestar atención a los detalles y cumplir el trabajo.</p>
    <p>• Miriam (Éxodo 15-21), Esdras (Esdras 7-8), Mujer Sunamita (2Reyes 4: 8-37).</p>`,
  },
  {
    letter: ['s'],
    text: `
    <p>• Estables y reservados.</p>
    <p>• No les gusta el cambio y prosperan en ambientes seguros no amenazantes.</p>
    <p>• A menudo son amables y comprensivos, así como buenos oyentes y trabajadores leales, que están felices de hacer el mismo trabajo de forma consistente.</p>
    <p>• Poseen una increíble capacidad para perdonar, son fiables, confiables y tienden a ser los mejores amigos.</p>
    <p>• Su mayor temor, sin embargo, es la pérdida de seguridad y sus posibles debilidades incluyen naturalmente no sólo resistencia al cambio, pero también dificultad para adaptarse a ella.</p>
    <p>• También pueden ser demasiado sensibles a la crítica y se les dificulta establecer prioridades.</p>
    <p>• Con el fin de evitar sentirse usados, los “S” necesitan aprender a ser más francos y decir “No”.</p>
    <p>• Les gusta evitar ser el centro de atención, pero cuando se les da la oportunidad de ayudar genuinamente a otros toman el liderazgo y se apropian de la situación adecuadamente.</p>
    <p>• Se sienten más valorados cuando realmente han ayudado a alguien.</p>
    <strong>DESARROLLANDO TU PERSONALIDAD</strong>
    <p><b>Cada personalidad posee fortalezas y es importante entender cómo establecer un balance entre tus habilidades naturales y las de otras personas. La siguiente información te brindará aquellas áreas en las que podrás enfocarte al trabajar en conjunto con otras personas.</b></p>
    <p>Las personalidades con esta letra son firmes, analíticos, introvertidos y guían personas.</p>
    <p>• Tomar iniciativa.</p>
    <p>• Incentivar confrontación constructiva.</p>
    <p>• Ser directo en sus intervenciones cuando es necesario.</p>
    <p>• Entender que los cambios pueden ser saludable y estar dispuesto a adoptarlos.</p>
    <p>• Considerar los objetivos generales de su familia o grupo, no solamente los procedimientos específicos.</p>
    `,
  },
  {
    letter: ['s', 'd'],
    text: `
    <p>• Líderes tranquilos con quien se puede contar para cumplir su trabajo.</p>
    <p>• Se desempeñan mejor en grupos pequeños y no disfrutan hablar frente a las multitudes.</p>
    <p>• Aunque pueden ser suaves y duros al mismo tiempo, disfrutan relacionarse con las personas, teniendo cuidado de no dominarlas.</p>
    <p>• Los desafíos los motivan, sobre todo los que requieren aplicar una solución sistemática.</p>
    <p>• Tienden a ser determinados, perseverando a través del tiempo y las luchas.</p>
    <p>• Se benefician de relaciones positivas y cuando otros los animan.</p>
    <p>• Marta (Lucas 10: 38-42), Job (Job 1: 5, Santiago 5:11)</p>`,
  },
  {
    letter: ['s', 'i'],
    text: `
    <p>• Consejeros inspiracionales que infunden calidez y sensibilidad.</p>
    <p>• Son tolerantes y tienen una capacidad increíble para perdonar, tienen muchos amigos porque aceptan y representan bien a otros.</p>
    <p>• Su naturaleza social es ser simpático y flexible lo que hace que se incline a ser demasiado tolerante y evite laconfrontación.</p>
    <p>• Se beneficiará al ser más orientado a tareas y prestar más atención a los detalles.</p>
    <p>• Son amables y considerados, incluyen a otros e inspiran a la gente a seguirlos.</p>
    <p>• Las palabras de afirmación ayudan mucho a este tipo de personalidad y con la motivación correcta pueden desempeñar bien el trabajo en equipo.</p>
    <p>• María Magdalena (Lucas 7: 36-47), Bernabé (Hechos 4, 9, 11-15), Eliseo (1 Reyes 19, 2 Reyes 2-13)</p>`,
  },
  {
    letter: ['s', 'c'],
    text: `
    <p>• Diplomático y constante.</p>
    <p>• Enfocado en los detalles.</p>
    <p>• Estables y contemplativos, Evalúa los hechos y la evidencia para llegar a una conclusión lógica.</p>
    <p>• Son claros y cautelosos a la hora de tomar decisiones, especialmente cuando la decisión involucra a otros.</p>
    <p>• Las posibles debilidades de este perfil incluyen, ser muy sensible e incapaz de manejar las críticas, tienen dificultad para ser conscientes en la forma en que tratan a los demás.</p>
    <p>• Funcionan mejor en proyectos específicos.</p>
    <p>• Pueden ser pacificadores, esto los hace miembros leales de un equipo y un buen amigo.</p>
    <p>• Moisés (Éxodo 3, 4, 20, 32), Juan (Juan 19: 26-27), Eliezer (Génesis 24)</p>`,
  },
  {
    letter: ['c'],
    text: `
    <p>• Obediente y analítico. Poseen un pensamiento cuidadoso y lógico que los impulsan hacia adelante donde la precisión es una prioridad.</p>
    <p>• Tienen altos estándares en cada área de sus vidas y tienen en cuenta todas las perspectivas posibles para la resolución de conflictos.</p>
    <p>• Aunque prosperan al encontrar soluciones, tienden a ignorar los sentimientos de los demás y pueden a menudo ser críticos y en ocasiones caprichosos.</p>
    <p>• Les cuesta expresar sus sentimientos.</p>
    <p>• Pueden ayudar a su equipo con una confrontación de la realidad de una situación cuando no están atascados en detalles mínimos.</p>
    <p>• El mayor temor de este perfil es la crítica y su necesidad de perfección es a menudo una debilidad, como es su tendencia a ceder en medio de un argumento.</p>
    <p>• Son completos en todas las actividades y pueden aportar un elemento concienzudo e igualado al equipo que proporcionará una opinión fundamentada.</p>
    <p>• Valoran tener la razón.</p>
    <strong>DESARROLLANDO TU PERSONALIDAD</strong>
    <p><b>Cada personalidad posee fortalezas y es importante entender cómo establecer un balance entre tus habilidades naturales y las de otras personas. La siguiente información te brindará aquellas áreas en las que podrás enfocarte al trabajar en conjunto con otras personas.</b></p>
    <p>Personalidades con esta letra son obedientes, competentes, orientados en tareas e introvertidos.</p>
    <p>• Ser decisivo cuando es necesario.</p>
    <p>• Cultiva relaciones personales.</p>
    <p>• Estar abierto a las ideas y métodos de otros.</p>
    <p>• Hace un balance entre los hechos y las personas.</p>
    <p>• Enfocarse en hacer lo correcto, no solamente en hacer las cosas correctamente.</p>
    <p>• Le entusiasma la idea de ayudar a otros a cumplir sus logros.</p>
    `,
  },
  {
    letter: ['c', 'i'],
    text: `
    <p>• Atento a los detalles.</p>
    <p>• Tienden a impresionar a otros haciendo las cosas bien y estabilizando las situaciones.</p>
    <p>• No son considerados como personas agresivas o manipuladoras.</p>
    <p>• Disfrutan de hacer parte de grandes y pequeños grupos de personas.</p>
    <p>• Aunque funcionan bien con la gente, a veces son demasiado sensibles a lo que otros piensan de ellos y de su trabajo.</p>
    <p>• Podrían beneficiarse al ser más asertivos y proactivos.</p>
    <p>• A menudo tiene una capacidad para discernir el carácter de manera acertada.</p>
    <p>• Confían fácilmente en aquellos que cumplen con sus normas.</p>
    <p>• Se conmueven por la aprobación genuina así como de las explicaciones concisas y lógicas.</p>
    <p>• Miriam (Éxodo 15-21, Números 12: 1-15), Esdras (Esdras 7, 8)</p>
    `,
  },
  {
    letter: ['c', 's'],
    text: `
    <p>• Sistemático y estable.</p>
    <p>• Tienden a hacer una cosa a la vez y a hacerlo bien.</p>
    <p>• Son reservados y cautelosos y prefieren trabajar detrás de escena para mantenerse enfocados.</p>
    <p>• Rara vez asumen riesgos o prueban cosas nuevas y naturalmente no les gustan los cambios repentinos en sus ambientes.</p>
    <p>• Perfeccionistas al pie de la letra.</p>
    <p>• Temen a la crítica lo cual asocian con el fracaso.</p>
    <p>• Trabajadores diligentes y su motivación viene de servir a los demás.</p>
    <p>• Esther (Ester 4), Zacarías (Lucas 1), José (Mateo 1: 1-23)</p>`,
  },
  {
    letter: ['c', 'd'],
    text: `
    <p>• Diseñadores cautelosos y decididos.</p>
    <p>• Están enfocados constantemente en las tareas y están muy conscientes de los problemas.</p>
    <p>• A veces son vistos como insensibles, pero en realidad se preocupan por las personas, solo que les cuesta demostrarlo.</p>
    <p>• A menudo sienten que son los únicos que pueden hacer el trabajo de la manera correcta.</p>
    <p>• Debido a sus habilidades administrativas son capaces de llevar a cabo planes asertivos para el cambio y el mejoramiento.</p>
    <p>• Tienden a tener una apariencia seria y podrían beneficiarse al ser más optimistas y entusiastas.</p>
    <p>• A pesar de su deseo por cumplir con sus tareas deben tomar tiempo en desarrollar relaciones sanas y simplemente amar a la gente.</p>
    <p>• Bezalel (Éxodo 35: 30-36, 8, 37: 1 - 9) Jocabed (Éxodo 1: 22-2: 4), Jetro (Éxodo 2,18) </p>
    `,
  },
];

export { discQuestions, spiritualQuestions, spiritualGifts, sacredPath, sacredPathQuestions, DISC };
