import proxybase from './proxybase';

class printQueueService {
  getPrintQueue(body = {}) {
    return proxybase
      .post(`/event/getPrintQueue`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  addToAPrintQueue(body) {
    return proxybase
      .post(`/event/addToAPrintQueue`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateAPrintQueue(identification, body = {}) {
    return proxybase
      .post(`/event/updateAPrintQueue/${identification}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const printQueue = new printQueueService();
export default printQueue;
