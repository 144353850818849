import { Button } from 'semantic-ui-react';
import moment from 'moment-timezone';

const CounselingRow = (props) => {
  const { counseling, set_showCounselings } = props;

  const {
    id,
    Guest,
    createdAt,
    attendingGroup,
    professionalSign,
    counselorSign,
    professionalField,
  } = counseling;

  const { name, lastName, phone } = Guest;

  return (
    <tr>
      <td>{moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY')}</td>
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{lastName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{Boolean(attendingGroup) ? 'Si' : 'No'}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{professionalField ? professionalField : 'No'}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{Boolean(professionalSign) ? 'Si' : 'No'}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{Boolean(counselorSign) ? 'Si' : 'No'}</p>
      </td>
      <td>
        <Button primary onClick={() => set_showCounselings(id)}>
          Ver
        </Button>
      </td>
    </tr>
  );
};

export default CounselingRow;
