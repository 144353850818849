import { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import churchForms from '../../services/churchForms';
import { volunteersRowDef } from '../../components/common/adminTable/volunteersRow';
import { Select, Input, Button } from 'semantic-ui-react';
import {
  changeDocTitle,
  churchMinistries,
  experienceVolunteersRoles,
  experienceVolunteersSections,
} from '../../components/helpers';
import ReportButton from '../admin/ReportButton';
import queryString from 'query-string';
import CreateVolunteer from '../experience/volunteersLogs/VolunteerForm';
import { Link } from 'react-router-dom';
import Table from '../../components/table/aggrid';

const AllVolunteers = (props) => {
  const [volunteers, set_volunteers] = useState([]);
  const [loading, set_loading] = useState(true);
  const [inputs, setInputs] = useState({});
  const [showImgs, set_showImgs] = useState(false);
  const [showNewVolunteerForm, set_showNewVolunteerForm] = useState(false);
  const [showVolunteerForm, set_showVolunteerForm] = useState(null);
  const [openVolLeaders, setOpenVolLeaders] = useState(null);

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  useEffect(() => getInfo(), [inputs.serviceArea, inputs.volunteerRole, inputs.volunteerSection]);

  useEffect(() => {
    changeDocTitle('Voluntarios');
  }, []);

  const onEnter = (e) => {
    if (e.key === 'Enter') getInfo();
  };

  const serviceArea = inputs.serviceArea || '';
  const volunteerRole = inputs.volunteerRole || '';
  const volunteerSection = inputs.volunteerSection || '';

  function getInfo() {
    set_loading(true);

    let body = {
      keyword: inputs.keyword || parsedQueryString.keyword,
      volunteerRole,
      volunteerSection,
    };

    churchForms
      .getVolunteerForm({ body, serviceArea })
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          props.history.push('?keyword=');
          set_volunteers(response.data.volunteers);
        } else {
          set_loading(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <section className="AdminTable">
      <div className="AdminTable__content">
        <div>
          <Link to="/admin?folder=volunteers">
            <Button primary>Atrás</Button>
          </Link>
          <Button primary onClick={() => getInfo()}>
            Recargar
          </Button>
          <ReportButton
            URL={`/experience/VolunteersReport${serviceArea ? `/${serviceArea}` : ''}`}
            fileName={`reporte_voluntarios.xlsx`}
            event="Descargar Reporte"
          />
        </div>
        <Input
          loading={loading}
          name={'keyword'}
          onChange={(e) => setInputs({ ...inputs, keyword: e.target.value })}
          value={inputs.keyword}
          onKeyPress={onEnter}
          style={{ width: '45%' }}
          placeholder="Buscar..."
        />
        <Select
          placeholder="Selecciona el area para consultar."
          style={{
            display: 'inline-block',
            margin: ' 7px 0',
            width: '45%',
          }}
          onChange={selectHandle}
          name="serviceArea"
          loading={loading}
          options={[
            { key: '', value: '', text: 'Todos' },
            ...churchMinistries.sort().map((a) => {
              return { key: a, value: a, text: a };
            }),
          ]}
        />
        <Select
          placeholder="Selecciona el rol para consultar."
          style={{
            display: 'inline-block',
            margin: ' 7px 0',
            width: '45%',
          }}
          onChange={selectHandle}
          name="volunteerRole"
          loading={loading}
          options={[
            { key: '', value: '', text: 'Todos' },
            ...experienceVolunteersRoles.sort().map((a) => {
              return { key: a, value: a, text: a };
            }),
          ]}
        />
        <Select
          placeholder="Selecciona la sección para consultar."
          style={{
            display: 'inline-block',
            margin: ' 7px 0',
            width: '45%',
          }}
          onChange={selectHandle}
          name="volunteerSection"
          loading={loading}
          options={[
            { key: '', value: '', text: 'Todos' },
            ...['Pendientes', ...experienceVolunteersSections(inputs?.serviceArea)].map((a) => {
              return { key: a, value: a, text: a?.replace(/_/g, ' ') };
            }),
          ]}
        />
      </div>
      <p>Total en pantalla: {volunteers.length}</p>
      <div>
        <Button primary onClick={() => set_showImgs(!showImgs)}>
          {showImgs ? 'Ocultar' : 'Mostrar'} fotos
        </Button>
        <Button onClick={() => set_showNewVolunteerForm(true)}>Añadir un voluntario</Button>
        {showNewVolunteerForm && (
          <CreateVolunteer
            mainInputs={inputs}
            getInfo={getInfo}
            set_loading={set_loading}
            loading={loading}
            set_showVolunteerForm={set_showNewVolunteerForm}
          />
        )}
      </div>

      <div>
        <Table
          rowData={volunteers}
          rowHeight={80}
          columnDefs={volunteersRowDef({
            setOpenVolLeaders,
            openVolLeaders,
            showVolunteerForm,
            set_showVolunteerForm,
            loading,
            set_loading,
            getInfo,
            showImgs,
          })}
          defaultCol={{
            suppressMovable: true,
          }}
          pagination={true}
        />
      </div>
    </section>
  );
};

export default AllVolunteers;
