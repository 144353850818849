import React from 'react';
import Button from '../../components/common/Button';
import { Input } from 'semantic-ui-react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import membersForms from '../../services/membersForms';
import GuestLeadersRow from '../../components/common/adminTable/GuestLeadersRow';

const GuestLeaders = (props) => {
  const [guestLeaders, set_guestLeaders] = React.useState([]);
  const [loaded, set_loaded] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);

  React.useEffect(() => {
    getInfo();
  }, []);

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  function getInfo(page = false) {
    set_loaded(false);
    set_endPage(false);

    let filters = {};
    Object.entries(inputs)
      .filter((input) => input[1] != null)
      .forEach((element) => {
        filters[element[0]] = element[1];
      });

    let body = { filter: { ...filters }, currentPage };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    membersForms
      .guestLeaders(body)
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);

          if (page && body.currentPage > 1)
            set_guestLeaders([...guestLeaders, ...response.data.guestLeaders]);
          else set_guestLeaders(response.data.guestLeaders);

          if (response.data.guestLeaders.length === 0) set_endPage(true);
        } else {
          set_loaded(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  const inputStyles = {
    display: 'inline-block',
    width: '45%',
    margin: ' 7px 0',
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button text={'Atrás'} onClick={() => props.history.goBack()} />
        <Button text={'Buscar'} onClick={() => getInfo()} />
        <div>
          <Input
            loading={!loaded}
            name={'keyword'}
            onChange={handleInputs}
            value={inputs.keyword}
            className={'crecerp1c3'}
            style={inputStyles}
            onKeyPress={onEnter}
            placeholder="Buscar por miembro"
          />
          <Input
            loading={!loaded}
            name={'liderKeyword'}
            onChange={handleInputs}
            value={inputs.liderKeyword}
            className={'crecerp1c3'}
            style={inputStyles}
            onKeyPress={onEnter}
            placeholder="Buscar por lider"
          />
        </div>
      </div>
      <div>
        <p>Total en pantalla: {guestLeaders.length}</p>
        <table>
          <thead>
            <tr>
              <th>Cedula</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Celular</th>
              <th>Nombre Líder</th>
              <th>Celular Líder</th>
            </tr>
          </thead>
          <tbody>
            {guestLeaders.map((guestLeader, i) => (
              <GuestLeadersRow guestLeader={guestLeader} />
            ))}
          </tbody>
        </table>
        <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
          <Button
            disabled={endPage || !loaded}
            onClick={() => loadMore()}
            text={loaded ? 'Cargar más' : 'Cargando...'}
          />
        </div>
      </div>
    </section>
  );
};

export default GuestLeaders;
