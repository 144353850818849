import React from 'react';
import { Button, Modal, TextArea, Header, Checkbox } from 'semantic-ui-react';
import { boolSiNo, textArea } from '../../../components/helpers';
import moment from 'moment';
import membersForms from '../../../services/membersForms';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const NewMemberModal = (props) => {
  const { set_showNewMembers, showNewMembers, newMember, changeNewMembersLine } = props;
  const {
    contacted,
    tutorObservations,
    Guest,
    country,
    comments,
    toPray,
    createdAt,
    callchecks,
    updatedAt,
  } = newMember;
  const { name, lastName, phone, email, identification, birthdate } = Guest;
  const [inputs, setInputs] = React.useState({});

  React.useEffect(() => {
    setInputs({ contacted, tutorObservations, toPray, comments, ...callchecks });
  }, [newMember]);

  function updateNewMember() {
    let body = inputs;
    body.guest_id = Guest.id;

    body.callchecks = {
      porque_no_interesado: body.porque_no_interesado || '',
      interesado_en_volver: !!body.interesado_en_volver,
      respondio_1_llamada: !!body.respondio_1_llamada,
      respondio_2_llamada: !!body.respondio_2_llamada,
      respondio_3_llamada: !!body.respondio_3_llamada,
      dejo_mensaje_voz: !!body.dejo_mensaje_voz,
      contesto_pero_ocupado: !!body.contesto_pero_ocupado,
      contesto_pero_otra_persona: !!body.contesto_pero_otra_persona,
      numero_equivocado: !!body.numero_equivocado,
    };

    membersForms
      .updateNewMembers(body)
      .then((response) => {
        if (response.data.success) {
          Swal.fire('Actualizado satisfatoriamente');
          set_showNewMembers(null);
          changeNewMembersLine(response.data.newMemberUpdated);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
      });
  }

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCheckers = (name) => setInputs({ ...inputs, [name]: !inputs[name] });

  return (
    <Modal open={showNewMembers === newMember.id} onClose={() => set_showNewMembers(null)}>
      <Modal.Content>
        <Modal.Description>
          <Header>Formulario de {name}</Header>
          <div className="NewMember-form">
            <p>
              <strong>Cedula: </strong>
              {identification}
            </p>
            <p>
              <strong>Nombre: </strong>
              {name}
            </p>
            <p>
              <strong>Apellido: </strong>
              {lastName}
            </p>
            {birthdate && (
              <p>
                <strong>Edad: </strong>
                {moment().diff(birthdate, 'years')}
              </p>
            )}
            <p>
              <strong>Celular: </strong>
              {phone}
            </p>
            <p>
              <strong>E-Mail: </strong>
              {email}
            </p>
            {false && (
              <p>
                <strong>Pais: </strong>
                {country}
              </p>
            )}
            <p>
              <strong>Fecha de llenado: </strong>
              {moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY hh:mm a')}
            </p>
            <p>
              <strong>Fecha última interacción: </strong>
              {moment.tz(updatedAt, 'America/Bogota').format('DD-MM-YYYY hh:mm a')}
            </p>
          </div>
          <div className="form-tutor">
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>
              ¿Hay algo en lo que podemos orar por ti?
            </p>
            {textArea({
              TextArea,
              handler: handleInputs,
              type: 'text',
              label: 'Peticiones de oración',
              name: `toPray`,
              value: inputs[`toPray`],
            })}
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>¿Qué tal el fin de semana?</p>
            {textArea({
              TextArea,
              handler: handleInputs,
              type: 'text',
              label: 'Comentarios de este fin de semana',
              name: `comments`,
              value: inputs[`comments`],
            })}
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>
              ¿Te gustaría volver este fin de semana?
            </p>
            <Checkbox
              label={`Interesado en volver - ${boolSiNo(inputs[`interesado_en_volver`])}`}
              name="interesado_en_volver"
              checked={!!inputs[`interesado_en_volver`]}
              toggle
              onClick={() => handleCheckers('interesado_en_volver')}
            />
            {!inputs[`interesado_en_volver`] &&
              textArea({
                TextArea,
                handler: handleInputs,
                type: 'text',
                label: 'No, preguntar por qué:',
                name: `porque_no_interesado`,
                value: inputs[`porque_no_interesado`],
              })}
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>Notas adicionales:</p>
            {textArea({
              TextArea,
              handler: handleInputs,
              type: 'text',
              label: 'Observaciones al contacto',
              name: `tutorObservations`,
              value: inputs[`tutorObservations`],
            })}
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>Finalización de llamada</p>
            <Checkbox
              label={`Marcar como contactado - ${boolSiNo(inputs[`contacted`])}`}
              name="contacted"
              checked={!!inputs[`contacted`]}
              toggle
              onClick={() => handleCheckers('contacted')}
            />
            <p style={{ margin: '20px 0 0 0', fontWeight: '800' }}>Seguimiento adicional</p>
            <Checkbox
              label={`Llamé 1 vez y no contestó, dejar buzón de voz - ${boolSiNo(
                inputs[`respondio_1_llamada`]
              )}`}
              name="respondio_1_llamada"
              checked={!!inputs[`respondio_1_llamada`]}
              toggle
              disabled={inputs[`contacted`]}
              onClick={() => handleCheckers('respondio_1_llamada')}
            />
            <Checkbox
              label={`Llamé 2 veces y no contestó - ${boolSiNo(inputs[`respondio_2_llamada`])}`}
              name="respondio_2_llamada"
              checked={!!inputs[`respondio_2_llamada`]}
              toggle
              disabled={inputs[`contacted`]}
              onClick={() => handleCheckers('respondio_2_llamada')}
            />
            <Checkbox
              label={`Llamé 3 veces y no contestó, dejar buzón de voz - ${boolSiNo(
                inputs[`respondio_3_llamada`]
              )}`}
              name="respondio_3_llamada"
              checked={!!inputs[`respondio_3_llamada`]}
              toggle
              disabled={inputs[`contacted`]}
              onClick={() => handleCheckers('respondio_3_llamada')}
            />
            <Checkbox
              label={`Dejé mensaje en buzón de voz - ${boolSiNo(inputs[`dejo_mensaje_voz`])}`}
              name="dejo_mensaje_voz"
              checked={!!inputs[`dejo_mensaje_voz`]}
              style={{ margin: '20px 0' }}
              toggle
              disabled={inputs[`contacted`]}
              onClick={() => handleCheckers('dejo_mensaje_voz')}
            />
            <Checkbox
              label={`Volver a llamar: contesto pero estaba ocupado - ${boolSiNo(
                inputs[`contesto_pero_ocupado`]
              )}`}
              name="contesto_pero_ocupado"
              checked={!!inputs[`contesto_pero_ocupado`]}
              toggle
              disabled={inputs[`contacted`]}
              onClick={() => handleCheckers('contesto_pero_ocupado')}
            />
            <Checkbox
              label={`Volver a llamar: contesto otra persona - ${boolSiNo(
                inputs[`contesto_pero_otra_persona`]
              )}`}
              name="contesto_pero_otra_persona"
              checked={!!inputs[`contesto_pero_otra_persona`]}
              toggle
              disabled={inputs[`contacted`]}
              onClick={() => handleCheckers('contesto_pero_otra_persona')}
            />
            <Checkbox
              label={`Número equivocado - ${boolSiNo(inputs[`numero_equivocado`])}`}
              name="numero_equivocado"
              checked={!!inputs[`numero_equivocado`]}
              toggle
              disabled={inputs[`contacted`]}
              onClick={() => handleCheckers('numero_equivocado')}
            />
            <div style={{ margin: '20px 0' }}>
              <Button primary onClick={() => updateNewMember()}>
                Enviar
              </Button>
              <Button onClick={() => set_showNewMembers(null)}>Cerrar</Button>
            </div>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
export default NewMemberModal;
