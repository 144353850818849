import styled from '@emotion/styled';

export const DashboardExperienciaComponent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafdff;
  border-radius: 20px;

  .title{
    background: linear-gradient(to right, #FC9133, #33fbd7);
    -webkit-background-clip: text;
    color: transparent;
    fontSize: 50px
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .service-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-5px);
    }
  }

  .card-icon {
    font-size: 40px; 
    color: #f573e6;
    margin-bottom: 10px;
  }

  .card-title {
    margin: 0 0 10px 0;
    font-size: 24px;
    background: linear-gradient(to left, #FC9133, #1bd0b0);
    -webkit-background-clip: text;
    color: transparent;
  }

  .card-content {
    font-size: 16px;

    p {
      margin: 5px 0;
    }

    strong {
      color: #333;
    }
  }
`;
