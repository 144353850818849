import { Button, Modal, Icon } from 'semantic-ui-react';

const ComunidadNetworkCatalogModal = (props) => {
  const { inputs, set_showComunidadNetwork } = props;
  let editingInputs = { ...inputs, ...inputs.Guest };
  const {
    businessName,
    serviceOffered,
    productDescription,
    facebook,
    instagram,
    businessPhone,
    lastName,
    twitter,
    name,
    email,
  } = editingInputs;

  return (
    <Modal onClose={() => set_showComunidadNetwork(null)} open={true}>
      <Modal.Header>{businessName}</Modal.Header>

      <Modal.Content>
        <Modal.Description>
          <p>
            <Icon name="building outline" />
            {serviceOffered}
          </p>
          <p>
            <Icon name="bullhorn" />
            {productDescription}
          </p>
          <p>
            <Icon name="phone" />
            {businessPhone}
          </p>
          <p>
            <Icon name="user" />
            {name} {lastName}
          </p>
          <p>
            <Icon name="mail" />
            {email}
          </p>
          {facebook && (
            <p>
              <Icon name="facebook" />
              <a href={`https://www.fb.com/${facebook}`} target="_blank" rel="noreferrer">
                {facebook}
              </a>
            </p>
          )}
          {instagram && (
            <p>
              <Icon name="instagram" />
              <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noreferrer">
                {instagram}
              </a>
            </p>
          )}
          {twitter && (
            <p>
              <Icon name="twitter" />
              <a href={`https://twitter.com/${twitter}`} target="_blank" rel="noreferrer">
                {twitter}
              </a>
            </p>
          )}
        </Modal.Description>
        <Modal.Actions style={{ margin: '20px 0', textAlign: 'end' }}>
          <Button onClick={() => set_showComunidadNetwork(null)} secondary>
            Cerrar
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default ComunidadNetworkCatalogModal;
