import { textArea } from '../helpers';
import { TextArea } from 'semantic-ui-react';

export default function MarkdownTextarea(props) {
  const { loading, inputs, name, label, handleInputs, errorInputs, showHelpers = true } = props;
  return (
    <>
      <p style={{ margin: '20px 0 0 0', fontWeight: 'bold' }}>{label}</p>
      <p style={{ margin: '0 0 10px 0' }}>
        <a target="_blank" href="https://www.markdownguide.org/cheat-sheet/" rel="noreferrer">
          Puedes formatear el texto con Markdown
        </a>
        :
      </p>
      {textArea({
        TextArea,
        label: label,
        name: name,
        disabled: loading,
        value: inputs[name],
        style: { display: 'inline-block' },
        handler: handleInputs,
        loading: loading,
        errorInputs,
      })}
      {showHelpers && (
        <p>
          <strong>Algunos tips de Markdown:</strong>
          <br />
          **<strong>Usa doble asterisco para negrilla</strong>**
          <br />*<i>un solo asterisco para cursiva</i>* <br /> ***
          <b>
            <i>tres asteriscos para negrilla y cursiva</i>
          </b>
          ***
          <br /> - crea listas con <br />- (guiones)
        </p>
      )}
    </>
  );
}
