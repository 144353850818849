import { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'semantic-ui-react';
import membersForms from '../../../services/membersForms';
import Swal from 'sweetalert2';

export const ManuallyUpdateGuestLeaders = (props) => {
  const { GuestLeader = {}, guest, getInfo, set_loading, loading } = props;
  const { name, lastName } = guest;
  const [GuestLeaderEdit, setGuestLeaderEdit] = useState(GuestLeader);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setGuestLeaderEdit(GuestLeader);
  }, [GuestLeader]);

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setGuestLeaderEdit({ ...GuestLeaderEdit, [name]: value });
  };

  const send = () => {
    set_loading(true);
    const body = {
      guestIdentification: guest.identification,
      leaderFullName: GuestLeaderEdit?.leaderFullName,
      leaderPhone: GuestLeaderEdit?.leaderPhone,
    };
    membersForms
      .UpsertGuestLeaders(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          Swal.fire(response.data.message);
          getInfo?.();
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error && error.response.data) {
          Swal.fire(error.response.data.message);
        }
      });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button size="tiny">Modificar lideres</Button>}
    >
      <Modal.Header>
        Lider de {name}&nbsp;{lastName}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            <strong>Nombre:</strong>
            <Input
              name="leaderFullName"
              onChange={handleInputs}
              value={GuestLeaderEdit?.leaderFullName}
            />
            <br />
            <strong>Telefono:</strong>
            <Input
              name="leaderPhone"
              onChange={handleInputs}
              type="number"
              value={GuestLeaderEdit?.leaderPhone}
            />
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
        <Button primary disabled={loading} onClick={send}>
          Enviar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
