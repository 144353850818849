import React from 'react';
import { Button, Tab, Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import { Margin, usePDF } from 'react-to-pdf';
import { clickableStyles, copytoClipboard, scrollUp } from '../../helpers';
import styled from '@emotion/styled';
import { questions } from './questions';
import Swal from 'sweetalert2';
import groupService from '../../../services/groupService';
import SignatureCanvas from 'react-signature-canvas';

const ResultsComp = styled.div`
  background-color: white;
  min-width: 80%;
  .info {
    padding: 40px;
    .userdata {
      display: inline-block;
      margin-right: 20px;
    }
  }
  h1 {
    padding: 20px 0;
  }
  .content {
    h2 {
      &.title {
        padding: 30px 0;
      }
    }
    .prospect,
    .leader {
      margin: 20px 0;
      h2 {
        padding: 10px 0 0 0;
        color: #2185d0;
        text-align: center;
      }
      .question {
        margin: 20px 0;
        p {
          margin: 10px 0;
          &.answer {
            font-style: italic;
          }
        }
      }
    }
  }
`;

const CanvasSign = ({ sign }) => {
  let canvas_sign = {};

  React.useEffect(() => {
    if (typeof sign === 'string') {
      let prospect_sign = JSON.parse(sign);
      canvas_sign?.fromData?.([prospect_sign]);
      canvas_sign?.off?.();
    }
  }, [sign]);

  return (
    <SignatureCanvas
      penColor="black"
      ref={(ref) => {
        canvas_sign = ref;
      }}
      canvasProps={{
        width: 300,
        height: 200,
      }}
    />
  );
};

const ProspectAndLeaderAnswers = (props) => {
  const { answers, set_showAnswers, getInfo } = props;
  const [sending, set_sending] = React.useState(false);
  const { toPDF, targetRef } = usePDF({
    method: 'save',
    page: { margin: Margin.MEDIUM },
    filename: 'respuestas-aspirante.pdf',
  });

  React.useEffect(() => {
    scrollUp();
  }, []);

  const setVars = (toUpdate) => {
    let body = {
      id: answers?.id,
      hexaCode: answers?.hexaCode,
    };
    if (toUpdate === 'assigned') body.assigned = !answers?.assigned;
    if (toUpdate === 'interviewed') body.interviewed = !answers?.interviewed;
    if (toUpdate === 'deleted') body.deleted = !answers?.deleted;

    set_sending(true);
    groupService
      .updateProspectLeaderForm(body)
      .then((response) => {
        set_sending(false);
        if (response.data.success) {
          getInfo();
        }
        Swal.fire(response?.data?.message);
      })
      .catch((error) => {
        set_sending(false);
        Swal.fire(error?.response?.data?.message);
      });
  };

  const deleteProspectSurvey = () =>
    Swal.fire({
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      showCancelButton: true,
      title: `¿Está seguro que quieres eliminar esta encuesta?`,
    }).then(async (result) => {
      if (result.value) {
        setVars('deleted');
        set_showAnswers(null);
      }
    });

  const condenseAnswers = (subject, answers) => {
    let condensed = {};
    for (let key in answers) {
      if (key.includes(subject)) {
        if (typeof answers[key] === 'object') {
          condensed = { ...condensed, ...answers[key] };
        } else {
          condensed[key] = answers[key];
        }
      }
    }
    return condensed;
  };

  const showAnswers = (subject, questions, answers) => {
    let questionType = ({ text, type, onlyInQuestions }) => {
      if (onlyInQuestions) {
        return null;
      } else if (type === 'title') {
        return <h2>{text}</h2>;
      } else if (type === 'text') {
        return <p>{text}</p>;
      } else {
        return <h3>{text}</h3>;
      }
    };
    return questions?.map((question) => (
      <div key={question.id} className="question">
        {questionType(question)}
        <p className="answer">{condenseAnswers(subject, answers)?.[question.id]}</p>
      </div>
    ));
  };

  const linkToLeader = `${window.location?.origin}/grupos/encuesta/${answers?.hexaCode}`;

  const guestData = (
    <>
      <p className="userdata">
        Respuestas de{' '}
        <strong>
          {answers?.Guest?.name
            ? `${answers?.Guest?.name} ${answers?.Guest?.lastName}`
            : answers?.prospect_fullName}
        </strong>
      </p>
      <p className="userdata">
        Telefono: <strong>{answers?.Guest?.phone ?? answers?.prospect_phone}</strong>
      </p>
      {!!answers?.Guest?.name && (
        <>
          <p className="userdata">
            Edad: <strong>{moment().diff(answers?.Guest?.birthdate, 'years')}</strong>
          </p>
          <p className="userdata">
            Genero: <strong>{answers?.Guest?.gender}</strong>
          </p>
          <p className="userdata">
            Identificación: <strong>{answers?.Guest?.identification}</strong>
          </p>
          <p className="userdata">
            Email: <strong>{answers?.Guest?.email}</strong>
          </p>
        </>
      )}

      <p className="userdata">
        Fecha de llenado del lider:{' '}
        <strong>{moment.tz(answers.createdAt, 'America/Bogota').format('DD-MM-YYYY')}</strong>
      </p>
    </>
  );

  const panes = [
    {
      menuItem: 'Lider del Aspirante',
      render: () => (
        <>
          <Button onClick={toPDF}>Guardar resultados del lider del aspirante</Button>
          <div ref={targetRef} className="printable content">
            {guestData}
            <div className="leader">
              <h2 className="title">Formulario del lider</h2>
              {showAnswers('leader', questions?.prospect_leader, answers)}
              <div className="signs-form">
                <CanvasSign sign={answers.leader_sign} />
                <p>Firma del lider</p>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      menuItem: 'Aspirante',
      render: () => (
        <>
          <div style={{ margin: '15px  0' }}>
            <Checkbox
              label="¿Asignado?"
              name="assigned"
              checked={answers?.assigned}
              disabled={sending}
              toggle
              onClick={() => setVars('assigned')}
            />
          </div>
          <div style={{ margin: '15px  0' }}>
            <Checkbox
              label="¿Se realizó entrevista?"
              name="interviewed"
              checked={answers?.interviewed}
              disabled={sending}
              toggle
              onClick={() => setVars('interviewed')}
            />
          </div>
          <div style={{ margin: '10px  0' }}>
            <Button onClick={deleteProspectSurvey}>Eliminar encuesta</Button>
          </div>
          <Button onClick={toPDF}>Guardar resultados de aspirante</Button>
          <div ref={targetRef} className="printable content">
            {guestData}
            <p>
              Asignado: <strong>{answers?.assigned ? 'Si' : 'No'}</strong>
            </p>
            <p>
              Entrevista realizada: <strong>{answers?.interviewed ? 'Si' : 'No'}</strong>
            </p>
            <div className="prospect">
              <h2 className="title">Formulario del aspirante</h2>
              {answers?.guest_id ? (
                <>
                  {showAnswers('prospect', questions?.prospect, answers)}
                  <div className="signs-form">
                    <CanvasSign sign={answers.prospect_sign} />
                    <p>Firma del aspirante</p>
                  </div>
                </>
              ) : (
                <p
                  onClick={() => copytoClipboard({ content: linkToLeader, Swal })}
                  style={{ ...clickableStyles }}
                >
                  Copiar link para enviar al discipulo
                </p>
              )}
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <ResultsComp>
      <div className="info">
        <Button
          primary
          compact
          onClick={() => set_showAnswers(null)}
          style={{ display: 'block', margin: '10px 0' }}
        >
          Ir Atrás
        </Button>
        {!answers?.guest_id && (
          <Button color="black" onClick={() => copytoClipboard({ content: linkToLeader, Swal })}>
            Copiar link para enviar al aspirante
          </Button>
        )}
        <h1>Respuestas</h1>
        <Tab panes={panes} />
      </div>
    </ResultsComp>
  );
};
export default ProspectAndLeaderAnswers;
