import React from 'react';
import ReservationStepCheck from './reservation/stepCheck';
import ReservationStepFillData from './reservation/stepFillData';
import ReservationStepSelectAService from './reservation/selectAService';
import ReservationSummary from './reservation/summary';
import queryString from 'query-string';
import { changeDocTitle, sortObjects } from '../../../components/helpers';
import EventService from '../../../services/eventService';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useStateCallback } from '../../../components/helpers/useStateCallback';
import { sendReservationHelper } from './reservation/components/reservationExtraInfo';

export const spouseGender = (gender) => (gender === 'Masculino' ? 'ella' : 'el');

const ServiceReservation = (props) => {
  const [Step, setStep] = React.useState('check');
  const [inputs, setInputs] = useStateCallback({});
  const [loading, set_loading] = React.useState(false);
  const [showSummaryForm, set_showSummaryForm] = React.useState(false);
  const [services, set_services] = React.useState({});

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  const defaulteventSlugname = (main = false) => {
    const { event, eventSlugname } = parsedQueryString;
    if (event) return event;
    if (eventSlugname) return eventSlugname;
    return 'Auditorio_Principal';
  };

  const firstEventOfQuery = Object.values(services)?.[0]?.[0];

  React.useEffect(() => {
    const getDate = (date) => moment.tz(date, 'America/Bogota').format('YYYY-MM-DD');
    set_loading(true);

    let eventSlugname = inputs.eventSlugname || defaulteventSlugname();
    props.history.push(`/servicio/reservacion?event=${eventSlugname}&id=event`);

    let body = { eventSlugname, currentWeek: true };
    EventService.getAuditoriumEvents(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          let events = {};
          sortObjects(response.data.events, 'date', 'asc').forEach((event) => {
            events[getDate(event.date)] = [...(events[getDate(event.date)] || []), event];
          });
          set_services(events);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  }, [inputs.eventSlugname]);

  const sendReservation = ({ goToSummary = true, redirect = '', eventSelected = {} }) => {
    set_loading(true);
    sendReservationHelper({
      inputs,
      event: firstEventOfQuery,
      eventSlugname: defaulteventSlugname(),
      special: parsedQueryString.special,
      eventSelected,
    })
      .then((response) => {
        let resp = {};
        set_loading(false);
        if (response.data.success) {
          if (response.data.created) {
            if (redirect) props.history.push(redirect);
            if (response.data.reserved) {
              resp = { ...resp, reserved: response.data.reserved, ...response.data.reserved };
              if (response.data.allReservations)
                resp = {
                  ...resp,
                  allReservations: response.data.allReservations,
                };
            }
            setInputs({ ...inputs, ...resp });
            if (goToSummary) setStep('summary');
          }
        } else {
          if (response.data)
            Swal.fire(response.data.message, response?.data?.submessage || '', 'error');
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const isAudPpal = defaulteventSlugname() === 'Auditorio_Principal';

  React.useEffect(() => {
    changeDocTitle('Reserva tu lugar');
  }, []);

  React.useEffect(() => {
    const {
      parentIdentification,
      identification,
      parentFullname,
      eventSlugname,
      parentPhone,
      phone,
      event,
      ac,
    } = parsedQueryString;
    setInputs({
      ...inputs,
      emergencyContact: parentFullname,
      emergencyNumber: parentPhone,
      eventSlugname: eventSlugname || event,
      parentFullname,
      parentIdentification,
      identification,
      phone,
      ac,
    });
  }, []);

  const stepRender = () => {
    if (Step === 'check') {
      return (
        <ReservationStepCheck
          setInputs={setInputs}
          inputs={inputs}
          isAudPpal={isAudPpal}
          set_loading={set_loading}
          loading={loading}
          setStep={setStep}
          services={services}
          history={props.history}
          parsedQueryString={parsedQueryString}
          defaulteventSlugname={defaulteventSlugname}
        />
      );
    } else if (Step === 'FillData') {
      return (
        <ReservationStepFillData
          setInputs={setInputs}
          parsedQueryString={parsedQueryString}
          firstEventOfQuery={firstEventOfQuery}
          setStep={setStep}
          history={props.history}
          services={services}
          inputs={inputs}
          loading={loading}
          sendReservation={sendReservation}
          defaulteventSlugname={defaulteventSlugname}
        />
      );
    } else if (Step === 'selectAService') {
      return (
        <ReservationStepSelectAService
          sendReservation={sendReservation}
          setInputs={setInputs}
          services={services}
          setStep={setStep}
          inputs={inputs}
          set_loading={set_loading}
          loading={loading}
          isAudPpal={isAudPpal}
          defaulteventSlugname={defaulteventSlugname}
          parsedQueryString={parsedQueryString}
          set_showSummaryForm={set_showSummaryForm}
        />
      );
    } else if (Step === 'summary') {
      return (
        <ReservationSummary
          setInputs={setInputs}
          setStep={setStep}
          history={props.history}
          inputs={inputs}
          isAudPpal={isAudPpal}
          set_showSummaryForm={set_showSummaryForm}
          showSummaryForm={showSummaryForm}
          defaulteventSlugname={defaulteventSlugname}
          parsedQueryString={parsedQueryString}
        />
      );
    }
  };

  return <div>{stepRender()}</div>;
};

export default ServiceReservation;
