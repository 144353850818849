import styled from '@emotion/styled';

export const CheckInComponent = styled.div`
  width: 70%;
  background-color: #fff;
  border-radius: 22px;
  height: auto;
  padding: 1.1rem;
  .info {
    display: flex;
    margin: 0 0 0 0;
    .profilePhoto {
      width: 150px;
      background-size: cover;
      background-position: center;
      margin: 20px 10px;
    }
    .data {
      margin: 20px 0 0 0;
      display: flex;
      &.pic {
        flex-direction: column;
      }
      p {
        margin: 5px 0;
      }
    }
    p {
      display: inline-block;
      strong {
        margin: 0 10px;
      }
    }
  }
  .photo {
    margin: 20px 0;
  }
  @media only screen and (max-width: 700px) {
    width: 95%;
    .info {
      p {
        width: 100%;
      }
    }
  }
`;

export const GuestIdFormComp = styled.div`
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
  form {
    max-width: 300px;
  }
  .Registration__input {
    padding: 0;
  }
  .ui.button {
    margin: 5px 0 0 0;
  }
`;
