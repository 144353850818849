import { Button } from 'semantic-ui-react';

const AnswersP1C3Row = (props) => {
  const { P1C3Answers, set_showAnswers, allTutors } = props;

  const { id, name, identification, lastName, phone, Crecer1stClass3rds } = P1C3Answers;

  const getTutor = (id) => {
    let finded = allTutors.find((tutor) => parseInt(tutor.key) === parseInt(id));
    if (finded) return finded;
    return { text: 'SIN TUTOR' };
  };

  return (
    <tr>
      <td>
        <p style={{ margin: '0' }}>{identification}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{name}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{lastName}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{phone}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{Boolean(Crecer1stClass3rds[0].appointment) ? 'Si' : 'No'}</p>
      </td>
      <td>
        <p style={{ margin: '0' }}>{Boolean(Crecer1stClass3rds[0].contacted) ? 'Si' : 'No'}</p>
      </td>
      {localStorage.getItem('userToken') && (
        <td>
          <p style={{ margin: '0' }}>{getTutor(Crecer1stClass3rds[0].tutor_id).text}</p>
        </td>
      )}
      <td>
        <Button onClick={() => set_showAnswers(id)}>Ver Respuestas</Button>
      </td>
    </tr>
  );
};

export default AnswersP1C3Row;
