import Button from '../common/Button';
import moment from 'moment-timezone';
import queryString from 'query-string';

const Row = (props) => {
  const { printQueue, deleteOfQueue } = props;
  const { identification, name, createdAt, event, info } = printQueue;

  const printQueueQuery = {
    event,
    identification,
    name,
    ...(info || {}),
  };

  return (
    <tr>
      <td>{identification}</td>
      <td>{name}</td>
      <td>{moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY, HH:mm:ss')}</td>
      <td>
        <a
          rel="noreferrer"
          href={`/checkin/print?${queryString.stringify(printQueueQuery, { sort: false })}`}
          target="_blank"
        >
          <Button text={'Imprimir'} />
        </a>
        <Button text={'Eliminar'} onClick={() => deleteOfQueue(identification)} />
      </td>
    </tr>
  );
};

export default Row;
