import moment from 'moment';
import { Button } from 'semantic-ui-react';

export const ServiceListRowDef = (getEvent, set_clickedEvent) => [
  {
    headerName: 'Id / Ver',
    field: 'id',
    cellRenderer: (val) => (
      <Button
        className="btn-grid"
        onClick={() => {
          getEvent(val.data?.id, val.data?.eventSlugname);
          set_clickedEvent(val.data);
        }}
        primary
      >
        {val.data?.id}
      </Button>
    ),
    width: 120,
  },
  {
    headerName: 'Nombre',
    field: 'eventSlugname',
    valueGetter: (val) => `${val.data?.eventSlugname?.replace(/_/g, ' ')}`,
    width: 200,
  },
  {
    headerName: 'Fecha',
    field: 'date',
    valueGetter: (val) =>
      `${moment.tz(val.data?.date, 'America/Bogota').format('DD [de] MMMM [de] YYYY')}`,
    width: 200,
  },
  {
    headerName: 'Hora',
    field: 'dateTime',
    valueGetter: (val) => `${moment.tz(val.data?.date, 'America/Bogota').format('hh:mm a')}`,
    width: 120,
  },
  {
    headerName: 'Pago',
    field: 'paid',
    valueGetter: (val) => `${val.data?.paidEvent ? val.data?.paidEvent : 'No'}`,
    width: 100,
  },
  {
    headerName: 'Capacidad',
    field: 'capacity',
    width: 100,
  },
  {
    headerName: 'Inscritos',
    field: 'guests',
    valueGetter: (val) => `${val.data?.AuditoriumGuests?.length}`,
    width: 100,
  },
  {
    headerName: 'Pagos',
    field: 'paidGuests',
    valueGetter: (val) =>
      `${
        val.data?.paidEvent
          ? val.data?.AuditoriumGuests?.filter((guest) => guest?.payment?.amount)?.length
          : 'n/a'
      }`,
    width: 90,
  },
  {
    headerName: 'Asistentes',
    field: 'attendants',
    valueGetter: (val) =>
      `${val.data?.AuditoriumGuests?.filter((guest) => guest?.attended)?.length}`,
    width: 100,
  },
];
