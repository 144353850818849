import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import guestService from '../../services/guest';
import GuestRow from '../../components/common/adminTable/guestRow';
import { Input, Button } from 'semantic-ui-react';

const AllGuests = (props) => {
  const [allGuests, set_allGuests] = React.useState([]);
  const [loading, set_loading] = React.useState(true);
  const [inputs, setInputs] = React.useState({});
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);

  React.useEffect(() => {
    getInfo();
  }, []);

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  function getInfo(page = false) {
    set_loading(true);
    set_endPage(false);

    let body = { keyword: inputs.keyword, currentPage };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    guestService
      .getAllGuests(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          if (page) set_allGuests([...allGuests, ...response.data.allGuests]);
          else set_allGuests(response.data.allGuests);
          if (response.data.allGuests.length === 0) {
            set_endPage(true);
          }
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };
  const onEnter = (e) => {
    if (e.key === 'Enter') {
      getInfo();
    }
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => props.history.goBack()}>Atrás</Button>
        <Button primary onClick={() => getInfo()}>
          Buscar
        </Button>
        <h2>Directorio de asistentes de Comunidad MDE</h2>
        <div className="options">
          <div className="search-query">
            <p>Buscar</p>
            <Input
              loading={loading}
              name={'keyword'}
              onChange={handleInputs}
              value={inputs.keyword}
              placeholder="Buscar por nombre, apellido, email, cedula o celular..."
              onKeyPress={onEnter}
            />
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Cedula</th>
            <th>Nombre</th>
            <th>Celular</th>
            <th>Email</th>
            <th>Ocupación</th>
            <th>Dashboard</th>
          </tr>
        </thead>
        <tbody>
          {allGuests.map((guest) => (
            <GuestRow guest={guest} history={props.history} />
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: 'center', margin: '20px 0 ' }}>
        <Button loading={loading} disabled={endPage} onClick={() => loadMore()}>
          Cargar más
        </Button>
      </div>
    </section>
  );
};

export default AllGuests;
