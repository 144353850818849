import React from 'react';
import GroupsLogs from './groupLog';
import groupService from '../../../services/groupService';
import LeadersAdminAllGroups from './allGroups';
import CreateNewGroup from './createAGroup';
import CoupleGroupRegistration from '../registration/groupRegistration';
import Swal from 'sweetalert2';
import { leaderCategories, groupCategories, seminary } from '../../../components/helpers';
import queryString from 'query-string';

const LeadersAdminGroup = (props) => {
  const [loading, set_loading] = React.useState(false);
  const [Step, setStep] = React.useState('check');
  const [inputs, setInputs] = React.useState({ action: 'Ver grupos' });

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  const getInfo = (extraProps = {}) => {
    set_loading(true);
    groupService
      .getAllGroupsByLeader({ ...inputs, ...extraProps })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          if (inputs.action === 'Ver grupos' && response.data.message)
            return Swal.fire(
              response.data.message,
              'Si crees que es un error revisa con los encargados',
              'warning'
            );
          if (response.data.groups && response.data.found && inputs.action === 'Ver grupos') {
            setInputs({
              ...inputs,
              groups: response.data.groups,
              groupsLeaders: response.data.groupsLeaders,
            });
            setStep('summary');
            return;
          }
          setStep('create');
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  };

  React.useEffect(() => {
    setInputs({
      ...inputs,
      activityName: parsedQueryString.activityName,
      kindgroup: parsedQueryString.kindgroup || 'Grupo_Discipulado',
      identification: parsedQueryString.identification,
      phone: parsedQueryString.phone,
    });
  }, []);

  React.useEffect(() => {
    if (Step === 'send') {
      set_loading(true);
      groupService
        .createGroup(inputs)
        .then((response) => {
          if (response && response.data && response.data.message) Swal.fire(response.data.message);
          if (response.data.groups && response.data.found) {
            setInputs({ ...inputs, groups: response.data.groups });
            setStep('summary');
          } else {
            getInfo();
          }
          set_loading(false);
        })
        .catch((error) => {
          if (error && error.response && error.response.data && error.response.data.message)
            Swal.fire(error.response.data.message);
          set_loading(false);
        });
    }
  }, [Step]);

  const isCouples =
    (inputs.activityName && inputs.activityName.includes('Parejas')) || inputs.coupleleadered;

  let kindgrouplist = groupCategories;

  if (inputs.kindgroup === 'Grupo_Seminario') {
    kindgrouplist = seminary;
  } else if (inputs.kindgroup === 'Equipo_de_Líderes') {
    kindgrouplist = leaderCategories;
  }

  const stepRender = () => {
    if (Step === 'check') {
      return (
        <GroupsLogs
          parsedQueryString={parsedQueryString}
          setInputs={setInputs}
          inputs={inputs}
          getInfo={getInfo}
          leaders
          next="list"
          kindgrouplist={kindgrouplist}
          setStep={setStep}
          set_loading={set_loading}
          loading={loading}
        />
      );
    } else if (Step === 'create') {
      return (
        <CreateNewGroup
          inputs={inputs}
          setInputs={setInputs}
          setStep={setStep}
          isCouples={isCouples}
        />
      );
    } else if (Step === 'fillLeader') {
      return (
        <CoupleGroupRegistration
          inputs={inputs}
          setInputs={setInputs}
          loading={loading}
          setStep={setStep}
          person={'person1'}
          strictNext
          next={isCouples ? 'fillLeader2' : 'send'}
          isCouples={isCouples}
        />
      );
    } else if (Step === 'fillLeader2') {
      return (
        <CoupleGroupRegistration
          inputs={inputs}
          loading={loading}
          setInputs={setInputs}
          setStep={setStep}
          person={'person2'}
          next={'send'}
          strictNext
          isCouples={isCouples}
        />
      );
    } else if (Step === 'summary') {
      return (
        <LeadersAdminAllGroups
          inputs={inputs}
          setInputs={setInputs}
          isCouples={isCouples}
          getInfo={getInfo}
          kindgrouplist={kindgrouplist}
          loading={loading}
          history={props.history}
        />
      );
    } else {
      return <p>Cargando</p>;
    }
  };

  return <div className="group_registration">{stepRender()}</div>;
};

export default LeadersAdminGroup;
