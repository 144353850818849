import { useContext } from 'react';
import { DashboardExperienciaComponent } from './styles/DashboardExperienciaComponent';
import { GuestDashboardContext } from './guestDashboardContext';

const Experiencia = () => {
  const { experienceVolunteer } = useContext(GuestDashboardContext);

  return (
    <DashboardExperienciaComponent>
      <div>
        <h2 className="title">Experiencia</h2>
        {experienceVolunteer?.Volunteers.length ? (
          <p>Estas son las areas en las que sirves actualmente.</p>
        ) : (
          <>
            <p>
              Actualmente no sirves en ningún equipo,{' '}
              <a href="https://www.comunidadmde.com/experiencia" target="_blank" rel="noreferrer">
                haz clic aqui para que te conectes con sus dones y pasiones al servicio de Dios.
              </a>
            </p>
          </>
        )}
      </div>
      <br></br>
      <div className="cards-container">
        {experienceVolunteer?.Volunteers?.map((item, index) => (
          <div key={index} className="service-card">
            <i className="fas fa-user card-icon"></i>
            <h3 className="card-title">{item.serviceArea}</h3>
            <div className="card-content">
              <p>
                <strong>Tu Rol:</strong> {item.role}
              </p>
              <p>
                <strong>Desde :</strong> {new Date(item.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>
    </DashboardExperienciaComponent>
  );
};

export default Experiencia;
