import { createContext, useState } from 'react';

const GuestDashboardContext = createContext({});

const GuestDashboardProvider = ({ children }) => {
  const [guest, setGuest] = useState({});
  const [crecerDates, setCrecerDates] = useState({});
  const [experienceVolunteer, setExperienceVolunteer] = useState({});
  const [loginCredentials, setLoginCredentials] = useState({});
  const [guestGroup, setGuestGroup] = useState({});
  const [guestGroups, setGuestGroups] = useState({});

  const dataState = {
    guest,
    setGuest,
    loginCredentials,
    setLoginCredentials,
    crecerDates,
    setCrecerDates,
    experienceVolunteer,
    setExperienceVolunteer,
    guestGroup, 
    setGuestGroup,
    guestGroups,
    setGuestGroups
  };

  return <GuestDashboardContext.Provider value={dataState}>{children}</GuestDashboardContext.Provider>;
};

export { GuestDashboardContext, GuestDashboardProvider };
