import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import crecerForms from '../../services/crecer';
import reportService from '../../services/reportService';
import P1C3Answers from '../../components/crecer/P1C3Answers';

const AllP1C3Answers = (props) => {
  const [allAnswers, set_allAnswers] = React.useState([]);
  const [loaded, set_loaded] = React.useState(null);
  const [showAnswers, set_showAnswers] = React.useState(null);
  const [inputs, setInputs] = React.useState({});
  const [allTutors, set_allTutors] = React.useState([]);
  const [rawTutors, set_rawTutors] = React.useState([]);
  const [endPage, set_endPage] = React.useState(false);
  const [currentPage, set_currentPage] = React.useState(1);
  const identification = inputs.identification || '';

  React.useEffect(() => {
    if (showAnswers === null) getInfo();
    getTutors();
  }, [showAnswers]);

  React.useEffect(() => {
    getTutors();
  }, []);

  React.useEffect(() => {
    getInfo();
  }, [inputs.tutor_id, inputs.contacted, inputs.tutor, inputs.appointment, inputs.in_person]);

  function getAttendantInfo() {
    set_loaded(false);

    let filters = {};
    Object.entries(inputs)
      .filter((input) => input[1] != null)
      .forEach((element) => {
        filters[element[0]] = element[1];
      });

    let body = { filter: { ...filters }, currentPage };
    let URL = `/crecer/crecerP1C3Report`;
    let fileName = `InformeCrecerPaso1Clase3.xlsx`;

    reportService
      .getReport({ URL, fileName, body })
      .then((response) => {
        set_loaded(true);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(true);
      });
  }

  function getTutors() {
    set_loaded(false);

    crecerForms
      .getTutors('showStudents')
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);
          set_rawTutors(response.data.Tutors);
          set_allTutors([
            { key: false, value: false, text: 'Sin tutor' },
            ...response.data.Tutors.map((a) => {
              return {
                key: a.id,
                value: a.id,
                text: `${a.Crecer1stClass3rds[0].DISC_letter.toUpperCase()} - ${a.name} ${
                  a.lastName
                }`,
              };
            }),
          ]);
        } else {
          set_loaded(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const loadMore = () => {
    set_currentPage(currentPage + 1);
  };

  React.useEffect(() => {
    if (currentPage !== 1) getInfo(true);
  }, [currentPage]);

  function getInfo(page = false) {
    set_loaded(false);
    set_endPage(false);

    let filters = {};
    Object.entries(inputs)
      .filter((input) => input[1] != null)
      .forEach((element) => {
        filters[element[0]] = element[1];
      });

    let body = { filter: { ...filters }, currentPage };

    if (!page) {
      set_currentPage(1);
      body.currentPage = 1;
    }

    crecerForms
      .getCrecerP1C3Form({ identification, body })
      .then((response) => {
        if (response.data.success) {
          set_loaded(true);

          if (page && body.currentPage > 1)
            set_allAnswers([...allAnswers, ...response.data.Answers]);
          else set_allAnswers(response.data.Answers);

          if (response.data.Answers.length === 0) set_endPage(true);
        } else {
          set_loaded(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loaded(null);
      });
  }

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <section className="AdminTable">
      <P1C3Answers
        loadMore={loadMore}
        endPage={endPage}
        getAttendantInfo={getAttendantInfo}
        showAnswers={showAnswers}
        rawTutors={rawTutors}
        getInfo={getInfo}
        handleInputs={handleInputs}
        inputs={inputs}
        selectHandle={selectHandle}
        loaded={loaded}
        allTutors={allTutors}
        allAnswers={allAnswers}
        set_showAnswers={set_showAnswers}
        {...props}
      />
    </section>
  );
};

export default AllP1C3Answers;
