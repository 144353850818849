import React from 'react';
import { Input, Button } from 'semantic-ui-react';
import GeneracionesServiceGuestsRow from '../../../../components/common/adminTable/GeneracionesServiceGuestsRow';
import { eventSlugnameTrim, scrollUp } from '../../../../components/helpers';
import moment from '../../../../services/moment_instance';

const GeneracionesChurchServiceGuests = (props) => {
  const [inputs, setInputs] = React.useState({});
  const [inScreen, set_inScreen] = React.useState(0);

  React.useEffect(() => {
    scrollUp();
  }, []);

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const filterUsers = (word) => {
    let serviceFiltered = props.serviceAttendance;
    if (word)
      serviceFiltered = serviceFiltered.filter(
        (att) =>
          att.GeneracionesGuest.name.toLowerCase().includes(word.toLowerCase()) ||
          att.GeneracionesGuest.lastName.toLowerCase().includes(word.toLowerCase()) ||
          att.GeneracionesGuest?.parent?.name?.toLowerCase()?.includes(word.toLowerCase())
      );

    if (serviceFiltered.length !== inScreen) set_inScreen(serviceFiltered.length);
    return serviceFiltered;
  };

  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => props.set_serviceAttendance([])}>Atrás</Button>
        <h2>Niños en el servicio</h2>
        {props.generacionesCategory && (
          <h3 style={{ textAlign: 'center', margin: '0' }}>
            {eventSlugnameTrim(props.generacionesCategory)}
          </h3>
        )}
        {props.clickedEvent?.date && (
          <p style={{ padding: '20px 0 0 0', margin: 0, fontSize: '24px' }}>
            <strong>
              {moment
                .tz(props.clickedEvent.date, 'America/Bogota')
                .format('dddd DD [de] MMMM hh:mm a')}
            </strong>
          </p>
        )}
        <p style={{ padding: '20px 0 0 0', margin: 0 }}>
          <strong>Total usuarios:</strong> {props.serviceAttendance.length}
        </p>
        <p style={{ padding: '0 0 0 0', margin: 0 }}>
          <strong>Total usuarios en pantalla:</strong> {inScreen}
        </p>
        <div className="options" style={{ padding: '20px 0 0 0', margin: 0 }}>
          <div className="search-query">
            <p style={{ padding: '0 0 0 0' }}>Buscar</p>
            <Input
              name={'keyword'}
              onChange={handleInputs}
              value={inputs.keyword}
              placeholder="Buscar"
            />
          </div>
        </div>
      </div>

      <table style={{ margin: '20px 0' }}>
        <thead>
          <tr>
            <th>Foto</th>
            <th>Nombre</th>
            <th>Edad</th>
            <th>Acudiente</th>
            <th>Telefono</th>
            <th>Ingresó</th>
            <th>Info Extra</th>
            <th>Ver más</th>
          </tr>
        </thead>
        <tbody>
          {filterUsers(inputs.keyword).map((guest) => (
            <GeneracionesServiceGuestsRow guest={guest} history={props.history} inputs={inputs} />
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default GeneracionesChurchServiceGuests;
