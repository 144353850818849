import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Input } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import groupService from '../../../services/groupService';
import { avoCaptConstructor, errorInput, localhostname, seminary } from '../../helpers';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export const groupTitle = (params) => {
  if (window.location.pathname.includes('crecer')) {
    return <h1>¡Bienvenido al seminario de {params?.seminary.replace(/_/g, ' ')}!</h1>;
  }
  return <h1>¡Bienvenido a tu grupo en Comunidad!</h1>;
};

const MemberLogGroup = (props) => {
  const { inputs, setInputs, setStep, next, parsedQueryString, set_loading, loading } = props;
  const [errorInputs, set_errorInputs] = React.useState({});

  const params = props.match.params;

  const verifyForm = () => {
    let mandatoryInputs = ['identification', 'phone'];

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(inputs.identification) !== parseInt(parsedQueryString.ac)
    ) {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(inputs.identification) !== parseInt(parsedQueryString.ac)
    ) {
      if (inputs['captcha'] && inputs['captcha'] !== 'null') {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: 'captcha',
            value: inputs['captcha'].length >= 10,
          },
        });
      }
    }

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  React.useEffect(() => {
    if (!parsedQueryString.activityName && inputs.kindgroup)
      props.history.push(`?kindgroup=${inputs.kindgroup}`);
  }, [inputs.kindgroup]);

  React.useEffect(() => {
    if (inputs.identification && inputs.phone && parsedQueryString.ac)
      enterReservation({ preventDefault: () => {} });
  }, [inputs.identification]);

  const enterReservation = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    set_loading(true);

    let nextStep = next;

    groupService
      .getGroupAttendant({ ...inputs, activityName: params?.seminary })
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          let info = {};
          if (response.data.groupMember && response.data.Attendants) {
            if (response.data.Attendants) info = { ...info, Attendants: response.data.Attendants };
            if (response.data.groupMember)
              info = { ...info, groupMember: response.data.groupMember };
            nextStep = 'summary';
          } else if (response.data.guest) {
            info = { ...info, person1: response.data.guest };
          }
          setInputs({ activityName: params?.seminary, ...inputs, ...info });
          setStep(nextStep);
        }
      })
      .catch((error) => {
        set_loading(false);
        if (error.response) Swal.fire(error.response.data.message);
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value });
  }

  if (window.location.pathname.includes('crecer') && !seminary.includes(params?.seminary)) {
    return (
      <form className="Registration__form">
        <h2>Error en el enlace</h2>
        <p>
          Si desea registrarse en Amor y Respeto{' '}
          <Link to={'/crecer/registrarse/Amor_y_Respeto._'}>haz clic aqui</Link>
        </p>
        <p>
          Si desea registrarse en Transformación Empresarial{' '}
          <Link to={'/crecer/registrarse/Transformacion_Empresarial._'}>haz clic aqui</Link>
        </p>
      </form>
    );
  }

  return (
    <>
      {groupTitle(params)}
      <form onSubmit={(e) => enterReservation(e)} className="Registration__form">
        <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
          Escribe tus datos
        </label>
        {Object.values(errorInputs).includes(true) && (
          <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
        )}
        <Input
          style={{ width: '45%' }}
          onChange={handleInputs}
          name={'identification'}
          value={inputs.identification}
          type="number"
          placeholder="Identificación"
          id="identification"
          className={`Registration__input Registration__input-${'identification'} ${
            errorInputs['identification'] ? 'error' : ''
          }`}
        />
        <Input
          placeholder="Celular"
          id="phone"
          name={'phone'}
          type="number"
          className={`Registration__input Registration__input-${'phone'} ${
            errorInputs['phone'] ? 'error' : ''
          }`}
          value={inputs.phone}
          onChange={handleInputs}
          style={{ width: '45%', margin: '5px' }}
        />

        <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
          {!loading && (
            <ReCAPTCHA
              style={{ margin: 'auto' }}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={captchaOnChange}
            />
          )}
        </div>
        <p style={{ color: 'red' }}>
          {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
        </p>
        <Button primary disabled={loading} onClick={(e) => enterReservation(e)}>
          Entrar
        </Button>
      </form>
    </>
  );
};

export default withRouter(MemberLogGroup);
