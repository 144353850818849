import { Link } from 'react-router-dom';
import Button from '../../components/common/Button';
import { HomeComponent } from '../../assets/styles/components/Home';

const PublicPage = () => {
  return (
    <HomeComponent>
      <div className="PublicHome">
        <h1 className="title">Bienvenido</h1>
        <div className="main">
          <div className="content">
            <h2>Tu perfil en Comunidad</h2>
            <p className="intro">
              Revisa tu perfil en Comunidad, tus pasos de crecer, las proximas clases, tu grupo, y
              más.
              <Link to="/perfil/crecer">
                <Button text="Entra a tu perfil" disabled={false} />
              </Link>
            </p>
          </div>
          <div className="content">
            <h2>Campus Medellín</h2>
            Regístrate en uno de nuestros eventos
            <Link to="/servicio/reservacion">
              <Button text="Registrate a una reunión" disabled={false} />
            </Link>
          </div>
        </div>
        <div className="habeas">
          <p>
            <strong>Habeas data:</strong> <br />
            La Iglesia se compromete a realizar la debida protección de los datos personales
            suministrados con base en la Ley 1581 de 2012 y sus decretos reglamentarios, respetando
            los derechos que los titulares poseen y ofreciendo las herramientas necesarias para que
            estos se garanticen. Igualmente se autoriza el uso y tratamiento de fotos y videos con
            fines comunicativos, en nuestras diferentes plataformas digitales y comunicación interna
            de la iglesia. <br />
            Si desea más información,{' '}
            <a
              href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=49981"
              target="_blank"
              rel="noreferrer"
            >
              puede dar click en TÉRMINOS Y CONDICIONES para ampliar la información.
            </a>
          </p>
        </div>
      </div>
    </HomeComponent>
  );
};

export default PublicPage;
