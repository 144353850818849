import { useEffect, useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { formSelectService, scrollUp, sortObjects } from '../../../components/helpers';
import eventService from '../../../services/eventService';
import { Select } from 'semantic-ui-react';
import VolunteersBySection from './volunteersBySection';
import VolunteerForm from './VolunteerForm';
import ConfirmedVolunteers from './confirmedVolunteers';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { renderMonthElement } from '../../../components/form/Form';
import { VolunteersBirthdaysModal } from '../../volunteer/components/volunteersBirthdays';

export const nextService = (section, services, eventSlugname) => {
  if (eventSlugname === 'Auditorio_Principal') {
    return (
      services?.find(
        (service) =>
          moment(service.date).format('DD-MM-YYYY') ===
          moment()
            .isoWeekday(section.includes('domin') ? 7 : 6)
            .format('DD-MM-YYYY')
      ) || []
    );
  } else {
    return services[0];
  }
};

export const getOneEventPerDay = (services) => {
  let events = [];
  services.forEach((service) => {
    if (
      !events.find(
        (event) =>
          moment(event.date).format('DD-MM-YYYY') === moment(service.date).format('DD-MM-YYYY')
      )
    ) {
      events.push(service);
    }
  });
  return events;
};

const ExperienceTeamArea = (props) => {
  const { experienceTeam, inputs, setInputs, getInfo, history, loading, set_loading } = props;
  const [previousEventsNames, set_previousEventsNames] = useState([]);
  const [services, set_services] = useState([]);
  const [showVolunteerForm, set_showVolunteerForm] = useState(false);
  const [focused, set_focused] = useState({});

  const [handleDate, set_handleDate] = useState({
    startDate: moment().startOf('day').isoWeekday(1),
    endDate: moment().endOf('day').isoWeekday(8),
  });

  useEffect(() => {
    scrollUp();
    getPreviousEventNames();
  }, []);

  const experienceTeamBySections = experienceTeam.reduce((acc, guest) => {
    if (!acc[guest?.section]) acc[guest?.section] = [];
    acc[guest?.section].push(guest);
    return acc;
  }, {});

  const eventSlugname = inputs.eventSlugname || 'Auditorio_Principal';

  useEffect(() => {
    set_loading(true);
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().add('7', 'days').endOf('month').toDate();

    let body = { eventSlugname, startDate, endDate };

    eventService
      .getAuditoriumEvents(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_services(sortObjects(response.data.events, 'date', 'asc'));
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  }, [eventSlugname]);

  const getPreviousEventNames = () => {
    set_loading(true);
    const postDays = moment().endOf('month').diff(moment(), 'days');
    eventService
      .getEventsNames({ postDays })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const sectionsTabs = (experienceTeamBySections) => {
    return (
      <Tab
        panes={Object.entries(experienceTeamBySections).map(([section, volunteers]) => {
          if (inputs?.role !== 'Coordinador' && section === 'Pendientes') return null;
          return {
            menuItem: section.replace(/_/g, ' '),
            render: () => (
              <VolunteersBySection
                volunteers={volunteers}
                history={history}
                set_loading={set_loading}
                loading={loading}
                eventSlugname={eventSlugname}
                getInfo={getInfo}
                services={services}
                setInputs={setInputs}
                inputs={inputs}
              />
            ),
          };
        })}
      />
    );
  };

  const handleDateChange = (startDate, endDate) => {
    setInputs((prev) => ({
      ...prev,
      startDate: startDate ? startDate.startOf('day')._d.getTime() : null,
      endDate: endDate ? endDate.endOf('day')._d.getTime() : null,
    }));
    set_handleDate({ startDate, endDate });
  };

  return (
    <section className="AdminTable" id="network">
      <h4 style={{ textAlign: 'center', margin: 0 }}>
        Bienvenido {inputs?.Guest?.name} {inputs?.Guest?.lastName}
      </h4>
      <p style={{ textAlign: 'center', margin: '0 0 10px 0' }}>{inputs?.role}</p>
      <div className="AdminTable__content">
        <h2>{inputs.serviceArea}</h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div />
        <div>
          {formSelectService({
            Select,
            label: 'Proximo evento a servir',
            name: 'eventSlugname',
            handler: (a, { value }) => {
              if (value === 'Servicio_del_fin_de_semana') value = 'Auditorio_Principal';
              setInputs({ ...inputs, eventSlugname: value });
            },
            value: eventSlugname,
            options: ['Servicio_del_fin_de_semana', ...previousEventsNames],
          })}
        </div>
      </div>
      {showVolunteerForm && (
        <VolunteerForm
          mainInputs={inputs}
          getInfo={getInfo}
          set_loading={set_loading}
          loading={loading}
          set_showVolunteerForm={set_showVolunteerForm}
        />
      )}
      <Tab
        panes={[
          {
            menuItem: 'Voluntarios',
            render: () => (
              <Tab.Pane attached={false}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '10px 0 30px 0',
                  }}
                >
                  <div>
                    <p style={{ margin: 0 }}>Fecha de servicio:</p>
                    <Select
                      placeholder="Fecha de servicio"
                      onChange={(a, { value }) => setInputs({ ...inputs, event_id: value })}
                      loading={loading}
                      value={inputs.event_id}
                      name="event_id"
                      options={getOneEventPerDay(services)?.map((a) => ({
                        key: a?.id,
                        value: a?.id,
                        text: moment(a?.date).format('dddd, DD-MMM-YYYY'),
                      }))}
                    />
                  </div>
                  {/*(inputs.role === 'Coordinador' || inputs.role === 'Entrenador') && (
                    <Button onClick={() => set_showVolunteerForm(true)}>
                      Añadir un voluntario
                    </Button>
                  )*/}
                </div>
                <VolunteersBirthdaysModal volunteers={experienceTeam} />

                {sectionsTabs(experienceTeamBySections)}
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Confirmados',
            render: () => (
              <Tab.Pane attached={false}>
                <div style={{ padding: '20px 0 0 0' }}>
                  <DateRangePicker
                    startDatePlaceholderText="Fecha Inicio"
                    endDatePlaceholderText="Fecha Fin"
                    startDate={handleDate.startDate}
                    startDateId="startDateRange"
                    minimumNights={0}
                    endDate={handleDate.endDate}
                    endDateId="endDateRange"
                    onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
                    focusedInput={focused}
                    onFocusChange={(focused) => set_focused(focused)}
                    isOutsideRange={() => false}
                    renderMonthElement={renderMonthElement}
                  />
                </div>
                <ConfirmedVolunteers
                  history={history}
                  services={services}
                  set_loading={set_loading}
                  loading={loading}
                  eventSlugname={eventSlugname}
                  getInfo={getInfo}
                  serviceArea={inputs.serviceArea}
                  section={inputs.role === 'Coordinador' ? null : inputs.section}
                  handleDate={handleDate}
                />
              </Tab.Pane>
            ),
          },
        ]}
      />
    </section>
  );
};

export default ExperienceTeamArea;
