import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Input } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { avoCaptConstructor, errorInput, localhostname } from '../../components/helpers';

const PublicCrecerAttendanceLogs = (props) => {
  const { inputs, setInputs, getGuest, parsedQueryString, set_loading, loading } = props;
  const [errorInputs, set_errorInputs] = React.useState({});

  const verifyForm = () => {
    let mandatoryInputs = ['identification', 'phone'];

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(inputs['identification']) !== parseInt(parsedQueryString.ac)
    ) {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    if (
      window.location.hostname !== localhostname &&
      avoCaptConstructor(inputs['identification']) !== parseInt(parsedQueryString.ac)
    ) {
      if (inputs['captcha'] && inputs['captcha'] !== 'null') {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: 'captcha',
            value: inputs['captcha'].length >= 10,
          },
        });
      }
    }

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5,
        },
      });
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  React.useEffect(() => {
    if (parsedQueryString.identification && parsedQueryString.phone && parsedQueryString.ac)
      enterReservation({ preventDefault: () => {} });
  }, [inputs.identification, inputs.phone]);

  const enterReservation = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    set_loading(true);
    getGuest();
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value });
  }

  return (
    <>
      <h1>¡Bienvenido!</h1>
      <p>Aquí puedes consultar tu progreso en crecer y actualizar tus datos.</p>
      <form onSubmit={(e) => enterReservation(e)} className="Registration__form">
        <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
          Escribe tus datos
        </label>
        {Object.values(errorInputs).includes(true) && (
          <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
        )}
        <Input
          style={{ width: '45%' }}
          onChange={handleInputs}
          name={'identification'}
          value={inputs.identification}
          type="number"
          placeholder="Identificación"
          id="identification"
          className={`Registration__input Registration__input-${'identification'} ${
            errorInputs['identification'] ? 'error' : ''
          }`}
        />
        <Input
          placeholder="Celular"
          id="phone"
          name={'phone'}
          type="number"
          className={`Registration__input Registration__input-${'phone'} ${
            errorInputs['phone'] ? 'error' : ''
          }`}
          value={inputs.phone}
          onChange={handleInputs}
          style={{ width: '45%', margin: '5px' }}
        />
        <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
          {!loading && (
            <ReCAPTCHA
              style={{ margin: 'auto' }}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={captchaOnChange}
            />
          )}
        </div>
        <p style={{ color: 'red' }}>
          {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
        </p>
        <Button primary disabled={loading} loading={loading} onClick={(e) => enterReservation(e)}>
          Entrar
        </Button>
      </form>
    </>
  );
};

export default PublicCrecerAttendanceLogs;
