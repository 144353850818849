import { DashboardFinanzaComponent } from './styles/DashboardFinanzaComponent';

// src/components/Grupos.js
const Financiero = () => {

  return (
    <DashboardFinanzaComponent>
      <h2 style={{ color: '#009700', fontSize: '40px' }}>Financiero</h2>
    </DashboardFinanzaComponent>
  ); 
};

export default Financiero;
