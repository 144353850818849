import proxybase from './proxybase';

class EventService {
  getDates(event, body = {}) {
    return proxybase
      .post(`/event/eventDates/${event ? event : ''}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateDates(body) {
    return proxybase
      .put(`/event/eventDates`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getSiteMap(body) {
    return proxybase
      .post(`/event/seatmap`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  CheckAuditoriumInscription(body) {
    return proxybase
      .post(`/event/checkInscription`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  MakeAReservation(body) {
    return proxybase
      .post(`/event/MakeAReservation`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getAuditoriumEvents(body) {
    return proxybase
      .post(`/event/getAuditoriumEvents`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  cancelReservation(body) {
    return proxybase
      .post(`/event/CancelAReservation`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  markAttendance(body) {
    return proxybase
      .post(`/event/markAttendance`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createEvent(body) {
    return proxybase
      .post(`/event/createEvent`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deleteEvent(body) {
    return proxybase
      .post(`/event/deleteEvent`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  editEvent(body) {
    return proxybase
      .post(`/event/editEvent`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getVIPGuests(body = {}) {
    return proxybase
      .post(`/event/getVIPGuests`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createVIPAttendants(body = {}) {
    return proxybase
      .post(`/event/createVIPGuests`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deleteVIPAttendants(body = {}) {
    return proxybase
      .post(`/event/updateVIPGuests`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  eventAttendance(body) {
    return proxybase
      .post(`/event/eventAttendance`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getEventsNames(body = {}) {
    return proxybase
      .post(`/event/getEventsNames`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  AddAditionalInfo(body) {
    return proxybase
      .post(`/event/addAditionalInfo`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  SetPaymentInfo(body) {
    return proxybase
      .post(`/event/SetPaymentInfo`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  GenCertificate(body) {
    return proxybase
      .post(`/event/certificate`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  GeneracionesParentMatch(body) {
    return proxybase
      .post(`/event/GeneracionesParentMatch`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  setCoordinatorReports(body) {
    return proxybase
      .post(`/experience/setCoordinatorReports`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  GetCoordinatorReport(body) {
    return proxybase
      .post(`/experience/getCoordinatorReports`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const eventService = new EventService();
export default eventService;
