import React from 'react';
import Form from '../../../components/form/Form';
import Swal from 'sweetalert2';
import membersForms from '../../../services/membersForms';
import { Checkbox, Button, TextArea, Input } from 'semantic-ui-react';
import { errorInput, textArea, formInput, localhostname } from '../../../components/helpers';
import ReCAPTCHA from 'react-google-recaptcha';
import SignatureCanvas from 'react-signature-canvas';
import CounselingDocuments from './counselingOfficialDoc';

const CounselingForm = (props) => {
  const [Attendant, set_Attendant] = React.useState({});
  const [errorInputs, set_errorInputs] = React.useState({});
  const [sent, set_sent] = React.useState(false);
  const [sending, set_sending] = React.useState(false);
  const [secondStep, set_secondStep] = React.useState(false);
  let canvasSign = {};

  const verifyForm = () => {
    let mandatoryInputs = [
      'name',
      'lastName',
      'identification',
      'phone',
      'email',
      'gender',
      'address',
    ];

    if (window.location.hostname !== localhostname) {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputs = {};

    if (secondStep) {
      errorInputs = errorInput({
        errorInputs,
        input: { name: 'sign', value: !canvasSign.isEmpty() },
      });
    }

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: Attendant[input] },
      });
    });

    if (Attendant['identification'] && Attendant['identification'].length <= 5) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'identification',
          value: Attendant['identification'].length >= 5,
        },
      });
    }

    if (Attendant['phone'] && Attendant['phone'].length <= 10) {
      errorInputs = errorInput({
        errorInputs,
        input: {
          name: 'phone',
          value: Attendant['phone'].length >= 10,
        },
      });
    }

    if (window.location.hostname !== localhostname) {
      if (Attendant['captcha'] && Attendant['captcha'] !== 'null') {
        errorInputs = errorInput({
          errorInputs,
          input: {
            name: 'captcha',
            value: Attendant['captcha'].length >= 10,
          },
        });
      }
    }

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  let sendForm = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    const sign = JSON.stringify(canvasSign.toData().flat());

    set_sending(true);
    let body = { ...Attendant, sign };
    membersForms
      .newCounseling(body)
      .then((response) => {
        if (response.data.success) {
          set_sending(false);
          set_sent(true);
          Swal.fire({
            icon: 'success',
            title: '¡Se ha enviado correctamente!',
            text: 'Gracias por haberte registrado, en un periodo de 5 a 8 dias te será asignado el consejero.',
          });
        } else {
          Swal.fire('Hubo un error, por favor intenta mas tarde.');
        }
      })
      .catch((error) => {
        Swal.fire('Hubo un error, por favor intenta mas tarde.');
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    set_Attendant({ ...Attendant, [name]: value });
  };

  const handleCheckers = (name) => set_Attendant({ ...Attendant, [name]: !Attendant[name] });

  function captchaOnChange(value) {
    set_Attendant({ ...Attendant, captcha: value });
  }

  const formConsejeria = () =>
    !sent ? (
      <>
        <h1>Formulario Necesito Ayuda</h1>
        {!secondStep ? (
          <>
            <p>Llena la siguiente información y nos pondremos en contacto contigo.</p>
            {Object.values(errorInputs).includes(true) && (
              <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
            )}
            <Form
              Attendant={Attendant}
              handleInputs={handleInputs}
              errorInputs={errorInputs}
              showGender
            />
            {formInput({
              Input,
              handler: handleInputs,
              label: 'Dirección',
              name: 'address',
              value: Attendant.address || '',
              errorInputs,
            })}
            <div className="checks">
              <Checkbox
                label="¿Asistes actualmente a un grupo de Comunidad?"
                name="attendingGroup"
                toggle
                checked={Attendant.attendingGroup}
                onClick={() => handleCheckers('attendingGroup')}
              />
            </div>
            {textArea({
              TextArea,
              label: 'Por favor, escribe con detalle la ayuda que necesitas',
              name: 'counselingReason',
              handler: handleInputs,
              value: Attendant.counselingReason,
              errorInputs,
            })}
            {textArea({
              TextArea,
              label: 'Tienes alguna petición de Oración',
              name: 'toPray',
              handler: handleInputs,
              value: Attendant.toPray,
              errorInputs,
            })}
          </>
        ) : (
          <>
            <p>Revisa el siguiente documento, firmalo y presiona en enviar.</p>
            <CounselingDocuments
              Attendant={Attendant}
              canvasSign={canvasSign}
              signComponent={(name) => {
                if (name === 'user')
                  return (
                    <SignatureCanvas
                      penColor="black"
                      ref={(ref) => {
                        canvasSign = ref;
                      }}
                      canvasProps={{
                        width: 300,
                        height: 200,
                        className: `sigCanvas  ${errorInputs['sign'] ? 'error' : ''}`,
                      }}
                    />
                  );
              }}
              errorInputs={errorInputs}
            />
            <p className="habeasdata">
              Al enviar aceptas el tratamiento de datos de Iglesia Comunidad Cristiana de Fe y
              Viento Fresco.
            </p>
            <Button onClick={(e) => sendForm(e)} primary disabled={sending}>
              Enviar
            </Button>
          </>
        )}
        <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
          <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA} onChange={captchaOnChange} />
        </div>
        <p style={{ color: 'red' }}>
          {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
        </p>
        {Object.values(errorInputs).includes(true) && (
          <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            if (verifyForm()) return;
            set_secondStep(!secondStep);
          }}
          style={{ display: 'block', margin: '0 auto' }}
          disabled={sending}
        >
          {secondStep ? 'Regresa a corregir' : 'Continua'}
        </Button>
      </>
    ) : (
      <>
        <h1>¡Gracias por contactarnos {Attendant.name}!</h1>
        <p>Muy pronto estaremos en contacto contigo.</p>
      </>
    );
  return (
    <div className="counseling">
      <form className="Registration__form">
        <p>Hola</p>
        <p>Este formulario esta temporalmente cerrado.</p>
        <p>Por favor, escribenos si tienes alguna pregunta.</p>
        {false && formConsejeria()}
      </form>
    </div>
  );
};

export default CounselingForm;
