import React, { useState } from 'react';
import { Button, Modal, Select } from 'semantic-ui-react';
import QRCode from 'qrcode.react';
import {
  formSelectService,
  generacionesServices,
  sortObjects,
} from '../../../../../../components/helpers';
import EventService from '../../../../../../services/eventService';
import generacionesService from '../../../../../../services/generacionesService';
import moment from '../../../../../../services/moment_instance';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import CancelReservationModal from './cancelGeneracionesReservation';
import ConfirmReservationModal, {
  confirmReservationGeneraciones,
  printEntry,
} from './confirmGeneracionesReservation';
import authService from '../../../../../../services/auth';

const RegisterToAService = (props) => {
  const { kid, parent, getInfo, preOpen } = props;
  const [sending, set_sending] = useState(false);
  const [open, setOpen] = useState(false);
  const [services, set_services] = React.useState({});
  const [loading, set_loading] = React.useState(false);
  const [inputs, setInputs] = React.useState({});
  const [previousEventsNames, set_previousEventsNames] = React.useState([]);

  const currentUser = authService.getCurrentUser();

  let query = window.location.search;
  let parsedQueryString = queryString.parse(query);

  const defaultEventSlugname = parsedQueryString.eventSlugname
    ? parsedQueryString.eventSlugname
    : 'Auditorio_Principal';

  React.useEffect(() => {
    getPreviousEventNames();
    getEvents();
  }, []);

  React.useEffect(() => {
    if (preOpen) setOpen(preOpen);
  }, [preOpen]);

  React.useEffect(() => {
    setInputs({
      ...inputs,
      generacionesCategory: generacionesCategory(kid.birthdate),
      eventSlugname: defaultEventSlugname,
    });
  }, [open]);

  const age = (birthdate) => moment().diff(birthdate, 'years');

  const generacionesCategory = (birthdate) => {
    let genCat = parsedQueryString?.category;
    if (age(birthdate) <= 3) genCat = 'Generaciones_de_0_a_3';
    if (age(birthdate) >= 4 && age(birthdate) <= 6) genCat = 'Generaciones_de_4_a_6';
    if (age(birthdate) >= 7 && age(birthdate) <= 9) genCat = 'Generaciones_de_7_a_9';
    if (age(birthdate) >= 10) genCat = 'Generaciones_de_10_a_13';
    if (localStorage.getItem('userToken') && parsedQueryString?.category)
      genCat = parsedQueryString?.category;

    return genCat;
  };

  const getPreviousEventNames = () => {
    const body = {
      prevDays: 1,
      postDays: 6,
      sundayServices: true,
    };
    set_loading(true);
    EventService.getEventsNames(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  function nextStep(service) {
    set_sending(true);
    const body = {
      generaciones_guest_id: kid.id,
      parent_guest_identification: parent.identification,
      service,
      generacionesCategory: generacionesCategory(kid.birthdate),
    };

    generacionesService
      .MakeAGeneracionesReservation(body)
      .then((response) => {
        set_sending(false);
        if (response.data.success) {
          if (response.data.created) {
            confirmReservationGeneraciones(
              inputs.eventSlugname || defaultEventSlugname,
              getInfo,
              kid,
              parent,
              set_loading
            );
          }
        }
      })
      .catch((err) => {
        set_sending(false);
      });
  }

  const getEvents = () => {
    set_loading(true);

    let body = {
      eventSlugname: inputs?.eventSlugname || defaultEventSlugname,
    };

    if (!!localStorage.getItem('userToken') && currentUser?.profile !== 1) {
      body = {
        ...body,
        startDate: moment.tz('America/Bogota')?.subtract(45, 'minutes')?.toDate(),
        endDate: moment.tz('America/Bogota')?.endOf('day')?.toDate(),
        dates_strict: true,
      };
    }

    EventService.getAuditoriumEvents(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          let events = {};
          if (response.data.events.length !== 0)
            sortObjects(response.data.events, 'date', 'asc').forEach((event) => {
              events[getDate(event.date)] = [...(events[getDate(event.date)] || []), event];
            });
          set_services(events);
          set_sending(false);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const getDate = (date) => moment.tz(date, 'America/Bogota').format('YYYY-MM-DD');

  const selectHandle = (a, { value, name }) => {
    setInputs({ ...inputs, [name]: value });
  };

  React.useEffect(() => {
    getEvents();
  }, [inputs.eventSlugname]);

  const verifyInscription = () => {
    try {
      const { GeneracionesEventsGuests } = kid;

      if (GeneracionesEventsGuests.length === 0) {
        return (
          <>
            <p style={{ fontWeight: 'bold' }}>
              Escoge el servicio en el que vas a inscribir el niño
            </p>
            <div>
              {formSelectService({
                Select,
                label: 'Auditorio',
                name: 'eventSlugname',
                handler: selectHandle,
                value: inputs.eventSlugname || defaultEventSlugname,
                options: previousEventsNames,
              })}
            </div>
            <div>
              {formSelectService({
                Select,
                label: 'Categoria',
                name: 'generacionesCategory',
                handler: selectHandle,
                value: inputs.generacionesCategory || generacionesCategory(kid.birthdate),
                options: generacionesServices,
              })}
            </div>
            {loading && <p>Cargando servicios</p>}
            {Object.keys(services).map((date) => (
              <>
                <p style={{ padding: '20px 0 0 0' }}>
                  {moment.tz(date, 'America/Bogota').format('dddd DD [de] MMMM')}
                </p>
                {services[date].map((service) => (
                  <Button
                    loading={sending}
                    primary
                    disabled={loading}
                    onClick={(e) => nextStep(service)}
                  >
                    <div className="time">
                      {moment.tz(service.date, 'America/Bogota').format('hh:mm a')}
                    </div>
                  </Button>
                ))}
              </>
            ))}
            {!loading && Object.keys(services).length === 0 && (
              <p style={{ textAlign: 'center', padding: '20px 0 0 0' }}>
                No hay reuniones disponibles
              </p>
            )}
          </>
        );
      }
      if (GeneracionesEventsGuests[0] && GeneracionesEventsGuests[0].AuditoriumEvent)
        return (
          <>
            <p>Registrado para este fin de semana</p>
            <p style={{ fontWeight: 'bold', fontSize: '25px', margin: 0 }}>
              {kid.name} {kid.lastName}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Edad: </span>
              {age(kid.birthdate)} años
            </p>
            <div>
              <div style={{ width: '100px', display: 'inline-block' }}>
                {parent.photo ? <img src={parent.photo} alt="" width={100} /> : 'Sin Foto'}
              </div>
              <div style={{ display: 'inline-block', padding: '0 0 0 20px', verticalAlign: 'top' }}>
                <p style={{ margin: 0 }}>
                  <span style={{ fontWeight: 'bold' }}>Acudiente:</span> {parent.name}{' '}
                  {parent.lastName}
                </p>
                <p style={{ margin: 0 }}>
                  <span style={{ fontWeight: 'bold' }}>Cedula:</span> {parent.identification}
                </p>
                <p style={{ margin: 0 }}>
                  <span style={{ fontWeight: 'bold' }}>Celular:</span> {parent.phone}
                </p>
              </div>
            </div>
            <p style={{ fontWeight: 'bold', margin: 0 }}>
              {GeneracionesEventsGuests[0].generacionesCategory?.replace(/_/g, ' ')}
            </p>
            <p>
              {moment
                .tz(GeneracionesEventsGuests[0].AuditoriumEvent?.date, 'America/Bogota')
                .format('dddd DD [de] MMMM hh:mm a')}
            </p>
            {localStorage.getItem('userToken') ? (
              <>
                {!GeneracionesEventsGuests?.[0]?.entryConfirmed ? (
                  <ConfirmReservationModal
                    kid={kid}
                    eventSlugname={inputs.eventSlugname || defaultEventSlugname}
                    getInfo={getInfo}
                    parent={parent}
                  />
                ) : (
                  <Button
                    onClick={() =>
                      printEntry(
                        kid,
                        parent,
                        moment
                          .tz(GeneracionesEventsGuests[0].AuditoriumEvent?.date, 'America/Bogota')
                          .format('dddd DD [de] MMMM hh:mm a'),
                        GeneracionesEventsGuests[0].generacionesCategory
                      )
                    }
                  >
                    Imprimir de nuevo
                  </Button>
                )}
              </>
            ) : (
              <>
                <div>
                  <QRCode
                    value={`${process.env.REACT_APP_HOSTNAME}/servicio/generaciones?identification=${parent.identification}&genKidId=${kid.id}`}
                  />
                </div>
              </>
            )}
            {GeneracionesEventsGuests?.[0]?.entryConfirmed && (
              <p>
                <span style={{ fontWeight: 'bold' }}>Entrada marcada </span>
                {moment
                  .tz(GeneracionesEventsGuests?.[0]?.entryConfirmed, 'America/Bogota')
                  .format('dddd DD [de] MMMM hh:mm a')}
              </p>
            )}
            {(!GeneracionesEventsGuests?.[0]?.entryConfirmed ||
              (!!localStorage.getItem('userToken') && currentUser?.profile === 1)) && (
              <CancelReservationModal
                kid={kid}
                eventSlugname={inputs.eventSlugname || defaultEventSlugname}
                getInfo={getInfo}
              />
            )}
          </>
        );
    } catch {
      return <p>Error</p>;
    }
  };

  return (
    <>
      {kid?.GeneracionesEventsGuests?.[0]?.AuditoriumEvent ? (
        <Button primary onClick={() => setOpen(!open)}>
          <p>Ver</p>
        </Button>
      ) : (
        <Button
          primary
          onClick={() => {
            currentUser?.profile === 1
              ? setOpen(!open)
              : nextStep(Object.values(services || {})?.[0]?.[0]);
          }}
        >
          <p>Inscribir</p>
        </Button>
      )}

      {open && (
        <Modal
          size="small"
          className="register generaciones"
          open={open}
          onClose={() => setOpen(false)}
        >
          <Modal.Header>Inscribir a un servicio</Modal.Header>
          <Modal.Content>{verifyInscription()}</Modal.Content>
          <Modal.Actions>
            <Button color="black" loading={sending} onClick={() => setOpen(false)}>
              Volver
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
};

export default withRouter(RegisterToAService);
