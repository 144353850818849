import React from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Input, Select } from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import EventService from '../../../../services/eventService';
import {
  errorInput,
  eventSlugnameTrim,
  clickableStyles,
  formSelectService,
  avoCaptConstructor,
  localhostname,
} from '../../../../components/helpers';
import moment from 'moment';
import Swal from 'sweetalert2';
import { habeasData } from '../../../../components/helpers/habeasData';
import { withRouter } from 'react-router-dom';

const ReservationStepCheck = (props) => {
  const {
    inputs,
    setInputs,
    setStep,
    defaulteventSlugname,
    isAudPpal,
    services,
    loading,
    set_loading,
  } = props;
  const [errorInputs, set_errorInputs] = React.useState({});
  const [changeEvent, set_changeEvent] = React.useState(false);
  const [previousEventsNames, set_previousEventsNames] = React.useState([]);

  React.useEffect(() => {
    getPreviousEventNames();
  }, []);

  React.useEffect(() => {
    if (inputs.identification && inputs.phone && inputs.ac)
      enterReservation({ preventDefault: () => {} });
  }, [inputs.identification, inputs.phone]);

  const verifyForm = () => {
    let mandatoryInputs = ['identification', 'phone'];

    if (
      window.location.hostname !== localhostname &&
      parseInt(inputs.ac) !== avoCaptConstructor(inputs.identification)
    ) {
      mandatoryInputs = [...mandatoryInputs, 'captcha'];
    }

    let errorInputsCheck = {};

    mandatoryInputs.forEach((input) => {
      errorInputsCheck = errorInput({
        errorInputs: errorInputsCheck,
        input: { name: input, value: inputs[input] },
      });
    });

    if (
      window.location.hostname !== localhostname &&
      parseInt(inputs.ac) !== avoCaptConstructor(inputs.identification)
    ) {
      if (inputs['captcha'] && inputs['captcha'] !== 'null') {
        errorInputsCheck = errorInput({
          errorInputs: errorInputsCheck,
          input: {
            name: 'captcha',
            value: inputs['captcha'].length >= 10,
          },
        });
      }
    }

    if (inputs['identification'] && inputs['identification'].length <= 5) {
      errorInputsCheck = errorInput({
        errorInputs: errorInputsCheck,
        input: {
          name: 'identification',
          value: inputs['identification'].length >= 5,
        },
      });
    }

    if (inputs['phone'] && inputs['phone'].length <= 10) {
      errorInputsCheck = errorInput({
        errorInputs: errorInputsCheck,
        input: {
          name: 'phone',
          value: inputs['phone'].length >= 10,
        },
      });
    }

    set_errorInputs(errorInputsCheck);
    return Object.values(errorInputsCheck).includes(true);
  };

  const age = (birthdate) => moment().diff(birthdate, 'years');

  const enterReservation = (e) => {
    e.preventDefault();
    if (verifyForm()) return;
    if (Object.keys(services).length === 0 && !loading) {
      set_changeEvent(true);
      return Swal.fire(
        'No hay eventos disponibles en este momento',
        'Verifica el enlace, o selecciona uno de los eventos disponibles',
        'warning'
      );
    }
    set_loading(true);

    let body = {
      ...inputs,
      eventSlugname: defaulteventSlugname(),
    };

    if (String(inputs.phone).substring(0, 2) === '57') {
      body = {
        ...body,
        phone: String(inputs.phone).substring(2, 15),
      };
    }

    if (defaulteventSlugname().includes('eraciones'))
      body = { ...body, doubleCheck: defaulteventSlugname(true) };

    EventService.CheckAuditoriumInscription(body)
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          let info = {};
          if (response.data.finded && response.data.guest) {
            info = { ...response.data.guest, ...info, age: age(response.data.guest.birthdate) };
          }

          if (response.data.allReservations)
            info = { allReservations: response.data.allReservations, ...info };

          if (response.data.groupLeader) info = { groupLeader: response.data.groupLeader, ...info };

          if (response.data.volunteer) info = { ...response.data.volunteer, ...info };

          if (response.data.reserved) {
            info = { ...response.data.reserved, ...info };

            if (response.data.seat) {
              info = { selected: response.data.seat, ...info };
            }
          }
          if (response.data.quarantine) {
            const { emergencyContact, emergencyNumber } = response.data.quarantine;
            info = { emergencyContact, emergencyNumber, ...info };
          }
          setInputs({ ...inputs, ...info });

          if (response.data?.reserved?.id) {
            setStep('summary');
          } else {
            setStep('FillData');
          }
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  function captchaOnChange(value) {
    setInputs({ ...inputs, captcha: value });
  }

  const selectHandle = (a, { value }) => {
    if (value === 'Servicio_del_fin_de_semana') value = 'Auditorio_Principal';
    props.history.push(`/servicio/reservacion?event=${value}`);
    setInputs({ ...inputs, eventSlugname: value });
    set_changeEvent(false);
  };

  const getPreviousEventNames = () => {
    set_loading(true);
    EventService.getEventsNames({ postDays: 360 })
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          set_previousEventsNames(response.data.eventsNames);
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  return (
    <div className="service">
      <h1>¡Bienvenido!</h1>
      <h2>
        Regístrate en un{isAudPpal ? 'a' : 'o'} de nuestr{isAudPpal ? 'a' : 'o'}s{' '}
        {isAudPpal ? 'reuniones' : 'eventos'}
      </h2>
      <form onSubmit={(e) => enterReservation(e)} className="Registration__form">
        <label style={{ display: 'block', padding: '20px', fontSize: '35px' }}>
          Escribe tus datos
        </label>
        {!!defaulteventSlugname() && !isAudPpal && (
          <p>
            Reservando para <strong>{eventSlugnameTrim(defaulteventSlugname())}</strong>
          </p>
        )}
        {isAudPpal && (
          <>
            <p>
              Reservando para <strong>servicio del fin de semana</strong>
            </p>
            <p>
              Si deseas asistir a nuestro Campus Medellín para servicio del fin de semana, ya no
              tienes que registrarte.
            </p>
          </>
        )}
        {!changeEvent ? (
          <p style={{ ...clickableStyles, margin: 0 }} onClick={() => set_changeEvent(true)}>
            <small>¿Quieres reservar para otro evento?</small>
          </p>
        ) : (
          <div>
            {formSelectService({
              Select,
              label: 'Evento a reservar',
              name: 'eventSlugname',
              handler: selectHandle,
              value: inputs.eventSlugname,
              options: ['Servicio_del_fin_de_semana', ...previousEventsNames],
            })}
          </div>
        )}
        {Object.values(errorInputs || {}).includes(true) && (
          <h4 style={{ color: 'red' }}>Por favor verifica los campos en rojo.</h4>
        )}
        <Input
          style={{ width: '33%', margin: '5px' }}
          onChange={handleInputs}
          name={'identification'}
          value={inputs.identification}
          type="number"
          placeholder="Identificación"
          id="identification"
          className={`Registration__input Registration__input-${'identification'} ${
            errorInputs['identification'] ? 'error' : ''
          }`}
        />
        <Input
          placeholder="Celular"
          id="phone"
          name={'phone'}
          type="number"
          className={`Registration__input Registration__input-${'phone'} ${
            errorInputs['phone'] ? 'error' : ''
          }`}
          value={inputs.phone}
          onChange={handleInputs}
          style={{ width: '33%', margin: '5px' }}
        />
        <div className={`captcha ${errorInputs.captcha ? 'error' : ''}`}>
          <ReCAPTCHA
            style={{ margin: 'auto' }}
            sitekey={process.env.REACT_APP_RECAPTCHA}
            onChange={captchaOnChange}
          />
        </div>
        <p style={{ color: 'red' }}>
          {errorInputs.captcha ? 'Por favor resuelve el captcha.' : ''}
        </p>
        {habeasData}
        <Button primary disabled={loading} onClick={(e) => enterReservation(e)}>
          Entrar
        </Button>
      </form>
    </div>
  );
};

export default withRouter(ReservationStepCheck);
