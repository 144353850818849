import { Button, Card, Icon } from 'semantic-ui-react';

const ComunidadNetworkCard = (props) => {
  const { network, set_showComunidadNetwork } = props;

  const { id, businessName, businessPhone, serviceOffered } = network;

  return (
    <Card className="comunidad-cart">
      <Card.Content>
        <Card.Header>{businessName}</Card.Header>
        <Card.Meta>
          <Icon name="phone" />
          {businessPhone}
        </Card.Meta>
        <Card.Description>
          {serviceOffered.substring(0, 100)}
          {serviceOffered.length > 99 && '...'}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button basic color="green" onClick={() => set_showComunidadNetwork(id)}>
            Ver más
          </Button>
        </div>
      </Card.Content>
    </Card>
  );
};

export default ComunidadNetworkCard;
