import proxybase from './proxybase';

class crecerForms {
  getCrecerP1C3Form({ identification = '', body }) {
    return proxybase
      .post(`/crecer/crecerP1C3/${identification}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getTutorsCrecerP1C3Form(body) {
    return proxybase
      .post(`/crecer/tutorsCrecerP1C3`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  setTutorReview(body) {
    return proxybase
      .post(`/crecer/setTutorReview`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getTutors(students = '', body = {}) {
    return proxybase
      .post(`/crecer/tutorsCrecer/${students}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  CrecerP1C3Form(body) {
    return proxybase
      .post(`/crecer/crecerP1C3Form`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  RepeatCrecerP1C3Form(body, identification) {
    return proxybase
      .post(`/crecer/repeat-crecerP1C3/${identification}`, body)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
const crecerFormsInstance = new crecerForms();
export default crecerFormsInstance;
