import { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import groupService from '../../../services/groupService';
import { Input, Button } from 'semantic-ui-react';
import { changeDocTitle } from '../../../components/helpers';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import Table from '../../../components/table/aggrid';
import { prospectLeadersRowDef } from '../../common/adminTable/ProspectLeadersRow';
import ProspectAndLeaderAnswers from './prospectAndLeaderAnswers';

const AllProspectLeaders = (props) => {
  const [ProspectLeaders, set_ProspectLeaders] = useState([]);
  const [loading, set_loading] = useState(true);
  const [inputs, setInputs] = useState({});
  const [showProspectForm, set_showProspectForm] = useState(null);

  let query = props.location.search;
  let parsedQueryString = queryString.parse(query);

  useEffect(
    () => getInfo(),
    [inputs.serviceArea, inputs.volunteerRole, inputs.ProspectLeadersection]
  );

  useEffect(() => {
    changeDocTitle('Aspirantes a Lideres');
  }, []);

  const onEnter = (e) => {
    if (e.key === 'Enter') getInfo();
  };

  const serviceArea = inputs.serviceArea || '';
  const volunteerRole = inputs.volunteerRole || '';
  const ProspectLeadersection = inputs.ProspectLeadersection || '';

  function getInfo() {
    set_loading(true);

    let body = {
      keyword: inputs.keyword || parsedQueryString.keyword,
      volunteerRole,
      ProspectLeadersection,
    };

    groupService
      .getAllProspectLeaders({ body, serviceArea })
      .then((response) => {
        if (response.data.success) {
          set_loading(false);
          props.history.push('?keyword=');
          set_ProspectLeaders(response.data.prospectLeaders);
        } else {
          set_loading(null);
        }
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  }

  if (showProspectForm) {
    return (
      <ProspectAndLeaderAnswers
        answers={ProspectLeaders?.find((form) => form.id === showProspectForm)}
        getInfo={getInfo}
        set_showAnswers={set_showProspectForm}
      />
    );
  }

  return (
    <section className="AdminTable">
      <div className="AdminTable__content">
        <div>
          <Link to="/admin?folder=ProspectLeaders">
            <Button primary>Atrás</Button>
          </Link>
          <Button primary disabled={loading} onClick={() => getInfo()}>
            Recargar
          </Button>
        </div>
        <Input
          loading={loading}
          name={'keyword'}
          onChange={(e) => setInputs({ ...inputs, keyword: e.target.value })}
          value={inputs.keyword}
          onKeyPress={onEnter}
          style={{ width: '45%' }}
          placeholder="Buscar..."
        />
      </div>

      <div>
        <Table
          rowData={ProspectLeaders}
          rowHeight={50}
          columnDefs={prospectLeadersRowDef({
            showProspectForm,
            set_showProspectForm,
            loading,
            set_loading,
            getInfo,
          })}
          defaultCol={{
            suppressMovable: true,
          }}
          pagination={true}
        />
      </div>
    </section>
  );
};

export default AllProspectLeaders;
