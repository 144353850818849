import styled from '@emotion/styled';

export const HomeComponent = styled.div`
  .admin {
    align-items: center;
    background-color: white;
    border-radius: 22px;
    grid-column-gap: 1rem;
    grid-template-areas: 'content buttons' 'link link';
    max-width: 80vw;
    padding: 1.1rem;
    * {
      text-align: center;
    }
    button {
      margin: 5px !important;
    }
  }
  background-color: white;
  border-radius: 12px;
  max-width: 80vw;
  padding: 1.1rem;

  .main {
    display: flex;
  }

  .buttons {
    text-align: center;
  }

  .title {
    color: #2596c2;
    font-size: 2.5rem;
  }

  .button {
    background-color: #2596c2;
    border-radius: 0.6rem;
    color: white;
    display: flex;
    margin: 1rem auto;
    max-width: 300px;
    height: 47px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    transition: 300ms;
    &:hover {
      background-color: black;
    }
  }
  .link {
    text-align: center;
    border-top: 0.5px solid lightgray;
    padding: 20px 0 5px 0;
    margin: 10px 0 0 0;
    align-self: end;
    a {
      color: gray;
    }
  }

  .PublicHome {
    background-color: white;
    border-radius: 22px;
    padding: 20px 30px;

    .title {
      color: #2596c2;
      font-size: 2.5rem;
    }

    .content {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding: 0 20px 0 0;
    }
    .habeas {
      font-size: 12px;
      padding: 10px 0 0 0;
      margin: 10px 0 0 0;
    }
  }
  @media only screen and (max-width: 600px) {
    justify-content: center;
    .PublicHome {
      padding: 0;
      width: 100%;
      .main {
        display: block;
      }
      .content {
        width: 100%;
        padding: 20px 0 0 0;
      }
    }
  }
`;
