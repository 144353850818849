import React from 'react';
import ReactToPrint from 'react-to-print';

import moment from '../../services/moment_instance';

const StandardCertificateIMG = (props) => {
  const { inputs, certificateIMG, sectionStyle } = props;
  const { field1Style, field2Style, field3Style, date1FieldStyles, date2FieldStyles } = inputs;
  let printable = React.createRef();

  const sectionStyles = {
    backgroundSize: 'cover',
    width: '806px',
    position: 'relative',
    margin: '0 auto',
    height: '603px',
    backgroundPosition: 'left top',
    ...(sectionStyle || {}),
  };

  const field1Styles = {
    textAlign: 'center',
    fontSize: '25px',
    position: 'absolute',
    top: '286px',
    width: '100%',
    color: 'white',
    margin: 0,
    ...(field1Style || {}),
  };

  const field2Styles = {
    textAlign: 'center',
    fontSize: '15px',
    position: 'absolute',
    top: '326px',
    width: '100%',
    margin: 0,
    color: 'white',
    ...(field2Style || {}),
  };
  const field3Styles = {
    textAlign: 'center',
    fontSize: '15px',
    position: 'absolute',
    top: '326px',
    width: '100%',
    margin: 0,
    color: 'white',
    ...(field3Style || {}),
  };

  const date1FieldStyle = {
    textAlign: 'center',
    fontSize: '15px',
    position: 'absolute',
    top: '326px',
    width: '100%',
    margin: 0,
    color: 'white',
    ...(date1FieldStyles || {}),
  };

  const date2FieldStyle = {
    textAlign: 'center',
    fontSize: '15px',
    position: 'absolute',
    top: '326px',
    width: '100%',
    margin: 0,
    color: 'white',
    ...(date2FieldStyles || {}),
  };

  const printButton = () => (
    <ReactToPrint
      trigger={() => (
        //eslint-disable-next-line
        <a
          href="#"
          onClick={(e) => e.preventDefault()}
          style={{ textDecoration: 'underline', zIndex: 2 }}
        >
          Imprimir o guardar en pdf
        </a>
      )}
      content={() => printable.current}
    />
  );

  const setADate = (date, format = 'DD [de] MMMM [de] yyyy') => {
    if (date) return moment.tz(date, 'America/Bogota').format(format);
    return '';
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ margin: '10px 0' }}>{printButton()}</div>
      <div ref={printable} className="blinded">
        <section style={sectionStyles}>
          <img
            src={certificateIMG}
            height="603px"
            width="806px"
            alt="Error al traer el certificado"
          />
          <p style={field1Styles}>{inputs.field1}</p>
          <p style={field2Styles}>{inputs.field2}</p>
          <p style={field3Styles}>{inputs.field3}</p>
          <p style={date1FieldStyle}>
            {setADate(
              inputs.date1Field,
              inputs.date2Field ? 'DD [de] MMMM' : 'DD [de] MMMM [de] yyyy'
            )}
          </p>
          <p style={date2FieldStyle}>{setADate(inputs.date2Field)}</p>
        </section>
      </div>
    </div>
  );
};
export default StandardCertificateIMG;
