import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { churchMinistries } from '../../../components/helpers';

const ServiceAreaTable = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 15px 0;
  }
  th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: 400;
    padding: 10px;
  }
  td {
    padding: 10px;
    border-bottom: 1px solid #f2f2f2;
  }
  tr:hover {
    background-color: #f9f9f9;
  }
`;

const VolunteersServiceAreaCount = (props) => {
  const { volunteers } = props;

  const allMinistriesCount = churchMinistries?.reduce(
    (acc, ministry) => ({ ...acc, [ministry]: 0 }),
    {}
  );

  const countAreas = useMemo(() => {
    return volunteers.reduce((acc, curr) => {
      const area = curr.Volunteer.serviceArea;
      return { ...acc, [area]: (acc[area] || 0) + 1 };
    }, allMinistriesCount);
  }, [volunteers]);

  return (
    <ServiceAreaTable>
      <table>
        <thead>
          <tr>
            <th>Area</th>
            <th>Cuenta</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(countAreas).map(([key, val])=> 
          <tr key={0}>
            <td>{key}</td>
            <td>{val}</td>
          </tr>

          )}
        </tbody>
      </table>
    </ServiceAreaTable>
  );
};

export const VolunteersServiceAreaModal = (props) => {
  const { volunteers } = props;
  const [open, setOpen] = useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => 
        setOpen(true)
      }
      open={open}
      trigger={<Button>Ver la cuenta de las areas</Button>}
    >
      <Modal.Header>Ver cuenta de las areas por voluntario</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <VolunteersServiceAreaCount volunteers={volunteers} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </Modal.Actions>
    </Modal>
  );
};
