import { Segment, Card, Header } from 'semantic-ui-react';
import { weekdays } from '../../helpers';
import moment from 'moment';
import { groupTitle } from './memberLogGroup';

const GroupSummary = (props) => {
  const { inputs } = props;
  const ChurchGroup = inputs?.groupMember?.ChurchGroup;
  const params = props.match.params;

  const attendantsNames = (
    <strong>
      {inputs.Attendants?.[0] &&
        `${inputs.Attendants?.[0].name} ${inputs.Attendants?.[0].lastName}`}
      {inputs.Attendants?.[1] &&
        ` y ${inputs.Attendants?.[1].name} ${inputs.Attendants?.[1].lastName}`}
      !
    </strong>
  );

  const leaderNames = (
    <>
      {ChurchGroup?.coupleleadered ? 'Los lideres son ' : 'El líder es '}
      <span style={{ fontWeight: 'bold' }}>
        {!!ChurchGroup?.maleLeader?.name &&
          `${ChurchGroup?.maleLeader?.name} ${ChurchGroup?.maleLeader?.lastName}`}
        {!!ChurchGroup?.coupleleadered && ' y '}
        {!!ChurchGroup?.femaleLeader?.name &&
          `${ChurchGroup?.femaleLeader?.name} ${ChurchGroup?.femaleLeader?.lastName}`}
      </span>
    </>
  );

  if (inputs?.groupMember?.pending) {
    return (
      <Segment className="selectedGroup">
        <div>
          <Header as="h2"> {groupTitle(params)}</Header>
          <p>¡Hola {attendantsNames}</p>
          <p>
            Ya estas preinscrito al grupo: <strong>{ChurchGroup?.groupName}</strong>
          </p>
          <p style={{ margin: '20px 0' }}>{leaderNames}</p>
          <p>
            {ChurchGroup?.coupleleadered ? 'Los lideres ' : 'El lider '}del grupo hará
            {!!ChurchGroup?.coupleleadered && 'n'} contacto para concretar los detalles.
          </p>
          <p style={{ fontStyle: 'italic', fontSize: '14px' }}>
            Si crees que ha pasado mucho tiempo y el lider no te ha contactado, por favor
            contactanos para ayudarte.
          </p>
        </div>
      </Segment>
    );
  }
  return (
    <Segment className="selectedGroup">
      <div>
        <Header as="h2">{groupTitle(params)}</Header>
        <p style={{ fontSize: '25px', margin: '0 0 20px 0' }}>Hola {attendantsNames}</p>
        <p>
          Grupo al que asistes: <strong>{ChurchGroup?.groupName}</strong>
        </p>
        <p>{leaderNames}</p>
        <Card.Content>
          <p>
            <strong>Dia: </strong>
            {weekdays[ChurchGroup?.dayOfWeek]}
          </p>
          <p>
            <strong>Hora: </strong>
            {moment(ChurchGroup?.time, 'HH:mm').format('hh:mm a z')}
          </p>
          <p>
            <strong>Lugar de reunión: </strong>
            {ChurchGroup?.address} - {ChurchGroup?.neighborhood}
          </p>
          <p>
            <strong>Categoría: </strong>
            {ChurchGroup?.activityName?.replace(/_/g, ' ')}
          </p>
        </Card.Content>
      </div>
    </Segment>
  );
};

export default GroupSummary;
