import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import moment from 'moment';
import EventService from '../../../../services/eventService';
import { errorInput, renderNumberOfTimes } from '../../../../components/helpers';
import CreateServiceForm from './createServiceForm';

const CreateService = (props) => {
  const { inputs, setInputs, getEvents, previousEventsNames, getPreviousEventNames } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, set_loading] = React.useState(false);
  const [errorInputs, set_errorInputs] = React.useState({});

  React.useEffect(() => {
    if (open) getPreviousEventNames();
  }, [open]);

  const verifyForm = () => {
    const mandatoryInputs = ['capacity', 'date', 'time', 'eventSlugname'];

    let errorInputs = {};

    mandatoryInputs.forEach((input) => {
      errorInputs = errorInput({
        errorInputs,
        input: { name: input, value: inputs[input] },
      });
    });

    set_errorInputs(errorInputs);
    return Object.values(errorInputs).includes(true);
  };

  const createEvent = () => {
    if (verifyForm()) return;
    set_loading(true);

    const { numReqFields, confirmDataInfo, summaryInfo } = inputs;

    let extraInfo = [];
    if (numReqFields && inputs.extraInfo) {
      renderNumberOfTimes(numReqFields).forEach((i) => {
        extraInfo.push({
          label: inputs[`extraInfo_${i}`],
          name: inputs[`extraInfo_${i}`].replace(/ /g, '_') + `_${i}`,
          mandatory: Boolean(inputs[`extraInfo_${i}_required`]),
        });
      });
    }

    let body = {
      ...inputs,
      date: `${moment(inputs.date).format('YYYY-MM-DD')} ${inputs.time} -05:00`,
      additionalinformation: { extraInfo, confirmDataInfo, summaryInfo },
    };

    EventService.createEvent(body)
      .then((response) => {
        set_loading(false);
        if (response.data.success) {
          setOpen(false);
          getEvents();
        }
      })
      .catch((err) => {
        set_loading(false);
      });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button secondary>Crear Evento</Button>}
    >
      <Modal.Header>Crear un evento</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <CreateServiceForm
            inputs={inputs}
            setInputs={setInputs}
            errorInputs={errorInputs}
            loading={loading}
            previousEventsNames={previousEventsNames}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loading} onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          content="Crear"
          disabled={loading}
          loading={loading}
          primary
          onClick={() => createEvent()}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateService;
