import React from 'react';
import { Input, Button, Checkbox } from 'semantic-ui-react';
import ChurchServiceGuestsRow from '../../../components/common/adminTable/ChurchServiceGuestsRow';
import { chPerm, eventSlugnameTrim, scrollUp } from '../../../components/helpers';
import moment from '../../../services/moment_instance';
import AddAGuest from './coordinators/addAGuest';

const ChurchServiceGuests = (props) => {
  const { serviceAttendance, set_serviceAttendance, clickedEvent, getEvent, history } = props;
  const [inputs, setInputs] = React.useState({});
  const [aditionalinformationUsers, set_aditionalinformation] = React.useState([]);
  const [paidEvent, set_paidEvent] = React.useState(false);

  React.useEffect(() => {
    scrollUp();
  }, []);

  React.useEffect(() => {
    set_aditionalinformation(
      serviceAttendance.filter((att) => Object.keys(att.aditionalinformation || {}).length)
    );
    if (serviceAttendance.find((att) => Object.keys(att.payment || {}).length)) set_paidEvent(true);
  }, []);

  const handleInputs = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs({ ...inputs, [name]: value });
  };

  const filterUsers = (word, attended, info, payment) => {
    let serviceFiltered = serviceAttendance;
    if (word)
      serviceFiltered = serviceFiltered.filter(
        (att) =>
          att.Guest.name.toLowerCase().includes(word.toLowerCase()) ||
          att.Guest.lastName.toLowerCase().includes(word.toLowerCase()) ||
          att.Guest.identification.includes(word) ||
          att.Guest.phone.includes(word)
      );
    if (attended) serviceFiltered = serviceFiltered.filter((att) => att.attended);
    if (info)
      serviceFiltered = serviceFiltered.filter(
        (att) => Object.keys(att.aditionalinformation || {}).length
      );
    if (payment)
      serviceFiltered = serviceFiltered.filter((att) => Object.keys(att.payment || {}).length);
    return serviceFiltered;
  };

  const inScreen = filterUsers(inputs.keyword, inputs.attended, inputs.info, inputs.payment);
  return (
    <section className="AdminTable" id="network">
      <div className="AdminTable__content">
        <Button onClick={() => set_serviceAttendance([])}>Atrás</Button>
        <Button onClick={() => getEvent(clickedEvent.id, clickedEvent.eventSlugname)}>
          Refrescar
        </Button>
        <h2>Usuarios del servicio</h2>
        {clickedEvent && clickedEvent.eventSlugname && (
          <h3 style={{ textAlign: 'center', margin: '0' }}>
            {eventSlugnameTrim(clickedEvent.eventSlugname)}
          </h3>
        )}
        {clickedEvent && clickedEvent.date && (
          <p style={{ padding: '20px 0 0 0', margin: 0, fontSize: '24px' }}>
            <strong>
              {moment.tz(clickedEvent.date, 'America/Bogota').format('dddd DD [de] MMMM hh:mm a')}
            </strong>
          </p>
        )}
        <p style={{ padding: '20px 0 0 0', margin: 0 }}>
          <strong>Total usuarios:</strong> {serviceAttendance.length}
        </p>
        <p style={{ padding: '0 0 0 0', margin: 0 }}>
          <strong>Total usuarios asistentes:</strong>{' '}
          {serviceAttendance.filter((att) => att.volunteer === null && att.attended).length}
        </p>
        <p style={{ padding: '0 0 0 0', margin: 0 }}>
          <strong>Total usuarios en pantalla:</strong> {inScreen.length}
        </p>
        <div className="options" style={{ padding: '20px 0 0 0', margin: 0 }}>
          <div className="search-query">
            <p style={{ padding: '0 0 0 0' }}>Buscar</p>
            <Input
              name={'keyword'}
              onChange={handleInputs}
              value={inputs.keyword}
              placeholder="Buscar"
            />
          </div>
          <div style={{ display: 'block', width: '100%' }}>
            <Checkbox
              label="Filtrar por asistencia"
              name="attended"
              checked={inputs.attended}
              toggle
              onClick={() => setInputs({ ...inputs, attended: !inputs.attended })}
            />
          </div>
          {aditionalinformationUsers.length !== 0 && (
            <div style={{ display: 'block', width: '100%' }}>
              <Checkbox
                label="Filtrar por asistentes con extras"
                name="generaciones"
                checked={inputs.info}
                toggle
                onClick={() => setInputs({ ...inputs, info: !inputs.info })}
              />
            </div>
          )}
          {paidEvent && (
            <div style={{ display: 'block', width: '100%' }}>
              <Checkbox
                label="Filtrar por asistentes con pagos"
                name="payment"
                checked={inputs.payment}
                toggle
                onClick={() => setInputs({ ...inputs, payment: !inputs.payment })}
              />
            </div>
          )}
        </div>

        <div style={{ margin: '20px 0 0 0' }}>
          {chPerm(['eventAdmin']) && <AddAGuest getEvent={getEvent} event={clickedEvent} />}
        </div>
      </div>

      <table style={{ margin: '20px 0' }}>
        <thead>
          <tr>
            <th>Cedula</th>
            {inputs.volunteer && <th>Foto</th>}
            <th>Nombre</th>
            {<th>Celular</th>}
            {!inputs.info && !inputs.payment && <th>Asistió</th>}
            {aditionalinformationUsers.length !== 0 && <th>Info Extra</th>}
            {paidEvent && <th>Info de Pago</th>}
            <th>Esta semana</th>
          </tr>
        </thead>
        <tbody>
          {inScreen.map((guest) => (
            <ChurchServiceGuestsRow
              guest={guest}
              aditionalinformationUsers={aditionalinformationUsers}
              history={history}
              inputs={inputs}
              onlyVolunteers={inputs.volunteer}
              updateData={() => {
                getEvent(clickedEvent.id);
                handleInputs({ target: { name: 'keyword', value: '' } });
              }}
              paidEvent={paidEvent}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default ChurchServiceGuests;
