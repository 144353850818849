import React from 'react';
import { Modal, Button, Checkbox } from 'semantic-ui-react';
import groupService from '../../../services/groupService';
import Swal from 'sweetalert2';
import CoupleGroupRegistration from '../../../components/groups/registration/groupRegistration';

const ChangeLeadersModal = (props) => {
  const { getInfo, isCouples, groupId } = props;
  const [inputs, setInputs] = React.useState({});
  const [loading, set_loading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [Step, setStep] = React.useState('person1');

  React.useEffect(() => {
    if (Step === 'send') {
      send();
    }
  }, [Step]);

  React.useEffect(() => {
    setInputs({ ...inputs, coupleleadered: Boolean(isCouples) });
  }, [isCouples]);

  const send = (e) => {
    if (e) e.preventDefault();
    if (inputs.coupleleadered && inputs.person1.gender === inputs.person2.gender)
      return Swal.fire('Los lideres deben ser de genero diferente.');

    set_loading(true);

    groupService
      .editGroupLeaders({ ...inputs, groupId })
      .then((response) => {
        if (response.data.success) {
          setOpen(false);
          getInfo();
        }
        if (response.data) Swal.fire(response.data.message);
        set_loading(false);
      })
      .catch((error) => {
        if (error.response) Swal.fire(error.response.data.message);
        set_loading(false);
      });
  };

  const handleCheckers = (name) => setInputs({ ...inputs, [name]: !inputs[name] });

  return (
    <Modal
      onClose={() => setOpen(false)}
      closeIcon
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button basic color="black">
          Cambiar lider{inputs.coupleleadered ? 'es' : ''}
        </Button>
      }
    >
      <Modal.Header> Cambiar lider{inputs.coupleleadered ? 'es' : ''} de grupo</Modal.Header>
      {!loading ? (
        <>
          <div className="selectorCheck" style={{ margin: '20px 0 0 0', textAlign: 'center' }}>
            <label
              style={{
                cursor: 'pointer',
                fontWeight: inputs.coupleleadered ? 'normal' : 'bold',
                padding: '0 10px',
              }}
              htmlFor="coupleleadered"
            >
              Un lider
            </label>
            <Checkbox
              label="Liderado en pareja"
              name="coupleleadered"
              style={{ fontWeight: inputs.coupleleadered ? 'bold' : 'normal' }}
              id="coupleleadered"
              checked={inputs.coupleleadered}
              toggle
              onClick={() => handleCheckers('coupleleadered')}
            />
          </div>

          <CoupleGroupRegistration
            inputs={inputs}
            setInputs={setInputs}
            loading={loading}
            setStep={setStep}
            person="person1"
            next={inputs.coupleleadered ? 'person2' : 'send'}
            strictNext
            leaderView
          />

          {inputs.coupleleadered && Step === 'person2' && (
            <CoupleGroupRegistration
              inputs={inputs}
              loading={loading}
              setInputs={setInputs}
              setStep={setStep}
              person="person2"
              next={'send'}
              strictNext
              leaderView
            />
          )}
        </>
      ) : (
        <p style={{ margin: '20px', textAlign: 'center' }}>Guardando...</p>
      )}
    </Modal>
  );
};

export default ChangeLeadersModal;
